import React, { useEffect,useState } from "react";
import {useJumboApp} from "@jumbo/hooks";
import ParticipantsSection from "./ParticipantsSection/ParticipantsSection"; 
import ChatSection from "./ChatSection/ChatSection";
import MicButton from "./VideoSection/MicButton";
import Videos from "./VideoSection/Videos";
import CameraButton from "./VideoSection/CameraButton";
import LeaveRoomButton from "./VideoSection/LeaveRoomButton"; 
import { connect } from "react-redux";
import {useNavigate} from "react-router-dom";
import { setTwilioAccessToken } from "../../../../store/actions";
import { getTokenFromTwilio, getTime } from "../utils/twilioUtils";
import Overlay from "./Overlay";
import Popover from '@mui/material/Popover'; 
import "./RoomPage.css"; 
import {  
  ChatRounded,
  PeopleRounded, 
} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import JumboIconButton from "@jumbo/components/JumboIconButton";
import { Typography, Grid, DialogTitle,Divider } from "@mui/material"; 
import "./VideoSection/css/meet.css";
import { LocalVideoTrack } from "twilio-video"; 
import LocalScreenSharingPreview from "./VideoSection/LocalScreenSharingPreview";
import {IconButton} from "@mui/material";
import { 
  PresentToAllRounded
} from "@mui/icons-material";
import {LAYOUT_NAMES} from "../../../../layouts/layouts";

const RoomPage = (props) => {
  const {setActiveLayout} = useJumboApp();  
  const { identity, roomId, setTwilioAccessTokenAction, showOverlay } = props;
  const navigate = useNavigate();
  const [room, setRoom] = useState(null);
  const {  connectOnlyWithAudio } = props;
  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
  });
  useEffect(() => {
    if (!identity || !roomId) {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
      navigate('/classes/classdetails/conferencing')      
    } else {
      getTokenFromTwilio(setTwilioAccessTokenAction, identity);
    }
  }, []); 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);

  const handlePeopleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => { 
      setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  const handleMessageClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleMessageClose = () => {
      setAnchorE2(null);
  };

  const open1 = Boolean(anchorE2);

  const id1 = open1 ? 'simple-popover' : undefined;

  //Screen handleScreenSharingEnabling
  const [isScreenSharingActive, setIsScreenSharingActive] = useState(false);
  const [screenShareTrack, setScreenShareTrack] = useState(null);
  const [screenShareStream, setScreenShareStream] = useState(null);

  const handleScreenSharingEnabling = () => {
    // handle screen sharing
    if (!isScreenSharingActive) {
      navigator.mediaDevices
        .getDisplayMedia()
        .then((stream) => {
          setScreenShareStream(stream);
          setIsScreenSharingActive(true);
          const screenTrack = new LocalVideoTrack(stream.getVideoTracks()[0], {
            name: "screen-share-track",
          });

          room.localParticipant.publishTrack(screenTrack);
          setScreenShareTrack(screenTrack);
          //event listener for chrome based web browsers popup
          stream.getVideoTracks()[0].onended = () => {
            room.localParticipant.unpublishTrack(screenTrack);
            setScreenShareTrack(null);
            setIsScreenSharingActive(false);
          };
        })
        .catch((err) => {
          console.error("cound not get an access to share screen", err);
        });
    } else {
      screenShareTrack.stop();
      room.localParticipant.unpublishTrack(screenShareTrack);
      setScreenShareTrack(null);
      setIsScreenSharingActive(false);
    }
  };

  return (
    <div className="meetPage">
            <div className="meet_videos_section" id="videoData">  
              {isScreenSharingActive ? (
                      <LocalScreenSharingPreview stream={screenShareStream} />
               ) :  <Videos room={room} setRoom={setRoom} /> }
            </div>
            <div className="meet_bottom">
              <div className="bottom_options bottom_options_left">
                 <p className="room_label_paragraph">ID: {roomId}</p>
                {/* <h3>
                  {getTime() } 
                </h3> */}
              {/* <div className="room_label">
                <p className="room_label_paragraph">ID: {roomId}</p>
              </div> */}
              </div>
              <div className="bottom_options bottom_options_center" style={{display:'flex',marginRight: '15%'}}>
                <span className="bottom_options_span">
                    <MicButton room={room} />
                </span>
                <span className="bottom_options_span">
                   {!connectOnlyWithAudio && <CameraButton room={room} />}
                </span> 
                <span className="bottom_options_span">
                   {/* <SwitchToScreenSharingButton room={room} /> */}
                    <div className="video_button_container"> 
                      <IconButton  onClick={handleScreenSharingEnabling}  style={{background: "red"}}>
                        <PresentToAllRounded style={{color:"#fff"}}/> 
                    </IconButton> 
                    </div>
                    
                </span> 
                <span className="bottom_options_span">
                  <LeaveRoomButton room={room} />
                </span>
              </div>
              <div className="bottom_options bottom_options_right" style={{display:'flex'}}> 
                  <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left', }}
                      style={{marginTop: "-54px"}} >
                        <DialogTitle id="scroll-dialog-title" style={{ marginBottom: '1px',  marginTop: '10px'}}>
                            <Grid container spacing={1.5}>
                              <Grid xs={10} sm={10} lg={10}>                    
                                <Typography variant={"h4"} mb={0}>Participants</Typography>               
                              </Grid>
                              <Grid xs={2} sm={2} lg={2}>                    
                                  <JumboIconButton onClick={handleClose} sx={{margin: '-10px -6px -10px 0'}} style={{float: 'right'}}>
                                      <CloseIcon/>
                                  </JumboIconButton>      
                              </Grid>
                            </Grid> 
                          </DialogTitle>
                          <Divider  sx={{mb:1}}/>
                       <ParticipantsSection  />
                 </Popover> 
                  <PeopleRounded className="bottom_options_icon" onClick={handlePeopleClick} />
                  <Popover
                      id={id1}
                      open={open1}
                      anchorEl={anchorE2}
                      onClose={handleMessageClose}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left', }}
                      style={{marginTop: "-54px"}}
          >
                      <DialogTitle id="scroll-dialog-title" style={{ marginBottom: '1px',  marginTop: '10px'}}>
                            <Grid container spacing={1.5}>
                              <Grid xs={10} sm={10} lg={10}>                    
                                <Typography variant={"h4"} mb={0}>Chat</Typography>               
                              </Grid>
                              <Grid xs={2} sm={2} lg={2}>                    
                                  <JumboIconButton onClick={handleMessageClose} sx={{margin: '-10px -6px -10px 0'}} style={{float: 'right'}}>
                                      <CloseIcon/>
                                  </JumboIconButton>      
                              </Grid>
                            </Grid> 
                        </DialogTitle>
                        <Divider sx={{mb:1}}/>
                        <ChatSection />
                 </Popover> 
                <ChatRounded
                  className="bottom_options_icon"
                  onClick={handleMessageClick}
                />
               
              </div>
      </div>
      {showOverlay && <Overlay />}
    </div> 
    // <div className="room_container">
    //   <ParticipantsSection />
    //   <VideoSection />
    //   <ChatSection />
    //   {showOverlay && <Overlay />}
    // </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setTwilioAccessTokenAction: (token) =>
      dispatch(setTwilioAccessToken(token)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(RoomPage);
