import React from 'react';
import {Grid, Typography} from "@mui/material";  
import ScheduleCard from "../../shared/widgets/ScheduleCard";
import PaymentHistory from "../../shared/widgets/PaymentHistory";
import Announcements from "../../shared/widgets/Announcements";
import HomeClassList from "../classes/homeClasses";
import Button from "@mui/material/Button";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";  
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../layouts/layouts"; 
import {setMenuName, setClassDetails} from '../../store/actions'
import {store} from '../../store/store'
import { USERROLECODE } from '../../utils/constants/constants'
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import VoiceChatOutlinedIcon from '@mui/icons-material/VoiceChatOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import RecentConversationsList from "../../pages/menus/chat/components/RecentConversationsList"; 
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';  
import Pagination from "@jumbo/components/Pagination";
import { financeServices } from "../../services/apis/finance";
import { classServices } from "../../services/apis/classes";
import { useQuery } from "react-query";
import AnnouncementList from "../../shared/widgets/Announcements/AnnouncementList";


const HomeDashboard = () => {
    const {setActiveLayout} = useJumboApp();   
    const navigate = useNavigate();
    const { t } = useTranslation(); 
    store.dispatch(setMenuName(t('sidebar.menu.home')));   
    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
    });
    const profilerole = Number(localStorage.getItem("switchprofile"));
    const loginDetails = store.getState().loginDetails;

    const handleClassCreation = () => {
        localStorage.setItem("class_local_details", '');
        store.dispatch(setClassDetails(''));     
        navigate("/classes/createclass", { state: {frompage:"home"} });
    }
    const handleAnnouncementCreate = () => {
        navigate("/classes/createannouncement", { state: {frompage:"home"} });
    }

    React.useEffect(() => {
        localStorage.setItem("ChatPerson",'');
        localStorage.setItem("ChatPersonProfilePic", '');
        localStorage.setItem("ChatPersonOnlineStatus",'');
        localStorage.setItem("ChatPersonProfileID", '');
        // window.scrollTo(0, 0)
    });
 
    const announcementheight = profilerole === USERROLECODE.student ? 450 : 450
    const myclassheight = profilerole === USERROLECODE.student ? 376 : 270
    const tranheight = profilerole === USERROLECODE.student ? 450 : 250

    const timelineheight =  profilerole === USERROLECODE.student ? 450 : 600
    const tranrightheight =  profilerole === USERROLECODE.student ? 425 : 450
    const chathegiht =  profilerole === USERROLECODE.student ? 500 : 690

    //payment History 
    const tranHistoryLimit = 10;
    let tranHistorypageSize = 10; 
    const [tranHistoryOffset, setTransHistoryOffset] = React.useState(0);
    const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, tranHistoryOffset, tranHistoryLimit ]; 
    const {data: finances, refetch: refetchFinanceList } = useQuery(
        userDetails, financeServices.dashboardfinanceList
    );  
    const [financesList, setFinancesList] = React.useState([]);
    const [transHistoryCurrentPage, setTransHistoryCurrentPage] = React.useState(1); 
    React.useEffect(() => {  
        if (finances && finances?.financeList?.length > 0) {
            // console.log(finances?.financeList)
            setFinancesList(finances?.financeList); 
        }  
    }, [finances]);

    const handleTransHistoryPagination = (page) => {
        let offsetVal = page * tranHistorypageSize - tranHistorypageSize;
        setTransHistoryOffset(offsetVal);
        setTransHistoryCurrentPage(page); 
        refetchFinanceList().catch(console.error);
    }

    //myClass pgination
    const  classlimit  = 10 ;
    const [classOffset, setClassOffset] = React.useState(0);
    const [classCurrentPage, setClassCurrentPage] = React.useState(1);  
    let classpageSize = 10; 
    const classUserDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, classOffset, classlimit, "classes"]; 
    const {data: classResult, refetch: refetchClassesList } = useQuery(
        classUserDetails, classServices.activeClassesList
    );   
    const handleClassesPagination = (page) => {
        let offsetVal = page * classpageSize - classpageSize;
        setClassOffset(offsetVal);
        setClassCurrentPage(page); 
        refetchClassesList().catch(console.error) 
    }

    //Announcement
    const  announcementLimit  = 10 ;
    const [announcementOffset, setAnnouncementOffset] = React.useState(0);
    const [announcementCurrentPage, setAnnouncementCurrentPage] = React.useState(1);
    const [isSuccess, setSuccess] = React.useState(false);
    let announcementPageSize = 10; 
    const announcementUserDetails = [loginDetails ? loginDetails.userCode : 0 , loginDetails ? loginDetails.roleCode : 0, announcementOffset, announcementLimit, '', 'home']; 
    // const userDetails = [1 , 2, offset, limit]; 
    const {data: announcementResult, refetch: refetchAnnouncementList } = useQuery(
        announcementUserDetails, classServices.dashboardAnnouncementList
    );  
    const handleAnnouncementPagination = (page) => {
        let offsetVal = page * announcementPageSize - announcementPageSize;
        setAnnouncementOffset(offsetVal);
        setAnnouncementCurrentPage(page); 
        refetchAnnouncementList().catch(console.error) 
    }
    React.useEffect(() => {
        if (isSuccess ) { 
            setSuccess(false) 
            refetchAnnouncementList().catch(console.error)
        }
    }, [isSuccess, refetchAnnouncementList]); 

    return (
        <>
            <div style={{ display: 'flex' }}>
                {/* <HomeOutlinedIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                <Typography variant="h1" mb={3}>{t('sidebar.menu.home')}</Typography> */}
                {profilerole === USERROLECODE.tutor && <Grid container spacing={3.75} style={{ marginLeft: '2%', textAlign: 'right',marginBottom: '2%'  }}>
                    <Grid item xs={12}  >
                        <Button
                            variant={"contained"}
                            startIcon={<AddToPhotosIcon />}
                            onClick={handleClassCreation}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.5rem'
                                },
                                textTransform: 'none',
                                fontSize: '12px',
                                marginTop: '1px',
                                marginLeft: '10px'
                            }}
                        >Create Class</Button> 
                        <Button
                            variant={"outlined"}
                            onClick={handleAnnouncementCreate}
                            startIcon={<CampaignIcon />}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.5rem'
                                },
                                textTransform: 'none',
                                fontSize: '12px',
                                marginTop: '1px',
                                 marginLeft: '10px'
                            }}
                        >Create Annoucement</Button>
                    </Grid>
                </Grid>} 
        </div>    
        <Grid container spacing={3.75}> 
            <Grid item xs={12}  md={8} lg={9}>                    
                {/* <Announcements frompage={'home'}/> */}
                <JumboCardQuick         
                title={
                    <div style={{ display: 'flex' }}><CampaignIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                        <Typography variant="h1" mb={3}>{t("widgets.title.recentTicket")}</Typography>
                    </div>} wrapperSx={{p: 0}}
                >
                <JumboScrollbar
                    autoHeight
                    autoHeightMin={announcementheight}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={200}
                >
                {/* {announcementResult && announcementResult.announcementList && announcementResult.announcementList.length > 0 && ( */}
                    <AnnouncementList announcementList={announcementResult && announcementResult.announcementList} deleteSuccess={setSuccess} frompage="home"/>
                {/* )}                 */}
                </JumboScrollbar>
                {/* {announcementResult && parseInt(announcementResult?.totalcount) > 10 && <div>
                <Pagination
                    currentPage={announcementCurrentPage}
                    totalCount={announcementResult ? parseInt(announcementResult?.totalcount) : 0}
                    pageSize={announcementPageSize}
                    primaryColor="#814DFA"
                    onPageChange={handleAnnouncementPagination} 
                />
            </div>} */}
            </JumboCardQuick>
                <JumboCardQuick style={{marginTop: '1%', padding:'24px'}} 
                title={
                    <div style={{ display: 'flex' }}><VoiceChatOutlinedIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                        <Typography variant="h2" mb={3}>{t("class.myClasses")}</Typography>
                    </div>} wrapperSx={{p: 0}}
                >
                    <JumboScrollbar
                        autoHeight
                        autoHeightMin={myclassheight}
                        autoHide
                        autoHideDuration={200}
                        autoHideTimeout={500}
                    >
                    {/* {classResult && classResult?.classesList?.length > 0 && ( */}
                        <HomeClassList classesList={classResult?.classesList} />
                    {/* )}                     */}
                    </JumboScrollbar>
                    {/* {classResult && parseInt(classResult?.totalcount) > 10 && <div>
                        <Pagination
                            currentPage={classCurrentPage}
                            totalCount={classResult ? parseInt(classResult?.totalcount) : 0}
                            pageSize={classpageSize}
                            primaryColor="#814DFA"
                            onPageChange={handleClassesPagination} 
                        />
                    </div>} */}
                </JumboCardQuick>
                {profilerole === USERROLECODE.tutor &&
                <JumboCardQuick style={{marginTop: '1%'}} 
                title={
                    <div style={{ display: 'flex' }}><MonetizationOnOutlinedIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                        <Typography variant="h2" mb={3}>{t("finance.transaction")}</Typography>
                    </div>} wrapperSx={{p: 0}}
                >
                    <JumboScrollbar
                        autoHeight
                        autoHeightMin={tranheight}
                        autoHide
                        autoHideDuration={200}
                        autoHideTimeout={500}
                    >
                    {/* {financesList && financesList.length > 0 && ( */}
                        <PaymentHistory financesList = {financesList} />
                    {/* )} */}
                                        
                    </JumboScrollbar>
                    {/* {finances && parseInt(finances?.totalcount) > 10 && 
                    <div>
                        <Pagination
                            currentPage={transHistoryCurrentPage}
                            totalCount={finances ? parseInt(finances?.totalcount) : 0}
                            pageSize={tranHistorypageSize}
                            primaryColor="#814DFA"
                            onPageChange={handleTransHistoryPagination} 
                        />
                    </div>} */}
                </JumboCardQuick> }
            </Grid> 
            <Grid item xs={12} md={4} lg={3}>                
                {/* <JumboCardQuick> */}
                    {/* <JumboScrollbar
                        autoHeight
                        autoHeightMin={timelineheight}
                        autoHide
                        autoHideDuration={200}
                        autoHideTimeout={500}
                    > */}
                          <ScheduleCard/>
                    {/* </JumboScrollbar>
                </JumboCardQuick> */}
                {profilerole === USERROLECODE.student &&
                <JumboCardQuick style={{marginTop: '3%', height: '536px'}} 
                title={
                    <div style={{ display: 'flex' }}><MonetizationOnOutlinedIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                        <Typography variant="h2" mb={3}>{t("finance.transaction")}</Typography>
                    </div>} wrapperSx={{p: 0}}
                >
                    <JumboScrollbar
                        autoHeight
                        autoHeightMin={tranrightheight}
                        autoHide
                        autoHideDuration={200}
                        autoHideTimeout={500}
                    >
                    {/* <PaymentHistory/>

                    </JumboScrollbar> */}

                    {/* {financesList && financesList.length > 0 && ( */}
                        <PaymentHistory financesList = {financesList} />
                    {/* )} */}
                                        
                    </JumboScrollbar>
                    {/* {finances && parseInt(finances?.totalcount) > 10 && 
                    <div>
                        <Pagination
                            currentPage={transHistoryCurrentPage}
                            totalCount={finances ? parseInt(finances?.totalcount) : 0}
                            pageSize={tranHistorypageSize}
                            primaryColor="#814DFA"
                            onPageChange={handleTransHistoryPagination} 
                        />
                    </div>} */}
                    
                </JumboCardQuick> }
                {profilerole === USERROLECODE.tutor &&
                <JumboCardQuick style={{marginTop: '3%'}} 
                title={
                    <div style={{ display: 'flex' }}><ChatOutlinedIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                        <Typography variant="h2" mb={3}>Chat</Typography>
                    </div>} wrapperSx={{p: 0}}
                >
                    <JumboScrollbar
                        autoHeight
                        autoHeightMin={chathegiht}
                        autoHide
                        autoHideDuration={200}
                        autoHideTimeout={500}
                    >
                    <RecentConversationsList frompage={'home'}/>

                    </JumboScrollbar>
                </JumboCardQuick> }
            </Grid>
        </Grid>       
     </>
    );
};

export default HomeDashboard;
