import React from 'react';
import {Typography} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import ScheduleItem from "./ScheduleItem"; 
import { useQuery } from "react-query";
import { classServices } from "../../../services/apis/classes"; 
import { store } from "../../../store/store";

const ScheduleList = () => {
    const loginDetails = store.getState().loginDetails;
    const userDetails = [loginDetails ? loginDetails.userCode : 0 , loginDetails ? loginDetails.roleCode : 0, 0, 10,''];  
    const {data: result } = useQuery( userDetails, classServices.announcementList );  
    return (
        <React.Fragment>
            <Typography variant={"h5"} color={"text.secondary"} mb={2}>Today's schedule</Typography>
            <Timeline sx={{
                m: 0,
                p: 0,
            }}>
                {
                    result?.eventLists?.length > 0 && result?.eventLists.map((schedule, index) => (
                       <ScheduleItem item={schedule} key={index}/>
                    ))
                }
            </Timeline>
        </React.Fragment>
    );
};

export default ScheduleList;
