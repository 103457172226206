import React from 'react';
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import {useJumboLayoutSidebar} from "@jumbo/hooks";
import {SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import List from "@mui/material/List";
import PropTypes from 'prop-types';
import { store } from "../../../app/store/store";
import { USERROLECODE } from '../../../app/utils/constants/constants';
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { setProfile, setLoginDetails } from '../../../app/store/actions';
import { authServices } from "../../../app/services/auth/user"; 
import { useMutation } from "react-query"; 
const JumboVerticalNavbar = ({items, translate}) => {
    const {sidebarOptions} = useJumboLayoutSidebar();

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);
    
    const loginDetails = store.getState().loginDetails;
    const switchprofile = store.getState().switchprofile; 
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [userLoginDetails, setUserLoginDetails] = React.useState(null);
    React.useEffect(() => { 
        setUserLoginDetails(loginDetails) ; 
       }, [loginDetails]);
    const mutation = useMutation(authServices.switchProfile, {
        onSuccess: (data) => {
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) { 
                enqueueSnackbar(data?.message, { variant: "success" });                           
                store.dispatch(setProfile(data?.body?.userRoleCode)); 
                store.dispatch(setLoginDetails(data?.body?.userDetails)); 
                setUserLoginDetails(data?.body?.userDetails)
                localStorage.setItem("switchprofile", data?.body?.userRoleCode)
                localStorage.setItem("isSwitchProfile", data?.body?.userRoleCode)
                navigate("/home", { replace: true });
                window.location.reload(false);
           }           
        },
        onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
        }
    });


    //Switch profile
    const handleSwitchProfile = () => {
        const profilerole = localStorage.getItem("switchprofile");      
        const profileCode = switchprofile ? switchprofile : profilerole ? profilerole : ''
        const data = {
        userCode: userLoginDetails && userLoginDetails.userCode ? Number(userLoginDetails.userCode) : '',
        userRoleCode: profileCode && Number(profileCode) === USERROLECODE.tutor ? USERROLECODE.student : USERROLECODE.tutor,
        }
        mutation.mutate(data);
    }
    return (
        <List
            disablePadding
            sx={{
                mr: isMiniAndClosed ? 0 : 2,
                pb: 2
            }}
        >
            {
                items.map((item, index) => (
                    <JumboNavIdentifier translate item={item} key={index} handleSwitchProfile={handleSwitchProfile}/>
                ))
            }
        </List>
    );
};

JumboVerticalNavbar.defaultProps = {
    items: PropTypes.array,
    translate: false
};

export default JumboVerticalNavbar;