import React from 'react';
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import List from "@mui/material/List"; 
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'; 
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Divider from "@mui/material/Divider";

const TutorProfileList = () => {
    return (
        <List disablePadding >
            <ListItem sx={{pt: '4px', pb: '4px'}}>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <LibraryAddOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary="Create Classes"/>
            </ListItem>
            <ListItem sx={{pt: '4px', pb: '4px'}}>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <PlayCircleFilledWhiteOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary="Sell Classes"/>
            </ListItem>
            <ListItem sx={{pt: '4px', pb: '4px'}}>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <CloudUploadOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary="Upload Course Material"/>
            </ListItem>
            <ListItem sx={{pt: '4px', pb: '4px'}}>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <CampaignOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary="Create Announcements"/>
            </ListItem>
            <ListItem sx={{pt: '4px', pb: '4px'}}>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <QuestionAnswerOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary="Chat with Students"/>
            </ListItem>
            <Divider sx={{mt:2}}/>
        </List>
    );
};

export default TutorProfileList;
