import React from 'react';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import PaymentList from "./PaymentList";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent";
import {getDateElements} from "@jumbo/utils";
import { USERROLECODE } from '../../../utils/constants/constants' 
const profilerole = localStorage.getItem("switchprofile");
const profile_color = Number(profilerole) === USERROLECODE.tutor ?  ["#FFA500", "#FFC55C"] : ["#843CF6", "#38B8F2"] ;
const PaymentHistory = ({financesList}) => {
    const current = new Date(); 
    return (
        // <JumboCardQuick noWrapper>            
            // <JumboContent>
                <PaymentList financesList={financesList}/>
            // </JumboContent>
        // </JumboCardQuick>
    );
};

export default PaymentHistory;
