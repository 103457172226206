import React, { useState } from 'react';
import {Grid , CardContent, Card, InputBase, styled, Button, Typography} from "@mui/material";  
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'; 
import { useTranslation } from "react-i18next";  
import CurrentPlan from "../../shared/widgets/CurrentPlan"; 
import SearchIcon from "@mui/icons-material/Search";  
import { useNavigate } from "react-router-dom"; 
import ViewClass from "../classes/viewclass"; 
import { USERROLECODE } from '../../utils/constants/constants'
import { makeStyles } from "@mui/styles"; 
import Pagination from "@jumbo/components/Pagination";
import { classServices } from "../../services/apis/classes"; 
import {store} from '../../store/store'
import { useQuery } from "react-query";
import {setMenuName, setClassDetails} from '../../store/actions'

const useStyles = makeStyles({ 
    pagination: {
        padding: '0px ', 
        borderRadius: '6px',
        textAlign: 'center',
        display: 'table',
        margin: '0 auto',  
        width: '100%',
        marginTop: '10px',
    }
});
export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    display: 'flex',
    borderRadius: 24,
    boxShadow: theme.shadows[25],

    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height: 24
    },
}));
const MarketPlaceDashboard = () => { 
    const { t } = useTranslation(); 
    const classes = useStyles();
    const navigate = useNavigate();
    const profilerole = Number(localStorage.getItem("switchprofile"));
    const [clickClass, setClickClass] = useState(false);
    store.dispatch(setMenuName(t('sidebar.menu.marketplace')));   
    const loginDetails = store.getState().loginDetails;
    const  limit  = 10 ;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); 
    const [viewClassDetails, setViewClassDetails] = useState(null); 
    const [searchText, setSearchText] = useState(''); 
    const [searchValue, setSearchValue] = useState(''); 
    const [successMsg, setSuccessMsg] = useState(false); 
   
    let pageSize = 10; 
    const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, offset, limit, "marketplace", searchValue]; 
    const {data: result, refetch: refetchClassesList } = useQuery(
        userDetails, classServices.classesList
    );  
    const handlePagination = (page) => {
        let offsetVal = page * pageSize - pageSize;
        setOffset(offsetVal);
        setCurrentPage(page); 
        refetchClassesList().catch(console.error) 
    }
     
    const handleClassCreation = () => {
        store.dispatch(setClassDetails('')); 
        navigate("/classes/createclass", { state: {frompage:"marketplace"} });
    }
    const handleClassView = (item) => { 
        setViewClassDetails(item)
        setClickClass(true);
    }
    const handleSearch = () => {
        setSearchValue(searchText)
        setOffset(0);
        setCurrentPage(1); 
        refetchClassesList().catch(console.error) 
    }
    React.useEffect(() => {
        localStorage.setItem("ChatPerson",'');
        localStorage.setItem("ChatPersonProfilePic", '');
        localStorage.setItem("ChatPersonOnlineStatus",'');
        localStorage.setItem("ChatPersonProfileID", '');
        window.scrollTo(0, 0)
    });
    React.useEffect(() => {
        if (successMsg || !clickClass) {
            setSuccessMsg(false);
            refetchClassesList().catch(console.error) 
        }
    }, [successMsg, refetchClassesList, clickClass]);
    const handlekeyPress = (e) => {
        if(e.keyCode == 13) {
            handleSearch()
        }
    }
    return (
        <>
            <div style={{ display: 'flex' }}>
                {/* <StoreIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                <Typography variant="h1" mb={3}>{t('sidebar.menu.marketplace')}</Typography>  */}
                {!clickClass && <Grid container spacing={3.75} style={{ marginLeft: '2%', textAlign: 'right', marginBottom: '2%' }} >
                    <Grid item xs={12}>
                        {profilerole === USERROLECODE.tutor && <Button
                            variant={"contained"}
                            startIcon={<AddToPhotosIcon />}
                            onClick={handleClassCreation}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.5rem'
                                },
                                textTransform: 'none',
                                fontSize: '12px',
                                marginTop: '1px'
                            }}   >Create Class</Button>}
                    </Grid>
                </Grid>}
            </div>   
            {!clickClass ?
                (<Grid container spacing={3.75}>
                    {profilerole === USERROLECODE.student && 
                    <>
                    <Grid item xs={10} md={10} lg={11} >
                        <Search >
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search Class Name, Short description, Description and Author Name"
                                sx={{ background: '#fff' }}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchText}
                                onKeyDown={handlekeyPress}
                                onChange={(event) => setSearchText(event.target.value)}
                            />
                        </Search>
                    </Grid>
                    <Grid item xs={2} md={2} lg={1} style={{ paddingLeft: '10px',marginTop: '2px' }}>
                        <Button variant={"contained"} style={{ textTransform: 'none', fontSize: '10px',
                                marginTop: '1px' }} onClick={() => handleSearch()} >Search</Button>
                    </Grid>
                    </>}
                    {(result && result?.classesList?.length > 0) ? result?.classesList?.map((item, index) => (
                        <Grid item xs={12} md={6} lg={6} >
                            <CurrentPlan values={item} key={index} frompage={"marketplace"} clickAction={() => handleClassView(item)} balanceAmount={result?.balance} success={setSuccessMsg}/>
                        </Grid>
                    )) : <Grid item xs={12} md={12} lg={12} ><Typography variant={"h2"} mb={.5} style={{ textAlign: 'center' }}>No Classes Found</Typography>
                    {profilerole === USERROLECODE.tutor && <Typography variant={"h6"} mt={2} style={{ textAlign: 'center' }}>Looks like you haven't made your classes yet</Typography>}</Grid>}
                
                </Grid>) : 
                (
                    <Card >
                        <CardContent>
                        <Grid container spacing={3.75}>
                            <Grid item xs={12} >
                                    <ViewClass objdatas={viewClassDetails} frompage={"marketplace"} onView={setClickClass} balanceAmount={result?.balance} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
            {!clickClass && result && parseInt(result?.totalcount) > 10 && <div className={classes.pagination}>
                <Pagination
                    currentPage={currentPage}
                    totalCount={result ? parseInt(result?.totalcount) : 0}
                    pageSize={pageSize}
                    primaryColor="#814DFA"
                    onPageChange={handlePagination} 
                />
            </div>}

               
        </>
    );
};

export default MarketPlaceDashboard;
