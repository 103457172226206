export const Actions = {
  SET_IS_ROOM_HOST: "SET_IS_ROOM_HOST",
  SET_IDENTITY: "SET_IDENTITY",
  SET_CONNECT_ONLY_WITH_AUDIO: "SET_CONNECT_ONLY_WITH_AUDIO",
  SET_ROOM_ID: "SET_ROOM_ID",
  SET_TWILIO_ACCESS_TOKEN: "SET_TWILIO_ACCESS_TOKEN",
  SET_SHOW_OVERLAY: "SET_SHOW_OVERLAY",
  SET_PARTICIPANTS: "SET_PARTICIPANTS",
  PROFILE: "PROFILE",
  MENUNAME: "MENUNAME",
  LOGIN_DETAILS: "LOGIN_DETAILS",
  CREDENTIAL: "CREDENTIAL",
  CLASS_DETAILS: "CLASS_DETAILS",
  SET_MESSAGES: "SET_MESSAGES",
  STOP_VIDEO: "STOP_VIDEO",
  CHAT_PERSON: "CHAT_PERSON",
  CHAT_PERSON_PROFILE: "CHAT_PERSON_PROFILE",
  CHAT_PERSON_ID:"CHAT_PERSON_ID",
  REFRESH_CHAT: "REFRESH_CHAT",
  CLASS_NAME: "CLASS_NAME",
  NOTIFICATION_COUNT: "NOTIFICATION_COUNT",
  CHAT_PERSON_ONLINE_STATUS: "CHAT_PERSON_ONLINE_STATUS",
  ROOM_CLASS_CODE: "ROOM_CLASS_CODE",
  ROOM_LESSON_CODE: "ROOM_LESSON_CODE",
  ROOM_STUDENT_CODE : "ROOM_STUDENT_CODE",
};

export const setIdentity = (identity) => {
  return {
    type: Actions.SET_IDENTITY,
    identity,
  };
};

export const setIsRoomHost = (isRoomHost) => {
  return {
    type: Actions.SET_IS_ROOM_HOST,
    isRoomHost,
  };
};

export const setConnectOnlyWithAudio = (onlyWithAudio) => {
  return {
    type: Actions.SET_CONNECT_ONLY_WITH_AUDIO,
    onlyWithAudio,
  };
};

export const setRoomId = (roomId) => {
  return {
    type: Actions.SET_ROOM_ID,
    roomId,
  };
};

export const setTwilioAccessToken = (token) => {
  return {
    type: Actions.SET_TWILIO_ACCESS_TOKEN,
    token,
  };
};

export const setShowOverlay = (showOverlay) => {
  return {
    type: Actions.SET_SHOW_OVERLAY,
    showOverlay,
  };
};

export const setParticipants = (participants) => {
  return {
    type: Actions.SET_PARTICIPANTS,
    participants,
  };
};
export const setProfile = (switchprofile) => {
  return {
    type: Actions.PROFILE,
    switchprofile,
  };
};

export const setMenuName = (menunames) => {
  return {
    type: Actions.MENUNAME,
    menunames,
  };
};

export const setLoginDetails = (loginDetails) => {
  return {
    type: Actions.LOGIN_DETAILS,
    loginDetails,
  };
};

export const setCredentials = (credentials) => {
  return {
    type: Actions.CREDENTIAL,
    credentials,
  };
};

export const setClassDetails = (classDetails) => {
  return {
    type: Actions.CLASS_DETAILS,
    classDetails,
  };
};

export const setMessages = (messages) => {
  return {
    type: Actions.SET_MESSAGES,
    messages,
  };
};

export const setStopVideo = (stopvideo) => {
  return {
    type: Actions.STOP_VIDEO,
    stopvideo,
  };
};

export const setChatPerson = (chatperson) => {
  return {
    type: Actions.CHAT_PERSON,
    chatperson,
  };
};
export const setChatPersonProfilePic = (chatpersonpic) => {
  return {
    type: Actions.CHAT_PERSON_PROFILE,
    chatpersonpic,
  };
};
export const setChatPersonProfileID = (chatpersonid) => {
  return {
    type: Actions.CHAT_PERSON_ID,
    chatpersonid,
  };
};

export const setRefreshChat = (chatRefresh) => {
  return {
    type: Actions.REFRESH_CHAT,
    chatRefresh,
  };
};

export const setClassName = (className) => {
  return {
    type: Actions.CLASS_NAME,
    className,
  };
};
export const setNotificationCount = (notification) => {
  return {
    type: Actions.NOTIFICATION_COUNT,
    notification,
  };
};
export const setChatPersonOnlineStatus = (chatPersonOnlineStatus) => {
  return {
    type: Actions.CHAT_PERSON_ONLINE_STATUS,
    chatPersonOnlineStatus,
  };
};
export const setRoomClassCode = (roomClassCode) => {
  return {
    type: Actions.ROOM_CLASS_CODE,
    roomClassCode,
  };
};
export const setRoomLessonCode = (roomLessonCode) => {
  return {
    type: Actions.ROOM_LESSON_CODE,
    roomLessonCode,
  };
};
export const setRoomStudentCode = (roomStudentCode) => {
  return {
    type: Actions.ROOM_STUDENT_CODE,
    roomStudentCode,
  };
}; 
export default Actions;
