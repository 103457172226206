import React from 'react';
import { CardContent, TextField, Select, InputLabel} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button"; 
import Grid from "@mui/material/Grid"; 
import MenuItem from "@mui/material/MenuItem"; 
import { Typography} from "@mui/material"; 
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";   
import { classServices } from "../../../services/apis/classes"; 
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { authServices } from "../../../services/auth/user"; 
import LoadingButton from "@mui/lab/LoadingButton";
import { store } from "../../../store/store";
import {convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateFromHTML} from 'draft-js-import-html';
let Editor = () => <React.Fragment/>;
const CreateAnnouncement = () => { 
    const [classType, setClassType] = React.useState(0);
    const [header, setHeader] = React.useState(''); 
    const [errorClass, setErrorClass] = React.useState('');
    const [errorHeader, setErrorHeader] = React.useState('');
    const [errorDesc, setErrorDesc] = React.useState('');
    const [process, setProcess] = React.useState('Insert');
    const loginDetails = store.getState().loginDetails;
    const navigate = useNavigate();
    const { t } = useTranslation();  
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const userCode = [loginDetails ? parseInt(loginDetails.userCode) : 0]; 
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty()); 
    React.useEffect(() => {
        Editor = require('react-draft-wysiwyg').Editor; 
    }, []);
    const {data: classList} = useQuery(
        userCode, classServices.getClasses 
    ); 
    React.useEffect(() => {
        if (classList?.classList?.length > 0) {
            setClassType(classList?.classList?.[0]?.classCode);
        }
    }, [classList]);
    React.useEffect(() => {
        if (location?.state?.announcementData) {
            const bindData = location?.state?.announcementData; 
            if (bindData.isCreate) {
                setClassType(bindData.classCode);
            } else {
                setClassType(bindData.classCode);
                setHeader(bindData.header);
                setEditorState(EditorState.createWithContent(stateFromHTML(bindData.description)));
                // setDescription(bindData.description); 
                setProcess('Update');
            }      
        }
        
    }, [classList, location]);
    
    const mutation = useMutation(classServices.announcement, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" });
                clearData(); 
                if (location.state.frompage === "classes") {
                    navigate("/classes/classdetails/announcement"); 
                } else {
                    navigate("/home"); 
                } 
           }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });
    const clearData = () => {
        setClassType(0)
        setHeader('')
        setErrorDesc('')
    }
    //Handle Post button
    const handlePost = async () => {  
        if (!classType) { 
            setErrorClass(t('announcement.errorClass'));
            return;
        }
        if (!header) {
            setErrorClass('');
            setErrorHeader(t('announcement.errorHeader'));
            return;
        } 
        if (!editorState.getCurrentContent().getPlainText('\u0001') ||  editorState.getCurrentContent().getPlainText('\u0001') === "") {
            setErrorClass('');
            setErrorHeader('');
            setErrorDesc(t('announcement.errorDesc'));
            return;
        }
        if (classType && header && editorState.getCurrentContent().getPlainText('\u0001') ) {
            setErrorClass('');
            setErrorHeader('');
            setErrorDesc(''); 
            let announcementCode = "0";
            if (process === "Update") {
                announcementCode = location?.state?.announcementData?.announcementCode;
            }
            mutation.mutate({
                userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
                userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,
                classCode: classType,
                header: header,
                description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                process: process,
                ipAddress: await authServices.getMyIpAddress(),  
                announcementCode: announcementCode,
            });
        }
    }
    //Handle Class
    const handleCloseClasses = () => {
        if (location.state.frompage === "classes") {
            navigate("/classes/classdetails/announcement"); 
        } else {
            navigate("/home"); 
        } 
    }
    
    return ( 
        <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
            <CardContent>
                <Box component="form"
                        sx={{
                            mx: -1, 
                            '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                p: 1,
                                mb: 2,
                                width: {xs: '100%', sm: '100%'}
                            },
                            '& .MuiFormControl-root.MuiFormControl-fluid': {
                                width: '100%'
                            }
                        }}
                        autoComplete="off"
                >
                        <FormControl size="small">
                        <Grid container spacing={3.5}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <InputLabel id="demo-simple-select-label">{t('announcement.chooseclass')}</InputLabel>                           
                            <Select
                                fullWidth
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={classType} 
                                label={t('announcement.chooseclass')}
                                error={errorClass ? true : false}
                                helperText={errorClass ? errorClass : ""}
                                onBlur={(event) => {
                                    if (event.target.value) {
                                        setErrorClass('');
                                    } else {
                                        setErrorClass(t('announcement.errorClass'));
                                    }
                                }} 
                                onChange={(event) => setClassType(event.target.value)}
                                >
                                    {classList?.classList?.length > 0 && classList?.classList.map((item,index) => (
                                        <MenuItem key={index} value={ item.classCode }>{ item.className }</MenuItem>
                                    )) } 
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={9}>
                            <TextField
                                fullWidth
                                id="header"
                                label={t('announcement.header')}
                                size="small"
                                onChange={(event) => setHeader(event.target.value)}
                                value={header}
                                error={errorHeader ? true : false}
                                helperText={errorHeader ? errorHeader : ""}
                                onBlur={(event) => {
                                    if (event.target.value) {
                                        setErrorHeader('');
                                    } else {
                                        setErrorHeader(t('announcement.errorHeader'));
                                    }
                                }}
                            />
                        </Grid>
                        </Grid>                               
                    </FormControl>                           
                    <FormControl>
                    <Grid container spacing={3.5}>
                        <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant="p" >{t('announcement.description')}</Typography> 
                                <Editor
                                    editorStyle={{
                                        width: '100%',
                                        minHeight: 100,
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'lightgray',
                                    }}
                                    editorState={editorState}
                                    onEditorStateChange={editorState => setEditorState(editorState)}
                                />
                               
                            {/* <CKEditor
                                    initData={description} 
                                    onBlur={(evt) => {
                                        if (evt.editor.getData()) {
                                            setErrorDesc('');
                                        } else {
                                            setErrorDesc(t('announcement.errorDesc'));
                                        }
                                    }} 
                                    onChange={evt => setDescription(evt.editor.getData())}
                                    // config={{
                                    //     contentsCss: [`${process.env.PUBLIC_URL}/fonts/noir-pro/styles.css`,
                                    //         `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`],
                                    // }}
                                /> */}
                                {errorDesc && <p style={{ fontSize: '0.75rem', color: '#E73145' }}>{errorDesc ? errorDesc : ""}</p>}
                        </Grid> 
                        </Grid>
                    </FormControl>
                                        
                    <Div sx={{mx: 1}} style={{textAlign:'right'}}>
                        <Button variant={"outlined"} style={{ textTransform: 'none', marginRight: '10px' }} onClick={handleCloseClasses}>{t('common.cancel')}</Button>
                        <LoadingButton   
                                    style={{textTransform: 'none'}}                                 
                                    type="button"
                                    variant="contained"
                                    size="medium"
                                    onClick={handlePost}
                                    loading={mutation.isLoading}
                                >{t('common.post')}</LoadingButton> 
                        {/* <Button variant={"contained"} style={{textTransform: 'none'}} onClick={handlePost}>{t('common.post')}</Button> */}
                    </Div>
                </Box>
            </CardContent>
        </Div>
        
    );
};

export default CreateAnnouncement;
