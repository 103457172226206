import React from "react";
import { useDispatch } from 'react-redux';
import routes from "./routes";
import {useJumboRoutes} from "@jumbo/hooks";
import { authServices } from "../app/services/auth/user";
import { useQuery } from "react-query";
import { Actions, setCredentials, setLoginDetails, setProfile, setClassDetails} from '../app/store/actions'
import { store } from "../app/store/store";

import {useNavigate} from "react-router-dom";
const AppRoutes = () => {
    const navigate = useNavigate();
    const appRoutes = useJumboRoutes(routes);
    const {data: result  } = useQuery(
        [], authServices.retrieveUserDetails
    );  
    const dispatch = useDispatch()
    React.useEffect(() => { 
        const token = localStorage.getItem("jwtToken");
        if (result && token) {  
            if (result?.statusFlag === 2) {
                localStorage.clear()  
                console.log('approutes logout')
                navigate('/')
            } else {
                store.dispatch(setLoginDetails(result?.userDetails));
                store.dispatch(setCredentials(result?.credential));
                if (result?.userDetails?.defaultRole) {
                    store.dispatch(setProfile(result?.userDetails?.roleCode));  
                    localStorage.setItem("switchprofile", result?.userDetails?.roleCode)
                }  
            } 
            if (localStorage.getItem("class_local_details")) {
                store.dispatch(setClassDetails(JSON.parse(localStorage.getItem("class_local_details"))));
            }
          
        } 
    }, [result]);
   
    return (
        <React.Fragment>
            {
                appRoutes
            }
        </React.Fragment>
    );
};
export default AppRoutes;
