import React from 'react'; 
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import {Typography, Tooltip, IconButton} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton"; 
import Span from "@jumbo/shared/Span";
import {useNavigate} from "react-router-dom";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'; 
import { alpha } from "@mui/material/styles";
import { store } from "../../store/store";
import { authServices } from "../../services/auth/user";
import { classServices } from "../../services/apis/classes";    
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { setClassName, setClassDetails } from '../../store/actions';   

const NotificationPost = ({indexval,item, success}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();  
    const { enqueueSnackbar } = useSnackbar();
    const [localIndex, setLocalIndex] = React.useState(false); 
    const loginDetails = store.getState().loginDetails;
    const mutation = useMutation(classServices.getClassDetails, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                return;
           }
            if (data?.statusFlag === 1) { 
                if (data?.body?.classDetails) {
                  localStorage.setItem("class_local_details", JSON.stringify(data?.body?.classDetails));
                  store.dispatch(setClassDetails(data?.body?.classDetails));   
                }
            }         
        },
        onError() {
        },
    });
    const bindClassDetails = () => {
        mutation.mutate({
            userCode: loginDetails ? Number(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? Number(loginDetails.roleCode): 0,   
            classCode: Number(item.classCode)
        });     
    }
    const showAnnouncement = (row) => {
        if (row.notifyType === "Announcement") {
            navigate('/home')
        }
        if (row.notifyType === "Files") {
            store.dispatch(setClassName(row?.className));
            bindClassDetails(); 
            navigate('/classes/classdetails/files')
        }
        if (row.notifyType === "Submission") {
            store.dispatch(setClassName(row?.className));
            bindClassDetails();
            navigate('/classes/classdetails/submissionfiles')
        }
        if (row.notifyType === "BuyClass") {
            navigate('/classes')
        }
    }
    
   const formatBytes = (bytes) => {        
    return bytes ? (bytes / Math.pow(1024,2)).toFixed(2) : ''
    }
    const dismissmutation = useMutation(classServices.dismissAnnouncement, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(t('common.error'), { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {  
                success(localIndex);           
           }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });

    const dismissNotification = useMutation(classServices.dismissNotification, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(t('common.error'), { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {  
                success(localIndex);           
           }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });
    const handleDismiss = async (item, e, indexval) => {  
        if (item.notifyType === "Announcement") {
            setLocalIndex(indexval);
            dismissmutation.mutate({
                userCode: loginDetails ? Number(loginDetails.userCode) : 0,
                userRoleCode: loginDetails ? Number(loginDetails.roleCode) : 0,
                classCode: Number(item.classCode),           
                announcementCode: Number(item.announcementCode),
                ipAddress: await authServices.getMyIpAddress()            
            });
        }
        if (item.notifyType === "Files" || item.notifyType === "Submission" || item.notifyType === "BuyClass") { 
            setLocalIndex(indexval);
            dismissNotification.mutate({
                userCode: loginDetails ? Number(loginDetails.userCode) : 0,
                userRoleCode: loginDetails ? Number(loginDetails.roleCode) : 0,
                classCode: Number(item.classCode),           
                fileCode: Number(item.fileCode),
                notifyType:item.notifyType,
                ipAddress: await authServices.getMyIpAddress()            
            });
        }
        e.preventDefault();
        e.stopPropagation();
    }
    return (
        <ListItemButton component={"li"} alignItems={"flex-start"} 
            sx={{
            p: theme => theme.spacing(1.25, 3),
            transition: 'all 0.2s',
            borderBottom: '1px solid #0000001a',
            '&:hover': {
                boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                transform: 'translateY(-4px)', 
                '& .MuiChip-animation': {
                    width: 'auto',
                    height: 'auto',
                    fontSize: 12,
                    display: 'block',
                }
            }
        }}>
            <ListItemAvatar onClick={(e) => showAnnouncement(item)}>
                <Avatar src={item.userProfile}/>
            </ListItemAvatar>
            {item?.notifyType === "Announcement" &&
                (<ListItemText onClick={(e) => showAnnouncement(item)}>
                    <Link underline={"none"}  >{item.username}</Link> has made a new announcement for
                <Link underline={"none"}  > {item.className}</Link>  
                <Typography component="p">
                        <Span sx={{ color: 'text.secondary' }}>{item.header}</Span></Typography>
                    <Typography component="p">
                        <Span sx={{ color: 'text.secondary' }}>{item.createddatetime}</Span></Typography>
                </ListItemText>)}
                {item?.notifyType === "Files" && (<ListItemText onClick={(e) => showAnnouncement(item)}>
                    <Link underline={"none"}  >{item.username}</Link> has uploaded a new file under
                <Link underline={"none"} > {item.fileOriginalName}</Link> for {item.className}
                <Typography component="p">
                        <Span sx={{ color: 'text.secondary' }}>File Size:  {formatBytes(item?.fileSize)} MB</Span></Typography>
                    <Typography component="p">
                        <Span sx={{ color: 'text.secondary' }}>{item.createdAt}</Span></Typography>
                </ListItemText>)}
            
                {item?.notifyType === "BuyClass" && (<ListItemText onClick={(e) => showAnnouncement(item)}>
                    <Link underline={"none"}  >{item.username}</Link> has joined class   
                <Link underline={"none"} > {item.className}</Link>   
                    <Typography component="p">
                        <Span sx={{ color: 'text.secondary' }}>{item.createdAt}</Span></Typography>
                </ListItemText>)}
            
             {item?.notifyType === "Submission" && (<ListItemText onClick={(e) => showAnnouncement(item)}>
                    <Link underline={"none"}  >{item.username}</Link> submitted  
                <Link underline={"none"} > {item.fileName}</Link> file 
                    <Typography component="p">
                        <Span sx={{ color: 'text.secondary' }}>{item.createdAt}</Span></Typography>
                </ListItemText>)}
            
                <Tooltip title={ "Dismiss"}>
                    <IconButton className={'MuiChip-animation'} onClick={(e) => handleDismiss(item, e,indexval)}
                        sx={{
                            width: 0,
                            height: 0,
                            fontSize: 0,
                            display: 'none',
                            transition: 'all 0.2s',
                        }} >
                          <CancelOutlinedIcon fontSize={"small"} /> 
                    </IconButton>
                </Tooltip> 
        </ListItemButton>
    );
};

export default NotificationPost;
