import React, { useState } from "react";
// import SendMessageButton from "../../resources/images/sendMessageButton.svg";
import { sendMessageUsingDataChannel } from "../../utils/twilioUtils";
import { IconButton } from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
const NewMessage = () => {
  const [message, setMessage] = useState("");
  const [disable, setDisable] = useState(true);

  const sendMessage = () => {
    // send message to other user
    sendMessageUsingDataChannel(message, true);
    setMessage("");
    setDisable(true);
  };

  const handleKeyPressed = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (message.trim()) {
        //sendMessage To other user
        setDisable(false);
        sendMessage();
      }else{
        setDisable(true);
      }
     
    }
  };

  const handleTextChange = (event) => { 
    if (event.target.value.trim()) {
      setDisable(false);
    }else{
      setDisable(true);
    }
    setMessage(event.target.value);
  };

  return (
    <div className="new_message_container">
      <input
        className="new_message_input"
        value={message}
        onChange={handleTextChange}
        placeholder="Type your message..."
        type="text"
        onKeyDown={handleKeyPressed}
      />
       <IconButton disabled={disable} className="new_message_button" onClick={sendMessage}  style={disable ?  { background: "#ccc"} : { background: "#098ad9"}}>
         <SendOutlinedIcon style={{color:"#fff"}}/>  
       </IconButton> 
      {/* <img
        className="new_message_button"
        src={SendMessageButton}
        onClick={sendMessage}
      /> */}
    </div>
  );
};

export default NewMessage;
