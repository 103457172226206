import React from 'react';
import Header from "./Header";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import {useJumboTheme} from "@jumbo/hooks"; 
import {alpha} from "@mui/material/styles"; 
import {ASSET_IMAGES} from "../../../../utils/constants/paths";
import {getAssetPath} from "../../../../utils/appHelpers";
import Contacts from "./components/Contacts";

const UserProfile = () => {
    const { theme } = useJumboTheme();
    React.useEffect(() => {
        localStorage.setItem("ChatPerson",'');
        localStorage.setItem("ChatPersonProfilePic", '');
        localStorage.setItem("ChatPersonOnlineStatus",'');
        localStorage.setItem("ChatPersonProfileID", '');
        window.scrollTo(0, 0)
    });
    return (
        <JumboContentLayout
            header={<Header/>}
            layoutOptions={{
                header: {
                    sx: {
                        mt: -4,
                        mb: -7.25,
                        mx: {xs: -4, lg: -6},
                        p: {xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11)},
                        background: `#002447 url(${getAssetPath(`${ASSET_IMAGES}/profile-bg.jpg`,"1920x580")})s no-repeat center`,
                        backgroundSize: 'cover',
                        color: 'common.white',
                        position: 'relative',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha(theme.palette.common.black, .65)
                        }
                    }
                },               
                wrapper: {
                    sx: {
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: 'column'
                        }
                    }
                },
                main: {
                    sx: {
                        [theme.breakpoints.down('sm')]: {
                            minHeight: 0
                        }
                    }
                }
            }}
        >
            <Contacts/>
        </JumboContentLayout>
    );
};

export default UserProfile;
