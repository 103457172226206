import React from 'react';
import {Typography, TextField, InputAdornment} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";  
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from "@mui/icons-material/Search";
import MenuBookIcon from '@mui/icons-material/MenuBook';
const Gradebook = () => {
    const {t} = useTranslation(); 
    function createData(gradeitem, marks, grade, comments, markedon, verifyres) {
        return { gradeitem, marks, grade, comments, markedon, verifyres };
      }
      
      const rows = [
        createData('Quiz 1', '-', '-', '-', '-'),
        createData('Quiz 2', '-', '-', '-', '-'),
        createData('Quiz 3', '-', '-', '-', '-'),
        createData('Quiz 4', '-', '-', '-', '-'),
        createData('Quiz 5', '-', '-', '-', '-'),
     
      ];
    return (
        <React.Fragment>            
            <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>               
                    <Grid xs={8}>                    
                    <div style={{ display: 'flex' }}><MenuBookIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                        <Typography variant="h1" mb={3}>{t('class.gradebook')}</Typography>
                    </div>                  
                    </Grid>
                    <Grid xs={4} style={{textAlign:'right'}}> 
                    <TextField  id="time" label={t('class.searchgradebook')}
                    fullwidth
                                        type="text"                                       
                                        size="small"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><SearchIcon size={'small'}/></InputAdornment>,
                                            style: { textAlign: 'right' }
                                        }}
                                    />                  
                    </Grid>
                    </Grid>  
            <Grid container spacing={3.5} style={{padding: '2%'}}>    
            <Grid item xs={12}  md={12} lg={12}> 
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Graded item</TableCell>
                        <TableCell>Marks</TableCell>
                        <TableCell>Grade</TableCell>
                        <TableCell>Comments</TableCell>
                        <TableCell>Marked On</TableCell>
                        <TableCell>Verify Results</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row, index) => (
                        <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.gradeitem}
                        </TableCell>
                        <TableCell>{row.marks}</TableCell>
                        <TableCell>{row.grade}</TableCell>
                        <TableCell>{row.comments}</TableCell>
                        <TableCell>{row.markedon}</TableCell>
                        <TableCell>
                        <Button
                        variant={"contained"}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem'
                            },
                            textTransform: 'none',
                            fontSize: '12px',
                            paddingBottom: '3px',
                            paddingTop: '3px'
                        }}
                    >{t('class.verify')}</Button>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </Grid> 
            </Grid>
           
        </React.Fragment>
    );
};

export default Gradebook;
