import React from 'react';
import ActiveConversationChat from "./ActiveConversationChat";

const ConversationChatGroupByDate = ({conversationMessages}) => {
    return (
        <React.Fragment>
            {
                conversationMessages?.map((messagesGroupByDate, index) => (
                    <ActiveConversationChat key={index} conversation={messagesGroupByDate}/>
                ))
            }
        </React.Fragment>
    );
};

export default ConversationChatGroupByDate;
