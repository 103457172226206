import React from "react";
import Participants from "./Participants"; 

const ParticipantsSection = () => {
  return (
    <div className="participants_section_container"> 
      <Participants />
    </div>
  );
};

export default ParticipantsSection;
