import React from 'react'; 
import Divider from "@mui/material/Divider";
import {Card, CardContent, Typography, Rating, Avatar, Button, Dialog,  DialogActions, DialogContent, DialogContentText, DialogTitle, Box,  Table, TableBody, Tooltip,IconButton} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";  
import Div from "@jumbo/shared/Div";  
import PropTypes from 'prop-types'; 
import Chip from '@mui/material/Chip';
import VoiceChatOutlinedIcon from '@mui/icons-material/VoiceChatOutlined'; 
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';   
import { USERROLECODE } from '../../../utils/constants/constants'
import { useTranslation } from "react-i18next"; 
import { TableCell, TableRow } from "@mui/material";
import styled from "@emotion/styled";
import { store } from "../../../store/store";
import { authServices } from "../../../services/auth/user";
import { classServices } from "../../../services/apis/classes"; 
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom"; 
import WarningIcon from '@mui/icons-material/Warning';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {setChatPerson,setChatPersonProfilePic, setChatPersonProfileID,setChatPersonOnlineStatus} from '../../../store/actions'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        borderBottom: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },

    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    }
}));
const CurrentPlan = ({ values, frompage, clickAction , balanceAmount, success}) => { 
    const { t } = useTranslation(); 
    const profilerole = Number(localStorage.getItem("switchprofile")); 
    const [joinClassOpen, setjoinClassOpen] = React.useState(false);    
    const [btnDisable, setBtnDisable] = React.useState(false); 
    const loginDetails = store.getState().loginDetails;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [validateClassDatas, setValidateClassDatas] = React.useState([]);
    const ValidateMutation = useMutation(classServices.validateJoinClass, {
        onSuccess: (data) => {  
            setBtnDisable(false);
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                if (data?.body.length > 0) { 
                    setValidateClassDatas(data?.body);
                    setAlertOpen(true);
                } else {
                    if (!balanceAmount) {
                        balanceAmount = 0;
                    }
                    let classLessionPrice = 0;
                    if (values?.classLessionPrice) {
                        classLessionPrice = parseInt(values?.classLessionPrice ? values?.classLessionPrice : 0) * parseInt(values?.noOfLessons ? values?.noOfLessons : 0) ;
                    }
                    if (parseFloat(balanceAmount) >= parseFloat(classLessionPrice)) {
                        setjoinClassOpen(true);
                        
                    } else {
                        navigate("/finance", { state: {frompage:"marketplace_topup",paymentAmt: parseFloat(classLessionPrice)-parseFloat(balanceAmount)}  });
                    } 
                } 
            }         
        },
        onError() {
            enqueueSnackbar("Something went wrong", { variant: "warning" });
            return;
        },
    });
    const joinClassAction = (e, values) => {  
        ValidateMutation.mutate({
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,      
            classCode: values ? Number(values.classCode) : 0,   
        });
        e.preventDefault();
        e.stopPropagation();
    }
    const handleChat = (e) => {
        if(loginDetails && Number(loginDetails.userCode) !==  Number(values?.userCode)) { 
            localStorage.setItem("ChatPerson", values?.username);
            localStorage.setItem("ChatPersonProfilePic", values?.profileUrl);
            localStorage.setItem("ChatPersonOnlineStatus", values?.onlineStatus);
            localStorage.setItem("ChatPersonProfileID", values?.userCode);
            store.dispatch(setChatPerson(values?.username));
            store.dispatch(setChatPersonProfilePic(values?.profileUrl)); 
            store.dispatch(setChatPersonOnlineStatus(values?.onlineStatus)); 
            store.dispatch(setChatPersonProfileID(values?.userCode)); 
            navigate(`/chats/conversation/${values?.userCode}`);
        } else {
            navigate(`/chats`);
        }  
        // navigate('/chats');
        e.preventDefault();
        e.stopPropagation();
    }
    const mutation = useMutation(classServices.joinClassCheckout, {
        onSuccess: (data) => {  
            setBtnDisable(false);
            if (data?.statusFlag === 2) {
                enqueueSnackbar("Your enrollment into "+values.username+"’s "+values.className +"  class is unsuccessful", { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                success(true);
                setjoinClassOpen(false);
                enqueueSnackbar("Your enrollment into "+values.username+"’s "+values.className +"  class is successful", { variant: "success" });
                return;
            }         
        },
        onError() {
            enqueueSnackbar("Something went wrong", { variant: "warning" });
            return;
        },
    });
    const handleCheckout = async (values) => {
        let payAmt = parseInt(values?.classLessionPrice ? values?.classLessionPrice : 0) * parseInt(values?.noOfLessons ? values?.noOfLessons : 0);
        setBtnDisable(true);
        mutation.mutate({
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,           
            classCode: values ? Number(values.classCode) : 0,  
            paymentAmount:  payAmt.toString() ,
            ipAddress: await authServices.getMyIpAddress()
        });
    }

    const handleProceed = () => {
        setAlertOpen(false);
        if (!balanceAmount) {
            balanceAmount = 0;
        }
        let classLessionPrice = 0;
        if (values?.classLessionPrice) {
            classLessionPrice = parseInt(values?.classLessionPrice ? values?.classLessionPrice : 0) * parseInt(values?.noOfLessons ? values?.noOfLessons : 0) ;
        }
        if (parseFloat(balanceAmount) >= parseFloat(classLessionPrice)) {
            setjoinClassOpen(true);
            
        } else {
            navigate("/finance", { state: {frompage:"marketplace_topup",paymentAmt: parseFloat(classLessionPrice)-parseFloat(balanceAmount)}  });
        } 
       
    }
    return (
        <> 
            {   
                values.statusname !== 'Completed' && (<Card onClick={clickAction} style={{ cursor: 'pointer' }}
                sx={{
                    '& .MuiChip-root ': {
                        marginTop: '3px',
                        marginLeft: '3%'
                    },

                    '& .MuiChip-label ': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', 
                        whiteSpace: 'nowrap',
                        fontSize: '10px'
                    },
                    '& .MuiCardContent-root:last-child ': {
                        padding: '10px !important'
                    },
          
                    }}>
                   
                    <Stack direction={"row"} sx={{ padding: '5px', marginTop: '5px', paddingLeft: '15px', paddingRight: '15px' }} > 
                    <Grid item xs={1}>
                        <VoiceChatOutlinedIcon style={{ marginTop: '4px' }} />
                    </Grid>
                    <Grid item xs={7}>
                            <div style={{ display: 'flex' }}><Div style={{
                                fontSize: '18px', marginLeft: '10px', whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis'}} title={values.className} >{values.className}</Div>
                                {(profilerole === USERROLECODE.student) && values.buyercount && parseInt(values.buyercount) > 0 && (
                                    <div style={{ marginTop: '6px', marginLeft: '5px' }}><div style={{ background: "#3bd2a2", borderRadius: '50px', color: '#fff', fontSize: "10px", paddingTop: '2px', paddingBottom: '1px', width: 'fit-content', paddingLeft: '10px', paddingRight: '10px', float: 'right' }}>Owned</div></div>
                                     )}  
                            </div>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <Div style={{ fontSize: '15px', color: '#2778c4', marginLeft: '10px' }} >$ {parseInt(values?.classLessionPrice ? values?.classLessionPrice : 0) } / lesson</Div>
                    </Grid>
                </Stack>
                <Divider style={{ borderColor: '#DEE2E6' }} />
                {/* <Divider /> */}
                <CardContent >
                    <Grid item xs={12} sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "2",
                        "-webkit-box-orient": "vertical"
                    }}>
                        <Typography
                            component={"div"}
                            variant={"body1"}
                            color={"text.secondary"}
                            style={{ fontSize: '12px', marginLeft: '5px', marginBottom: '4px' }}
                            mb={2}
                        >
                          {values.shortDescription}
                        </Typography>
                       
                    </Grid>
                    <Grid item xs={12} >
                        <Divider style={{ borderColor: '#DEE2E6', marginTop: '4px', marginBottom: '4px' }} />
                    </Grid>

                    <Stack direction={"row"} spacing={2} style={{ marginLeft: '5px' }}>
                        <Grid item xs={8}>
                            <Typography
                                component={"div"}
                                variant={"body1"}
                                color={"text.secondary"}
                                mb={1}
                            >
                                    <Typography component={"span"} variant={"h5"}>{values.duration}{' '}</Typography><span >Mins</span> | <span style={{ color: 'black' }}>{values.classFreqName}</span> | <Typography component={"span"} variant={"h5"}>{values.noOfLessons} </Typography> Lessons
                        </Typography>
                            <Typography
                                component={"div"}
                                variant={"body1"}
                                color={"text.secondary"}
                                style={{ fontSize: '12px', marginBottom: '3px' }}
                                mb={2}
                            >
                                {values.classDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {values.statusname === "Ongoing" && <Div sx={{ textAlign: 'center', marginTop: '5px', marginRight: '-19px' }}>
                                <div style={{ background: "#F39711", borderRadius: '50px', color: '#fff', fontSize: "10px", paddingTop: '2px', paddingBottom: '1px', width: 'fit-content', paddingLeft: '10px', paddingRight: '10px', float: 'right' }}>{values.statusname}</div>
                            </Div>}
                        </Grid>
                    </Stack>
               
                    {(profilerole === USERROLECODE.tutor) && (<Typography variant={"p"} color={'text.primary'} style={{ marginLeft: '5px' }}>Class Size </Typography>)}
                    {(profilerole === USERROLECODE.tutor) && (<Grid container spacing={3.5} style={{ paddingLeft: '5px' }}>
                        <Grid item xs={4}>
                            <div style={{ fontSize: '12px', display: 'flex' }}>Total
                                <Chip label={values.classSize} sx={{background:  '#002366', color:'white'}}  style={{ height: '15px', fontsize: '10px !important' }} size="small" />
                            </div>
                        </Grid>
                     
                        <Grid item xs={4}>
                            <div style={{ fontSize: '12px', display: 'flex' }}>Booked
                            <Chip label={values.booked} color="warning" size="small"
                                    style={{ height: '15px', fontsize: '10px !important' }} />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{ fontSize: '12px', display: 'flex' }}>Available
                            <Chip label={(parseInt(values.classSize) > 0) ? parseInt(values.classSize)-parseInt(values.booked) : 0 } color="success" size="small" style={{ height: '15px', fontsize: '10px !important' }} />
                            </div>
                        </Grid>
                        </Grid>)}

                        {(profilerole === USERROLECODE.student) && (<Grid container spacing={3.5} style={{ paddingLeft: '5px' }}>
                        <Grid item xs={5}>
                            <div style={{ fontSize: '12px', display: 'flex' }}>Class Size 
                                <Chip label={values.classSize} sx={{background:  '#002366', color:'white'}} style={{ height: '15px', fontsize: '10px !important' }} size="small" />
                            </div>
                        </Grid>
{/*                      
                        <Grid item xs={7} style={{marginTop: '-8px', marginLeft: '-11px'}}>
                            {(profilerole === USERROLECODE.student && frompage === "marketplace") && ( 
                                values.buyercount && parseInt(values.buyercount) === 0 && (<Button
                                    variant={"contained"}  
                                    color={"secondary"} 
                                    onClick= {(e) => joinClassAction(e, values)}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '1.5rem'
                                        },
                                        textTransform: 'none',
                                        fontSize: '11px',
                                        marginTop: '1px',
                                        marginLeft: '10px',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        float: 'right',
                                        marginRight:"-10px",
                                    }}
                                >{t('class.joinClass')}</Button> ) 
                                        
                            )}
                        </Grid> */}
                    </Grid>)}
                    <Divider style={{ borderColor: '#DEE2E6', marginTop: '4px' }} />
                    <Grid container spacing={3.5} style={{ marginBottom: '-19px' }} >
                        <Grid item xs={6}  >
                            <Div sx={{ color: '#002366', fontWeight: '400', float: 'left', alignItems: 'center', display: 'flex', padding: '5px' }}>
                                <Div sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    }}>
                                          <Avatar src={values?.profileUrl} alt="user" sx={{ width: 24, height: 24, mb: 2 }} />  
                                </Div>
                                <Div sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}>
                                    <Div style={{ marginLeft: '5px', marginTop: '-12px' }}  ><span style={{ fontSize: '11px' }}>{values.username}</span>
                                        <div style={{ marginTop: '-7px' }}><Rating style={{ fontSize: '10px' }} name="size-small" readOnly={true} defaultValue={5} size="small" /></div></Div>
                                </Div>
                                <Tooltip title="Chat" style={{ marginLeft: '5px', marginTop: '-12px' }}>
                                        <IconButton onClick={(e) => handleChat(e)}>
                                            <ChatOutlinedIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Tooltip> 
                            </Div>
                             
                        </Grid>
                        <Grid item xs={6}  >
                        <Div sx={{ color: '#002366', fontWeight: '400', float: 'right', alignItems: 'center', display: 'flex', padding: '5px' }}> 
                               
                                    {(profilerole === USERROLECODE.student && frompage === "marketplace") && ( 
                                values.buyercount && parseInt(values.buyercount) === 0 && (( (parseInt(values.classSize) > 0) ? parseInt(values.classSize)-parseInt(values.booked) : 0 ) > 0) && (<Button
                                    variant={"contained"}  
                                    color={"secondary"} 
                                    onClick= {(e) => joinClassAction(e, values)}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '1.5rem'
                                        },
                                        textTransform: 'none',
                                        fontSize: '11px',
                                        marginTop: '1px',
                                        marginLeft: '10px',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        float: 'right',
                                        margin :'1px 1px 5px 0px !important'
                                    }}
                                >{t('class.joinClass')}</Button> ) 
                                        
                                    )}
                                    
                                    {(profilerole === USERROLECODE.student && frompage === "marketplace") && (((parseInt(values.classSize) > 0) ? parseInt(values.classSize) - parseInt(values.booked) : 0) <= 0 && (
                                        // <div style={{ marginLeft: '5px' }}><div style={{ background: "#3bd2a2", borderRadius: '50px', color: '#fff', fontSize: "10px", paddingTop: '2px', paddingBottom: '1px', width: 'fit-content', paddingLeft: '10px', paddingRight: '10px', float: 'right' }}>  <BlockIcon /> <span>Class is full</span></div></div>
                                           <div style={{display: 'flex',
                                            background: 'grey',
                                            padding: '5px',
                                            borderRadius: '50px',  
                                            margin :'1px 1px 5px 0px',
                                            color: 'white'}}>  
                                            <InfoOutlined fontSize={"small"} style={{marginLeft: '5px',fontSize:'15px',marginTop: '1px'}}/>    <div style={{marginLeft: '5px',marginRight: '7px',fontSize:'12px'}}>Class is full</div></div>
                                       
                                        ) 
                                        
                            )}
                                    </Div>
                                   
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>) 
               
            }

            {/* joinClass Popup */}
            <Dialog
                    open={joinClassOpen}
                    onClose={() => setjoinClassOpen(false)}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    sx={{
                        '& .MuiDialog-paper ':{
                            width: '720px',
                        }  
                    }} 
                    >
                
                    <DialogTitle id="scroll-dialog-title"> Join Class </DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description" 
                            tabIndex={-1}
                        >
                            <Box component="form"
                                 sx={{
                                    mx: -1, 
                                    '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                        p: 1,
                                        mb: 2,
                                        width: {xs: '100%', sm: '100%'}
                                    },
                                    '& .MuiFormControl-root.MuiFormControl-fluid': {
                                        width: '100%'
                                    }
                                }}
                                autoComplete="off"
                        >
                            <Grid container spacing={3.5}>                       
                                <Grid item xs={12} sm={12} lg={12}>
                            <Table> 
                            <TableBody>
                            <StyledTableRow key={"firstitem"}>
                                <TableCell sx={{pl: theme => theme.spacing(3)}}>
                                Wallet Balance
                                </TableCell>
                                 <TableCell align="right">  ${balanceAmount}</TableCell> 
                            </StyledTableRow>
                            <StyledTableRow key={"firstitem"}>
                                <TableCell sx={{pl: theme => theme.spacing(3)}}>
                                Cost of {values?.className} Class
                                </TableCell>
                                <TableCell align="right">  ${parseInt(values?.classLessionPrice ? values?.classLessionPrice : 0) * parseInt(values?.noOfLessons ? values?.noOfLessons : 0)}</TableCell> 
                            </StyledTableRow>
                            <StyledTableRow key={"firstitem"}>
                                <TableCell sx={{pl: theme => theme.spacing(3)}}>
                                Remaining Balance
                                </TableCell>
                                 <TableCell align="right"> ${parseInt(balanceAmount) - parseInt(values?.classLessionPrice ? values?.classLessionPrice : 0) * parseInt(values?.noOfLessons ? values?.noOfLessons : 0)}</TableCell> 
                            </StyledTableRow>
                            </TableBody>
                                    </Table>
                                </Grid>
                                </Grid>
                                             
                     </Box>
                  </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={() => setjoinClassOpen(false)}>{t('common.cancel')}</Button>
                        <Button variant={"contained"} disabled={btnDisable} style={{textTransform: 'none'}} onClick={() => handleCheckout(values)} >Checkout</Button>
                    </DialogActions>
                </Dialog>
          {/* Alert Dialog    */}
          <Dialog
                    open={alertOpen}  
                    onClose={() => setAlertOpen(false)}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{
                        '& .MuiDialog-paper ':{
                            width: '720px',
                        },  
                        '& .MuiDialogTitle-root' : {
                            padding: '16px 24px 0px 24px'
                        }
                    }}
                >
                   <DialogTitle id="scroll-dialog-title" style={{background: "#f397113d", marginBottom: '1px'}}>
                    <Grid xs={12} sm={12} lg={12}>                    
                        <div style={{ display: 'flex' }}><WarningIcon  color={'warning'} style={{ marginRight: '5px', marginTop: '3px' }} />
                        <Typography variant="h1"  color={'warning'}>{t('common.warning')}</Typography>
                        </div>                   
                    </Grid>
                </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    <Typography variant="h5" style={{padding: '10px'}}  color={'warning'}> {
                            (validateClassDatas && validateClassDatas.length > 0)  ? validateClassDatas[0].message : ''
                        } </Typography>
                        <Table> 
                            <TableBody>
                                {validateClassDatas.map((item, index) => {
                                    return (
                                        <StyledTableRow key={"firstitem"}>
                                            <TableCell sx={{ pl: theme => theme.spacing(3) }}>
                                               {item.className}
                                            </TableCell>
                                            <TableCell align="left">  {item.dayName} </TableCell>
                                            <TableCell align="right">   {item.classTime}</TableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                        {/* <Typography variant="h4" style={{marginTop: '20px', marginBottom: '0px'}}  color={'warning'}>  
                           {"Are you sure you want to proceed ?"}
                          </Typography> */}
                    </DialogContentText>
                    </DialogContent>
                <DialogActions> 
                        <Button variant={"outlined"}   style={{textTransform: 'none'}} onClick={() => setAlertOpen(false)}>Cancel</Button>
                        {/* <Button variant={"contained"}  style={{textTransform: 'none'}} onClick={() => handleProceed()}>Proceed</Button> */}
                    </DialogActions>
                </Dialog>
            
        </>
    );
};
CurrentPlan.propTypes = {
    values: PropTypes.object, 
    frompage : PropTypes.node,
    clickAction: PropTypes.func, 
};
export default CurrentPlan;
