import React from 'react';
import {CardContent,  FormControlLabel,Typography, Grid, Avatar,Radio, RadioGroup } from "@mui/material";
import Link from "@mui/material/Link"; 
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers"; 
import Button from "@mui/material/Button"; 
import {ASSET_AVATARS} from "../../../utils/constants/paths";
import { useSnackbar } from "notistack";
import ProfilePlan from "./profile-plan";
import ProfileList from "./profile-list";
import TutorProfileList from './tutor-profile-list';
import { useTranslation } from "react-i18next"; 
import { useNavigate } from "react-router-dom";
import { USERROLECODE } from '../../../utils/constants/constants'
import { useDispatch } from 'react-redux'
import {useMutation} from "react-query";
import { authServices } from "../../../services/auth/user";
import { store } from "../../../store/store"
import { Actions, setProfile} from '../../../store/actions'

const ChooseProfile = () => {
    const {setActiveLayout} = useJumboApp();   
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const [state, setState] = React.useState({      
        profile: '',
    });
    const dispatch = useDispatch()
    const loginDetails = store.getState().loginDetails;

    const { profile } = state;
    const {t} = useTranslation();
    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        
    };

    const mutation = useMutation(authServices.switchProfile, {
        onSuccess: (data) => {
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) { 
                // enqueueSnackbar(data?.message, { variant: "success" });                
                store.dispatch(setProfile(data?.body?.userRoleCode));
                localStorage.setItem("switchprofile", data?.body?.userRoleCode)
                navigate("/home", { replace: true });
                window.location.reload(false);
           }           
        },
        onError: (error) => {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        }
    });


    const handlesetProfile = () => {
     if (!profile) {
         enqueueSnackbar('Please select a profile', { variant: "warning" });
         return;
     }   
     const data = {
        userCode: loginDetails && loginDetails.userCode ? Number(loginDetails.userCode) : '',
        userRoleCode: profile === 'tutor' ? USERROLECODE.tutor : USERROLECODE.student,
        setDefaultRoleCode: true,
     }
     mutation.mutate(data);
        //navigate("/home", {replace: true}); 
    }

    return ( 
            <>
            <CardContent sx={{ pt: 2 }}>
            <Grid item xs={12} lg={12}  >
                <Link href="#" underline="none" sx={{display: 'inline-flex'}}>
                    <img src={`${ASSET_IMAGES}/logo.png`} alt="Logo"/>
                </Link>
                <Grid container xs={12} lg={12} style={{justifyContent: 'center', marginBottom: '1%' , marginTop: '-20px'}} >
                    <Typography variant={"h1"} mb={0}>I am a</Typography>
                </Grid>
            </Grid> 
            <Grid item xs={12} lg={12}>
                    <Grid container spacing={3.75} style={{justifyContent: 'center'}}>
                        <Grid item xs={12} md={6} lg={3}>
                        <ProfilePlan
                            title={ <Avatar
                            sx={{
                                width: 120,
                                height: 120,
                                // boxShadow: 2,
                                position: 'relative',
                            }}
                            src={getAssetPath(`${ASSET_AVATARS}/student.png`, "60x60")}
                            alt={""}
                            style={{backgroundColor: 'transparent', padding: '6%', display: 'inline-flex' }}
                        />}
                            subheader={t('profile.student')}
                            headerSx={{
                                textAlign: 'center',
                                bgcolor: '#002366',
                                borderRadius: '50%',
                                height: 224,
                                width: 224,
                                margin: theme => theme.spacing(5, 'auto', 0)
                            }} >
                                <ProfileList /> 
                                <RadioGroup
                                    row
                                    aria-label="profile"
                                    name="profile"
                                    value={profile}
                                    onChange={handleChange}  >
                                        <FormControlLabel
                                            value="student"
                                            control={<Radio/>}
                                            label="Set as default"
                                        />                           
                                </RadioGroup> 
                            </ProfilePlan> 
                        </Grid> 
                    <Grid item xs={12} sm={6} lg={3}>
                        <ProfilePlan
                            title={ <Avatar
                            sx={{
                                width: 120,
                                height: 120,
                                // boxShadow: 2,
                                position: 'relative',
                            }}
                            src={getAssetPath(`${ASSET_AVATARS}/tutor.png`, "60x60")}
                            alt={""}
                            style={{backgroundColor:  'transparent', padding: '6%', display: 'inline-flex' }}
                        />}
                        subheader={t('profile.tutor')}
                        headerSx={{
                            textAlign: 'center',
                            bgcolor: '#428d75',
                            borderRadius: '50%',
                            height: 224,
                            width: 224,
                            margin: theme => theme.spacing(5, 'auto', 0)
                        }} >
                           <TutorProfileList /> 
                            <RadioGroup
                                row
                                aria-label="profile"
                                name="profile"
                                value={profile}
                                onChange={handleChange} >
                                <FormControlLabel
                                    value="tutor"
                                    control={<Radio/>}
                                    label="Set as default"
                                />                      
                            </RadioGroup>
                         </ProfilePlan> 
                         <Grid style={{textAlign : 'right', marginTop: '10%'}}>
                         <Button variant={"contained"} style={{textTransform : 'none'}} color="primary" onClick={handlesetProfile} >Proceed</Button>
                         </Grid>
                        </Grid>
                    </Grid>
                </Grid>           
                
        </CardContent>
        </> 
        
    );
};

export default ChooseProfile;
