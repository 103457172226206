import React, {useState} from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import {alpha, Avatar,  ListItemAvatar, ListItemText, Typography, Tooltip, IconButton, Dialog, DialogTitle, 
    DialogContent, DialogActions,DialogContentText,Button,  Box, FormControl, Grid, 
    TextField} from "@mui/material"; 
import { ASSET_AVATARS } from "../../../utils/constants/paths"; 
import { getAssetPath } from "../../../utils/appHelpers"; 
import { useMutation } from "react-query";
import { classServices } from "../../../services/apis/classes"; 
import { useSnackbar } from "notistack"; 
import { useTranslation } from "react-i18next";   
import { authServices } from "../../../services/auth/user";   
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadDialog from '../../../pages/classes/uploadFiles/uploadDialog';
import { useQuery } from "react-query";
import {  useLocation } from "react-router-dom"; 
import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from "@mui/lab/LoadingButton";
import { store } from "../../../store/store"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MUIDataTable from "mui-datatables";
import { USERROLECODE } from '../../../utils/constants/constants'; 
var aws = require('aws-sdk');
 //  material ui datatable theme styles
 
const FileItem = ({ item, indexValue, profilerole ,success, handleClick, ipAddress}) => {  
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const [isSuccess, setSuccessfull] = React.useState(false);
    const  scroll  =  'paper' ;
    const [confirmDelete, setConfirmDelete] = React.useState("")
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false); 
    const [itemIndex, setItemIndex] = useState(null); 
    const credentialDetails = store.getState().credentials;
    const classDetails = store.getState().classDetails;
    const loginDetails = store.getState().loginDetails;
    const userData = [loginDetails ? parseInt(loginDetails.userCode) : 0, classDetails ? Number(classDetails.classCode) : '']; 
    const {data: classData} = useQuery(
        userData, classServices.getClasses
    );  
    React.useEffect(() => { 
        if (isSuccess) {  
            success(true)
            setSuccessfull(false)
        }
    }, [isSuccess, success]);  
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
 
    //Delete 
    const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
    const [scrollDelete, setScrollDelete] = React.useState('paper');

    const descriptionElementRef2 = React.useRef(null);
     React.useEffect(() => {
         if (openDeletePopup) {
             const {current: descriptionElement} = descriptionElementRef2;
             if (descriptionElement !== null) {
                 descriptionElement.focus();
             }
         }
     }, [openDeletePopup]);
    
    const handleEditFile = (rowIndex) => { 
        setItemIndex(rowIndex.rowIndex);
        setOpen(true)
    }

    const handleDeletePopup = (rowIndex) => {   
        setItemIndex(rowIndex.rowIndex);
        setOpenDeletePopup(true);
        setScrollDelete('paper');  
    }
    
    const clearData = () => {
        setOpenDeletePopup(false)
        setConfirmDelete('')
    }

     //uploadFiles api
     const deleteFilesApi = useMutation(classServices.deleteFile, {
        onSuccess: (data) => {   
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                setLoading(false);
                return;
        }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" });                
                clearData()
                success(true)
                setLoading(false); 
        }  
        },
        onError: (error) => {
            setLoading(false);
            enqueueSnackbar(error, { variant: "error" });
        }
    });

    const handleDeleteFile = async () => { 
            const data = {
                userCode: loginDetails ? Number(loginDetails.userCode) : '',
                userRoleCode: loginDetails ? Number(loginDetails.roleCode) : '',
                ipAddress: await authServices.getMyIpAddress(),
                fileCode: Number(item[itemIndex].fileCode)
            } 
            setLoading(true);
            //Delete  file in s3
            const s3 = new aws.S3({
                accessKeyId: credentialDetails?.awsAccessKey,
                secretAccessKey: credentialDetails?.awsSecretKey,
                Bucket: credentialDetails?.s3bucket,
                region: credentialDetails?.region
            });
            if (item[itemIndex].filePath && item[itemIndex].filePath.split("/").length > 5) {
                await s3.deleteObject({ Bucket: credentialDetails?.s3bucket, Key: item[itemIndex].filePath.split("/")[3]+'/' + item[itemIndex].filePath.split("/")[4]+'/'+ item[itemIndex].filePath.split("/")[5]+'/'+ item[itemIndex].filePath.split("/")[6] +'/'+ item[itemIndex].filePath.split("/")[7] }, (err, data) => { 
                });
            }
            deleteFilesApi.mutate(data)
    } 


    const handleDownloadFiles = (indexval, e) => {
        if (Number(profilerole) === USERROLECODE.student) {
            setItemIndex(indexval) 
            if (item[indexval].filePath) {
                fetch(item[indexval].filePath, {
                    method: 'GET',
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        // Create blob link to download
                        const url = window.URL.createObjectURL(
                            new Blob([blob]),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            item[indexval].fileOriginalName + '.' + item[indexval].fileType,
                        );
                        // Append to html link element page
                        document.body.appendChild(link);
                        // Start download
                        link.click();
                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                    });
            }
        }
        e.preventDefault();
    }

   const formatBytes = (bytes) => {        
        return bytes ? (bytes / Math.pow(1024,2)).toFixed(2) : ''
    }
 
    const columns = [
    {
        label: 'S.No.',
        field: 'sno',
        name: 'sno',
        options: {
        filter: false,
        setCellProps: () => ({
            style: {
            textAlign: 'center',
            width: '5%',
            position: 'sticky',
            },
        }),
        setCellHeaderProps: () => ({
            style: {
            textAlign: 'center',
            width: '5%',
            position: 'sticky',
            },
        }),
        customBodyRender: (rowData, rowIndex) => (
            <Box>{rowIndex.rowIndex + 1}</Box>
        ),
        },
    },

    {
        label: 'File Name', 
        name: 'fileOriginalName',
        options: {
        filter: true,
        filterType: 'textField',
        setCellProps: () => ({
            style: {
            textAlign: 'left',
            width: '20%',
            },
        }),
        setCellHeaderProps: () => ({
            style: {
            textAlign: 'left',
                width: '20%' ,
                cursor: 'pointer'
            },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
               
            <div style={{ cursor: 'pointer'}}  onClick={(e) => handleDownloadFiles(tableMeta.rowIndex, e)}>
                {tableMeta.rowData[1]} 
            </div>
            );
        },
        },
    },
    {
        label: 'Created At',
        name: 'createdAt',
        options: {
        filter: false,  
        filterType: 'textField',
        setCellProps: () => ({
            style: {
                textAlign: 'left',
                width: '20%',
            },
        }),
        setCellHeaderProps: () => ({
            style: {
                textAlign: 'left',
                width: '20%',
            },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
            return <div>{tableMeta.rowData[2]}</div>;
        },
        },
        },  
        {
        label: 'File Size',
        name: 'fileSize',
        options: {
            filter: false,  
            filterType: 'textField',
            setCellProps: () => ({
            style: {
                    textAlign: 'left',
                    width: '20%',
            },
            }),
            setCellHeaderProps: () => ({
            style: {
                    textAlign: 'left',
                    width: '20%',
            },
            }),
            customBodyRender: (value, tableMeta, updateValue) => {
            return <div>{formatBytes(tableMeta.rowData[3])} MB </div>;
            },
        },
        }, 
        {
            label: 'File Type',
            name: 'fileType',
            options: {
                filter: false,  
                sort: false,
                filterType: 'textField',
                setCellProps: () => ({
                style: {
                        textAlign: 'left',
                        width: '20%',
                },
                }),
                setCellHeaderProps: () => ({
                style: {
                        textAlign: 'left',
                        width: '20%',
                        cursor: 'pointer'
                },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div style={{ cursor: 'pointer' }} onClick={(e) => handleDownloadFiles(tableMeta.rowIndex, e)}> 
                        {tableMeta.rowData[4] === 'txt' && (
                    <Avatar src={getAssetPath(`${ASSET_AVATARS}/txt.png`,"40x40")} style= {{overflow: 'initial'}}/> 
                )}
                {(tableMeta.rowData[4] === 'docx' || tableMeta.rowData[4] === 'doc') && ( 
                    <Avatar src={getAssetPath(`${ASSET_AVATARS}/doc.png`,"40x40")} style= {{overflow: 'initial'}}/> 
                )}
                {(tableMeta.rowData[4] === 'xls' || 
               tableMeta.rowData[4] === 'csv' || tableMeta.rowData[4] === 'xlsx') && (
                    <Avatar src={getAssetPath(`${ASSET_AVATARS}/xls.png`,"40x40")} style= {{overflow: 'initial'}}/> 
                )}
                {(tableMeta.rowData[4] === 'png' || 
                tableMeta.rowData[4] === 'jpg' ||  tableMeta.rowData[4] === 'jpeg') && (
                    <Avatar src={getAssetPath(`${ASSET_AVATARS}/image.png`,"40x40")} style= {{overflow: 'initial'}}/> 
                )}
                {tableMeta.rowData[4] === 'pdf' && (
                    <Avatar src={getAssetPath(`${ASSET_AVATARS}/pdf.png`,"40x40")} style= {{overflow: 'initial'}}/> 
                )}
                 {tableMeta.rowData[4] === 'ppt' && (
                    <Avatar src={getAssetPath(`${ASSET_AVATARS}/ppt.png`,"40x40")} style= {{overflow: 'initial'}}/> 
                        )}
                     { (tableMeta.rowData[4] !== 'ppt' && tableMeta.rowData[4] !== 'pdf' && tableMeta.rowData[4] !== 'png' &&   tableMeta.rowData[4] !== 'jpg' &&  tableMeta.rowData[4] !== 'jpeg' && tableMeta.rowData[4] !== 'xls' && tableMeta.rowData[4] !== 'csv' && tableMeta.rowData[4] !== 'xlsx' && tableMeta.rowData[4] !== 'docx' && tableMeta.rowData[4] !== 'doc' && tableMeta.rowData[4] !== 'txt'   ) && <Avatar alt={tableMeta.rowData[4]} src={getAssetPath(`${ASSET_AVATARS}/files.png`, "40x40")} style={{ overflow: 'initial' }} /> }
                    </div>;
                    
                   
                },
            },
        }, 
        {
            label: 'Option',
            name: 'option',
            options: {
                filter: false,  
                sort: false,
                filterType: 'textField',
                setCellProps: () => ({
                style: {
                        textAlign: 'left',
                        width: '20%',
                },
                }),
                setCellHeaderProps: () => ({
                style: {
                        textAlign: 'left',
                        width: '20%',
                },
                }),
                customBodyRender: (rowData,  rowIndex) => {
                    return <div>
                         {Number(profilerole) === USERROLECODE.student && (  
                            <Tooltip title="Download">
                            <IconButton    onClick={(e) => handleDownloadFiles(rowIndex.rowIndex, e)}>
                                <CloudDownloadIcon fontSize={"small"}/>
                            </IconButton>
                            </Tooltip> )}
                        {Number(profilerole) === USERROLECODE.tutor && (     
                    <Tooltip title={"Edit"}>
                        <IconButton>
                        <EditIcon fontSize={"small"} onClick={() => handleEditFile(rowIndex)}/>
                        </IconButton>
                    </Tooltip> )}
                    {Number(profilerole) === USERROLECODE.tutor && ( 
                    <Tooltip title="Delete">
                        <IconButton>
                        <DeleteIcon fontSize={"small"} onClick={(e) => handleDeletePopup(rowIndex)} />
                        </IconButton>
                    </Tooltip> )} </div>;
                },
            },
         },
    ]; 
    // options for material datatable
  const options = {
    body: {
      noMatch: 'Sorry, no matching records found',
    },
    download: false,
      print: false, 
      filter: false,
    responsive: 'standard',
    fixedHeader: true,
    selectableRows: false,
    fixedSelectColumn: true,
    tableBodyHeight: '500px' ,
    viewColumns:false
  };
   
        
    return (
        < div style={{
            '& tfoot': {
                   display: 'none'
               }
           }}> 
             <MUIDataTable
                  title={''}
                  data={item}
                  columns={columns}
                  options={options}
                
                /> 
        {/* <ListItemButton
            component={"li"}
                onClick={(e) => handleDownloadFiles(item, e)}
                sx={{
                p: theme => theme.spacing(1.25, 3),
                transition: 'all 0.2s',
                borderBottom: '1px solid #0000001a',
                '&:hover': {
                    boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                    transform: 'translateY(-4px)', 
                    '& .MuiChip-animation': {
                        width: 'auto',
                        height: 'auto',
                        fontSize: 12,
                        display: 'block',
                    }
                }
            }}
        >
            
             <ListItemAvatar sx={{ display: { xs: 'none', md: 'block' }}}> 
            
             {item['fileType'] === 'txt' && (
                 <Avatar src={getAssetPath(`${ASSET_AVATARS}/txt.png`,"40x40")} style= {{overflow: 'initial'}}/> 
             )}
             {item['fileType'] === 'docx' && (
                 <Avatar src={getAssetPath(`${ASSET_AVATARS}/doc.png`,"40x40")} style= {{overflow: 'initial'}}/> 
             )}
             {(item['fileType'] === 'xls' || 
             item['fileType'] === 'csv' || item['fileType'] === 'xlsx') && (
                 <Avatar src={getAssetPath(`${ASSET_AVATARS}/xls.png`,"40x40")} style= {{overflow: 'initial'}}/> 
             )}
             {(item['fileType'] === 'png' || 
             item['fileType'] === 'jpg' ||  item['fileType'] === 'jpeg') && (
                 <Avatar src={getAssetPath(`${ASSET_AVATARS}/image.png`,"40x40")} style= {{overflow: 'initial'}}/> 
             )}
             {item['fileType'] === 'pdf' && (
                 <Avatar src={getAssetPath(`${ASSET_AVATARS}/pdf.png`,"40x40")} style= {{overflow: 'initial'}}/> 
             )}
              {item['fileType'] === 'ppt' && (
                 <Avatar src={getAssetPath(`${ASSET_AVATARS}/ppt.png`,"40x40")} style= {{overflow: 'initial'}}/> 
             )}               
            </ListItemAvatar>
            <ListItemText                          
                primary={
                <Typography variant={"body1"} component={"div"}>
                    <Typography variant={"h6"}>{item?.fileOriginalName}</Typography>
                        <Typography
                            variant={"body1"}
                            color={'text.secondary'}                                        
                        >
                       <Typography variant={"span"}>Created At: </Typography> 
                       {item?.createdAt} | <Typography variant={"span"}>File Size: </Typography>
                        {formatBytes(item?.fileSize)} MB
                        </Typography>                                                        
                </Typography>
                }
                sx={{flex: 1}}
            />

                {Number(profilerole) === USERROLECODE.student && (  
                <Tooltip title="Download">
                <IconButton >
                    <CloudDownloadIcon fontSize={"small"}/>
                </IconButton>
                </Tooltip> )}

            {Number(profilerole) === USERROLECODE.tutor && (     
            <Tooltip title={"Edit"}>
                <IconButton>
                <EditIcon fontSize={"small"} onClick={() => handleEditFile(item)}/>
                </IconButton>
            </Tooltip> )}
            {Number(profilerole) === USERROLECODE.tutor && ( 
            <Tooltip title="Delete">
                <IconButton>
                <DeleteIcon fontSize={"small"} onClick={(e) => handleDeletePopup(item)} />
                </IconButton>
            </Tooltip> )}
        </ListItemButton>    */}
       
       
         {/* upload File Popup */}
        {open && <UploadDialog open={open} scroll={scroll} classesFolderList = {classData?.classesFolderList} 
        onClose={setOpen} folderCode={location?.state?.filedetails?.folderCode} 
        file={item[itemIndex]}  isSuccessful={setSuccessfull} />}   

        {/* Delete Alert */}
        <Dialog
            open={openDeletePopup}
            onClose={() => setOpenDeletePopup(false)}
            scroll={scrollDelete}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                '& .MuiDialog-paper ':{
                    width: '720px',
                }  
            }}
        >
            <DialogTitle id="scroll-dialog-title" style={{background: "#f397113d", marginBottom: '1px'}}>
                <Grid xs={12} sm={12} lg={12}>                    
                    <div style={{ display: 'flex' }}><WarningIcon  color={'warning'} style={{ marginRight: '5px', marginTop: '3px' }} />
                    <Typography variant="h1"  color={'warning'}>{t('common.warning')}</Typography>
                    </div>                   
                </Grid>
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef2}
                    tabIndex={-1}
                >
                    <Box component="form"
                    sx={{
                        mx: -1, 
                        '& .MuiFormControl-root:not(.MuiTextField-root)': {
                            p: 1,
                            mb: 2,
                            width: {xs: '100%', sm: '100%'}
                        },
                        '& .MuiFormControl-root.MuiFormControl-fluid': {
                            width: '100%'
                        }
                    }}
                    autoComplete="off"
                >
                <FormControl size="small">
                    <Grid container spacing={3.5} style={{marginBottom: '3%'}}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <Typography variant="h4">{t('class.deleteFilesMsg')}</Typography>
                            <Typography variant="p">{t('class.deleteFilesMsg1')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3.5}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="delete"
                                label={t('class.confirmDelete')}
                                size="small"
                                value={confirmDelete}
                                onChange={e => setConfirmDelete(e.target.value)}
                            />
                        </Grid>                       
                    </Grid>                                               
                </FormControl>
            
            </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} 
                onClick={() => clearData()}>{t('common.cancel')}</Button>
                <LoadingButton   
                                    style={{textTransform: 'none'}}                                 
                                    variant="contained"
                                    size="medium"
                                    loading={isLoading}
                                    disabled={confirmDelete && confirmDelete === 'DELETE' ? false : true }  
                                    onClick={(e) => handleDeleteFile(e)}>{t('class.deleteFile')}
                                    </LoadingButton>
                {/* <Button variant={"contained"} 
                 disabled={confirmDelete && confirmDelete === 'DELETE' ? false : true } 
                 style={{textTransform: 'none'}} onClick={(e) => handleDeleteFile(e)}>{t('class.deleteFile')}</Button> */}
            </DialogActions>
        </Dialog>  
        </div> 
    );
};
 
/* Todo item prop define */
export default FileItem;
