import React from 'react';
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import { connect } from "react-redux";
import MessagesDropdown from "../../../../shared/MessagesDropdown";
import NotificationsDropdown from "../../../../shared/NotificationsDropdown";
import SearchGlobal from "../../../../shared/SearchGlobal";
import {IconButton, Slide, useMediaQuery, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS, SIDEBAR_VIEWS} from "@jumbo/utils/constants";
import {useJumboHeaderTheme} from "@jumbo/hooks";
import StoreIcon from '@mui/icons-material/Store';  
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'; 
import VoiceChatOutlinedIcon from '@mui/icons-material/VoiceChatOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';  
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { classServices } from "../../../../services/apis/classes";  
import { setNotificationCount } from '../../../../store/actions';
import { useQuery } from "react-query";
import { store } from "../../../../store/store";
import { isMobile, isBrowser } from 'react-device-detect';
import { authServices } from "../../../../services/auth/user";
import {useNavigate} from "react-router-dom";
const Header = (props) => {
    const { menunames, loginDetails } = props; 
    const navigate = useNavigate();
    const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
    const [dropdownSearchVisibility, setDropdownSearchVisibility] = React.useState(false);
    const { headerTheme } = useJumboHeaderTheme();
    const [menuname, setMenuName] = React.useState(''); 
    const showDropdownSearch = useMediaQuery('(max-width:575px)');
    const handleSidebar = () => { 
        if (isMobile) {
            setSidebarOptions({variant: SIDEBAR_VARIANTS.PERSISTENT, view: SIDEBAR_VIEWS.full,style: SIDEBAR_STYLES.FULL_HEIGHT, open: !sidebarOptions.open}); 
        }  
        if (isBrowser) {
            setSidebarOptions({variant: SIDEBAR_VARIANTS.PERMANENT, view: SIDEBAR_VIEWS.full,style: SIDEBAR_STYLES.FULL_HEIGHT, open: !sidebarOptions.open}); 
        }   
    }
    
    const userDetails = [loginDetails ? loginDetails.userCode : 0 , loginDetails ? loginDetails.roleCode : 0, 0, 100];  
    const {data: notifications , refetch: refetchNotififcationList  } = useQuery(
        userDetails, classServices.notificationList
    ); 
    // const menunames = store.getState().menunames;
    React.useEffect(() => { 
        setMenuName(menunames); 
    }, [menunames]);

    React.useEffect(() => { 
        store.dispatch(setNotificationCount(notifications));  
    }, [notifications]);
    
    const MINUTE_MS = 30000;
    //Check Jwt token issue
    //  const {data: result, refetch: refetchLoginDetails  } = useQuery(
    //     [], authServices.retrieveUserDetails
    // );   
    // React.useEffect(() => { 
    //     const token = localStorage.getItem("jwtToken");
    //     if (result && token) {  
    //         if (result?.statusFlag === 2) {
    //             localStorage.clear()  
    //             console.log('notification logout')
    //             navigate('/')
    //         }   
    //     } 
    // }, [result]);
    React.useEffect(() => {
      const interval = setInterval(() => {
        //   console.log('Logs every minute');
          refetchNotififcationList().catch(console.error) 
        //   refetchLoginDetails().catch(console.error) 
      }, MINUTE_MS);
    
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [refetchNotififcationList])


    return (
        <React.Fragment >
            {
                (sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER || (sidebarOptions.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && !sidebarOptions?.open)) &&
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
                            mr: 3,
                        }}
                        onClick={handleSidebar}
                    >
                        {
                            sidebarOptions?.open ? <MenuOpenIcon/> : <MenuIcon/>
                        }
                    </IconButton>
            }
            {
                sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <Logo sx={{mr: 3}} mode={headerTheme.type ?? "light"}/>
            }
            {/* {
                showDropdownSearch &&
                <Slide in={dropdownSearchVisibility}>
                    <Div
                        sx={{
                            zIndex: 5,
                            left: 0,
                            right: 0,
                            position: 'absolute',
                            height: '100%',
                        }}
                    >
                        <SearchGlobal
                            sx={{
                                maxWidth: 'none',
                                height: '100%',
                                display: 'flex',

                                '& .MuiInputBase-root': {
                                    flex: 1,
                                    borderRadius: 0,
                                    background: theme => theme.palette.background.default,
                                },
                                '& .MuiInputBase-input': {
                                    pr: 6,
                                }
                            }}
                        />
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: 15,
                                top: '50%',
                                color: 'inherit',
                                transform: 'translateY(-50%)',
                            }}
                            onClick={() => setDropdownSearchVisibility(false)}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Div>
                </Slide>
            } */}
            {
                !showDropdownSearch &&
                <SearchGlobal
                    sx={{
                        maxWidth: {xs: 240, md: 320}
                    }}
                />
            }
           
           
            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center",marginTop:'6px' }}>
                {(menuname === "Home" || !menuname) && <HomeOutlinedIcon fontSize={"large"} style={{ marginRight: '5px',marginTop: '-5px'}} />} 
                {menuname === "My Classes" && <VoiceChatOutlinedIcon fontSize={"large"} style={{ marginRight: '5px', marginTop: '-5px' }} />}
                {menuname === "Calendar" && <DateRangeIcon fontSize={"large"} style={{ marginRight: '5px', marginTop: '-5px' }} />}
                {menuname === "Chat" && <ChatOutlinedIcon fontSize={"large"} style={{ marginRight: '5px', marginTop: '-5px' }} />}
                {menuname === "Finance" && <MonetizationOnOutlinedIcon fontSize={"large"} style={{ marginRight: '5px',marginTop: '-5px'}} />}
                {menuname === "Marketplace" && <StoreIcon fontSize={"large"} style={{ marginRight: '5px', marginTop: '-5px' }} />}
                {menuname === "Profile" && <PermIdentityIcon fontSize={"large"} style={{ marginRight: '5px', marginTop: '-5px' }} />}
                <Typography variant="h1" style={{fontSize: '30px'}}> {menuname ? menuname : "Home"} </Typography> 
              </div>
             
            
            <Stack direction="row" alignItems="center" spacing={1.25} sx={{ ml: "auto" }}> 
                {/* {
                    showDropdownSearch &&
                    <JumboIconButton elevation={25} onClick={() => setDropdownSearchVisibility(true)}>
                        <SearchIcon fontSize={"small"}/>
                    </JumboIconButton>
                } */}
                <MessagesDropdown/>
                <NotificationsDropdown/>
                <AuthUserDropdown/>
            </Stack>
           
           
        </React.Fragment>
    );
};
const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  }; 
  export default connect(mapStoreStateToProps)(Header);
// export default Header;
