import React  from 'react';
import FileItem from "./FileItem"; 
import { List, Typography } from "@mui/material"; 
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { authServices } from "../../../services/auth/user";  
import { USERROLECODE } from '../../../utils/constants/constants'

const FilesList = ({ fileList ,isSuccessful } ) => {  
    const { t } = useTranslation();
    const [isSuccess, setSuccess] = React.useState(false);
    const ipAddress =   authServices.getMyIpAddress();
    const navigate = useNavigate();
    
    React.useEffect(() => {
        if (isSuccess) {   
            setSuccess(false)
            isSuccessful(true)
        }
    }, [isSuccess,isSuccessful]);  
    const handleEditAnnouncement = (item) => {
    navigate("/classes/createannouncement", { state: {frompage:"home", announcementData : item} });
    } 
    const profilerole = Number(localStorage.getItem("switchprofile"));
    return (
        <>
        <List disablePadding sx={{mb: 1}}>
            {
                fileList?.length > 0 ? (
                    <FileItem item={fileList}   indexValue={1} profilerole={profilerole} success={setSuccess} handleClick={() => handleEditAnnouncement(fileList)} ipAddress = {ipAddress}/>
                )  : <div>
                {profilerole === USERROLECODE.student && (
                <Typography variant={"h2"} mb={.5} style={{textAlign: 'center'}}>{t('class.noFilesStudent')}</Typography> )
                }
                {profilerole === USERROLECODE.tutor && (
                <Typography variant={"h6"} mt={2} style={{ textAlign: 'center' }}>{t('class.noFilesTutor')}</Typography> )
                }</div>
            }
           
            </List>  
            </>
    );
};

export default FilesList;
