import React from 'react';
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import SentMessageContent from "./SentMessageContent";
import ReceivedMessageContent from "./ReceivedMessageContent";
import { store } from '../../../../../../store/store';

const ActiveConversationChat = ({conversation}) => {
    const loginDetails = store.getState().loginDetails;
    const userCode = loginDetails ? loginDetails.userCode : 0
    return (
        <React.Fragment>
            {conversation?.createddate && (<Div sx={{
                position: 'relative',
                textAlign: 'center',

                '&:after': {
                    display: 'inline-block',
                    content: "''",
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    height: '1px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'divider',
                },
            }}>
                <Chip
                    label={conversation?.createddate}
                    variant="outlined"
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        fontSize: '10px',
                        bgcolor: theme => theme.palette.background.paper,
                        borderColor: 'divider',
                        borderRadius: 2
                    }}
                />
            </Div>)} 
                <React.Fragment> 
                {conversation?.senderId === userCode ? (
                    <SentMessageContent message={conversation} />

                ) : (
                        <ReceivedMessageContent message={conversation} />
                    )}
                
                </React.Fragment>
            
        </React.Fragment>
    );
};
/* Todo conversation prop define */
export default ActiveConversationChat;
