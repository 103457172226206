import React, {useState} from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import AnnouncementList from "./AnnouncementList";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {useTranslation} from "react-i18next";
import CampaignIcon from '@mui/icons-material/Campaign';
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles"; 
import Pagination from "@jumbo/components/Pagination";
import { classServices } from "../../../services/apis/classes"; 
import { useQuery } from "react-query";
import { store } from "../../../store/store"
import { USERROLECODE } from '../../../utils/constants/constants'

const useStyles = makeStyles({ 
    pagination: {
        padding: '0px ', 
        borderRadius: '6px',
        textAlign: 'center',
        display: 'table',
        margin: '0 auto',  
        width: '100%',
        marginTop: '10px',
    }
});
const Announcements = ({frompage,classCode}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const loginDetails = store.getState().loginDetails;
    const profilerole = Number(localStorage.getItem("switchprofile"));

    const  limit  = 10 ;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isSuccess, setSuccess] = useState(false);
    let pageSize = 10; 
    const userDetails = [loginDetails ? loginDetails.userCode : 0 , loginDetails ? loginDetails.roleCode : 0, offset, limit, classCode ? Number(classCode) : '',frompage]; 
    // const userDetails = [1 , 2, offset, limit]; 
    const {data: result, refetch: refetchAnnouncementList } = useQuery(
        userDetails, classServices.announcementList
    );  
    const handlePagination = (page) => {
        let offsetVal = page * pageSize - pageSize;
        setOffset(offsetVal);
        setCurrentPage(page); 
        refetchAnnouncementList().catch(console.error) 
    }
    React.useEffect(() => {
        if (isSuccess ) { 
            setSuccess(false) 
            refetchAnnouncementList().catch(console.error)
        }
    }, [isSuccess, refetchAnnouncementList]); 
    const announcementheight = profilerole === USERROLECODE.student ? 390 : 325
    return (
        <>            
            {!classCode && (
            <JumboCardQuick         
                title={
                    <div style={{ display: 'flex' }}><CampaignIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                        <Typography variant="h1" mb={3}>{t("widgets.title.recentTicket")}</Typography>
                    </div>} wrapperSx={{p: 0}}
                >
                <JumboScrollbar
                    autoHeight
                    autoHeightMin={announcementheight}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={200}
                >
                        <AnnouncementList announcementList={result && result.announcementList} deleteSuccess={setSuccess} frompage="home"/>
                </JumboScrollbar>
            </JumboCardQuick>
            )}
            {classCode && (
                <AnnouncementList announcementList={result && result.announcementList} deleteSuccess={setSuccess} frompage="classes"/>
            )}
            {result && parseInt(result?.totalcount) > 10 && <div className={classes.pagination}>
                <Pagination
                    currentPage={currentPage}
                    totalCount={result ? parseInt(result?.totalcount) : 0}
                    pageSize={pageSize}
                    primaryColor="#814DFA"
                    onPageChange={handlePagination} 
                />
            </div>}
        </>
    );
};

export default Announcements;
