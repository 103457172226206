import React from 'react';
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from "@mui/material/IconButton";
import {ListItemButton, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import { store } from '../../../store/store';

const MessageItem = ({item}) => {
    const navigate = useNavigate();
    const showChat = (row, e) => {   
        localStorage.setItem("ChatPerson", row?.username);
        localStorage.setItem("ChatPersonProfilePic", row?.profilepic);
        localStorage.setItem("ChatPersonOnlineStatus", row?.onlineStatus);
        localStorage.setItem("ChatPersonProfileID",  row?.senderId);
        navigate('/chats/conversation/' + row?.senderId, 
        { state: {frompage:"chat", isRefresh : true } }) 
    }
    return (
        <ListItemButton component={"li"} disableRipple onClick={(e) => showChat(item, e)}>
            <ListItemAvatar>
                <Avatar src={item.profilepic}/>
            </ListItemAvatar>
            <ListItemText primary={<Typography variant={"h6"} mb={.25}>{item.username}</Typography>}
                          secondary={<Typography noWrap color={"text.secondary"}>{item.message}</Typography>}
            />
            {/* <IconButton elevation={1} edge={"end"} size={"small"}><MoreHorizIcon/></IconButton> */}
        </ListItemButton>
    );
};

export default MessageItem;
