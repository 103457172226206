import React from 'react';
import {useJumboTheme} from "@jumbo/hooks";
import {IconButton, Typography, useMediaQuery} from "@mui/material";
import Div from "@jumbo/shared/Div";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Avatar from "@mui/material/Avatar"; 
import Badge from "@mui/material/Badge";  
import {useNavigate} from "react-router-dom";
import { connect } from "react-redux";

const ActiveConversationHeader = (props) => {
    // const { activeConversation } = useChatApp();
    const { chatperson, chatpersonpic, chatPersonOnlineStatus } = props;
    const navigate = useNavigate();
    const {theme} = useJumboTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const handleCloseConversation = () => {
        navigate(`/chats`);
    }
    if (!chatperson)
        return null;
    return (
        <Div
            sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                borderBottom: 1,
                borderBottomColor: 'divider',
                background: 'white',
                p: theme => theme.spacing(2, 3),
            }}
        >
            {
                md &&
                <IconButton aria-label="Previous" sx={{mr: 2}} onClick={handleCloseConversation}>
                    <ArrowBackIcon/>
                </IconButton>
            }
            <Div
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flex: 1,
                    mr: 2
                }}
            >
                <Avatar
                    alt={chatperson}
                    src={chatpersonpic}
                    sx={{mr: 2}}
                />
                <Div>
                    <Typography variant={'h5'} mb={.5}>
                        {chatperson} 
                    </Typography>
                    <Typography variant={"body1"} color={'text.secondary'} sx={{textTransform: 'capitalize'}}>
                        <Badge
                            overlap="circular"
                            variant="dot"
                            sx={{
                                m: theme => theme.spacing(-.25, 2, 0, 1), 
                                '& .MuiBadge-badge': {
                                    height: 10,
                                    width: 10,
                                    borderRadius: '50%',
                                    backgroundColor: ( (chatPersonOnlineStatus === true  || chatPersonOnlineStatus === "true" ) ? '#72d63a' : '#F7BB07'
                                    )
                                }
                            }}
                        />{"online"}
                        {/* {activeConversation?.contact?.status} */}
                    </Typography>
                </Div>
            </Div>
             
        </Div>
    );
};

const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
};
export default connect(mapStoreStateToProps)(ActiveConversationHeader); 