import React, {useEffect} from 'react';
import ListSubheader from "@mui/material/ListSubheader";
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import {useJumboLayoutSidebar} from "@jumbo/hooks";
import {SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import {useTranslation} from "react-i18next"; 
import { USERROLES } from '../../../app/utils/constants/constants'  
import { connect } from "react-redux";
// import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import { Button, Tooltip,IconButton} from "@mui/material"; 

const JumboNavSection = ({item, translate, switchprofile, handleSwitchProfile}) => {
    const { sidebarOptions } = useJumboLayoutSidebar();
    const {t} = useTranslation(); 
    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    const label = React.useMemo(() => {
        return translate ? t(item.label) : item.label;
    }, [item, translate, t]);

    if (!item || !item.label) return null; 
    const subMenus = (item && item.children && item.children.length > 0) ? item.children : null;
    const profilerole = localStorage.getItem("switchprofile");    
    const profileCode = switchprofile ? switchprofile : profilerole ? profilerole : ''
    const profile = USERROLES.find(role => role.userRoleCode === Number(profileCode));
    
    return (
        <>        
            {
              
                <ListSubheader
                    component="li"
                    disableSticky
                    sx={{
                        fontSize: '100%',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        textTransform: 'uppercase',
                        bgcolor: 'transparent',
                        padding: '3px 30px 3px',
                        marginBottom: '15%'
                        //  theme => theme.spacing(3.75, 3.75, 1.875)
                    }}
                >
                    {!isMiniAndClosed ?
                        <Button
                            fullWidth
                            disableElevation
                            variant={"contained"}
                            // endIcon={<RepeatOutlinedIcon />}
                            sx={{
                                mb: 1,
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.5rem'
                                },
                                fontSize: '14px',
                                textTransform: 'none'
                            }}
                            onClick={handleSwitchProfile}
                        > {profile?.userRoleName}</Button>
                        :
                        <Tooltip title={profile?.userRoleName}>
                             <span onClick={handleSwitchProfile} style={{paddingLeft: 0, cursor:'pointer', 
                                paddingLeft: "3px",
                                /* margin: 0 0 0.5rem; */
                                fontSize: '1.5rem',
                                lineHeight: 1.2,
                                fontWeight: 400,
                                color: '#37373C',
                                fontFamily: 'NoirPro,Arial'}}>{profile?.userRoleName.slice(0,1)}</span>
                         </Tooltip>
                        
                    }
                    </ListSubheader> 
            }
            {
                subMenus &&
                subMenus.map((child, index) => {
                    return (<JumboNavIdentifier item={child} key={index}/>)
                })
            }
        </>
    )
};
const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
};
export default connect(mapStoreStateToProps)(React.memo(JumboNavSection)); 
//todo: put an equal deep check for the props
// export default React.memo(JumboNavSection);
