import React from 'react';
import PropTypes from 'prop-types';

import {CssBaseline, Toolbar} from "@mui/material";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS, SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import JumboLayoutHeader from "./JumboLayoutHeader";
import JumboLayoutFooter from "./JumboLayoutFooter";
import JumboLayoutSidebar from "./JumboLayoutSidebar";
import Div from "@jumbo/shared/Div";
import {useJumboLayoutHeader} from "@jumbo/hooks";
import useJumboLayoutRoot from "@jumbo/hooks/useJumboLayoutRoot";
import useJumboLayoutContent from "@jumbo/hooks/useJumboLayoutContent";
import { useLocation } from "react-router-dom";
// import { USERROLECODE } from '../../../utils/constants/constants' 
const profilerole = localStorage.getItem("switchprofile");
const Background_color = Number(profilerole) === 2 ? 'CmtLayout-root tutor_backgroundcolor' : 'CmtLayout-root student_backgroundcolor';
const JumboLayout = (props) => {
    const {sidebarOptions} = useJumboLayoutSidebar();
    const {headerOptions} = useJumboLayoutHeader();
    const {rootOptions} = useJumboLayoutRoot();
    const {contentOptions} = useJumboLayoutContent();
    const location = useLocation();

    const contentMargin = React.useMemo(() => {
        if (sidebarOptions?.variant === SIDEBAR_VARIANTS.TEMPORARY) {
            return 0;
        } else if (sidebarOptions?.view === SIDEBAR_VIEWS.MINI) {
            return sidebarOptions?.minWidth;
        }
        return sidebarOptions?.open ? sidebarOptions?.width : 0;
    }, [
        sidebarOptions?.open,
        sidebarOptions?.width,
        sidebarOptions?.minWidth,
        sidebarOptions?.view,
        sidebarOptions?.style,
        sidebarOptions?.variant,
    ]);

    const headerHeightProps = React.useMemo(() => {
        if (props?.headerSx?.height) {
            return {height: props?.headerSx?.height}
        }
        return {};
    }, [props?.headerSx]);
    return (
        <Div
            sx={{
                display: 'flex',
                flex: 1,
                minWidth: 0,
                minHeight: '100%',
                flexDirection: 'column',
                ...rootOptions?.sx
            }}
            className={Background_color}
        >
            <CssBaseline/>
            {
                sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <JumboLayoutHeader sx={props.headerSx}  style={{backgroundColor:'red'}}>
                    {props.header}
                </JumboLayoutHeader>
            }

            <Div
                sx={{
                    display: 'flex',
                    flex: 1,
                    minWidth: 0,
                    position: 'relative',
                }}
                className="CmtLayout-wrapper"
            >
                <JumboLayoutSidebar headerHeightProps={headerHeightProps}>
                    {props.sidebar}
                </JumboLayoutSidebar>
                <Div
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        flex: 1,
                        flexDirection: 'column',
                        minHeight: '100%',
                        marginLeft: {
                            sm: `${contentMargin}px`
                        },
                        transition: theme => theme.transitions.create(['margin-left']),
                    }}
                    className='CmtLayout-main'
                >

                    {
                        sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                        <JumboLayoutHeader  sx={Number(profilerole) === 2 ? {backgroundColor:'#fff9de',backgroundImage:'linear-gradient(90deg, #fff9de 0%, #fff1c424 30%)', position: 'absolute'} : {backgroundColor:'#dff3f8', backgroundImage:'linear-gradient(90deg, #dff3f8 0%, #7ee8fa00 30%)', position: 'absolute'}}>
                            {props.header}
                        </JumboLayoutHeader>
                    }
                    {
                        !headerOptions.hide && headerOptions.fixed &&
                        <Toolbar sx={{...headerHeightProps}}/>
                    }
                    <Div
                        sx={sidebarOptions?.view === SIDEBAR_VIEWS.MINI ? {
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            pb: 4,
                            // pl: '0px',
                            // pr: '30px', 
                            px: { lg: 6, xs: 1 },
                            ...((location.pathname === '/' || (location.pathname.indexOf('room') > -1)) ? contentOptions?.sx ?? {} : {})
                        }
                         : {
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            pb: 4,
                            // pl: '0px',
                            // pr: '30px', 
                            pr: {lg: 5, xs: 1},
                            pl: {lg: 1, xs: 1},
                            ...((location.pathname === '/' || (location.pathname.indexOf('room') > -1)) ? contentOptions?.sx ?? {} : {})
                        }}
                        className= 'CmtLayout-main'
                    >
                        {props.children}
                    </Div>
                    <JumboLayoutFooter>
                        {props.footer}
                    </JumboLayoutFooter>
                </Div>
            </Div>
        </Div>
    );
};

JumboLayout.propTypes = {
    header: PropTypes.node,
    headerSx: PropTypes.object,
    sidebar: PropTypes.node,
    footer: PropTypes.node,
    children: PropTypes.node,
};

export default JumboLayout;