import React from 'react';
import { Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button"; 
import Grid from "@mui/material/Grid";  
import {useTranslation} from "react-i18next";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { USERROLECODE } from '../../../utils/constants/constants'
  
const Students = () => { 
    const {t} = useTranslation();
    const profilerole = Number(localStorage.getItem("switchprofile"));

    const [open, setOpen] = React.useState(false); 

    const handleClickOpen = (scrollType) => () => {
        setOpen(true); 
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    return ( 
        <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
            {/* <CardContent>  */}
                <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>               
                    <Grid xs={8}>                    
                        <div style={{ display: 'flex' }}><PeopleAltOutlinedIcon style={{ marginRight: '5px' }} />
                            <Typography variant="h1" mb={3}>  {t('class.studentlist')}</Typography>
                        </div>                   
                    </Grid>   
                    {profilerole === USERROLECODE.student && <Grid xs={4} style={{ textAlign: 'right' }}>
                        <Button
                            variant={"contained"}
                            startIcon={<CloudUploadIcon />}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.5rem'
                                },
                                textTransform: 'none'
                            }}
                            onClick={handleClickOpen('paper')}
                        >{t('class.upload')}</Button>
                    </Grid>}                
                </Grid>                               
            {/* </CardContent> */}
        </Div>
        
    );
};

export default Students;
