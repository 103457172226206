import React, { useEffect } from "react"; 
import ConnectingButtons from "./ConnectingButtons";
import { connect } from "react-redux";
import { setIsRoomHost } from "../../../../store/actions";
import {useJumboApp} from "@jumbo/hooks";
import {ASSET_IMAGES} from "../../../../utils/constants/paths";
import "./IntroductionPage.css";
import {LAYOUT_NAMES} from "../../../../layouts/layouts";

const IntroductionPage = ({ setIsRoomHostAction }) => {
  useEffect(() => {
    setIsRoomHostAction(false);
  }, []);

  const {setActiveLayout} = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
  });

  return (
    <div className="introduction_page_container">
      <div className="introduction_page_panel">
        <img src={`${ASSET_IMAGES}/logo.png`}  className="introduction_page_image" />
        <ConnectingButtons />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
  };
};

export default connect(null, mapDispatchToProps)(IntroductionPage);
