import React from 'react';
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import {useNavigate} from 'react-router-dom';
import Annoucements from "../../../shared/widgets/Announcements/Annoucements";
import CampaignIcon from '@mui/icons-material/Campaign';
import { USERROLECODE } from '../../../utils/constants/constants'
import { store } from "../../../store/store"

const AnnouncementList = () => {
    const {t} = useTranslation();
    const profilerole = localStorage.getItem("switchprofile");
    const navigate = useNavigate(); 
    const createannouncement =  () => {
        navigate(`/classes/classdetails/createannouncement`, { state: {frompage:"classes", announcementData : {classCode: classDetails.classCode, isCreate: true}} });
    };  
    const classDetails = store.getState().classDetails;
    return (
        <React.Fragment>            
            <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>               
                    <Grid xs={8}>                    
                    <div style={{ display: 'flex' }}><CampaignIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                        <Typography variant="h1" mb={3}>{t('class.announcement')}</Typography>
                    </div>                  
                    </Grid>
                    <Grid xs={4} style={{textAlign:'right'}}> 
                    { Number(profilerole) === USERROLECODE.tutor && <Button
                        variant={"contained"}
                        startIcon={<CampaignOutlinedIcon />}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem'
                            },
                            textTransform: 'none',
                            fontSize: '12px',
                            paddingBottom: '3px',
                            paddingTop: '3px'
                        }}
                        onClick={createannouncement}
                    >{t('class.createannouncement')}</Button>}                  
                    </Grid>
                    </Grid>  
                    <Grid container spacing={3.5}>    
                        <Grid item xs={12}  md={12} lg={12}> 
                            <Annoucements frompage={'class'} classCode = {classDetails.classCode} />
                        </Grid> 
                 </Grid>
           
        </React.Fragment>
    );
};

export default AnnouncementList;
