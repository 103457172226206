import React, {useState} from 'react';
import {Card, CardContent, IconButton, Typography} from "@mui/material";
import Link from "@mui/material/Link"; 
import Div from "@jumbo/shared/Div";
import {alpha} from "@mui/material/styles";
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import { authServices } from "../../../services/auth/user";
import {useMutation} from "react-query";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import {Form, Formik} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff'; 
import {setLoginDetails, setCredentials, setProfile} from '../../../store/actions'
import { store } from "../../../store/store";

const Login2 = () => {
    const {setActiveLayout} = useJumboApp();
    const {t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
     
    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    });

    const validationSchema = yup.object({
        email: yup
            .string(t('login.emailText'))
            .email(t('login.errorMail'))
            .required(t('login.errorEmailRequired')),
        password: yup
            .string(t('login.passwordText'))
            .required(t('login.errorPasswordRequired')),
    });

    const mutation = useMutation(authServices.login, {
        onSuccess: (data) => {
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) { 
                enqueueSnackbar(data?.message, { variant: "success" }); 
                store.dispatch(setLoginDetails(data?.body?.userDetails));
                store.dispatch(setCredentials(data?.body?.credential));
                if (data?.body?.userDetails?.defaultRole) {
                    store.dispatch(setProfile(data?.body?.userDetails?.roleCode)); 
                    localStorage.setItem("switchprofile", data?.body?.userDetails?.roleCode)
                    navigate("/home", { replace: true });
                    window.location.reload(false);
                   } else {
                    navigate("/profile", {replace: true});
                }
               
           }           
        },
        onError: (error) => {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        }
    });

    const onSignIn = async (email, password) => {
        const data = {email: email, password: password, ipAddress: await authServices.getMyIpAddress(), switchProfile: ''}        
        mutation.mutate(data);
        // navigate("/profile", {replace: true}); 
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };
    const handleSignup = () => {
        navigate("/signup", {replace: true});
    }
    return (
        <Div sx={{
            width: 820,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/keith-luke.jpg`, "640x428")}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .65)
                        }
                    }}
                >
                    <Div 
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}> 
                        <Div sx={{mb: 2}}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>Sign in</Typography>
                           
                        </Div>                    

                        <Div sx={{mt: 'auto'}}> 
                                <img src={`${ASSET_IMAGES}/logo-white.png`} alt="Skyber"/> 
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{flex: 1, p: 4}}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true);
                            onSignIn(data.email, data.password);
                            localStorage.setItem("jwtToken","");
                            setSubmitting(false);
                        }}
                       
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'  >                                
                                {/* {mutation.isError && <p>{mutation.error.message}</p>} */}
                                <Div sx={{mb: 3, mt: 1}}>
                                    <JumboTextField
                                        fullWidth
                                        inputProps={{ tabIndex: "0" }}
                                        name="email"
                                        label={t('login.email')}
                                        autoComplete="off"  
                                    />
                                </Div>
                                <Div sx={{mb: 2, mt: 1}}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label={t('login.password')}
                                        type={showPassword ? 'text' : 'password'}
                                        autoComplete="off"
                                        InputProps={{
                                            minLength: 8,
                                            maxLength: 20,
                                            endAdornment: (
                                                <IconButton
                                                onClick={handleClickShowPassword}
                                                edge='end'
                                                aria-label='toggle password visibility'>    
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}                                       
                                              </IconButton>
                                            ),
                                        }}
                                    />
                                </Div> 
                                <Div sx={{mb: 2, mt: 1}} style={{textAlign: 'right'}}>                       
                                <LoadingButton   
                                    style={{textTransform: 'none'}}                                 
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting || mutation.isLoading}
                                >Sign in</LoadingButton>
                                </Div>          
                                {/* <Typography textAlign={"center"} variant={"body1"} mb={1}>New user?
                                    <Link  onClick={handleSignup} style={{ cursor: 'pointer'}}> Sign up</Link>
                                </Typography>                              */}
                            </Form>
                        )}
                    </Formik>                   
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login2;
