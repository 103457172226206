import React from 'react';
import {Typography, Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next"; 
import MailsList from "../../classes/mail/components/MailsList";
import ForumIcon from '@mui/icons-material/Forum';
const Gradebook = () => {
    const {t} = useTranslation(); 
    
    return (
        <React.Fragment>            
            <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>               
                <Grid xs={8}>                    
                    <div style={{ display: 'flex' }}><ForumIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                        <Typography variant="h1" mb={3}>{t('class.forum')}</Typography>
                    </div>                  
                </Grid>                   
            </Grid>
            <Divider style={{ marginBottom: '30px' }}/>  
            <Grid container spacing={3.5} style={{padding: '2%'}}>    
                <Grid item xs={12}  md={12} lg={12}> 
                <Typography variant="h3" mb={2}>{t('class.forumheading')}</Typography>
                </Grid> 
            </Grid>
            <Grid container spacing={3.5}>    
                <Grid item xs={12}  md={12} lg={12}> 
                <MailsList />
                </Grid> 
            </Grid>
           
        </React.Fragment>
    );
};

export default Gradebook;
