import React from 'react';
import {Card, Typography} from "@mui/material"; 
import {useTranslation} from "react-i18next"; 
import CreateClass from "./createClass";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';

const ClassIndex = () => {
    const { t } = useTranslation();  
    return (
        <React.Fragment>
            <div style={{ display: 'flex' }}><AddToPhotosIcon style={{ marginRight: '5px' }} />
                <Typography variant="h1" mb={3}>  {t('class.createClass')}</Typography>
            </div>
            <Card sx={{display: 'flex', mb: 3.5}}>
                 <CreateClass/> 
            </Card> 
        </React.Fragment>
    );
};

export default ClassIndex;
