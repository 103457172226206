import React, {useState} from 'react'; 
import FilesList from "./FilesList"; 
import { makeStyles } from "@mui/styles"; 
import Pagination from "@jumbo/components/Pagination";
import { classServices } from "../../../services/apis/classes"; 
import { useQuery } from "react-query";
import { store } from "../../../store/store"

const useStyles = makeStyles({ 
    pagination: {
        padding: '0px ', 
        borderRadius: '6px',
        textAlign: 'center',
        display: 'table',
        margin: '0 auto',  
        width: '100%',
        marginTop: '10px',
    }
});
const Files = ({ classDetails, folderDetails, isSuccess, isSuccessful }) => {  
    const classes = useStyles();
    const loginDetails = store.getState().loginDetails;
    const  limit  = 10 ;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); 
    
    const [fileItemSuccess, setFileItemSuccess] = useState(false); 
    let pageSize = 10; 

    const userDetails = [loginDetails ? loginDetails.userCode : 0 , loginDetails ? loginDetails.roleCode : 0, offset, 
        limit, classDetails ? Number(classDetails.classCode) : '', folderDetails ? Number(folderDetails.folderCode) : ''];  
    const {data: result, refetch: refetchFileList } = useQuery(
        userDetails, classServices.fileList
    );  
    const handlePagination = (page) => {
        let offsetVal = page * pageSize - pageSize;
        setOffset(offsetVal);
        setCurrentPage(page); 
        refetchFileList().catch(console.error) 
    }
   
    React.useEffect(() => {
        if (isSuccess || fileItemSuccess) {  
            refetchFileList().catch(console.error)
            setFileItemSuccess(false)  
            isSuccessful(false)
        }
    }, [isSuccess, fileItemSuccess, isSuccessful, refetchFileList]); 
    return (
        <>                
            <FilesList fileList={result && result.fileList} isSuccessful= {setFileItemSuccess} />
            {result && parseInt(result?.totalcount) > 10 && <div className={classes.pagination}>
                <Pagination
                    currentPage={currentPage}
                    totalCount={result ? parseInt(result?.totalcount) : 0}
                    pageSize={pageSize}
                    primaryColor="#814DFA"
                    onPageChange={handlePagination} 
                />
            </div>}
        </>
    );
};

export default Files;
