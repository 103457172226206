import React from "react"; 
import {Checkbox, ListItem, Typography} from "@mui/material";
const OnlyWithAudioCheckbox = (props) => {
  const { connectOnlyWithAudio, setConnectOnlyWithAudio } = props;

  const handleConnectionTypeChange = () => {
    setConnectOnlyWithAudio(!connectOnlyWithAudio);
  };

  return (
    // <div className="checkbox_container">
    //   <div className="checkbox_connection" onClick={handleConnectionTypeChange}>
    //     {connectOnlyWithAudio && (
    //       <img className="checkbox_image" src={CheckImg}></img>
    //     )}
    //   </div>
    //   <p className="checkbox_container_paragraph">Only audio</p>
    // </div>
    <ListItem style={{paddingLeft: '0px'}}>
     <Checkbox
          checked={connectOnlyWithAudio}
          onChange={handleConnectionTypeChange}
          name= "Onlyaudio" 
          value={connectOnlyWithAudio} 
      />
    <Typography variant={'body1'}>{"Only Audio"}</Typography>
  </ListItem> 
  );
};

export default OnlyWithAudioCheckbox;
