import React from 'react';
import TransactionList from "./TransactionList"; 
import JumboScrollbar from "@jumbo/components/JumboScrollbar"; 
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import LatestPosts from "../LatestPosts";

const TransactionHistory = ({scrollHeight, financeData}) => {
    const {t} = useTranslation();
    return (
        <JumboCardQuick
            title={t('finance.transaction')} 
            wrapperSx={{p: 0}}
        >
            <JumboScrollbar
                autoHeight
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
                autoHeightMin={scrollHeight ? scrollHeight : 356}
            >
                <TransactionList datas={financeData}/>
            </JumboScrollbar>
        </JumboCardQuick>
    );
};
/* Todo scrollHeight prop define :- */
LatestPosts.propTypes = {
    scrollHeight: PropTypes.number,
    financeData: PropTypes.object,
};
export default TransactionHistory;
