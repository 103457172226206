import React from 'react';
import List from "@mui/material/List"; 
import { classServices } from "../../services/apis/classes";  
import {  useQuery } from "react-query";
import { connect } from "react-redux";
import NotificationPost from "./NotificationPost"; 
import {Link, Typography} from "@mui/material"; 
import { setNotificationCount } from '../../store/actions'; 
import { store } from "../../store/store"; 
const NotificationList = (props) => {
    const {loginDetails} = props;
    const limit  = 10 ; 
    const [offset, setOffset] = React.useState(0); 
    const [currentPage, setCurrentPage] = React.useState(1);
    const [notifiyList, setNotifiyList] = React.useState([]);
    const [isVisibility, setVisibility] = React.useState(false);
    const [success, setSuccess] = React.useState(-1);
    const userDetails = [loginDetails ? loginDetails.userCode : 0 , loginDetails ? loginDetails.roleCode : 0, offset, limit];  
    const {data: result, refetch: refetchNotififcationList } = useQuery(
        userDetails, classServices.notificationList
    ); 
    const {data: myData, refetch: dismissrefetchNotififcationList } = useQuery(
        userDetails, classServices.notificationList
    ); 
    React.useEffect(() => { 
        store.dispatch(setNotificationCount(myData));  
    }, [myData]);
    React.useEffect(() => { 
        if (result && result?.notificationList?.length > 0) {
            if (offset) {
                setNotifiyList([...notifiyList, ...result?.notificationList]); 
            } else {
                setNotifiyList(result?.notificationList); 
            } 
        }  
    }, [result, offset]);
    React.useEffect(() => { 
        if (notifiyList && notifiyList?.length > 0) { 
            if (parseInt(notifiyList?.length+1) >= parseInt(result?.totalcount)) {
                setVisibility(false);
            } else {
                setVisibility(true);
            }
        }  
    }, [notifiyList]);
    React.useEffect(() => {  
        if (success > -1) {  
            const notifiy_List = Object.assign([], notifiyList)       
            if (success !== -1) {
                notifiy_List.splice(success, 1)
                setNotifiyList(notifiy_List);
                setSuccess(-1);
                dismissrefetchNotififcationList().catch(console.error);
            }
        }  
    }, [success]);
    
    const handleSeeMore = () => {  
        let offsetVal = (currentPage + 1) * limit - limit; 
        setOffset(offsetVal); 
        setCurrentPage(currentPage + 1);
        refetchNotififcationList().catch(console.error);
    }
    return (
        <List disablePadding>
            {
                notifiyList   && notifiyList.length > 0 && notifiyList.map((item, index) => {
                    return (
                        <NotificationPost key={index} item={item} success={setSuccess} indexval={index}  />
                    )
                })
            }
             {(!notifiyList || notifiyList.length === 0) && <div><Typography variant={"h5"} mb={4} style={{ textAlign: 'center', color: "#a9a9a9" }}>There was no notification found</Typography></div>}
           {isVisibility && <Link   style={{textAlign: 'center', cursor: 'pointer'}} onClick={()=>handleSeeMore()}>
                <Typography underline="hover" component={"h1"}   style={{marginTop: '10px', marginBottom: '30px',  fontSize: '15px', fontWeight: '500'}}>See More</Typography>
            </Link> } 
        </List>      
    );
};

const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };

export default connect(mapStoreStateToProps)(NotificationList);
