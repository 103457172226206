import React from 'react'; 
import Divider from "@mui/material/Divider";
import {Card, CardContent, Typography, Avatar, Tooltip,IconButton} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";  
import Div from "@jumbo/shared/Div";  
import PropTypes from 'prop-types'; 
import Chip from '@mui/material/Chip';
import VoiceChatOutlinedIcon from '@mui/icons-material/VoiceChatOutlined'; 
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';    
import { USERROLECODE } from '../../../utils/constants/constants'
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import FolderCopyIcon from '@mui/icons-material/FolderCopy'; 
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import MovieIcon from '@mui/icons-material/Movie';
import { useNavigate } from "react-router-dom";
import { setClassName, setClassDetails, setChatPerson,setChatPersonProfilePic, setChatPersonProfileID,setChatPersonOnlineStatus} from '../../../store/actions'
import { store } from "../../../store/store"; 
import { useMutation } from "react-query";
import { classServices } from "../../../services/apis/classes"; 

const ClassesList = ({values, clickAction, loginDetails}) => { 
    const profilerole = Number(localStorage.getItem("switchprofile"));
    const navigate = useNavigate();
    
    const mutation = useMutation(classServices.getClassDetails, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                return;
           }
            if (data?.statusFlag === 1) { 
                if (data?.body?.classDetails) {
                  localStorage.setItem("class_local_details", JSON.stringify(data?.body?.classDetails));
                  store.dispatch(setClassDetails(data?.body?.classDetails));   
                }
            }         
        },
        onError() {
        },
    });
    const bindClassDetails = () => {
        mutation.mutate({
            userCode: loginDetails ? Number(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? Number(loginDetails.roleCode): 0,   
            classCode: Number(values.classCode)
        });     
    }
    const handleChat = (e) => {
        if(loginDetails && Number(loginDetails.userCode) !==  Number(values?.userCode)) { 
            localStorage.setItem("ChatPerson", values?.username);
            localStorage.setItem("ChatPersonProfilePic", values?.profileUrl);
            localStorage.setItem("ChatPersonOnlineStatus", values?.onlineStatus);
            localStorage.setItem("ChatPersonProfileID", values?.userCode);
            store.dispatch(setChatPerson(values?.username));
            store.dispatch(setChatPersonProfilePic(values?.profileUrl)); 
            store.dispatch(setChatPersonOnlineStatus(values?.onlineStatus)); 
            store.dispatch(setChatPersonProfileID(values?.userCode)); 
            navigate(`/chats/conversation/${values?.userCode}`);
        } else {
            navigate(`/chats`);
        }
        e.preventDefault();
        e.stopPropagation();
    }
    const handleConferencing = (e) => {
        bindClassDetails();
        store.dispatch(setClassName(values?.className));
        navigate('/classes/classdetails/conferencing');
        e.preventDefault();
        e.stopPropagation();
    }
    const handleFiles = (e) => {
        bindClassDetails();
        store.dispatch(setClassName(values?.className));
        navigate('/classes/classdetails/files');
        e.preventDefault();
        e.stopPropagation();
    }
    const handleSubmissions = (e) => {
        bindClassDetails();
        store.dispatch(setClassName(values?.className));
        navigate('/classes/classdetails/submissionfiles');
        e.preventDefault();
        e.stopPropagation();
    }
    const handleMultimedia = (e) => {
        bindClassDetails();
        store.dispatch(setClassName(values?.className));
        navigate('/classes/classdetails/multimedia');
        e.preventDefault();
        e.stopPropagation();
    }
    
    return (
        <Card onClick={clickAction} style={values.statusname === 'Completed' ? { cursor: 'pointer', backgroundColor:'#d9dee8' } : {cursor: 'pointer'}} 
        sx={{            
                '& .MuiChip-root ': {
                    marginTop: '3px',
                    marginLeft: '3%'
                },

                '& .MuiChip-label ': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', 
                    whiteSpace: 'nowrap',
                    fontSize: '10px'                 
                },
             '& .MuiCardContent-root:last-child ': {
                 padding: '10px !important' 
            },
          
        }}>
            <Stack direction={"row"} sx={{  padding: '5px',marginTop: '5px',paddingLeft: '15px',paddingRight: '15px' }} > 
                <Grid item xs={1}>
                    <VoiceChatOutlinedIcon style={{ marginTop: '4px' }} /> 
                </Grid>
                <Grid item xs={7}>
                    <Div style={{  fontSize: '18px', marginLeft: '10px', whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis' }} title={values.className} >{values.className}</Div>  
                </Grid>  
                <Grid item xs={4} style={{ textAlign: 'right' }}> 
                    <Div style={{  fontSize: '15px', color: '#2778c4',  marginLeft: '10px' }} title={values.classLessionPrice} >${values.classLessionPrice} /lesson</Div>   
                </Grid> 
            </Stack>
            <Divider style={values.statusname === 'Completed' ? {borderColor: '#afb6bd'} : { borderColor: '#DEE2E6'}}/>
            {/* <Divider /> */}
            <CardContent > 
              <Grid item xs={12} sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "2",
                    "-webkit-box-orient": "vertical"
                  }}>
                     <Typography
                            component={"div"}
                            variant={"body1"}
                            color={"text.secondary"}
                            style={{ fontSize: '12px', marginLeft: '5px'}}
                            mb={2}
                        >
                              {values.shortDescription}
                        </Typography> 
                       
                </Grid>
                <Grid item xs={12} >
                    <Divider style={values.statusname === 'Completed' ? { borderColor: '#afb6bd', marginTop: '4px', marginBottom: '4px' } : { borderColor: '#DEE2E6', marginTop: '4px', marginBottom: '4px' }} /> 
                </Grid>

                <Stack direction={"row"} spacing={2} style = {{ marginLeft: '5px'}}>
                    <Grid item xs={8}>
                        <Typography
                            component={"div"}
                            variant={"body1"}
                            color={"text.secondary"}
                            mb={1}
                        >
                            <Typography component={"span"} variant={"h5"}>{values.duration} </Typography><span >Mins</span> | <span style={{ color: 'black'}}>{values.classFreqName}</span> | <Typography component={"span"} variant={"h5"}>{values.noOfLessons} </Typography> Lessons 
                        </Typography>
                        <Typography
                            component={"div"}
                            variant={"body1"}
                            color={"text.secondary"}
                            style={{ fontSize: '12px', marginBottom: '3px'}}
                            mb={2}
                        >
                            {values.classDate}
                        </Typography> 
                    </Grid> 
                    <Grid item xs={3}> 
                          {values.statusname === "Ongoing" && <Div sx={{ textAlign: 'center', marginTop: '5px', marginRight: '-19px' }}>
                            <div style={{ background: "#F39711", borderRadius: '50px', color: '#fff', fontSize: "10px", paddingTop: '2px', paddingBottom: '1px', width: 'fit-content',paddingLeft: '10px', paddingRight: '10px', float: 'right' }}>{values.statusname}</div>
                        </Div>}
                        {values.statusname === "Completed"   && <Div sx={{ textAlign: 'center',marginTop: '5px', marginRight: '-19px'  }}>
                            <div style={{ background: "#3bd2a2", borderRadius: '50px', color: '#fff', fontSize: "10px", paddingTop: '2px', paddingBottom: '1px',width: 'fit-content',paddingLeft: '10px', paddingRight: '10px', float: 'right'  }}>{values.statusname}</div>
                        </Div>} 
                    </Grid> 
                </Stack>
               
                {(profilerole === USERROLECODE.tutor) && (<Typography variant={"p"} color={'text.primary'} style={{ marginLeft: '5px' }}>Class Size </Typography>)}
                    {(profilerole === USERROLECODE.tutor) && (<Grid container spacing={3.5} style={{ paddingLeft: '5px' }}>
                        <Grid item xs={4}>
                            <div style={{ fontSize: '12px', display: 'flex' }}>Total
                                <Chip label={values.classSize} sx={{background:  '#002366', color:'white'}} style={{ height: '15px', fontsize: '10px !important' }} size="small" />
                            </div>
                        </Grid>
                     
                        <Grid item xs={4}>
                            <div style={{ fontSize: '12px', display: 'flex' }}>Booked
                            <Chip label={values.booked} color="warning" size="small"
                                    style={{ height: '15px', fontsize: '10px !important' }} />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{ fontSize: '12px', display: 'flex' }}>Available
                            <Chip label={(parseInt(values.classSize) > 0) ? parseInt(values.classSize)-parseInt(values.booked) : 0 } color="success" size="small" style={{ height: '15px', fontsize: '10px !important' }} />
                            </div>
                        </Grid>
                    </Grid>)}
                <Divider style={values.statusname === 'Completed' ? { borderColor: '#afb6bd', marginTop: '4px' } : { borderColor: '#DEE2E6', marginTop: '4px' }} /> 
                <Grid container spacing={3.5} style={{marginBottom: '-19px'}} > 
                    <Grid item xs={6}  >
                        <Div sx={{ color: '#002366', fontWeight: '400', float: 'left', alignItems: 'center', display: 'flex', padding: '5px' }}>
                            <Div sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column', 
                            }}>
                                 <Avatar src={values?.profileUrl} alt="user" sx={{ width: 24, height: 24, mb: 2 }} />  
                            </Div>
                            <Div sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column', 
                            }}>
                               <Div  style={ {marginLeft: '5px',marginTop: '-15px'}}  ><span style={{fontSize: '11px'}}>{values.username}</span>
                                     </Div>
                                </Div>  
                            </Div>
                             
                    </Grid>
                    <Grid item xs={6}  >
                        <Div sx={{ color: '#002366', fontWeight: '400', float: 'right', alignItems: 'center', display: 'flex', padding: '5px' }}> 
                            <>
                                <Tooltip title="Conferencing">
                                        <IconButton onClick={(e) => handleConferencing(e)}>
                                            <CastForEducationIcon fontSize={"small"}/>
                                        </IconButton>
                                </Tooltip>
                                <Tooltip title="Files">
                                        <IconButton onClick={(e) => handleFiles(e)}>
                                            <FolderCopyIcon fontSize={"small"}/>
                                        </IconButton>
                                </Tooltip>
                                {(values.classType === '1' ) &&<Tooltip title="Submissions">
                                    <IconButton onClick={(e) => handleSubmissions(e)}>
                                        <AutoStoriesIcon fontSize={"small"} />
                                    </IconButton>
                                </Tooltip>}
                                <Tooltip title="Multimedia">
                                        <IconButton onClick={(e) => handleMultimedia(e)}>
                                            <MovieIcon fontSize={"small"}/>
                                        </IconButton>
                                </Tooltip>
                                <Tooltip title="Chat">
                                        <IconButton onClick={(e) => handleChat(e)}>
                                            <ChatOutlinedIcon fontSize={"small"}/>
                                        </IconButton>
                                </Tooltip>
                                {/* <Tooltip title={"Conferencing"}> <IconButton ><CastForEducationIcon onClick={(e) => handleConferencing(e)} style={{ marginTop: '3px', marginLeft: '9px' }} fontSize={"small"} /></IconButton></Tooltip>
                        <Tooltip title={"Files"}> <IconButton ><FolderCopyIcon onClick={(e) =>handleFiles(e)} style={{ marginTop: '3px',marginLeft: '9px' }} fontSize={"small"}  /></IconButton></Tooltip>
                        <Tooltip title={"Submissions"}>  <IconButton ><AutoStoriesIcon onClick={(e) =>handleSubmissions(e)} style={{ marginTop: '3px',marginLeft: '9px' }} fontSize={"small"} /></IconButton></Tooltip>
                        <Tooltip title={"Multimedia"}>  <IconButton ><MovieIcon onClick={(e) =>handleMultimedia(e)} style={{ marginTop: '3px',marginLeft: '9px' }} fontSize={"small"} /></IconButton></Tooltip>
                                <Tooltip title={"Chat"}> <IconButton ><ChatOutlinedIcon onClick={(e) => handleChat(e)} style={{ marginTop: '3px', marginLeft: '9px' }} fontSize="small" /></IconButton></Tooltip> */}
                            </>
                            {/* <Div style={{ marginLeft: '5px', fontSize: '11px' }}>Chat</Div> */}
                        </Div>  
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
ClassesList.propTypes = {
    values: PropTypes.object, 
    frompage : PropTypes.node,
    clickAction: PropTypes.func, 
};
export default ClassesList;
