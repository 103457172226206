import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setIsRoomHost, setIdentity, setRoomId, } from "../../../../store/actions";
import { useLocation } from "react-router-dom"; 
import JoinRoomContent from "./JoinRoomContent";
import LoadingOverlay from "./LoadingOverlay"; 
import "./JoinRoomPage.css"; 
import {LAYOUT_NAMES} from "../../../../layouts/layouts";
import {useJumboApp} from "@jumbo/hooks";
import {Card, CardContent,  Typography, Link} from "@mui/material"; 
import Div from "@jumbo/shared/Div"; 
import {ASSET_IMAGES} from "../../../../utils/constants/paths";
import { getAssetPath } from "../../../../utils/appHelpers";
import { alpha } from "@mui/material/styles";
import { useQuery } from "react-query"; 
import { classServices } from "../../../../services/apis/classes"; 
import { useNavigate } from "react-router-dom";

const JoinRoomPage = (props) => {
  const { setIsRoomHostAction, isRoomHost, classDetails, loginDetails, setIdentityAction, setRoomIdAction } = props; 
  const search = useLocation().search;
  const navigate = useNavigate();
  const {setActiveLayout} = useJumboApp();
  const classCode = classDetails ? Number(classDetails.classCode) : 0;
  const loginCode = loginDetails ? loginDetails.userCode : 0;
  const roleCode = loginDetails ? loginDetails.roleCode : 0;
  const userDetails = [loginCode ? loginCode : 0, classCode ? classCode : 0];
  const classPrice = new URLSearchParams(search).get("price");
  const lessonCode = new URLSearchParams(search).get("lesson");
  const {data: result } = useQuery(
    userDetails, classServices.meetingDetails
  );  
  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
  });
  useEffect(() => {
    const isRoomHosts = new URLSearchParams(search).get("host");
    if (isRoomHosts) {
      setIsRoomHostAction(true);
    }
  
  }, []);

  React.useEffect(() => {
    if (result && result.length > 0) { 
      setIdentityAction(result ? result[0].userName : "");
      setRoomIdAction(result ? result[0].roomId : "");     
      navigate("/room");
    }   
}, [result]);

  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const titleText = isRoomHost ? "Host Meeting" : "Join Meeting";
  return ( 
    <Div sx={{
      width: 820,
      maxWidth: '100%',
      margin: 'auto',
      p: 4
  }}>
      <Card
          sx={{
              display: 'flex',
              minWidth: 0,
              flexDirection: {xs: 'column', md: 'row'}
          }}
      >
          <CardContent
              sx={{
                  flex: '0 1 300px',
                  position: 'relative',
                  background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/keith-luke.jpg`, "640x428")}) no-repeat center`,
                  backgroundSize: 'cover',

                  '&::after': {
                      display: 'inline-block',
                      position: 'absolute',
                      content: `''`,
                      inset: 0,
                      backgroundColor: alpha('#0267a0', .65)
                  }
              }}
          >
              <Div
                  sx={{
                      display: 'flex',
                      minWidth: 0,
                      flex: 1,
                      flexDirection: 'column',
                      color: 'common.white',
                      position: 'relative',
                      zIndex: 1,
                      height: '100%'
                  }}> 
                  <Div sx={{mb: 2}}>
                      <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>{titleText}</Typography>
                     
                  </Div>                    

                  <Div sx={{mt: 'auto'}}> 
                          <img src={`${ASSET_IMAGES}/logo-white.png`} alt="Skyber"/> 
                  </Div>
              </Div>
          </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }} > 
          <JoinRoomContent setShowLoadingOverlay={setShowLoadingOverlay} data={result?.joinDetails} classCode={classCode} loginCode={loginCode} roleCode={roleCode} perLessonPrice={classPrice} lessonCode={lessonCode}/>
          {showLoadingOverlay && <LoadingOverlay />}
        </CardContent>  
       
      </Card>
     
  </Div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(JoinRoomPage);
