 
import FolderCopyIcon from '@mui/icons-material/FolderCopy'; 
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CampaignIcon from '@mui/icons-material/Campaign';
import MovieIcon from '@mui/icons-material/Movie';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import InfoIcon from '@mui/icons-material/Info';
import QuizIcon from '@mui/icons-material/Quiz';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ForumIcon from '@mui/icons-material/Forum';

const profilerole = localStorage.getItem("switchprofile");
export const LAYOUTS = {
    VERTICAL_DEFAULT: "vertical-default",
    VERTICAL_MINIMAL: "vertical-minimal"
};


export const tutorclassMenus = [ 
    {id: 1, name: 'Class Info', slug: 'viewclass', icon: <InfoIcon fontSize={"small"}/>},
    {id: 2, name: 'My Students', slug: 'students', icon: <PeopleAltIcon fontSize={"small"}/>},
    {id: 3, name: 'Announcements', slug: 'announcement', icon: <CampaignIcon fontSize={"small"}/>},
    {id: 4, name: 'Conferencing', slug: 'conferencing', icon: <CastForEducationIcon fontSize={"small"}/>},    
    {id: 5, name: 'Files', slug: 'files', icon: <FolderCopyIcon fontSize={"small"}/>},
    {id: 6, name: 'Submissions', slug: 'submissionfiles', icon: <AutoStoriesIcon fontSize={"small"}/>},
    {id: 7, name: 'Multimedia', slug: 'multimedia', icon: <MovieIcon fontSize={"small"}/>},  
    {id: 8, name: 'Quiz', slug: 'quiz', icon: <QuizIcon fontSize={"small"}/>},    
    {id: 9, name: 'Gradebook', slug: 'gradebook', icon: <MenuBookIcon fontSize={"small"}/>},    
    {id: 10, name: 'Forum', slug: 'forum', icon: <ForumIcon fontSize={"small"}/>}  
];

export const studentclassMenus = [
    {id: 1, name: 'Class Info', slug: 'viewclass', icon: <InfoIcon fontSize={"small"}/>},
    {id: 2, name: 'Announcements', slug: 'announcement', icon: <CampaignIcon fontSize={"small"}/>},
    {id: 3, name: 'Conferencing', slug: 'conferencing', icon: <CastForEducationIcon fontSize={"small"}/>},
    {id: 4, name: 'Files', slug: 'files', icon: <FolderCopyIcon fontSize={"small"}/>},
    {id: 5, name: 'Submissions', slug: 'submissionfiles', icon: <AutoStoriesIcon fontSize={"small"}/>},
    {id: 6, name: 'Multimedia', slug: 'multimedia', icon: <MovieIcon fontSize={"small"}/>},    
    {id: 7, name: 'Quiz', slug: 'quiz', icon: <QuizIcon fontSize={"small"}/>},    
    {id: 8, name: 'Gradebook', slug: 'gradebook', icon: <MenuBookIcon fontSize={"small"}/>},    
    {id: 9, name: 'Forum', slug: 'forum', icon: <ForumIcon fontSize={"small"}/>}    
];


export const tutorWebinarclassMenus = [
    {id: 1, name: 'Webinar Info', slug: 'viewclass', icon: <InfoIcon fontSize={"small"}/>},
    {id: 2, name: 'My Students', slug: 'students', icon: <PeopleAltIcon fontSize={"small"}/>},
    {id: 3, name: 'Announcements', slug: 'announcement', icon: <CampaignIcon fontSize={"small"}/>},
    {id: 4, name: 'Conferencing', slug: 'conferencing', icon: <CastForEducationIcon fontSize={"small"}/>},    
    {id: 5, name: 'Files', slug: 'files', icon: <FolderCopyIcon fontSize={"small"}/>},
    {id: 6, name: 'Submissions', slug: 'submissionfiles', icon: <AutoStoriesIcon fontSize={"small"}/>},
    {id: 7, name: 'Multimedia', slug: 'multimedia', icon: <MovieIcon fontSize={"small"}/>},  
    {id: 8, name: 'Quiz', slug: 'quiz', icon: <QuizIcon fontSize={"small"}/>},    
    {id: 9, name: 'Gradebook', slug: 'gradebook', icon: <MenuBookIcon fontSize={"small"}/>},    
    {id: 10, name: 'Forum', slug: 'forum', icon: <ForumIcon fontSize={"small"}/>} 
];

export const studentWebinarclassMenus = [
    {id: 1, name: 'Webinar Info', slug: 'viewclass', icon: <InfoIcon fontSize={"small"}/>},
    {id: 2, name: 'Announcements', slug: 'announcement', icon: <CampaignIcon fontSize={"small"}/>},
    {id: 3, name: 'Conferencing', slug: 'conferencing', icon: <CastForEducationIcon fontSize={"small"}/>},
    {id: 4, name: 'Files', slug: 'files', icon: <FolderCopyIcon fontSize={"small"}/>},
    {id: 6, name: 'Multimedia', slug: 'multimedia', icon: <MovieIcon fontSize={"small"}/>},
    
];

export const classMenus = profilerole === 'tutor' ? tutorclassMenus : studentclassMenus;
export const classMenus_Webinar = profilerole === 'tutor' ? tutorWebinarclassMenus : studentWebinarclassMenus;