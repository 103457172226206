import React from 'react';
import {Typography, Grid, Chip, Divider, IconButton, Button, Avatar, Rating, Dialog,  DialogActions, DialogContent, DialogContentText, DialogTitle, Box,  Table, TableBody} from "@mui/material"; 
import {useTranslation} from "react-i18next";   
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom"; 
import {makeStyles} from "@mui/styles";  
import Div from "@jumbo/shared/Div";
import { TableCell, TableRow } from "@mui/material";
import styled from "@emotion/styled";
import InfoIcon from '@mui/icons-material/Info';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';  
import { USERROLECODE } from '../../../utils/constants/constants'
import { store } from "../../../store/store"
import { useSnackbar } from "notistack";
import { classServices } from "../../../services/apis/classes"; 
import { useMutation } from "react-query";
import { authServices } from "../../../services/auth/user";
import WarningIcon from '@mui/icons-material/Warning';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {setChatPerson,setChatPersonProfilePic, setChatPersonProfileID,setChatPersonOnlineStatus} from '../../../store/actions'

const useStyles = makeStyles(theme => ({
    padtop: {
      paddingTop: '10px !important',
    },
    pads: {
        padding: '10px !important', 
    },
    padtopright: {
        paddingTop: '10px !important',
        textAlign: 'right',
    },
    padtopleft: {
        paddingTop: '20px !important',
        paddingLeft: '10px !important',
    },
    padtopleftright: {
        paddingTop: '20px !important',
        paddingLeft: '10px !important',
        textAlign: 'right',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        borderBottom: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },

    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    }
}));
const ViewClassIndex = ({ objdatas, frompage, onView, balanceAmount }) => {
    const classes = useStyles();
    const { t } = useTranslation();  
    const navigate = useNavigate();
    const [datas, setDdatas] = React.useState(null);
    const profilerole = Number(localStorage.getItem("switchprofile"));
    const [joinClassOpen, setjoinClassOpen] = React.useState(false);   
    const [isSubscribed, setSubscribed] = React.useState(false);  
    const [btnDisable, setBtnDisable] = React.useState(false);
    const loginDetails = store.getState().loginDetails;
    const { enqueueSnackbar } = useSnackbar();
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [validateClassDatas, setValidateClassDatas] = React.useState([]);
    //Get stored values
    const classDetails = store.getState().classDetails;
    const handleChat = (e) => {  
        if(loginDetails && Number(loginDetails.userCode) !==  Number(datas?.userCode)) { 
            localStorage.setItem("ChatPerson", datas?.username);
            localStorage.setItem("ChatPersonProfilePic", datas?.profileUrl);
            localStorage.setItem("ChatPersonOnlineStatus", datas?.onlineStatus);
            localStorage.setItem("ChatPersonProfileID", datas?.userCode);
            store.dispatch(setChatPerson(datas?.username));
            store.dispatch(setChatPersonProfilePic(datas?.profileUrl)); 
            store.dispatch(setChatPersonOnlineStatus(datas?.onlineStatus)); 
            store.dispatch(setChatPersonProfileID(datas?.userCode)); 
            navigate(`/chats/conversation/${datas?.userCode}`);
        } else {
            navigate(`/chats`);
        }  
        // navigate('/chats');
        e.preventDefault();
        e.stopPropagation();
    }
    React.useEffect(() => { 
        if (classDetails && frompage !== 'marketplace') { 
            objdatas = classDetails;
            setDdatas(objdatas); 
        } else {
            setDdatas(objdatas);
        } 
     },[classDetails, objdatas]);

    const handleBack = () => {
        if (frompage === 'marketplace') {
            onView(false)  
        } else {
            navigate("/classes");  
        } 
    } 
   
    const ValidateMutation = useMutation(classServices.validateJoinClass, {
        onSuccess: (data) => {  
            setBtnDisable(false);
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                if (data?.body.length > 0) { 
                    setValidateClassDatas(data?.body);
                    setAlertOpen(true);
                } else {
                    if (!balanceAmount) {
                        balanceAmount = 0;
                    }
                    let classLessionPrice = 0;
                    if (datas?.classLessionPrice) {
                        classLessionPrice = parseInt(datas?.classLessionPrice ? datas?.classLessionPrice : 0) * parseInt(datas?.noOfLessons ? datas?.noOfLessons : 0) ;
                    }
                    if (parseFloat(balanceAmount) >= parseFloat(classLessionPrice)) {
                        setjoinClassOpen(true);
                        
                    } else {
                        navigate("/finance", { state: {frompage:"marketplace_topup",paymentAmt: parseFloat(classLessionPrice)-parseFloat(balanceAmount)}  });
                    } 
                } 
            }         
        },
        onError() {
            enqueueSnackbar("Something went wrong", { variant: "warning" });
            return;
        },
    });
    const joinClassAction = (e, values) => {  
        ValidateMutation.mutate({
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,      
            classCode: values ? Number(values.classCode) : 0,   
        });
        e.preventDefault();
        e.stopPropagation();
    }
    
    const mutation = useMutation(classServices.joinClassCheckout, {
        onSuccess: (data) => {  
            setBtnDisable(false);
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) { 
                setSubscribed(true);
                setjoinClassOpen(false);
                enqueueSnackbar(data?.message, { variant: "success" });
                return;
            }         
        },
        onError() {
            enqueueSnackbar("Something went wrong", { variant: "warning" });
            return;
        },
    });
    const handleCheckout = async (values) => {
        setBtnDisable(true);
        let payAmt = parseInt(values?.classLessionPrice ? values?.classLessionPrice : 0) * parseInt(values?.noOfLessons ? values?.noOfLessons : 0);
        mutation.mutate({
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,           
            classCode: values ? Number(values.classCode) : 0,  
            paymentAmount:  payAmt.toString() ,
            ipAddress: await authServices.getMyIpAddress()
        });
    }
    const handleProceed = () => {
        setAlertOpen(false);
        if (!balanceAmount) {
            balanceAmount = 0;
        }
        let classLessionPrice = 0;
        if (datas?.classLessionPrice) {
            classLessionPrice = parseInt(datas?.classLessionPrice ? datas?.classLessionPrice : 0) * parseInt(datas?.noOfLessons ? datas?.noOfLessons : 0) ;
        }
        if (parseFloat(balanceAmount) >= parseFloat(classLessionPrice)) {
            setjoinClassOpen(true);
            
        } else {
            navigate("/finance", { state: {frompage:"marketplace_topup",paymentAmt: parseFloat(classLessionPrice)-parseFloat(balanceAmount)}  });
        } 
       
    }
    return (
        <React.Fragment> 
            {datas && (<><Grid container spacing={3.75} style={frompage === 'class' ? { padding: '0 3%', paddingBottom: '5px', paddingTop: '7px' } : { padding: 'unset' }}>
                <Grid item xs={9} md={9} lg={9} className={classes.padtopleft}>
                    <div style={{ display: 'flex', marginLeft: '17px' }}>
                        {frompage !== 'class' ?
                            (<div style={{ display: 'flex' }}><IconButton style={{ marginTop: '-10px' }} onClick={handleBack}>
                                <ArrowBackIcon />
                            </IconButton> <Typography variant="h1" ml={1}>  {datas?.className} </Typography></div>) : (<>
                                <InfoIcon style={{ marginTop: '2px' }} /> <Typography variant="h1" ml={1}
                                    style={{ marginLeft: '4px' }}>Class Info</Typography></>)}
                    
                    </div>
                </Grid>
                
                <Grid item xs={3} md={3} lg={3} className={classes.padtopleftright}>
                    <Typography variant="h1" ml={1}> <span style={{color: '#b1afaf'}}>$</span>{parseInt(datas?.classLessionPrice) }/lesson
                    </Typography>
                </Grid>
            </Grid>
                <Divider style={{ marginBottom: '30px' }} />
                <Grid container spacing={3.75}>
                    <Grid item xs={6} md={3} lg={2} className={classes.padtop}>
                        <Typography variant="p" style={{ display: 'flex', float: 'right' }} color={"text.secondary"} >{t('class.classtype')}  </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} lg={1} style={{ paddingLeft: '0px' }} className={classes.padtop}>
                        <Typography variant="h5" style={{ marginLeft: '10px' }} >{datas?.classTypeName}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2} className={classes.padtop}>
                        <Typography variant="p" style={{ display: 'flex', float: 'right' }} color={"text.secondary"} >{t('class.total')}  </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} lg={1} style={{ paddingLeft: '0px' }} className={classes.padtop}>
                        <Typography variant="h5" style={{ marginLeft: '10px' }} >{datas?.noOfLessons}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2} className={classes.padtop}>
                        <Typography variant="p" style={{ display: 'flex', float: 'right' }} color={"text.secondary"} >{t('class.price')}  </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} lg={1} style={{ paddingLeft: '0px' }} className={classes.padtop}>
                        <Typography variant="h5" style={{ marginLeft: '10px' }} ><span style={{color: '#b1afaf' }}>$</span>{datas?.classLessionPrice}/lesson</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} style={{ paddingLeft: '0px' }} className={classes.padtopright}>
                       
                        {(profilerole === USERROLECODE.student && frompage === "marketplace") && ((datas?.buyercount && parseInt(datas?.buyercount) > 0) || isSubscribed) ? ( 
                                    <div style={{ marginTop: '6px', marginLeft: '5px' }}><div style={{ background: "#3bd2a2", borderRadius: '50px', color: '#fff', fontSize: "10px", paddingTop: '2px', paddingBottom: '1px', width: 'fit-content', paddingLeft: '10px', paddingRight: '10px', float: 'right' }}>Owned</div></div>
                                     ) : ( frompage !== 'class' && profilerole === USERROLECODE.student  && (((parseInt(datas.classSize) > 0) ? parseInt(datas.classSize) - parseInt(datas.booked) : 0) > 0) && <Button
                                     variant={"contained"}
                                     color={"secondary"}
                                     onClick= {(e) => joinClassAction(e, datas)}
                                     sx={{
                                         '& .MuiSvgIcon-root': {
                                             fontSize: '1.5rem'
                                         },
                                         textTransform: 'none',
                                         fontSize: '12px',
                                         marginTop: '1px',
                                         marginLeft: '10px',
                                         padding: '4px'
                                     }}
                            >{t('class.joinClass')} </Button>)}  
                    </Grid>


                    {/* <Grid item xs={6} md={2} lg={2} className={classes.padtop}>
                        <Typography variant="p" style={{ display: 'flex', float: 'right' }} color={"text.secondary"} >{t('class.frequency')}  </Typography>
                    </Grid> */}
                    {/* <Grid item xs={6} md={2} lg={1} style={{ paddingLeft: '0px' }} className={classes.padtop}>
                        <Typography variant="h5" style={{ marginLeft: '10px' }} >{datas?.classFreqName}</Typography>
                    </Grid> */}
                    <Grid item xs={6} md={2} lg={2} className={classes.padtop}>
                        <Typography variant="p" style={{ display: 'flex', float: 'right' }} color={"text.secondary"} >{t('class.period')}  </Typography>
                    </Grid>
                    <Grid item xs={6} md={3} lg={4} style={{ paddingLeft: '0px' }} className={classes.padtop}>
                        <Typography variant="h5" style={{ marginLeft: '10px' }} >{datas?.classDate}</Typography>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} style={{ paddingLeft: '0px' }} className={classes.padtop}>
                    {(profilerole === USERROLECODE.student && frompage === "marketplace") && (((parseInt(datas.classSize) > 0) ? parseInt(datas.classSize) - parseInt(datas.booked) : 0) <= 0 && ( 
                                           <div style={{float:'right'}}><div style={{display: 'flex',
                                           background: 'grey',
                                           padding: '5px',
                                           borderRadius: '50px',  
                                            margin: '1px 1px 5px 0px',
                                           width:'fit-content',
                                           color: 'white'}}>  
                                            <InfoOutlined fontSize={"small"} style={{marginLeft: '5px'}}/>    <div style={{marginLeft: '5px',marginRight: '7px',fontSize:'12px'}}>Class is full</div></div></div>
                                       
                                        ) 
                                        
                            )}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className={classes.padtop} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Divider style={{ width: '92%' }} />
                    </Grid>
                    {datas?.durationList?.length > 0 && datas?.durationList?.map((item, index) => (
                        (<><Grid key={index} item xs={4} md={4} lg={2} className={classes.padtop}>
                            <Typography variant="h5" style={{ display: 'flex', float: 'right' }} >{item?.dayName} </Typography>
                        </Grid>
                            <Grid item xs={8} md={6} lg={6} className={classes.pads} >
                                <Chip
                                    size={"small"}
                                    color={"success"}
                                    label={item?.startTime + " - " + item?.endTime}
                                    icon={<AccessTimeIcon fontSize={"small"} />}
                                />
                                <Chip
                                    size={"small"}
                                    color={"warning"}
                                    label={item?.duration + " Mins"}
                                    style={{ marginLeft: '10px' }}
                                    icon={<AvTimerIcon fontSize={"small"} />}
                                />
                            </Grid>
                
                            <Grid item xs={12} md={2} lg={4} className={classes.pads}>
                            </Grid></>)
                    ))}
                    {datas?.durationList?.length > 0 &&
                        <Grid item xs={12} md={12} lg={12} className={classes.padtop} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Divider style={{ width: '92%' }} />
                        </Grid>
                    }
                                   
                    <Grid item xs={4} md={4} lg={2} className={classes.padtop}  >
                        <Typography variant="h5" style={{ display: 'flex', float: 'right' }} >Description </Typography>
                    </Grid>
                    <Grid item xs={8} md={8} lg={10} className={classes.pads}>
                        <Typography
                            component={"div"}
                            variant={"body1"}
                            color={"text.secondary"}
                        >
                            {datas?.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className={classes.padtop} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Divider style={{ width: '92%' }} />
                    </Grid>
                    <Grid item xs={8} md={6} lg={6} className={classes.padtop}>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px', marginTop: '4px' }}>
                            <Div sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <Avatar src={datas?.profileUrl} alt="user" sx={{ width: 30, height: 30, mb: 2 }} />  
                            </Div>
                            <Div sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}>
                                <Div style={frompage === 'marketplace' ? { marginLeft: '5px', marginTop: '-10px' } : { marginLeft: '5px', marginTop: '-15px' }}  ><span >{datas?.username}</span>
                                    {profilerole === USERROLECODE.tutor && <span style={{ fontSize: '11px', color: '#8595A6 !important', marginTop: '-20px', marginLeft: '5px' }}>{datas?.createddatetime}</span>}
                                    {frompage === "marketplace" && <div style={{ marginTop: '-7px' }}><Rating style={{ fontSize: '10px' }} name="size-small" readOnly={true} defaultValue={5} size="small" /></div>}</Div> 
                            </Div>
                            <Div sx={{ color: 'primary', fontWeight: '400', cursor: 'pointer', alignItems: 'center', display: 'flex', padding: '5px', marginTop: '-17px', marginLeft: '10px' }} onClick={(e) => handleChat(e)}>
                            <ChatOutlinedIcon style={{ marginTop: '3px' }} fontSize="small" />
                            <Div style={{ marginLeft: '5px', fontSize: '11px' }}>Chat</Div>
                        </Div>
                           
                        </div>
                    </Grid>
                    {/* <Grid item xs={6} md={2} lg={2}>
                        <Div sx={{ color: 'primary', fontWeight: '400', cursor: 'pointer', alignItems: 'center', display: 'flex', padding: '5px', marginTop: '-17px', marginLeft: '10px' }} onClick={(e) => handleChat(e)}>
                            <ChatOutlinedIcon style={{ marginTop: '3px' }} fontSize="small" />
                            <Div style={{ marginLeft: '5px', fontSize: '11px' }}>Chat</Div>
                        </Div>
                    </Grid> */}
                    <Grid item xs={12} md={6} lg={6} className={classes.padtopright} style={{ marginTop: '10px' }}>
                        <div style={{ fontSize: '12px', display: 'flex', float: 'right', alignItems: 'center', marginRight: '25px' }}>
                            <span style={{ marginLeft: '10px' }}>Class Size  </span>
                            <div style={{ fontSize: '12px', marginLeft: '10px', display: 'flex' }}>Total
                                <Chip label={datas?.classSize} color="primary" style={{ height: '15px', fontsize: '10px !important', marginLeft: '10px', marginTop: '2px' }} size="small" />
                            </div>
                            {profilerole === USERROLECODE.tutor &&
                                (<div style={{ fontSize: '12px', marginLeft: '10px', display: 'flex' }}>Booked
                                                <Chip label={datas?.booked} color="warning" size="small"
                                        style={{ height: '15px', fontsize: '10px !important', marginLeft: '10px', marginTop: '2px' }} />
                                </div>)}
                            {profilerole === USERROLECODE.tutor && (
                                <div style={{ fontSize: '12px', marginLeft: '10px', display: 'flex' }}>Available
                                        <Chip label={(parseInt(datas.classSize) > 0) ? parseInt(datas.classSize) - parseInt(datas.booked) : 0} color="success" size="small" style={{ height: '15px', fontsize: '10px !important', marginLeft: '10px', marginTop: '2px', marginRight: '10px' }} />
                                </div>)}
                        </div>
                        
                    </Grid>
                    <Grid item xs={2} md={2} lg={2} className={classes.padtop}>
                        
                    </Grid>
                    <Grid item xs={2} md={2} lg={2} className={classes.padtop}>
                      
                    </Grid>
                </Grid></>)}
            
             {/* joinClass Popup */}
             <Dialog
                    open={joinClassOpen}
                    onClose={() => setjoinClassOpen(false)}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    sx={{
                        '& .MuiDialog-paper ':{
                            width: '720px',
                        }  
                    }} 
                    >
                
                    <DialogTitle id="scroll-dialog-title"> Join Class </DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description" 
                            tabIndex={-1}
                        >
                            <Box component="form"
                                 sx={{
                                    mx: -1, 
                                    '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                        p: 1,
                                        mb: 2,
                                        width: {xs: '100%', sm: '100%'}
                                    },
                                    '& .MuiFormControl-root.MuiFormControl-fluid': {
                                        width: '100%'
                                    }
                                }}
                                autoComplete="off"
                        >
                            <Grid container spacing={3.5}>                       
                                <Grid item xs={12} sm={12} lg={12}>
                            <Table> 
                            <TableBody>
                            <StyledTableRow key={"firstitem"}>
                                <TableCell sx={{pl: theme => theme.spacing(3)}}>
                                Wallet Balance
                                </TableCell>
                                 <TableCell align="right">  ${balanceAmount}</TableCell> 
                            </StyledTableRow>
                            <StyledTableRow key={"firstitem"}>
                                <TableCell sx={{pl: theme => theme.spacing(3)}}>
                                Cost of {datas?.className} Class
                                </TableCell>
                                <TableCell align="right">  ${parseInt(datas?.classLessionPrice ? datas?.classLessionPrice : 0) * parseInt(datas?.noOfLessons ? datas?.noOfLessons : 0)}</TableCell> 
                            </StyledTableRow>
                            <StyledTableRow key={"firstitem"}>
                                <TableCell sx={{pl: theme => theme.spacing(3)}}>
                                Remaining Balance
                                </TableCell>
                                 <TableCell align="right"> ${parseInt(balanceAmount) - parseInt(datas?.classLessionPrice ? datas?.classLessionPrice : 0) * parseInt(datas?.noOfLessons ? datas?.noOfLessons : 0)}</TableCell> 
                            </StyledTableRow>
                            </TableBody>
                                    </Table>
                                </Grid>
                                </Grid>
                                             
                     </Box>
                  </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={() => setjoinClassOpen(false)}>{t('common.cancel')}</Button>
                        <Button variant={"contained"} disabled={btnDisable} style={{textTransform: 'none'}} onClick={() => handleCheckout(datas)} >Checkout</Button>
                    </DialogActions>
                </Dialog>
        {/* Alert Dialog    */}
        <Dialog
                    open={alertOpen}  
                    onClose={() => setAlertOpen(false)}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{
                        '& .MuiDialog-paper ':{
                            width: '720px',
                        },  
                        '& .MuiDialogTitle-root' : {
                            padding: '16px 24px 0px 24px'
                        }
                    }}
                >
                   <DialogTitle id="scroll-dialog-title" style={{background: "#f397113d", marginBottom: '1px'}}>
                    <Grid xs={12} sm={12} lg={12}>                    
                        <div style={{ display: 'flex' }}><WarningIcon  color={'warning'} style={{ marginRight: '5px', marginTop: '3px' }} />
                        <Typography variant="h1"  color={'warning'}>{t('common.warning')}</Typography>
                        </div>                   
                    </Grid>
                </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    <Typography variant="h5" style={{padding: '10px'}}  color={'warning'}> {
                            (validateClassDatas && validateClassDatas.length > 0)  ? validateClassDatas[0].message : ''
                        } </Typography>
                        <Table> 
                            <TableBody>
                                {validateClassDatas.map((item, index) => {
                                    return (
                                        <StyledTableRow key={"firstitem"}>
                                            <TableCell sx={{ pl: theme => theme.spacing(3) }}>
                                               {item.className}
                                            </TableCell>
                                            <TableCell align="left">  {item.dayName} </TableCell>
                                            <TableCell align="right">   {item.classTime}</TableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                        {/* <Typography variant="h4" style={{marginTop: '20px', marginBottom: '0px'}}  color={'warning'}>  
                           {"Are you sure you want to proceed ?"}
                          </Typography> */}
                    </DialogContentText>
                    </DialogContent>
                <DialogActions> 
                        <Button variant={"outlined"}   style={{textTransform: 'none'}} onClick={() => setAlertOpen(false)}>Cancel</Button>
                        {/* <Button variant={"contained"}  style={{textTransform: 'none'}} onClick={() => handleProceed()}>Proceed</Button> */}
                    </DialogActions>
                </Dialog>
            
        </React.Fragment>
    );
};
ViewClassIndex.propTypes = {
    objdatas: PropTypes.object,
    frompage: PropTypes.node,
    onView: PropTypes.node,
};
export default ViewClassIndex;
