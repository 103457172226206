import React from 'react';
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {Button, Grid} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PageHeader = ({title, subheader, icon}) => {
    const navigate = useNavigate();
    const handleBack =() => {
        navigate(`/classes`);
    };
    return (
        <Grid container spacing={3.75}>
            <Grid  item xs={12} md={9} lg={9} >
                <Div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mb: 2
                }}
            >         
                {icon && <div style={{ display: 'flex' }}>{icon}
                    <Typography variant={"h2"} style={{marginLeft: '0.5%'}}>{title}</Typography>
                </div>}  
                {!icon && <Typography variant={"h2"}>{title}</Typography> }
                <Typography variant={"body1"} color={"text.secondary"}>{subheader}</Typography>
            </Div>
            </Grid>
            {/* <Grid  item xs={12} md={3} lg={3} style={{textAlign: 'right'}}>
            <Button
                disableElevation
                variant={"contained"}
                startIcon={<ArrowBackIcon />}
                sx={{
                    mb: 1,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    },
                    textTransform: 'none'
                }}
                onClick={handleBack}
            >Back</Button>
            </Grid> */}
        </Grid>
        
    );
};

export default PageHeader;