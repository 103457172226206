import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div"; 
import Button from "@mui/material/Button";  

const JoinRoomButtons = ({ handleJoinToRoom, isRoomHost }) => {
  const successButtonText = isRoomHost ? "Host" : "Join"; 
  const navigate = useNavigate(); 
  const pushToIntroductionPage = () => {
    navigate("/");
  };

  return (
    <Div sx={{mb: 2, mt: 1}} style={{textAlign: 'right'}}>                       
        <LoadingButton   
            style={{textTransform: 'none'}}                                 
            type="button"
            size="medium"
            variant="contained" 
            onClick={handleJoinToRoom}
      >{successButtonText}</LoadingButton>
        <Button variant={"outlined"} style={{ textTransform: 'none', marginLeft: '10px' }} onClick={pushToIntroductionPage}>Cancel</Button> 
      </Div>     
  );
};

export default JoinRoomButtons;
