import React, {useState} from 'react';
import {Grid,Box,alpha, Avatar, List, ListItemAvatar, ListItemText, ListItemButton} from "@mui/material";   
import { useTranslation } from "react-i18next";  
import ClassesList from "../../../shared/widgets/CurrentPlan/ClassesList"; 
import {InputBase, styled, Typography} from "@mui/material"; 
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'; 
import {setMenuName} from '../../../store/actions'
import { USERROLECODE } from '../../../utils/constants/constants'
import { makeStyles } from "@mui/styles"; 
import Pagination from "@jumbo/components/Pagination";
import { classServices } from "../../../services/apis/classes";  
import { useQuery } from "react-query"; 
import { store } from "../../../store/store";
import { setClassDetails } from '../../../store/actions'
import { useMutation } from "react-query";
import MUIDataTable from "mui-datatables";
import Span from "@jumbo/shared/Span";

const useStyles = makeStyles({ 
    pagination: {
        padding: '0px ', 
        borderRadius: '6px',
        textAlign: 'center',
        display: 'table',
        margin: '0 auto',  
        width: '100%',
        marginTop: '10px',
    }
});
export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    display: 'flex',
    borderRadius: 24,
    boxShadow: theme.shadows[25],

    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height: 24
    },
}));
const HomeClassList = ({classesList}) => { 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 
    const classes = useStyles();
    const loginDetails = store.getState().loginDetails;  
    // const  limit  = 10 ;
    // const [offset, setOffset] = useState(0);
    // const [currentPage, setCurrentPage] = useState(1);  
    // let pageSize = 10; 
    // const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, offset, limit, "classes"]; 
    // const {data: result, refetch: refetchClassesList } = useQuery(
    //     userDetails, classServices.activeClassesList
    // );   
    // const handlePagination = (page) => {
    //     let offsetVal = page * pageSize - pageSize;
    //     setOffset(offsetVal);
    //     setCurrentPage(page); 
    //     refetchClassesList().catch(console.error) 
    // }
    React.useEffect(() => {
        localStorage.setItem("ChatPerson",'');
        localStorage.setItem("ChatPersonProfilePic", '');
        localStorage.setItem("ChatPersonOnlineStatus",'');
        localStorage.setItem("ChatPersonProfileID", '');
        window.scrollTo(0, 0)
    });
    // store.dispatch(setMenuName(t('sidebar.menu.classes')));      
    const profilerole = Number(localStorage.getItem("switchprofile"));
    
    const mutation = useMutation(classServices.getClassDetails, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                return;
           }
            if (data?.statusFlag === 1) { 
                if (data?.body?.classDetails) {
                  localStorage.setItem("class_local_details", JSON.stringify(data?.body?.classDetails));
                  store.dispatch(setClassDetails(data?.body?.classDetails));  
                  navigate("/classes/classdetails/viewclass", { state: {items: data?.body?.classDetails} });
                }
            }         
        },
        onError() {
        },
    });
  
   const handleClassDetails = (item) => {  
        mutation.mutate({
            userCode: loginDetails ? Number(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? Number(loginDetails.roleCode): 0,   
            classCode: Number(item.classCode)
        });       
    }

 
    return (
        <>        

            <List disablePadding sx={{mb: 1}}>
                {
                   classesList && classesList?.length > 0 ? classesList?.map((item, index) => (                   
                        
                            <ListItemButton onClick={() => handleClassDetails(item)}
                                component={"li"}
                                // onClick={() => handleClick(item)}
                                sx={{
                                    p: theme => theme.spacing(1.25, 3),
                                    transition: 'all 0.2s',
                                    borderBottom: '1px solid #0000001a',
                                    '&:hover': {
                                        boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                                        transform: 'translateY(-4px)', 
                                        '& .MuiChip-animation': {
                                            width: 'auto',
                                            height: 'auto',
                                            fontSize: 12,
                                            display: 'block',
                                        }
                                    }
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4} lg={4} display={'flex'}>
                                        <ListItemAvatar sx={{ minWidth: 68 }}>
                                            <Avatar src={item?.profileUrl} sx={{ width: "48px", height: "48px" }} />                                
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography variant={"h5"} mb={.5}>{item.className}</Typography>}
                                            secondary={ 
                                                    <Typography variant={"body1"} color={'text.secondary'} fontSize={"small"}  style={{marginTop: '-6px',  marginLeft: '-6px' }}>
                                                    <Span sx={{color: 'primary.main'}}>{item.username}</Span>
                                                </Typography> 
                                            }
                                        />                                          
                                        </Grid>
                                        <Grid item xs={5} md={3} lg={3} style={{display: 'flex'}}>
                                        <ListItemText                          
                                        primary={
                                        <Typography variant={"body1"} component={"div"}>
                                            <Typography variant="p"  color={"text.secondary"} >{t('class.total')}  </Typography>                                            
                                            <Typography variant={"h6"}>{item?.noOfLessons}</Typography>                                                                                  
                                        </Typography>
                                        }
                                        sx={{flex: 1}}
                                    />
                                        </Grid>
                                        <Grid item xs={7} md={5} lg={5}>
                                        <ListItemText                          
                                            primary={
                                            <Typography variant={"body1"} component={"div"}>  
                                                <Typography variant="p"  color={"text.secondary"} >{t('class.period')}  </Typography>                                          
                                                <Typography variant={"h6"}>{item?.classDate}</Typography>                                                                                  
                                            </Typography>                                           
                                            }
                                            sx={{flex: 1}}
                                        />
                                        </Grid>
                                </Grid>
                            </ListItemButton>
                            
                    )) : <Grid item xs={12} md={12} lg={12} > <Typography variant={"h2"} mb={.5} style={{ textAlign: 'center' }}>No Classes Found</Typography>
                    {profilerole === USERROLECODE.tutor && <Typography variant={"h6"} mt={2} style={{ textAlign: 'center' }}>Looks like you haven't made your classes yet</Typography>}</Grid>
                }
           
            </List>
            {/* {result && parseInt(result?.totalcount) > 10 && <div className={classes.pagination}>
                <Pagination
                    currentPage={currentPage}
                    totalCount={result ? parseInt(result?.totalcount) : 0}
                    pageSize={pageSize}
                    primaryColor="#814DFA"
                    onPageChange={handlePagination} 
                />
            </div>} */}
        </>
    );
};

export default HomeClassList;
