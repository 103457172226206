import React from "react"; 
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';  
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StoreIcon from '@mui/icons-material/Store';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import VoiceChatOutlinedIcon from '@mui/icons-material/VoiceChatOutlined';
 
const menus = [
    {
        label: ' ',
        type: "section",
        children: [
            {
                uri: "/home",
                label: 'sidebar.menu.home',
                type: "nav-item",
                icon: <HomeOutlinedIcon sx={{ fontSize: 20 }} />
            },
            
            {
                uri: "/classes",
                label: 'sidebar.menu.classes',
                type: "nav-item",
                icon: <VoiceChatOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/marketplace",
                label: 'sidebar.menu.marketplace',
                type: "nav-item",
                icon: <StoreIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/calendar",
                label: 'sidebar.menu.calendar',
                type: "nav-item",
                icon: <DateRangeIcon sx={{ fontSize: 20 }} />
            },
            {
                uri:"/chats",
                label: 'sidebar.menu.chat',
                type: "nav-item",
                icon: <ChatOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/finance",
                label: 'sidebar.menu.finance',
                type: "nav-item",
                icon: <MonetizationOnOutlinedIcon sx={{ fontSize: 20 }} />
            } 
        ]
    }
        
];

export default menus;
