import React from "react"; 
import CrmDashboard from "../pages/menus/crm/CrmDashboard"; 
import Home from "../pages/menus/home";  
import Finance from "../pages/menus/finance"; 
import ChatApp from "../pages/menus/chat";
import Calendar from "../pages/menus/calendar/calendar";
import MarketPlace from "../pages/menus/marketPlace";
import UserProfile from "../pages/menus/users/user-profile";
import Chat from "../pages/menus/live-chat/Chat.js";

const dashboardRoutes = [ 
    {
        path: "/dashboards/crm",
        element: <CrmDashboard/>,
        checkauth: false
    },
    {
        path: "/home",
        element: <Home/>,
        checkauth: false
    },
   
    {
        path: "/finance",
        element: <Finance/>,
        checkauth: false
    },
    {
        path: "/calendar",
        element: <Calendar/>,
        checkauth: false
    },
    {
        path: [
            "/chats",
            "/chats/:chatBy/:id",
        ],
        element: <ChatApp/>,
        checkauth: false
    },
    {
        path: "/marketplace",
        element: <MarketPlace/>,
        checkauth: false
    },
    {
        path: "/user/profile",
        element: <UserProfile/>,
        checkauth: false
    },
    
    {
        path: "/livechat",
        element: <Chat/>
    }
];

export default dashboardRoutes;
