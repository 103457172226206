import React, { useState} from 'react';
import { Typography,TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip,  alpha, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid"; 
import {useTranslation} from "react-i18next";
import MovieIcon from '@mui/icons-material/Movie';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import {useDropzone} from "react-dropzone";
import DndWrapper from "./DndWrapper";
import { USERROLECODE } from '../../../utils/constants/constants' 
import { authServices } from "../../../services/auth/user";
import { classServices } from "../../../services/apis/classes";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import S3 from 'react-aws-s3';  
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog"; 
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'; 
import LoadingButton from "@mui/lab/LoadingButton";
import { getAssetPath } from "../../../utils/appHelpers";
import { store } from "../../../store/store";
import DeleteIcon from "@mui/icons-material/Delete";
import { videoFiles } from '../../../utils/constants/constants'
var aws = require('aws-sdk');

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
}; 
const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Div sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Div>
            )}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};



const Multimedia = () => { 
    const {t} = useTranslation(); 
    const descriptionElementRef = React.useRef(null);    
    const [value, setValue] = React.useState(0);
    const profilerole = Number(localStorage.getItem("switchprofile"));
    const [files, setFiles] = useState([]);
    const [getFilesData, setFileData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [header, setHeader] = React.useState('');
    const [description, setDescription] = React.useState(''); 
    const [errorHeader, setErrorHeader] = React.useState('');
    const [errorDesc, setErrorDesc] = React.useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [filesData, setFilesData] = useState(null); 
    const [isLoading, setLoading] = useState(false); 
    const [process, setProcess] = useState('Insert'); 
    const { showDialog, hideDialog } = useJumboDialog();
    const [fileTypeErrror, setFileTypeErrror] = useState('');
    const [multimediaList, setMultimediaList] = useState([]);
    //Get stored values
  
    const classDetails = store.getState().classDetails;
    const loginDetails = store.getState().loginDetails;
    const credentialDetails = store.getState().credentials;
    const config = {
        bucketName: credentialDetails?.s3bucket,
        dirName: 'multimedia', /* optional */
        region: credentialDetails?.region,
        accessKeyId: credentialDetails?.awsAccessKey,
        secretAccessKey: credentialDetails?.awsSecretKey, 
    }

    //Call multimedia list
    const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, classDetails ? parseInt(classDetails.classCode) : 0 , 0, 10]; 
    const {data: result, refetch: refetchMultimediaList } = useQuery(
        userDetails, classServices.multimediaList
    );  
    
    const mutation = useMutation(classServices.multimedia, {
        onSuccess: (data) => {  
            setOpen(false);
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" });  
                setFiles([]);
                setHeader('');
                setDescription('');
                setLoading(false);
                setFilesData(null); 
                if (process === 'Delete') {
                    hideDialog();
                }
                refetchMultimediaList().catch(console.error); 
           }
        },
        onError() {
            setLoading(false);
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });
    React.useEffect(async () => {
        if (result) {
            if (result?.mediaList?.length > 0) {
                setValue(0);
            }
            setMultimediaList(result && result?.mediaList?.length > 0 ? result?.mediaList : [])
        } 
    }, [ result]);
    
    React.useEffect(async () => {
          //File data
        if (filesData && filesData.length > 0) { 
            mutation.mutate({
                userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
                userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,
                classCode: classDetails ? classDetails.classCode : '',
                header: header,
                description: description,
                process: "Insert",
                filePaths:'',
                files: filesData,
                ipAddress:'',
                multimediaCode: '',
            });
        }
    }, [ filesData]); 
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'video/*',
        onDrop: acceptedFiles => { 
            let hasNoType = '';
            const dropFiles = Object.assign([], files);
            if (acceptedFiles && acceptedFiles.length > 0) {
                acceptedFiles.forEach(f => {
                    let f_length = f?.path?.split('.').length;
                    let type = f?.path?.split('.')[f_length - 1];
                    if (videoFiles.includes(type.toUpperCase())) { 
                         f.image = getAssetPath(`${ASSET_IMAGES}/icon-video.jpg`,"40x40") 
                        dropFiles.push(f)
                    } else {
                        hasNoType = 'formaterror';
                    }
                    if (parseInt(f?.size) > 500000000) { 
                        hasNoType = 'filesize';
                    }  
                });
            }
            if (hasNoType) {
                setFileTypeErrror(hasNoType)
                return;
            } else {
                setFileTypeErrror('')
            } 
            setFiles(dropFiles);
            setFileData(acceptedFiles.map(file =>
                Object.assign(file, {
                    preview: file ,
                }),)
            );
        },
    });
     
    // const thumbs = files.map(file => (
    //     <div style={thumb} key={file.name}>
    //         <div style={thumbInner}>
    //         <video width="400" controls>
    //             <source src={URL.createObjectURL(file.preview)}/>
    //         </video> 
    //         </div>
    //     </div>
    // ));
 

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
     
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    const handleClickOpen = (scrollType) => () => {
        setFiles([]);
        setHeader('');
        setDescription('');
        setFilesData(null);
        setOpen(true);
        setProcess("Insert");
        setScroll(scrollType);
    };
    // const hiddenbrowser_sideclick = () => {
    //     // window.addEventListener('contextmenu', function (e) {
    //     //     e.preventDefault();
    //     //   }, false);
    //     [...document.querySelectorAll(".js-noMenu")].forEach( el => 
    //         el.addEventListener('contextmenu', e => e.preventDefault())
    //        );
    // };
    const fileUpload = async () => {
        let fileData = []; let flag = 0;
        if (getFilesData.length > 0) {
            await getFilesData.forEach(async (file) => {
                const file_name = 'media_' + loginDetails?.userCode + "_"+classDetails.classCode+"_"+ Date.now();
                const fileName = loginDetails.userCode + '/' + classDetails.classCode + '/' + file_name;
                const ReactS3Client = new S3(config);
                //Upload new file
                await ReactS3Client
                    .uploadFile(file, fileName)
                    .then(data => { fileData.push({ fileName: file_name, filePath: data.location }) })
                    .catch(err => console.error(err, 'error'))
                flag = flag + 1;
                if (flag === getFilesData.length) {
                    setFilesData(fileData);
                } 
            })
           
        } else {
            enqueueSnackbar("Please upload video", { variant: "warning" }); 
            return;
        }

    }
    const handleUpload = async () => {
        if (!header) {
            setErrorHeader(t('class.errorHeader'));
            return;
        }
        if (!description) {
            setErrorHeader('');
            setErrorDesc(t('class.errorDesc'));
            return;
        }
        setErrorHeader('');
        setErrorDesc('');
        //multimedia upload
        setLoading(true);
        await fileUpload(); 
         
       
    }
    const deleteVideo = async (multimediaCode,filePaths) => {  
        if (filePaths && filePaths.split("/").length > 5) { 
              //Delete file in DB 
            const s3 = new aws.S3({
                accessKeyId: credentialDetails?.awsAccessKey,
                secretAccessKey: credentialDetails?.awsSecretKey,
                Bucket: credentialDetails?.s3bucket,
                region: credentialDetails?.region
            });
            await s3.deleteObject({ Bucket: credentialDetails?.s3bucket, Key: filePaths.split("/")[3]+'/' + filePaths.split("/")[4]+'/'+ filePaths.split("/")[5]+'/'+ filePaths.split("/")[6]}, (err, data) => { 
                console.log(data);
            });
        }  
      
         await mutation.mutate({ 
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,
            classCode: classDetails ? classDetails.classCode : '',
            header: '',
            description: '',
            process: "Delete",
            filePaths:filePaths,
            files: '',
            ipAddress: await authServices.getMyIpAddress(),
            multimediaCode: multimediaCode, 
        })
    }
    const handleDelete = async (multimediaCode, e, filePaths) => {
        setProcess('Delete'); 
        showDialog({
            variant: "confirm",
            title: "Are you sure",
            content: "You want to delete this video?",
            onYes: async () => deleteVideo(multimediaCode,filePaths),
            onNo: () => hideDialog(),
        })
        e.stopPropagation();
    }
    const handleRemoveVideo = (row) => { 
        setFiles(files.filter(item => item?.name !== row?.name));
    }
    
    [...document.querySelectorAll(".js-noMenu")].forEach( el => 
        el.addEventListener('contextmenu', e => e.preventDefault())
       );
    return ( 
        <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
            {/* <CardContent>  */}
                <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>               
                    <Grid xs={8}>                    
                        <div style={{ display: 'flex' }}><MovieIcon style={{ marginRight: '5px' }} />
                            <Typography variant="h1" mb={3}>  {t('class.multimedia')}</Typography>
                        </div>                   
                    </Grid>  
                    <Grid xs={4} style={{textAlign: 'right'}}>
                    {profilerole === USERROLECODE.tutor && <Button
                        variant={"contained"}
                        startIcon={<VideoCallIcon />}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem'
                            },
                            textTransform: 'none',
                            fontSize: '12px',
                            paddingBottom: '3px',
                            paddingTop: '3px'
                        }}
                        onClick={handleClickOpen('paper')}
                    >{t('class.addvideo')}</Button>}
                    </Grid>                 
                </Grid>      

                 <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}} className="js-noMenu">               
                    <Grid xs={12}>                    
                    <Div sx={{ bgcolor: 'background.paper' }}>
                        {multimediaList.length > 0 ? multimediaList.map((item, index) => ( 
                            <TabPanel value={value} index={index}  >
                                <video style={{ height:"400px", width:"100%"}}  controlsList="nodownload" controls >
                                     <source src={item.filePath}/>
                                </video>
                            </TabPanel>
                        )) :
                            <div>
                                <Typography variant={"h2"} mb={.5} style={{ textAlign: 'center' }}>No Multimedia Found</Typography>
                                {profilerole === USERROLECODE.tutor && <Typography variant={"h6"} mt={2} style={{ textAlign: 'center' }}>Looks like you haven't made your video yet</Typography>}
                            </div>}
                             
                            <Grid container>               
                            <Grid xs={12}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                >
                                    {multimediaList?.length > 0 && multimediaList.map((item, index) => (  
                                        <Tab sx={{ 
                                            transition: 'all 0.2s',
                                            borderBottom: '1px solid #0000001a',
                                            '&:hover': {
                                                boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                                                transform: 'translateY(-4px)', 
                                                '& .MuiChip-animation': {
                                                    width: '100%',
                                                    height: 'auto',
                                                    fontSize: 12,
                                                    display: 'block',
                                                }
                                            }
                                        }}
                                            label={
                                                <>
                                                {profilerole === USERROLECODE.tutor && <Tooltip title=  "Delete">
                                                <Div className={'MuiChip-animation'} onClick={(e) => handleDelete(item.multimediaCode, e, item.filePath)}
                                                    sx={{
                                                        width: 0,
                                                        height: 0,
                                                        fontSize: 0,
                                                        display: 'none',
                                                        transition: 'all 0.2s',
                                                    }} >
                                                            <DeleteIcon style={{float: 'right'}} fontSize={"small"} />   
                                                </Div>
                                            </Tooltip>}
                                            <img src={`${ASSET_IMAGES}/icon-video.jpg`} alt="tabimage" style={{ width: 40, height: 40 }} />
                                            <div style={{ width: "100px", textTransform: "capitalize", fontWeight: "400" }}>{item.header}</div> 
                                            </>
                                            }
                                            {...a11yProps(index)}
                                        /> 
                                    ))} 
                           
                        </Tabs>
                        </Grid>
                       
                    </Grid>                  
                    </Div>                
                    </Grid>                   
                </Grid>

                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">{t('class.uploadVideo')}</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <Box component="form"
                                sx={{
                                    mx: -1, 
                                    '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                        p: 1,
                                        mb: 2,
                                        width: {xs: '100%', sm: '100%'}
                                    },
                                    '& .MuiFormControl-root.MuiFormControl-fluid': {
                                        width: '100%'
                                    }
                                }}
                                autoComplete="off" >
                        <FormControl size="small">
                            <Grid container spacing={3.5}>                       
                                <Grid item xs={12} sm={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        id="header"
                                        label="Header"
                                        size="small"
                                        value={header}
                                        onChange={e => setHeader(e.target.value)}
                                        error={errorHeader ? true : false}
                                        helperText={errorHeader ? errorHeader : ""}
                                        onBlur={(event) => {
                                            if (event.target.value) {
                                                setErrorHeader('');
                                            } else {
                                                setErrorHeader(t('class.errorHeader'));
                                            }
                                         }} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        id="description"
                                        label="Description"
                                        size="small"
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                        error={errorDesc ? true : false}
                                        helperText={errorDesc ? errorDesc : ""}
                                        onBlur={(event) => {
                                            if (event.target.value) {
                                                setErrorDesc('');
                                            } else {
                                                setErrorDesc(t('class.errorDesc'));
                                            }
                                         }} 
                                    />
                                </Grid>
                            </Grid>                               
                        </FormControl>                           
                        <FormControl>
                        <Grid container spacing={3.5}>
                            <Grid item xs={12} sm={12} lg={12}>
                            <Div>
                                <DndWrapper>
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} /> 
                                        <Typography variant={"body1"}>Drag 'n' drop some video here, or click to select
                                            video</Typography>
                                    </div>
                                </DndWrapper> 
                                            {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
                                {fileTypeErrror && (
                                    fileTypeErrror === "formaterror" ? <Typography fontSize={"small"} color='error'>
                                    {t('announcement.errorFileFormat')}
                                    </Typography> :  <Typography fontSize={"small"} color='error'>
                                    {t('announcement.errorFileType')}
                                    </Typography>                            
                                )}  
                                 {files && files.length > 0 && (
                                    <Grid container style={thumbsContainer}>
                                    {
                                        files.map((row) => (
                                            <Grid xs={3} md={2} lg={2} 
                                            sx={{
                                                boxShadow: '0 3px 10px 0 ${alpha(#000, 0.2)}',
                                                marginRight: '10px',
                                                transform: 'translateY(-4px)', 
                                                '& .MuiChip-animation': {
                                                    width: 'auto',
                                                    height: 'auto',
                                                    fontSize: 12,
                                                    display: 'block',
                                                } }
                                            }>
                                            <div style={{textAlign: 'center'}} >
                                                <Tooltip title="Delete">
                                                <IconButton
                                                    className={'MuiChip-animation'} 
                                                    onClick={() => handleRemoveVideo(row)}            
                                                    sx={{
                                                    width: 0,
                                                    height: 0,
                                                    fontSize: 0,
                                                    display:'none',
                                                    transition: 'all 0.2s',
                                                    float: 'right'
                                                    }}>
                                                <CancelOutlinedIcon fontSize={"small"} />
                                                </IconButton>
                                                </Tooltip>
                                                <img src={row.image} alt="imagAlt" style={{width: '60px', height: '60px'}} />
                                            </div>
                                            
                                        </Grid>
                                            
                                        ))
                                    }
                                    </Grid>  
                                    )}            
                            </Div>
                             
                            </Grid> 
                            </Grid>
                        </FormControl>
                       
                    </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={() => setOpen(false)}>Cancel</Button>
                        <LoadingButton   
                                    style={{textTransform: 'none'}}                                 
                                    type="submit"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => handleUpload(false)}
                                    loading={isLoading}
                                >Upload</LoadingButton> 
                    </DialogActions>
                </Dialog>
        </Div>
        
    );
};

export default Multimedia;
