import React from 'react';
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import List from "@mui/material/List";
import SearchIcon from '@mui/icons-material/Search';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import VoiceChatOutlinedIcon from '@mui/icons-material/VoiceChatOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'; 
import Divider from "@mui/material/Divider";

const ProfileList = () => {
    return (
        <List disablePadding >
            <ListItem sx={{pt: '4px', pb: '4px'}}>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <SearchIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary="Search for Classes"/>
            </ListItem>
            <ListItem sx={{pt: '4px', pb: '4px'}}>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <InsertLinkOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary="Buy Classes"/>
            </ListItem>
            <ListItem sx={{pt: '4px', pb: '4px'}}>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <VoiceChatOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary="Attend Class"/>
            </ListItem>
            <ListItem sx={{pt: '4px', pb: '4px'}}>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <AutoStoriesOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary="Access Course Materials"/>
            </ListItem>
            <ListItem sx={{pt: '4px', pb: '4px'}}>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <QuestionAnswerOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary="Chat with Tutor"/>
            </ListItem>
            <Divider sx={{mt:2}}/>
        </List>
    );
};

export default ProfileList;
