import React from 'react';
import MailAppProvider from "./MailAppProvider";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import MailAppSidebar from "./components/MailAppSidebar";
import MailDetail from "./components/MailDetail";
import {useJumboTheme} from "@jumbo/hooks"; 
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'; 
import { connect } from "react-redux";

const MailApp = (props) => {
    const {theme} = useJumboTheme(); 
    const { classDetails, className } = props;
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        }
    }), [theme]);

    return (
        <MailAppProvider>
            <JumboContentLayout
                sidebar={<MailAppSidebar/>}
                header={
                    <PageHeader
                        title={classDetails?.className ? classDetails?.className : className}
                        icon={<VideoCameraFrontIcon style={{fontSize:'26px', marginTop:'1px'}}/>}/>
                }
                layoutOptions={layoutOptions}
            >
                {/* {
                    lg && (
                        <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                            <FolderDropdown/>
                            <MailFab/>
                        </Stack>
                    )
                } */}
                <MailDetail/> 
            </JumboContentLayout>
        </MailAppProvider>
    );
};
const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  }; 
export default connect(mapStoreStateToProps)(MailApp);
