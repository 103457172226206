import React from 'react';
import { Typography, Breadcrumbs, Link} from "@mui/material"; 
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button"; 
import Grid from "@mui/material/Grid"; 
import { useQuery } from "react-query"; 
import {useTranslation} from "react-i18next";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';  
import { useNavigate, useLocation } from "react-router-dom"; 
import { USERROLECODE } from '../../../utils/constants/constants'
import Files from "../../../shared/widgets/FileList/Files";
import UploadDialog from './uploadDialog';
import { classServices } from "../../../services/apis/classes"; 
import { store } from "../../../store/store"

const UploadFiles = (filedetails) => { 
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const profilerole = Number(localStorage.getItem("switchprofile")); 
    const classDetails = store.getState().classDetails;
    const loginDetails = store.getState().loginDetails; 
    const [isSuccess, setSuccessfull] = React.useState(false); 
    const userData = [loginDetails ? parseInt(loginDetails.userCode) : 0, classDetails ? Number(classDetails.classCode) : '']; 
    const {data: classData} = useQuery(
        userData, classServices.getClasses
    ); 
  
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => { 
        setOpen(true);
        setScroll(scrollType);
    };
   
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    

    const back = () => {
        navigate("/classes/classdetails/files");
    }
     
    return ( 
        <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
                <Grid container  style={{paddingLeft: '3%', paddingRight: '3%', marginBottom: '2%'}}>
                    <Grid xs={8} >
                     <div>
                        <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit"  style={{cursor: 'pointer'}} onClick={back}>
                            Files
                        </Link>                        
                        <Typography color="text.primary">{location.state.filedetails.folderName}</Typography>
                    </Breadcrumbs>
                    </div>                   
                    </Grid>
                    {profilerole === USERROLECODE.tutor && <Grid xs={4} style={{ textAlign: 'right' }}>
                        <Button
                            variant={"contained"}
                            startIcon={<CloudUploadIcon />}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.5rem'
                                },
                                textTransform: 'none'
                            }}
                            onClick={handleClickOpen('paper')}
                        >{t('class.uploadFile')}</Button>
                    </Grid>}
                </Grid>
                <Grid container>               
                    <Grid xs={12}>
                        <Files classDetails={classDetails} folderDetails = {location.state.filedetails} isSuccess={isSuccess} isSuccessful={setSuccessfull}/>
                    </Grid>
                </Grid>                    
                  {/* upload File Popup */}
                {open && <UploadDialog open={open} scroll={scroll} 
                classesFolderList = {classData?.classesFolderList} onClose={setOpen} 
                folderCode={location?.state?.filedetails?.folderCode} isSuccessful={setSuccessfull}/>}

        </Div>
        
    );
};

export default UploadFiles;
