import React from "react"; 
import CreateClass from "../pages/classes/createClass"; 
import CreateAnnouncement from "../pages/classes/createAnnouncement"; 
import MyClasses from "../pages/classes/myClasses"; 
import MailApp from "../pages/classes/mail";
import Login2 from "../pages/auth-pages/login2"; 

const classesRoutes = [ 
    {
        path: "/classes/createclass",
        element: <CreateClass/>,
        checkauth: false,
        defaultelement: <Login2 />
    },
    {
        path: "/classes/createannouncement",
        element: <CreateAnnouncement/>,
        checkauth: false
    },
    {
        path: "/classes",
        element: <MyClasses/>,
        checkauth: false
    },
    {
        path: "/classes/classdetails",
        element: <MailApp/>,
        checkauth: false
    },

    {
        path: [
            "/classes/classdetails/:category",
            "/classes/classdetails/:category/:id",
            "/classes/classdetails/:category/message/:messageID",
            "/classes/classdetails/:category/:id/message/:messageID"
        ],
        element: <MailApp/>,
        checkauth: false
    },
    {
        path: "/classes/uploadFiles",
        element: <uploadFiles/>,
        checkauth: false
    }   
    
];

export default classesRoutes;
