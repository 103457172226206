import React, {Suspense} from 'react';
import {IconButton, Avatar} from "@mui/material";
import menus from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import {DrawerHeader} from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {useJumboLayoutSidebar, useJumboSidebarTheme} from "@jumbo/hooks";
import {SIDEBAR_STYLES, SIDEBAR_VIEWS, SIDEBAR_VARIANTS} from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";   
import { USERROLES } from '../../../../utils/constants/constants'  
import { connect } from "react-redux";
import {isMobile, isBrowser} from 'react-device-detect';

const Sidebar = (props) => {
    const { loginDetails , switchprofile} = props;
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar(); 
    const isMiniAndClosed = React.useMemo(() => { 
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]); 
    const profilerole = localStorage.getItem("switchprofile");
    const profileCode = switchprofile ? switchprofile : profilerole ? profilerole : ''
    const profile = USERROLES.find(role => role.userRoleCode === Number(profileCode)); 
    React.useEffect(() => {  
    }, [loginDetails, switchprofile]);
    return (
        <React.Fragment>
            <SidebarHeader/>
            <JumboScrollbar
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                px: 3
                            }}
                        >
                            <SidebarSkeleton/>
                        </Div>
                    }
                >
                    <JumboVerticalNavbar translate items={menus} switchprofile={switchprofile}/>
                    {/* <div style={!isMiniAndClosed ?
                        { padding: '5% 0 0 5%',   background: '#d9dee8', position: 'absolute', bottom: '0', width: '100%'
                        } : { padding: '5% 0 0 5%', position: 'absolute', bottom: '0', width: '100%'} 
                    }>
                    <div style={!isMiniAndClosed ? { display: 'flex', alignItems: 'center' } : { display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                         <Div sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <Avatar src={loginDetails?.profileUrl} alt="user" sx={{ width: 30, height: 30}} style={{marginBottom: '10px'}} /> 
                        </Div>
                            {!isMiniAndClosed && <Div sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}>
                                <Div style={{ marginLeft: '10px', marginTop: '-15px', color: '#002366', display: 'flex', flexDirection: 'column' }}  >
                                    <span >{loginDetails && loginDetails?.firstName.concat(' ', loginDetails.lastName)}</span>
                                    <span style={{ fontSize: '12px', fontWeight: 300, textTransform: 'uppercase' }}>{profile?.userRoleName}</span>
                                </Div>
                            </Div>}
                           
                       </div> </div> */}
                </Suspense>
            </JumboScrollbar>
        </React.Fragment>
    );
};

const SidebarHeader = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {sidebarTheme} = useJumboSidebarTheme();

    const isMiniAndClosed = React.useMemo(() => { 
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    const handleMouseLeave = () => {   
        if (isMobile) {
            setSidebarOptions({variant: SIDEBAR_VARIANTS.PERSISTENT, view: SIDEBAR_VIEWS.MINI,style: SIDEBAR_STYLES.FULL_HEIGHT, open: false});  
        }  
        if(isBrowser) {
            setSidebarOptions({variant: SIDEBAR_VARIANTS.PERMANENT, view: SIDEBAR_VIEWS.MINI,style: SIDEBAR_STYLES.FULL_HEIGHT, open: false}); 
        }  
        // if (!sidebarOptions?.view) {
        //     setSidebarOptions({variant: SIDEBAR_VARIANTS.PERSISTENT , open: false});
        //  }
    };
    return (
        <React.Fragment>
            {
                sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <DrawerHeader>
                    <Logo mini={isMiniAndClosed} mode={sidebarTheme.type}/>
                    {
                        <Zoom in={sidebarOptions?.open}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ml: 0, mr: -1.5}}
                                onClick={handleMouseLeave}
                            >
                                <MenuOpenIcon/>
                            </IconButton>
                        </Zoom>
                    }
                </DrawerHeader>
            }
        </React.Fragment>
    )
};
const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
};
export default connect(mapStoreStateToProps)(Sidebar);  
