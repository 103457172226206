import React from 'react';
import Div from "@jumbo/shared/Div";
import AuthUserSummary from "../AuthUserSummary";
import ChatGlobalSearch from "../ChatGlobalSearch"; 
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {TabContext, TabPanel} from "@mui/lab"; 
import RecentConversationsList from "../RecentConversationsList"; 

const ChatAppSidebar = () => { 
    return (
        <React.Fragment  >
            <Div sx={{ p: 2, pb: 1.25 }}>
                <AuthUserSummary/>
            </Div>
            <TabContext value={"chat"}> 
                <JumboScrollbar
                    style={{minHeight: 200}}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}
                >
                    <TabPanel value={"chat"} sx={{p: 0}}> 
                        <RecentConversationsList fromPage={'chat'}/>
                    </TabPanel> 
                </JumboScrollbar>
            </TabContext>
        </React.Fragment>
    );
};

export default ChatAppSidebar;
