import React from 'react';
import {Avatar, ListItemAvatar, ListItemText, Typography, Grid} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ListItemButton from "@mui/material/ListItemButton";
import { USERROLECODE } from '../../../utils/constants/constants'

const PaymentItemStudent = ({ item }) => {
    const profilerole = localStorage.getItem("switchprofile");
    let bgclr = "#38529A";
    if (item.transType === "Buy") {
        bgclr = "#38529A";
    } else if (item.transType === "Topup") {
        bgclr = "#17A9FC";
    } else {
        bgclr = "#CC4BB7";
    }
    return (
        <ListItemButton component={"li"} sx={{p: theme => theme.spacing(1, 3)}}>  
            <ListItemText sx={{
                    flexBasis: '60%'
                    }}
                >
                <Typography variant='h6' mb={.5}>{item.createddatetime}</Typography>
                <Typography variant='body1' component={"span"}>{item.transName}</Typography>
                </ListItemText>
                <ListItemText style={{float:'right'}}>
                    <Typography variant='body1' component={"span"}>$ {item.totalamt}</Typography>
                    {item.transactionType === 'Credit' ?
                        <ArrowUpwardIcon color='success' sx={{ml: 1, verticalAlign: 'middle'}} fontSize={"small"}/> :
                        <ArrowDownwardIcon color='error' sx={{ml: 1, verticalAlign: 'middle'}} fontSize={"small"}/>
                    }
            </ListItemText>         
        </ListItemButton>   
             
    );
};
/* Todo item prop define */

export default PaymentItemStudent;
