import axios from "../config";
import {AUTH_URL} from "../../utils/constants/paths";
const classServices = {};
//Get classes list
classServices.getClasses = async ({ queryKey }) => { 
    const userCode = queryKey[0];
    const classCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const { data } = await axios.post(AUTH_URL + '/classes/getClassesJwt', {
        userCode: userCode,
        classCode: classCode
    });
    if (data?.token) { 
        const  result = await axios.post(AUTH_URL + '/classes/getClasses', {
            userCode: userCode,
            classCode: classCode,
            jwtToken: data?.token
        }); 
        return result?.data?.body;
    } else {
        return "";
    } 
};

//Curd class
classServices.class = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/classJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/class', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//getClassDetails
classServices.getClassDetails = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/getClassDetailsJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/getClassDetails', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//Curd announcement
classServices.announcement = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/announcementJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/announcement', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//Get Announcement List
classServices.announcementList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10;
    const classCode = queryKey[4] ? parseInt(queryKey[4]) : ''
    const frompage = queryKey[5] ? queryKey[5] : ''
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit,
        classCode: classCode,
        frompage:frompage
    }
    const { data } = await axios.post(AUTH_URL + '/classes/announcementListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/announcementList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};

// dismissAnnouncement
classServices.dismissAnnouncement = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/dismissAnnouncementJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/dismissAnnouncement', values); 
        return result?.data;
    } else {
        return "";
    } 
};


//Get Classes List
classServices.classesList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10;
    const fromPage = queryKey[4] ? queryKey[4] : '';
    const searchText = queryKey[5] ? queryKey[5] : '';
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit,
        fromPage: fromPage,
        searchText: searchText
    }
    const { data } = await axios.post(AUTH_URL + '/classes/classesListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/classesList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};


//Curd Folder
classServices.createFolder = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/createFolderJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/createFolder', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//deleteFolder
classServices.deleteFolder = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/deleteFolderJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/deleteFolder', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//Get Folder List for class
classServices.folderList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10;
    const classCode = queryKey[4] ? queryKey[4] : '';
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit,
        classCode: classCode,
    }
    const { data } = await axios.post(AUTH_URL + '/classes/folderListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/folderList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};

//uploadFiles Folder
classServices.uploadFiles = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/uploadFilesJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/uploadFiles', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//deleteFile Folder
classServices.deleteFile = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/deleteFilesJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/deleteFiles', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//Get Files List
classServices.fileList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10;
    const classCode = queryKey[4] ? parseInt(queryKey[4]) : ''
    const folderCode = queryKey[5] ? parseInt(queryKey[5]) : ''
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit,
        classCode: classCode,
        folderCode: folderCode
    }
    const { data } = await axios.post(AUTH_URL + '/classes/fileListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/fileList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};


//createSubmissionFolder
classServices.createSubmissionFolder = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/createSubmissionFolderJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/createSubmissionFolder', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//Get Folder List for class submissions
classServices.submissionFolderList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10;
    const classCode = queryKey[4] ? queryKey[4] : '';
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit,
        classCode: classCode,
    }
    const { data } = await axios.post(AUTH_URL + '/classes/submissionFolderListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/submissionFolderList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};

//deleteSubmissionFolder
classServices.deleteSubmissionFolder = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/deleteSubmissionFolderJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/deleteSubmissionFolder', values); 
        return result?.data;
    } else {
        return "";
    } 
};


//uploadFiles Folder
classServices.uploadSubmissionFiles = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/uploadSubmissionFilesJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/uploadSubmissionFiles', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//deleteSubmissionFiles
classServices.deleteSubmissionFiles = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/deleteSubmissionFilesJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/deleteSubmissionFiles', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//Get submissionFileListJwt 
classServices.submissionFileList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10;
    const classCode = queryKey[4] ? parseInt(queryKey[4]) : ''
    const folderCode = queryKey[5] ? parseInt(queryKey[5]) : ''
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit,
        classCode: classCode,
        folderCode: folderCode
    }
    const { data } = await axios.post(AUTH_URL + '/classes/submissionFileListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/submissionFileList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};

//Get submissionsList 
classServices.submissionsList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10;
    const classCode = queryKey[4] ? parseInt(queryKey[4]) : ''
    const folderCode = queryKey[5] ? parseInt(queryKey[5]) : ''
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit,
        classCode: classCode,
        folderCode: folderCode
    }
    const { data } = await axios.post(AUTH_URL + '/classes/submissionsListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/submissionsList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};


//Curd Multimedia
classServices.multimedia = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/classMultimediaJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/classMultimedia', values); 
        return result?.data;
    } else {
        return "";
    } 
};


//Get Multimedia List
classServices.multimediaList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const classCode = queryKey[2] ? parseInt(queryKey[2]) : ''
    const offset = queryKey[3] ? parseInt(queryKey[3]) : 0;
    const limit = queryKey[4] ? parseInt(queryKey[4]) : 10;
  
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        classCode: classCode,
        offset: offset,
        limit: limit 
    }
    const { data } = await axios.post(AUTH_URL + '/classes/classMultimediaListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/classMultimediaList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};



//Get Conference List
classServices.conferenceList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const classCode = queryKey[2] ? parseInt(queryKey[2]) : 0; 
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        classCode: classCode, 
    }
    const { data } = await axios.post(AUTH_URL + '/classes/classConferenceListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/classConferenceList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};


//Get Meeting details
classServices.meetingDetails = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const classCode = queryKey[1] ? parseInt(queryKey[1]) : 0;  
    let values = {
        userCode: userCode,
        classCode: classCode, 
    }
    const { data } = await axios.post(AUTH_URL + '/classes/getJoinClassDetailsJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/getJoinClassDetails', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};


//Join class checkout
classServices.joinClassCheckout = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/joinClassCheckoutJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/joinClassCheckout', values); 
        return result?.data;
    } else {
        return "";
    } 
};


//Validate week days
classServices.getNoOfLessons = async (values) => { 
    const  result = await axios.post(AUTH_URL + '/classes/getNoOfLessons', values); 
    return result?.data; 
};

 

//Get contactUser
classServices.contactUser = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0; 
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode
    }
    const { data } = await axios.post(AUTH_URL + '/classes/contactUsersJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/contactUsers', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};

 

//addMsg
classServices.addMsg = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/addMessageJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/addMessage', values); 
        return result?.data;
    } else {
        return "";
    } 
};


//getMessage
classServices.getMsg = async ({ queryKey }) => {
    const senderId = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const receiverId = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const userCode = queryKey[2] ? parseInt(queryKey[2]) : 0; 
    if (receiverId) {
        let values = {
            senderId: senderId,
            receiverId: receiverId, 
            userCode: userCode
        }
        const { data } = await axios.post(AUTH_URL + '/classes/getMessageJwt', values);
        if (data?.token) {
            values.jwtToken = data?.token;
            const  result = await axios.post(AUTH_URL + '/classes/getMessage', values); 
            return result?.data?.body;
        } else {
            return "";
        } 
    } else {
        return "";
    } 
}; 

//getUnseenMessagesJwt
classServices.getUnseenMessages = async ({ queryKey }) => {
    const receiverId = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    let values = {
        receiverId: receiverId
    }
    const { data } = await axios.post(AUTH_URL + '/classes/getUnseenMessagesJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/getUnseenMessages', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
}; 

//getNotificationsJwt
classServices.getNotifications = async ({ queryKey }) => {
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ?  parseInt(queryKey[1]) : 0;
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode
    }
    const { data } = await axios.post(AUTH_URL + '/classes/getNotificationsJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/getNotifications', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
}; 

//updateMessageSeenJwt
classServices.updateMessageSeen = async (values) => {   
    const { data } = await axios.post(AUTH_URL + '/classes/updateMessageSeenJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/updateMessageSeen', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
}; 

//Join class
classServices.joinClass = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/insertJoinClassJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/insertJoinClass', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//disconnect class class
classServices.disconnetClass = async (values) => { 
        const  result = await axios.post(AUTH_URL + '/classes/creditTutorAccount', values); 
        return result?.data ?  result?.data : "";
     
};

//Get Notification List
classServices.notificationList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10; 
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit
    }
    const { data } = await axios.post(AUTH_URL + '/classes/notificationListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/notificationList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};


//Dismiss Files
classServices.dismissNotification = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/classes/dismissNotificationJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/dismissNotification', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//Validate class
classServices.validateClass = async (values) => { 
    const  result = await axios.post(AUTH_URL + '/classes/validateClass', values); 
    return result?.data;
    
};
//Validate join class
classServices.validateJoinClass = async (values) => { 
    const  result = await axios.post(AUTH_URL + '/classes/validateJoinClass', values); 
    return result?.data;
};

//Get Classes List
classServices.activeClassesList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10;
    const fromPage = queryKey[4] ? queryKey[4] : '';
    const searchText = queryKey[5] ? queryKey[5] : '';
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit,
        fromPage: fromPage,
        searchText: searchText
    }
    const { data } = await axios.post(AUTH_URL + '/classes/activeClassesListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/activeClassesList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};

//Get dashboardAnnouncementList List
classServices.dashboardAnnouncementList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10;
    const classCode = queryKey[4] ? parseInt(queryKey[4]) : ''
    const frompage = queryKey[5] ? queryKey[5] : ''
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit,
        classCode: classCode,
        frompage:frompage
    }
    const { data } = await axios.post(AUTH_URL + '/classes/dashboardAnnouncementListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/classes/dashboardAnnouncementList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};


export {classServices};