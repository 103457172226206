import React, { useState} from 'react';
import { alpha, TextField, Dialog, DialogActions, DialogContent, DialogContentText, Tooltip, DialogTitle, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl"; 
import {useMutation} from "react-query";
import { useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button"; 
import Grid from "@mui/material/Grid";  
import {useTranslation} from "react-i18next";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import FolderIcon from '@mui/icons-material/Folder'; 
import { useNavigate } from "react-router-dom";
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { USERROLECODE} from '../../../utils/constants/constants'
import { classServices } from "../../../services/apis/classes"; 
import { authServices } from "../../../services/auth/user";
import { store } from "../../../store/store";
import { useQuery } from "react-query";
import Pagination from "@jumbo/components/Pagination";
import { makeStyles } from "@mui/styles"; 
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from '@mui/icons-material/Warning'; 
import UploadDialog from './uploadDialog';
var aws = require('aws-sdk');
window.Buffer = window.Buffer || require("buffer").Buffer;
const useStyles = makeStyles({ 
    pagination: {
        padding: '0px ', 
        borderRadius: '6px',
        textAlign: 'center',
        display: 'table',
        margin: '0 auto',  
        width: '100%',
        marginTop: '10px',
    }
}); 
const FileList = () => { 
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const classStyles = useStyles();  
    const [confirmDelete, setConfirmDelete] = React.useState("")
    const [deleteRow, setDeleteRow] = React.useState("")
    const profilerole = Number(localStorage.getItem("switchprofile")); 
    const [folderName, setFolderName] = useState("");
    const [errorFolderName, setErrorFolderName] = useState("");
    const classDetails = store.getState().classDetails;
    const loginDetails = store.getState().loginDetails;
    const credentialDetails = store.getState().credentials;
    // const credentialDetails = store.getState().credentials;
    const  limit  = 24 ;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isSuccess, setSuccess] = useState(false);
    const [isSuccessful, setSuccessfull] = React.useState(false); 
    let pageSize = 24;   
    const userData = [loginDetails ? parseInt(loginDetails.userCode) : 0, classDetails ? Number(classDetails.classCode) : '']; 
    const {data: classData, refetch: refetchClassList} = useQuery(
        userData, classServices.getClasses
    ); 
    const userDetails = [loginDetails ? loginDetails.userCode : 0 , loginDetails ? loginDetails.roleCode : 0, offset, limit, classDetails ? Number(classDetails.classCode) : '']; 
    // const userDetails = [1 , 2, offset, limit]; 
    const {data: result, refetch: refetchFolderList } = useQuery(
        userDetails, classServices.folderList
    );  
    const handlePagination = (page) => {
        let offsetVal = page * pageSize - pageSize;
        setOffset(offsetVal);
        setCurrentPage(page); 
        refetchFolderList().catch(console.error) 
        refetchClassList().catch(console.error) 
    }
    React.useEffect(() => {
        if (isSuccess ) { 
            setSuccess(false) 
            refetchFolderList().catch(console.error)
        }
    }, [isSuccess, refetchFolderList]);   


    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => { 
        setOpen(true);
        setScroll(scrollType);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
   

    const handleFilesOpen = (row, e) => {
        navigate("/classes/classdetails/uploadFiles", {state: {filedetails: row}});
    };


    const [openFolder, setOpenFolder] = React.useState(false); 


    const handleClickOpenFolder = (scrollType) => () => {
        setOpenFolder(true); 
    };

    const descriptionElementRef1 = React.useRef(null);
    React.useEffect(() => {
        if (openFolder) {
            const {current: descriptionElement} = descriptionElementRef1;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openFolder]);

    const clearData = () => {
        setOpenFolder(false)
        setFolderName('')
        setOpenDeletePopup(false)
        setDeleteRow('')
        setConfirmDelete('')   
        setErrorFolderName('');
        setOpen(false)  
    }

    const refreshList = () => {
        setOffset(0);
        setCurrentPage(1); 
        refetchFolderList().catch(console.error) 
    }


    //createfolder api
    const createFolder = useMutation(classServices.createFolder, {
        onSuccess: (data) => {   
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" });
                refreshList()
                clearData()
           }  
        },
        onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
        }
    });

    const handleCreateFolder = async () => {   
        if (!folderName) { 
            setErrorFolderName(t('class.errorFolderName'));
            return;
           } else {
            setErrorFolderName(''); 
            const data = {
                userCode: loginDetails ? Number(loginDetails.userCode) : '',
                userRoleCode: loginDetails ? Number(loginDetails.roleCode) : '',
                classCode: classDetails ? Number(classDetails.classCode) : '',
                folderName: folderName,
                ipAddress: await authServices.getMyIpAddress()            
            }
            createFolder.mutate(data);
        }
    }

     //Delete 
     const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
     const [scrollDelete, setScrollDelete] = React.useState('paper');
 
     const descriptionElementRef2 = React.useRef(null);
     React.useEffect(() => {
         if (openFolder) {
             const {current: descriptionElement} = descriptionElementRef2;
             if (descriptionElement !== null) {
                 descriptionElement.focus();
             }
         }
     }, [openFolder]);

    //deleteFolder api
    const deleteFolder = useMutation(classServices.deleteFolder, {
        onSuccess: (data) => {   
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" });
                refreshList()
                clearData()
           }  
        },
        onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
        }
    });

    const handleDeletePopup = (row) => { 
        setDeleteRow(row)   
        setOpenDeletePopup(true);
        setScrollDelete('paper');  
    }

    const handleDeleteFolder = async () => {
     const data = {
            userCode: loginDetails ? Number(loginDetails.userCode) : '',
            userRoleCode: loginDetails ? Number(loginDetails.roleCode) : '',
            classCode: classDetails ? Number(classDetails.classCode) : '',
            folderCode: deleteRow.folderCode,
            ipAddress: await authServices.getMyIpAddress()            
        } 
        await emptyS3Directory(credentialDetails?.s3bucket,  'file/' +loginDetails.userCode+'/'+ classDetails.classCode+'/'+deleteRow.folderCode);
        //Delete  file in s3
      
       
        // await s3.deleteObject({ Bucket: credentialDetails?.s3bucket, Key:  'file/' +loginDetails.userCode+'/'+ classDetails.classCode+'/'+deleteRow.folderCode }, (err, data) => { 
        //     console.log(data, 'delete file');
        // });
    
        deleteFolder.mutate(data);
    }   
      
    const emptyS3Directory = async (bucket, dir) => {
        const s3 = new aws.S3({
            accessKeyId: credentialDetails?.awsAccessKey,
            secretAccessKey: credentialDetails?.awsSecretKey,
            Bucket: credentialDetails?.s3bucket,
            region: credentialDetails?.region
        }); 
        const listParams = {
          Bucket: bucket,
          Prefix: dir
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
      
        if (listedObjects.Contents.length === 0) {
          return;
        }
      
        // const deleteParams = {
        //   Bucket: bucket,
        //   Delete: {
        //     Objects: []
        //   }
        // };
      
        // deleteParams.Delete.Objects = listedObjects.Contents.map(({
        //   Key
        // }) => Key);
        listedObjects.Contents.map(async (item) => {
            await s3.deleteObject({ Bucket: credentialDetails?.s3bucket, Key: item.Key }, (err, data)       => {  console.log(data, 'delete file');  })
        })
        // listedObjects.Contents.map(({
        //     Key
        //   }) =>   await s3.deleteObject({ Bucket: credentialDetails?.s3bucket, Key: Key }, (err, data)       => {  console.log(data, 'delete file');  }));
      
        // await s3.deleteObjects(deleteParams).promise();
        if (listedObjects.IsTruncated) {
          await emptyS3Directory(bucket, dir);
        }
      }
    return (
        <> 
        <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
                <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>               
                    <Grid xs={4}>
                     <div style={{ display: 'flex' }}><FolderCopyOutlinedIcon style={{ marginRight: '5px' }} />
                        <Typography variant="h1" mb={3}>  {t('class.uploadedFiles')}</Typography>
                    </div>                   
                    </Grid>
                   
                    {profilerole === USERROLECODE.tutor && 
                    <Grid xs={8} style={{ textAlign: 'right' }}>
                        <Button
                            variant={"outlined"}
                            startIcon={<CreateNewFolderOutlinedIcon />}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.2rem'
                                },
                                textTransform: 'none',
                                marginRight: '2%',
                                fontSize: '12px',
                                paddingBottom: '3px',
                                paddingTop: '3px'
                            }}
                            onClick={handleClickOpenFolder('paper')}
                        >{t('class.newfolder')}</Button>

                        <Button
                            variant={"contained"}
                            startIcon={<CloudUploadIcon />}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.2rem'
                                },
                                textTransform: 'none',
                                fontSize: '12px',
                                paddingBottom: '5px',
                                paddingTop: '3px'
                            }}
                            onClick={handleClickOpen('paper')}
                        >{t('class.uploadFile')}</Button>
                    </Grid>}
                </Grid>                    
        </Div>
        {result && result.folderList.length > 0 ? (
        <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>
        {
            result.folderList.map((row) => (
                <Grid xs={3} md={2} lg={2} sx={{'&:hover': {
                    boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                    transform: 'translateY(-4px)', 
                    '& .MuiChip-animation': {
                        width: 'auto',
                        fontSize: 12,
                        display: 'block',
                        textAlign: 'right',
                        padding: '1%'
                    } }}
                }>
                {profilerole === USERROLECODE.tutor && (
                <Tooltip title="Delete">
                    <Div className={'MuiChip-animation'} 
                        onClick={(e) => handleDeletePopup(row, e)}
                        sx={{ width: 0, height: 0, fontSize: 0, display: 'none', transition: 'all 0.2s'}}>                
                        <DeleteIcon fontSize={"small"} />
                    </Div>
                </Tooltip>)}
                <div style={{textAlign: 'center'}} >                   
                    <FolderIcon onClick={(e) => handleFilesOpen(row, e)} style={{ marginRight: '5px',fontSize:'72px',color:'#ffcb3d', cursor:'pointer' }} />
                    <Typography variant="h6" mb={3}>{row.folderName}</Typography>                    
                </div>
                
               </Grid>
                 
            ))
        }
        </Grid>  
            ) : <div><Typography variant={"h2"} mb={.5} style={{ textAlign: 'center' }}>{t('class.noFiles')}</Typography>
            {profilerole === USERROLECODE.tutor && <Typography variant={"h6"} mt={2} style={{ textAlign: 'center' }}>Looks like you haven't create your file folder yet</Typography>}</div>}  
 
            

        {result && parseInt(result?.totalcount) > 10 && <div className={classStyles.pagination}>
                <Pagination
                    currentPage={currentPage}
                    totalCount={result ? parseInt(result?.totalcount) : 0}
                    pageSize={pageSize}
                    primaryColor="#814DFA"
                    onPageChange={handlePagination} 
                />
            </div>}

        {/* upload File Popup */}
        {open && <UploadDialog open={open} scroll={scroll} classesFolderList = {classData?.classesFolderList} 
        onClose={setOpen} folderCode={''} isSuccessful={setSuccessfull}/>}

        {/* create folder */}
        <Dialog
            open={openFolder}
            onClose={() => setOpenFolder(false)}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                '& .MuiDialog-paper ':{
                    width: '720px',
                }  
            }}
        >
            <DialogTitle id="scroll-dialog-title">{t('class.createfolder')}</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef1}
                    tabIndex={-1}
                >
                    <Box component="form"
                sx={{
                    mx: -1, 
                    '& .MuiFormControl-root:not(.MuiTextField-root)': {
                        p: 1,
                        mb: 2,
                        width: {xs: '100%', sm: '100%'}
                    },
                    '& .MuiFormControl-root.MuiFormControl-fluid': {
                        width: '100%'
                    }
                }}
                autoComplete="off"
        >
                {/* <FormControl size="small"> */}
                    <Grid container spacing={3.5}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="foldername"
                                label={t('class.folderName')}
                                size="small"
                                value={folderName}
                                onChange={(event) => setFolderName(event.target.value)}                                
                                error={errorFolderName ? true : false}
                                helperText={errorFolderName ? errorFolderName : ""}
                                onBlur={(event) => {
                                    if (event.target.value) {
                                     setErrorFolderName('');
                                    } else {
                                     setErrorFolderName(t('class.errorFolderName'));
                                    }
                                }} 
                                // onKeyDown={handlekeyPress}
                            />
                        </Grid>                      
                    </Grid>                                               
                {/* </FormControl>          */}
                   
            
            </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={() => clearData()}>{t('common.cancel')}</Button>
                <Button variant={"contained"} style={{textTransform: 'none'}} onClick={(e) => handleCreateFolder(e)}>{t('class.create')}</Button>
            </DialogActions>
        </Dialog>

         {/* Delete Alert */}
         <Dialog
            open={openDeletePopup}
            onClose={() => setOpenDeletePopup(false)}
            scroll={scrollDelete}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                '& .MuiDialog-paper ':{
                    width: '720px',
                }  
            }}
        >
            <DialogTitle id="scroll-dialog-title" style={{background: "#f397113d", marginBottom: '1px'}}>
                <Grid xs={12} sm={12} lg={12}>                    
                    <div style={{ display: 'flex' }}><WarningIcon  color={'warning'} style={{ marginRight: '5px', marginTop: '3px' }} />
                    <Typography variant="h1"  color={'warning'}>{t('common.warning')}</Typography>
                    </div>                   
                </Grid>
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef2}
                    tabIndex={-1}
                >
                    <Box component="form"
                    sx={{
                        mx: -1, 
                        '& .MuiFormControl-root:not(.MuiTextField-root)': {
                            p: 1,
                            mb: 2,
                            width: {xs: '100%', sm: '100%'}
                        },
                        '& .MuiFormControl-root.MuiFormControl-fluid': {
                            width: '100%'
                        }
                    }}
                    autoComplete="off"
                >
                <FormControl size="small">
                    <Grid container spacing={3.5} style={{marginBottom: '3%'}}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <Typography variant="h4">{t('class.deleteFilesFolderMsg')}</Typography>
                            <Typography variant="p">{t('class.deleteFilesFolderMsg1')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3.5}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="delete"
                                label={t('class.confirmDelete')}
                                size="small"
                                value={confirmDelete}
                                onChange={e => setConfirmDelete(e.target.value)}
                            />
                        </Grid>                       
                    </Grid>                                               
                </FormControl>
            
            </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} 
                onClick={() => clearData()}>{t('common.cancel')}</Button>
                <Button variant={"contained"} 
                 disabled={confirmDelete && confirmDelete === 'DELETE' ? false : true } 
                 style={{textTransform: 'none'}} onClick={(e) => handleDeleteFolder(e)}>{t('class.deleteFolder')}</Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

export default FileList;
