import axios from "../config";
import {AUTH_URL} from "../../utils/constants/paths";
const calendarServices = {};
//Calendar Data
calendarServices.calendarList = async ({ queryKey }) => {
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const month = queryKey[2] ? parseInt(queryKey[2]) : 0;
    
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        month: month
    }
    const { data } = await axios.post(AUTH_URL + '/calendar/calendarListJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/calendar/calendarList', values); 
        return result?.data?.body?.calendarList ? result?.data?.body?.calendarList : [];
    } else {
        return "";
    } 
};
export {calendarServices};