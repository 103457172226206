import React from 'react';
import {List} from "@mui/material"; 
import TransactionItem from "./TransactionItem";
import ListItemButton from "@mui/material/ListItemButton";
import { ListItemText, Typography, Grid } from "@mui/material";

const TransactionList = ({datas}) => {
    return (
        <List disablePadding>
            <ListItemButton component={"li"} sx={{p: theme => theme.spacing(1, 3), bgcolor: '#ffc4d559'}}> 
            <Grid container spacing={3}>
                <Grid item xs={5} md={5} lg={5} style={{display: 'flex'}}>
                <ListItemText sx={{
                    flexBasis: '30%'
                }} primary={<Typography variant='h5' mb={.5}>Transaction</Typography>} 
                />
                </Grid>
                <Grid item xs={4} md={4} lg={4} style={{textAlign:'right'}}>
                <ListItemText primary={<Typography variant="body1" sx={{fontSize: 13}}>Debit/Credit</Typography>}   />  
                </Grid>
                <Grid item xs={3} md={3} lg={3} style={{textAlign: 'right'}}>
                <ListItemText sx={{alignSelf: 'self-start', flexGrow: 0}}>
                    <Typography variant='body1' component={"span"}>Balance</Typography> 
                </ListItemText> 
            </Grid>
            </Grid>                         
            </ListItemButton>
            {
                datas?.length > 0 && datas?.map((item, index) => (
                    <TransactionItem item={item} key={index}/>
                ))
            }
        </List>
    );
};

export default TransactionList;
