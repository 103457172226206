import React from 'react';
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import {useJumboHeaderTheme} from "@jumbo/hooks";
import {ThemeProvider} from "@mui/material";
import { connect } from "react-redux";

const MessagesTriggerButton = (props) => {
    const {notification} = props
    const {headerTheme} = useJumboHeaderTheme();
    return (
        <ThemeProvider theme={headerTheme}>
              {notification && parseInt(notification?.msgcount) > 0 ?  
                <JumboIconButton badge={{variant: "dot"}} elevation={25} style={{background: '#fff'}}>
                   <ChatBubbleOutlineOutlinedIcon sx={{fontSize: '1rem'}}/>
                </JumboIconButton> : 
                <JumboIconButton elevation={25} style={{background: '#fff'}}>
                        <ChatBubbleOutlineOutlinedIcon sx={{fontSize: '1rem'}}/>
                </JumboIconButton> 
             } 
        </ThemeProvider>
    );
};

const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };

export default connect(mapStoreStateToProps)(MessagesTriggerButton);
 
