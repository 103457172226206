import React from 'react';
import {Avatar, CardActions, CardContent, Typography, Badge} from "@mui/material";
import Card from "@mui/material/Card";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Div from "@jumbo/shared/Div";

const CardIconText = ({variant, icon, title, subTitle, color, onHoverIcon, height, disableHoverEffect, hideArrow, totallessons, duration, startdate, enddate, totalseat,availableseat,bookedseat,lessons, ...restProps}) => {
    const [hover, setHover] = React.useState(false);
    const [avatarVariant, setAvatarVariant] = React.useState("circular");

    React.useEffect(() => {
        if(!disableHoverEffect) {
            setAvatarVariant(hover ? "square" : "circular");
        }
    }, [disableHoverEffect, hover]);

    const avatarProps = variant === "contained" ? {
        bgcolor: color,
        transition: 'all 0.3s ease-in-out',
        color: 'common.white',
    } : {
        bgcolor: 'common.white',
        transition: 'all 0.3s ease-in-out',
        border: '1px solid',
        borderColor: color,
        color: color,
    };

    return (
        <Card {...restProps}>
            <CardActions
                disableSpacing
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                sx={{
                    p: 0,
                    alignItems: 'stretch',
                    position: 'relative',
                    cursor: 'pointer',
                    height: height,
                    '&:hover .CardAction-arrow': {
                        transform: 'translate(-1rem, -50%)',
                    }
                }}
            >
                <Div sx={{
                    display: 'flex',
                    width: 95,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>    
                    {/* <Typography variant={"p"} fontWeight={"500"} mb={.5}>
                            Tution
                        </Typography>                */}
                    <Avatar variant={avatarVariant} sx={{
                        ...avatarProps,
                        ...(hover && !disableHoverEffect ? {width: "100%", height: "100%"} : {width: 56, height: 56})
                    }}>
                        {
                            (hover && onHoverIcon) ? onHoverIcon : icon
                        }                        
                    </Avatar>
                </Div>
                <CardContent sx={{p: 1, flex: 1, alignSelf: 'center', display: 'flex',   flexDirection: 'column', textAlign: 'center'}}>
                    {
                        title &&
                        <Typography variant={"h1"} fontWeight={"500"} mb={.5}>{title}</Typography>
                    }                    
                    {
                        duration && lessons &&
                        <Typography variant={"h6"} color={'text.primary'}>{duration} / {lessons} lessons</Typography>
                    }

                    {
                        startdate && enddate &&
                        <Typography variant={"h6"} color={'text.primary'}>{startdate} - {enddate}</Typography>
                    }

                    {
                        totalseat &&
                        <Div>
                            <Typography variant={"h6"} color={'text.primary'}>Total seats
                                <Badge color="primary" badgeContent={totalseat} style={{marginLeft: '6%;'}}></Badge>                                                  
                            </Typography>
                            <Typography variant={"h6"} color={'text.primary'}>Booked seats
                            <Badge badgeContent={bookedseat} color="success" style={{marginLeft: '6%;'}}></Badge>                                                  
                            </Typography>
                            <Typography variant={"h6"} color={'text.primary'}>Available seats 
                            <Badge badgeContent={availableseat} color="warning" style={{marginLeft: '6%;'}}></Badge>                                                 
                            </Typography>
                        </Div>
                    }
                </CardContent>
                {
                    !hideArrow &&
                    <Div className="CardAction-arrow" sx={{
                        right: '0',
                        top: '50%',
                        color: 'primary.main',
                        position: 'absolute',
                        transition: 'all 0.3s ease',
                        transform: 'translate(100%, -50%)',
                    }}>
                        {/* <ArrowForwardOutlinedIcon/> */}
                    </Div>
                }
            </CardActions>
        </Card>
    );
};

CardIconText.defaultProps = {
    height: 116,
    color: "primary.main",
    variant: "contained"
};

export default CardIconText;
