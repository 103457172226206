import React, {useState} from 'react';
import Div from "@jumbo/shared/Div";
import {Card, CardContent, TextField, Typography, IconButton, InputAdornment} from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import {alpha} from "@mui/material/styles";
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import { useTranslation } from "react-i18next";
import { authServices } from "../../../services/auth/user";
import { useSnackbar } from "notistack";
import {useMutation} from "react-query";
import { useNavigate } from "react-router-dom";
import { USER } from '../../../utils/constants/constants' 
import { Visibility, VisibilityOff} from "@mui/icons-material";

const Signup2 = () => {
    const {setActiveLayout} = useJumboApp();
    const {t} = useTranslation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [dob, setDob] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [errorFn, setErrorFn] = useState('');
    const [errorLn, setErrorLn] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [errorMobileNo, setErrorMobileNo] = useState('');
    const [errorPaswd, setErrorPaswd] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [errorDate, setErrorDate] = useState(''); 
    const [showPassword, setShowPassword] = useState('');
    //Password validation
    const password_validation = USER.PASSWORD_VALIDATION;
    const mail_validation = USER.MAIL_VALIDATION;
    const navigate = useNavigate();
    function isInThePast(date) {
        const today = new Date(); 
        today.setHours(0, 0, 0, 0); 
        return date < today;
      }
    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    });
    const mutation = useMutation(authServices.createUser, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" });
                clearData();
                navigate("/signin", {replace: true});
           }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });
    const handleSignUp = async () => { 
        if (!firstName) {
            setErrorFn(t('signup.errorFN'));
            return;
        }
        if (!lastName) {
            setErrorFn('');
            setErrorLn(t('signup.errorLN'));
            return;
        }
        if (!email || !mail_validation.test(email)) {
            setErrorFn('');
            setErrorLn('');
            setErrorEmail(t('signup.errorEmail'));
            return;
        }
        if (!userPassword || !password_validation.test(userPassword)) {
            setErrorFn('');
            setErrorLn('');
            setErrorEmail('');
            setErrorPaswd(t('signup.errorPaswd'));
            return;
        }
        if (!mobileNumber || mobileNumber.length < 8) {
            setErrorFn('');
            setErrorLn('');
            setErrorEmail('');
            setErrorPaswd('');
            setErrorMobileNo(t('signup.errorMobileNo'));
            return;
        }
        if (dob) {
            if (!isInThePast(new Date(dob))) {
                setErrorFn('');
                setErrorLn('');
                setErrorEmail('');
                setErrorPaswd('');
                setErrorMobileNo('');
                setErrorDate(t('class.errorValidDate'));
            }  
        }
       
        setErrorFn('');
        setErrorLn('');
        setErrorEmail('');
        setErrorPaswd('');
        setErrorMobileNo('');
        setErrorDate('');
        mutation.mutate({
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobileNumber: mobileNumber,
            dateOfBirth: dob,
            userPassword: userPassword,
            userCode: 0,
            userRoleCode: 0,
            ipAddress: await authServices.getMyIpAddress()
        });
    }

    const clearData = () => {
        setErrorFn('');
        setErrorLn('');
        setErrorEmail('');
        setErrorPaswd('');
        setErrorMobileNo('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setUserPassword('');
        setDob('');
        setMobileNumber(''); 
    }
     // password show and hide functionality
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }; 
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
     // for date formating
    function formatDate(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    const handleSignin = () => {
        navigate("/signin", {replace: true});
    }
    return (
        <Div sx={{
            width: 820,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>

            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/keith-luke.jpg`, "640x428")}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{mb: 2}}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>Sign up</Typography> 
                        </Div>

                        <Div sx={{mt: 'auto'}}>
                            <Link href="#" underline="none" sx={{display: 'inline-flex'}}>
                                <img src={`${ASSET_IMAGES}/logo-white.png`} alt="Skyber"/>
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent
                    sx={{
                        flex: 1,
                        p: 4
                    }}
                >
                    <Div sx={{ mt: 1, mb: 3 , display: 'flex'}}>
                        <TextField
                            fullWidth
                            id="firstname"
                            label={t('signup.fName')}
                            value={firstName}
                            autoFocus
                            autoComplete='off'
                            inputProps={{ maxLength: 20 }}
                            onChange={e => setFirstName(e.target.value)}
                            error={errorFn ? true : false}
                            helperText={errorFn ? errorFn : ""}
                            onBlur={(event) => {
                                if (event.target.value) {
                                    setErrorFn('');
                                } else {
                                    setErrorFn(t('signup.errorFN'));
                                }
                              }} 
                        />
                        <TextField
                            fullWidth
                            id="lastname"
                            label={t('signup.lName')}
                            sx={{ ml: 1 }}
                            value={lastName}
                            autoComplete='off'
                            inputProps={{ maxLength: 20 }}
                            onChange={e => setLastName(e.target.value)}
                            error={errorLn ? true : false}
                            helperText={errorLn ? errorLn : ""}
                            onBlur={(event) => {
                                if (event.target.value) {
                                    setErrorLn('');
                                } else {
                                    setErrorLn(t('signup.errorLN'));
                                }
                              }} 
                        />
                    </Div>
                    <Div sx={{mt: 1, mb: 3}}>
                        <TextField
                            fullWidth
                            id="email"
                            label={t('signup.email')}
                            value={email}
                            autoComplete='off'
                            inputProps={{ maxLength: 200 }}
                            onChange={e => setEmail(e.target.value)}
                            error={errorEmail ? true : false}
                            helperText={errorEmail ? errorEmail : ""}
                            onBlur={(event) => {
                                if (event.target.value && mail_validation.test(event.target.value)) {
                                    setErrorEmail('');
                                } else {
                                    setErrorEmail(t('signup.errorEmail'));
                                }
                              }} 
                        />
                    </Div>
                    <Div sx={{mt: 1, mb: 2}}>
                        <TextField
                            fullWidth
                            id="password"
                            label={t('signup.password')}
                            value={userPassword} 
                            autoComplete='off'
                            onChange={e => setUserPassword(e.target.value)}
                            error={errorPaswd ? true : false}
                            helperText={errorPaswd ? errorPaswd : ""}
                            onBlur={(event) => {
                                if (event.target.value && password_validation.test(event.target.value)) {
                                    setErrorPaswd('');
                                } else {
                                    setErrorPaswd(t('signup.errorPaswd'));
                                }
                            }} 
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                minLength: 8,
                                maxLength: 20,
                                endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                    edge='end'
                                    aria-label='toggle password visibility'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}>
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                    </IconButton>
                                </InputAdornment>
                                ),
                            }}
                        />
                    </Div>
                    <Div sx={{mt: 1, mb: 2}}>
                        <TextField
                            fullWidth
                            id="mobileno"
                            inputProps={{ maxLength: 8 }}
                            label={t('signup.mobileNo')} 
                            value={mobileNumber}
                            autoComplete='off'
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }} 
                            onChange={e => setMobileNumber(e.target.value)}
                            error={errorMobileNo ? true : false}
                            helperText={errorMobileNo ? errorMobileNo : ""}
                            onBlur={(event) => {
                                if (event.target.value && mobileNumber.length >= 8) {
                                    setErrorMobileNo('');
                                } else {
                                    setErrorMobileNo(t('signup.errorMobileNo'));
                                }
                              }} 
                        />
                    </Div>
                    <Div sx={{ mt: 1, mb: 2 }}>
                    <TextField
                            fullWidth
                            id="dob"
                            label={t('signup.dob')}
                            value={dob}
                            type="date" 
                            autoComplete='off'
                            InputProps={{ inputProps: { max: formatDate(new Date()) } }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => setDob(e.target.value)}
                            error={errorDate ? true : false}
                            helperText={errorDate ? errorDate : ""}
                            onBlur={(event) => {
                                if (!isInThePast(new Date(event.target.value))) {
                                    setErrorDate(t('class.errorValidDate'));
                                } else {
                                    setErrorDate(''); 
                                }
                            }} 
                        />
                    </Div>
                    <Div sx={{textAlign: 'right'}}>
                        <Button variant="contained" onClick={handleSignUp}  sx={{mb: 2 , textTransform : 'none'}}>Sign up</Button>
                    </Div>
                    <Typography textAlign={"center"} variant={"body1"} mb={1}>Existing user?
                        <Link onClick={handleSignin} style={{ cursor: 'pointer'}}> Sign in</Link>
                    </Typography>
                </CardContent>
            </Card>

        </Div>
    );
};

export default Signup2;
