import React, { useState } from "react"; 
import {IconButton} from "@mui/material";
import { 
  KeyboardVoiceRounded,
  MicOffRounded, 
} from "@mui/icons-material";
const MicButton = ({ room }) => {
  const [isMicMuted, setIsMicMuted] = useState(false);

  const handleMicButtonPressed = () => {
    isMicMuted ? unmute() : mute();
    setIsMicMuted(!isMicMuted);
  };

  const mute = () => {
    // mute our microphone so other users will be not able to hear us
    room.localParticipant.audioTracks.forEach((localAudioTrackPublication) => {
      localAudioTrackPublication.track.disable();
    });
  };

  const unmute = () => {
    // turn on mic back
    room.localParticipant.audioTracks.forEach((localAudioTrackPublication) => {
      localAudioTrackPublication.track.enable();
    });
  };

  return (
    <div className="video_button_container">
      <IconButton  onClick={handleMicButtonPressed}  style={{background: "red"}}>
        {isMicMuted ? <MicOffRounded style={{color:"#fff"}}/> : <KeyboardVoiceRounded style={{color:"#fff"}}/>}
       </IconButton>  
    </div>
  );
};

export default MicButton;
