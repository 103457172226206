import React from 'react'; 
import StyledMenu from "../../../../../shared/StyledMenu";
import {classMenus,classMenus_Webinar} from "@jumbo/constants";
import {useParams} from "react-router-dom";
import FilterItem from "./FilterItem";
import { store } from "../../../../../store/store"
import { USERROLECODE } from '../../../../../utils/constants/constants'

const FiltersList = () => {
    const { category } = useParams();
    const classDetails = store.getState().classDetails;
    const profilerole = Number(localStorage.getItem("switchprofile"));

    return (
        <React.Fragment>
            {/* <Typography
                variant={"h6"}
                color={"text.secondary"}
                sx={{
                    textTransform: 'uppercase',
                    letterSpacing: '1px',
                    fontSize: '11px'
                }}
            >
                Filter
            </Typography> */}
            <StyledMenu sx={{mb: 2}}>
                {
                    (classDetails?.classType === '1' ) ? classMenus.map((filter, index) => (
                        <FilterItem
                            key={index}
                            slug={filter.slug}
                            name={filter.name}
                            icon={filter.icon}
                            selected={filter.slug === category}
                        />
                    )) : classMenus_Webinar.map((filter, index) => (
                        <FilterItem
                            key={index}
                            slug={filter.slug}
                            name={filter.name}
                            icon={filter.icon}
                            selected={filter.slug === category}
                        />
                    ))
                }
            </StyledMenu>
        </React.Fragment>
    );
};

export default FiltersList;
