import React, { useState} from 'react';
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { alpha, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Tooltip} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button"; 
import Grid from "@mui/material/Grid"; 
import {useTranslation} from "react-i18next";
import FolderIcon from '@mui/icons-material/Folder';
import { useNavigate } from "react-router-dom";
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { USERROLECODE } from '../../../utils/constants/constants'
import { classServices } from "../../../services/apis/classes"; 
import { authServices } from "../../../services/auth/user";
import Pagination from "@jumbo/components/Pagination";
import { makeStyles } from "@mui/styles"; 
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from '@mui/icons-material/Warning';
import { store } from "../../../store/store";
import moment from 'moment';
var aws = require('aws-sdk');

const useStyles = makeStyles({ 
    pagination: {
        padding: '0px ', 
        borderRadius: '6px',
        textAlign: 'center',
        display: 'table',
        margin: '0 auto',  
        width: '100%',
        marginTop: '10px',
    }
}); 
const SubMissionsFiles = () => { 

        const { t } = useTranslation();
        const navigate = useNavigate();
        const { enqueueSnackbar } = useSnackbar();
        const classStyles = useStyles();
       
        const profilerole = Number(localStorage.getItem("switchprofile")); 
      
        const [submissionTitle, setSubmissionTitle] = useState("");
        const [startDate, setStartDate] = useState("");
        const [endDate, setEndDate] = useState("");
        const [startTime, setStartTime] = useState("");
        const [endTime, setEndTime] = useState("");
        const [errorsubmissionTitle, setErrorSubmissionTitle] = useState("");
        const [errorStartDate, setErrorStartDate] = useState("");
        const [errorStartTime, setErrorStartTime] = useState("");
        const [errorEndDate, setErrorEndDate] = useState("");
        const [errorEndTime, setErrorEndTime] = useState("");

        const classDetails = store.getState().classDetails;
        const loginDetails = store.getState().loginDetails;
        const credentials = store.getState().credentials;
        const  limit  = 24 ;
        const [offset, setOffset] = useState(0);
        const [currentPage, setCurrentPage] = useState(1);
        const [isSuccess, setSuccess] = useState(false);
        let pageSize = 24; 
        const userDetails = [loginDetails ? loginDetails.userCode : 0 , loginDetails ? loginDetails.roleCode : 0, offset, limit, classDetails ? Number(classDetails.classCode) : '']; 
        // const userDetails = [1 , 2, offset, limit]; 
        const {data: result, refetch: refetchSubmissionFolderList } = useQuery(
            userDetails, classServices.submissionFolderList
        );  
        const handlePagination = (page) => {
            let offsetVal = page * pageSize - pageSize;
            setOffset(offsetVal);
            setCurrentPage(page); 
            refetchSubmissionFolderList().catch(console.error) 
        }
        React.useEffect(() => {
            if (isSuccess ) { 
                setSuccess(false) 
                refetchSubmissionFolderList().catch(console.error)
            }
        }, [isSuccess, refetchSubmissionFolderList]); 


        const [openFolder, setOpenFolder] = React.useState(false);
        const [scrollFolder, setScrollFolder] = React.useState('paper');

        const handleClickOpenFolder = (scrollType) => () => {
            clearData();
            setOpenFolder(true);
            setScrollFolder(scrollType);
        };

        const descriptionElementRef1 = React.useRef(null);
        React.useEffect(() => {
            if (openFolder) {
                const {current: descriptionElement} = descriptionElementRef1;
                if (descriptionElement !== null) {
                    descriptionElement.focus();
                }
            }
        }, [openFolder]);
        
        const handleFilesOpen = (row) => {
            navigate("/classes/classdetails/submissions", {state: {filedetails: row}});
        }

        const clearData = () => {
            setOpenFolder(false)
            setSubmissionTitle('')
            setStartDate('')
            setEndDate('')
            setStartTime('')
            setEndTime('')
            setErrorSubmissionTitle('')
            setErrorStartDate('')
            setErrorStartTime('')
            setErrorEndDate('')
            setErrorEndTime('')
            setConfirmDelete('')
            setDeleteRow('')
            setOpenDeletePopup('')
        }

        const refreshList = () => {
            setOffset(0);
            setCurrentPage(1); 
            refetchSubmissionFolderList().catch(console.error) 
        }


      //createSubmissionFolder api
      const createSubmissionFolder = useMutation(classServices.createSubmissionFolder, {
        onSuccess: (data) => {   
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" });
                refreshList()
                clearData()
           }  
        },
        onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
        }
    });

    const handleCreateSubmissionFolder = async () => {  
        if (!submissionTitle) {
            setErrorSubmissionTitle(t('class.errorSubmissionTitle'));
            return;
        }
        if (!startDate) {
            setErrorStartDate(t('class.errorStartDate'));
            return
        }
        if (!endDate) {
            setErrorEndDate(t('class.errorEndDate'));
            return
        }
        if (isInThePast(new Date(startDate))) {
            setErrorStartDate(t('class.errorValidDate'));
            return;
        } 
        if (isInThePast(new Date(endDate))) {
            setErrorEndDate(t('class.errorValidDate'));
            return;
        } 
        if (new Date(startDate) > new Date(endDate)) {
            setErrorEndDate(t('class.errorEndDategreater'));
            return
        }
        if (!startTime) {
            setErrorStartTime(t('class.errorStartTime'));
            return
        }
        if (!endTime) {
            setErrorEndTime(t('class.errorEndTime'));
            return
        }  
        const data = {
            userCode: loginDetails ? Number(loginDetails.userCode) : '',
            userRoleCode: loginDetails ? Number(loginDetails.roleCode) : '',
            classCode: classDetails ? Number(classDetails.classCode) : '',
            submissionTitle: submissionTitle,
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime,
            ipAddress: await authServices.getMyIpAddress()            
        }
        createSubmissionFolder.mutate(data);
    }

    const handleSubmissionTitle = (value) => {
        setSubmissionTitle(value)
    }

    const handleEndDate = (date) => {
        setEndDate(date)       
    }

    const handleStartDate = (date) => { 
        setStartDate(date)       
    }

    const handleStartTime = (value) => {
      setStartTime(value)
    };

    const handleEndTime = (value) => {
        setEndTime(value)
    };

    // for date formating
    function formatDate(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

     //Delete 
     const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
     const [scrollDelete, setScrollDelete] = React.useState('paper');
     const [confirmDelete, setConfirmDelete] = React.useState("")
     const [deleteRow, setDeleteRow] = React.useState("")
 
     const descriptionElementRef2 = React.useRef(null);
     React.useEffect(() => {
         if (openFolder) {
             const {current: descriptionElement} = descriptionElementRef2;
             if (descriptionElement !== null) {
                 descriptionElement.focus();
             }
         }
     }, [openFolder]);

     const handleDeletePopup = (row) => { 
        setDeleteRow(row)   
        setOpenDeletePopup(true);
        setScrollDelete('paper');  
    }

    //deleteSubmissionFolder api
    const deleteSubmissionFolder = useMutation(classServices.deleteSubmissionFolder, {
        onSuccess: (data) => {   
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" });
                refreshList()
                clearData()
           }  
        },
        onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
        }
    });

    const handleDeleteFolder = async (row, e) => {        
        const data = {
            userCode: loginDetails ? Number(loginDetails.userCode) : '',
            userRoleCode: loginDetails ? Number(loginDetails.roleCode) : '',
            folderCode: deleteRow.folderCode,
            ipAddress: await authServices.getMyIpAddress()            
        }
        await emptyS3Directory(credentials?.s3bucket,  'file/' +loginDetails.userCode+'/'+ classDetails.classCode+'/'+deleteRow.folderCode);

        deleteSubmissionFolder.mutate(data);    
    }

    const emptyS3Directory = async (bucket, dir) => {
        const s3 = new aws.S3({
            accessKeyId: credentials?.awsAccessKey,
            secretAccessKey: credentials?.awsSecretKey,
            Bucket: credentials?.s3bucket,
            region: credentials?.region
        }); 
        const listParams = {
          Bucket: bucket,
          Prefix: dir
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
      
        if (listedObjects.Contents.length === 0) {
          return;
        }      
     
        listedObjects.Contents.map(async (item) => {
            await s3.deleteObject({ Bucket: credentials?.s3bucket, Key: item.Key }, (err, data)       => {  console.log(data, 'delete file');  })
        })     
        if (listedObjects.IsTruncated) {
          await emptyS3Directory(bucket, dir);
        }
      }
      function isInThePast(date) {
        const today = new Date(); 
        today.setHours(0, 0, 0, 0); 
        return date < today;
      }
    return (
        <> 
        <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
                <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>               
                    <Grid xs={8}>                    
                        <div style={{ display: 'flex' }}><AutoStoriesIcon style={{ marginRight: '5px' }} />
                            <Typography variant="h1" mb={3}>  {t('class.submissions')}</Typography>
                        </div>                   
                    </Grid>  
                   
                    {profilerole === USERROLECODE.tutor && 
                    <Grid xs={4} style={{ textAlign: 'right' }}>
                        <Button
                            variant={"contained"}
                            startIcon={<CreateNewFolderOutlinedIcon />}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.2rem'
                                },
                                textTransform: 'none',
                                marginRight: '2%',
                                fontSize: '12px',
                                paddingBottom: '3px',
                                paddingTop: '3px'
                            }}
                            onClick={handleClickOpenFolder('paper')}
                        >{t('class.createsubmission')}</Button>
                        
                    </Grid>}
                </Grid>                    
        </Div>
        {result && result.folderList.length > 0 ? (
        <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>
        {
            result.folderList.map((row) => (
              <Grid xs={3} md={2} lg={2}  
                sx={{ 
                    transition: 'all 0.2s',
                    // borderBottom: '1px solid #0000001a',
                    '&:hover': {
                        boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                        transform: 'translateY(-4px)', 
                        '& .MuiChip-animation': {
                            width: '100%',
                            fontSize: 12,
                            display: 'block',
                            textAlign: 'right',
                            padding: '1%'
                        }
                    }
                }}>
                {profilerole === USERROLECODE.tutor && (
                <Tooltip title="Delete">
                    <Div className={'MuiChip-animation'} 
                        onClick={(e) => handleDeletePopup(row, e)}
                        sx={{ width: 0, height: 0, fontSize: 0, display: 'none', transition: 'all 0.2s'}}>                
                        <DeleteIcon fontSize={"small"} />
                    </Div>
                </Tooltip>)}
                <div style={{textAlign: 'center'}}>                 
                    <FolderIcon  onClick={(e) => handleFilesOpen(row)}
                     style={{ marginRight: '5px',fontSize:'72px',color:'#ffcb3d', cursor:'pointer' }} />
                    <Typography variant="h6" mb={3}>{row.folderName}</Typography>                    
                </div>
                
               </Grid>
                 
            )) 
        }
        </Grid> 
        ) :
        <div><Typography variant={"h2"} mb={.5} style={{ textAlign: 'center' }}>{t('class.noSubmissions')}</Typography>
                    {profilerole === USERROLECODE.tutor && <Typography variant={"h6"} mt={2} style={{ textAlign: 'center' }}>Looks like you haven't create your submissions folder yet</Typography>}</div>}  
        {result && parseInt(result?.totalcount) > 10 && <div className={classStyles.pagination}>
                <Pagination
                    currentPage={currentPage}
                    totalCount={result ? parseInt(result?.totalcount) : 0}
                    pageSize={pageSize}
                    primaryColor="#814DFA"
                    onPageChange={handlePagination} 
                />
            </div>}                 
        
        {/* create folder */}

        <Dialog
            open={openFolder}
            onClose={() => setOpenFolder(false)}
            scroll={scrollFolder}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                '& .MuiDialog-paper ':{
                    width: '720px',
                }  
            }}>
            <DialogTitle id="scroll-dialog-title">{t('class.createsubmission')}</DialogTitle>
            <DialogContent dividers={scrollFolder === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef1}
                    tabIndex={-1}
                >
                <Box component="form"
                sx={{
                    mx: -1, 
                    '& .MuiFormControl-root:not(.MuiTextField-root)': {
                        p: 1,
                        mb: 2,
                        width: {xs: '100%', sm: '100%'}
                    },
                    '& .MuiFormControl-root.MuiFormControl-fluid': {
                        width: '100%'
                    }
                }}
                autoComplete="off"
                >
                <FormControl size="small">
                    <Grid container spacing={3.5}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="title"
                                label="Title"
                                size="small"
                                value={submissionTitle}
                                onChange={e => handleSubmissionTitle(e.target.value)}
                                error={errorsubmissionTitle ? true : false}
                                helperText={errorsubmissionTitle ? errorsubmissionTitle : ""}
                                onBlur={(event) => {
                                    if (event.target.value) {
                                        setErrorSubmissionTitle('');
                                    } else {
                                        setErrorSubmissionTitle(t('class.errorSubmissionTitle'));
                                    }
                                }}
                            />
                        </Grid>                      
                    </Grid>                                               
                 </FormControl> 

                 <FormControl  size="small">
                    <Grid container spacing={3.5}>                            
                        <Grid item xs={12} sm={8} lg={8}>
                        <TextField
                            fullWidth
                            id="date"
                            label={t('class.startdate')}
                            type="date"
                            defaultValue="" 
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={startDate}
                            InputProps={{ inputProps: { min: formatDate(new Date()) } }}
                            size="small"
                            onChange={e => handleStartDate(e.target.value)}
                            error={errorStartDate ? true : false}
                            helperText={errorStartDate ? errorStartDate : ""}
                            onBlur={(event) => {
                                if (event.target.value) {
                                    if (endDate && new Date(event.target.value) > new Date(endDate)) {
                                        setErrorStartDate(t('class.errorStartDatelesser'));
                                    } else { setErrorStartDate(''); }
                                    if (isInThePast(new Date(event.target.value))) {
                                        setErrorStartDate(t('class.errorValidDate'));
                                    } else {
                                        setErrorStartDate(''); 
                                    }
                                } else {
                                    setErrorStartDate(t('class.errorStartDate'));
                                }
                            }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <TextField
                            id="time"
                            label={t('class.starttime')}
                            type="time"
                            InputLabelProps={{
                               shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            sx={{width: 150}}
                            size="small"
                            value={startTime}
                            onChange={e => handleStartTime(e.target.value)}
                            error={errorStartTime ? true : false}
                            helperText={errorStartTime ? errorStartTime : ""}
                            onBlur={(event) => {
                                if (event.target.value) {
                                    setErrorStartTime('');
                                } else {
                                    setErrorStartTime(t('class.errorStartTime'));
                                }
                            }}
                            />
                        </Grid>    
                    </Grid>     
                </FormControl>

                <FormControl  size="small">
                    <Grid container spacing={3.5}>                            
                        <Grid item xs={12} sm={8} lg={8}>
                        <TextField
                            fullWidth
                            id="date"
                            label={t('class.enddate')}
                            type="date"
                            defaultValue="" 
                            InputLabelProps={{
                                shrink: true,
                            }}
                            size="small"
                            value={endDate} 
                            onChange={e => handleEndDate(e.target.value)}
                            InputProps={{ inputProps: { min: formatDate(startDate) } }}
                            error={errorEndDate ? true : false}
                            helperText={errorEndDate ? errorEndDate : ""}
                            onBlur={(event) => {
                                if (event.target.value) {
                                    if (startDate && new Date(startDate) > new Date(event.target.value)) {
                                        setErrorEndDate(t('class.errorEndDategreater'));
                                    } else { setErrorEndDate(''); }
                                    if (isInThePast(new Date(event.target.value))) {
                                        setErrorEndDate(t('class.errorValidDate'));
                                    } else {
                                        setErrorEndDate(''); 
                                    }
                                } else {
                                    setErrorEndDate(t('class.errorEndDate'));
                                }
                            }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                           <TextField
                           id="time"
                           label={t('class.endtime')}
                           type="time"
                           defaultValue="07:30"
                           InputLabelProps={{
                            shrink: true,
                           }}
                           inputProps={{
                             step: 300, // 5 min
                            }}
                            sx={{
                                width: 150
                            }}
                            size="small"
                            value={endTime}
                            onChange={e => handleEndTime(e.target.value)}
                            error={errorEndTime ? true : false}
                            helperText={errorEndTime ? errorEndTime : ""}
                            onBlur={(event) => {
                                if (event.target.value) {
                                    setErrorEndTime('');
                                } else {
                                    setErrorEndTime(t('class.errorEndTime'));
                                }
                            }}
                            />
                            </Grid>    
                    </Grid>     
                </FormControl>
            </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={() => clearData()}>{t('common.cancel')}</Button>
                <Button variant={"contained"} style={{textTransform: 'none'}} onClick={(e) => handleCreateSubmissionFolder(e)}>{t('class.confirm')}</Button>
            </DialogActions>
        </Dialog>


        {/* Delete Alert */}
        <Dialog
            open={openDeletePopup}
            onClose={() => setOpenDeletePopup(false)}
            scroll={scrollDelete}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                '& .MuiDialog-paper ':{
                    width: '720px',
                }  
            }}
        >
            <DialogTitle id="scroll-dialog-title" style={{background: "#f397113d", marginBottom: '1px'}}>
                <Grid xs={12} sm={12} lg={12}>                    
                    <div style={{ display: 'flex' }}><WarningIcon  color={'warning'} style={{ marginRight: '5px', marginTop: '3px' }} />
                    <Typography variant="h1"  color={'warning'}>{t('common.warning')}</Typography>
                    </div>                   
                </Grid>
            </DialogTitle>
            <DialogContent dividers={scrollDelete === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef2}
                    tabIndex={-1}
                >
                    <Box component="form"
                    sx={{
                        mx: -1, 
                        '& .MuiFormControl-root:not(.MuiTextField-root)': {
                            p: 1,
                            mb: 2,
                            width: {xs: '100%', sm: '100%'}
                        },
                        '& .MuiFormControl-root.MuiFormControl-fluid': {
                            width: '100%'
                        }
                    }}
                    autoComplete="off"
                >
                <FormControl size="small">
                    <Grid container spacing={3.5} style={{marginBottom: '3%'}}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <Typography variant="h4">{t('class.deleteFilesFolderMsg')}</Typography>
                            <Typography variant="p">{t('class.deleteFilesFolderMsg1')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3.5}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="delete"
                                label={t('class.confirmDelete')}
                                size="small"
                                value={confirmDelete}
                                onChange={e => setConfirmDelete(e.target.value)}
                            />
                        </Grid>                       
                    </Grid>                                               
                </FormControl>
            
            </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} 
                onClick={() => clearData()}>{t('common.cancel')}</Button>
                <Button variant={"contained"} 
                 disabled={confirmDelete && confirmDelete === 'DELETE' ? false : true } 
                 style={{textTransform: 'none'}} onClick={(e) => handleDeleteFolder(e)}>{t('class.deleteFolder')}</Button>
            </DialogActions>
        </Dialog>

        </>
    );
};

export default SubMissionsFiles;
