import React from 'react';
import { List , Typography} from "@mui/material";
import MessageItem from "./MessageItem"; 
import { classServices } from "../../../services/apis/classes";  
import { store } from '../../../store/store';
import {  useQuery } from "react-query";

const MessagesList = () => {
    const loginDetails = store.getState().loginDetails;
    const userDetails = [loginDetails ? loginDetails.userCode : 0];
    const {data: result} = useQuery(
        userDetails, classServices.getUnseenMessages
    );
    return (
        <List disablePadding>
            {
                result?.mesageList?.length > 0 && result?.mesageList.map((item, index) => {
                    return (
                        <MessageItem key={index} item={item} />
                    )
                })
            }    
              {(!result || result?.mesageList?.length === 0) && <div><Typography variant={"h5"} mb={4} style={{ textAlign: 'center', color: "#a9a9a9" }}>There was no messages found</Typography></div>}
        </List>
    );
};

export default MessagesList;
