import React  from "react";
import { usePaginationRange, DOTS } from "./usePaginationRange";  
import { makeStyles } from "@mui/styles"; 
// import { USERROLECODE } from './utils/constants/constants' 
// const profilerole = localStorage.getItem("switchprofile");
// const btn_color = Number(profilerole) === 2 ? '#F39711' : '#002366';
const useStyles = makeStyles( theme => ({ 
    firstdiv: {
        display: 'flex',
        flex: 'wrap',
        alignItems: 'center',
        justifyContent: 'center'
    },
    seconddiv: {
        display: 'flex',
        borderRadius: '12px'
    },
    btn: {
        display: 'flex',
        alignItems: 'center',
        width: '40px',
        background: 'white',
        border: '1px solid #d9dee8',
        borderRadius: '10px',
        margin: '5px' ,
    },
    svgimg: {
        width: '20px',
        height: '20px'
    },
    spande: {
        display: 'flex',
        alignItems: 'center', 
        cursor: 'pointer'
    },
    spandesign: {
        display: 'flex',
        alignItems: 'center', 
        borderColor: "#d9dee8",
        cursor: 'pointer'
    },
    
    secondbtn: {
        height: '40px',
        width: '40px',
        border: '1px solid #d9dee8',
        background: 'white',
        fontSize: '12px',
        margin: '5px',
        borderRadius: '10px',
        color: '#002366',
        cursor: 'pointer'
    },
    svgcolorchange: {
        height: '40px',
        width: '40px',
        border: '1px solid #d9dee8',
        background: 'white',
        fontSize: '12px',
        margin: '5px',
        borderRadius: '10px',
        fill: '#ccc',
        cursor: 'pointer',
    } ,
    secondbtn1: {
        height: '40px',
        width: '40px',
        border: '1px solid #d9dee8',
        background: 'white',
        fontSize: '12px',
        margin: '5px',
        borderRadius: '10px',
        background: '#d9dee8',
        cursor: 'pointer',
        color: '#002366',
    }
   
}));
const Pagination = (props) => {
    const classes = useStyles();
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize, 
  } = props;

  const paginationRange = usePaginationRange({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
    
  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  
  let lastPage = paginationRange[paginationRange.length - 1];
  return (
      
    <div className={classes.firstdiv}>
      
      <div className={classes.seconddiv}>
        <button
          onClick={onPrevious}
          disabled={currentPage === 1}
          className={currentPage === 1 ? classes.svgcolorchange : classes.secondbtn} 
        >
          <svg
            className={classes.svgimg}
            viewBox="0 0 20 20"
          >
            <path
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
              fillRule="evenodd"
            ></path>
          </svg>
        </button>
        {paginationRange.map((pageNumber) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return (
              <span key={"lists" + pageNumber} className={pageNumber === currentPage ? classes.spandesign : classes.spande}>
                &#8230;
              </span>
            );
          }
          // Render our Page Pills
          return (
            <div key={"list" + pageNumber}>
              <button
                onClick={() => onPageChange(pageNumber)} 
                 className={pageNumber === currentPage ? classes.secondbtn1 : classes.secondbtn}  
              >
                {pageNumber}
              </button>
            </div>
          );
        })}
        {/*  Right Navigation arrow */}
        <button
          onClick={onNext}
          disabled={currentPage === lastPage}
          className={currentPage === lastPage ? classes.svgcolorchange : classes.secondbtn}
        >
          <svg
             className={classes.svgimg}
            viewBox="0 0 20 20"
          >
            <path
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
              fillRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
       
    </div>
  );
};

export default Pagination;
