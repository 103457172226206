import React from 'react';
import {Typography, List, Grid} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import PaymentItemTutor from "./paymentItemTutor"; 
import PaymentItemStudent from "./PaymentItemStudent"; 
import { makeStyles } from "@mui/styles"; 
import { USERROLECODE } from '../../../utils/constants/constants'

const useStyles = makeStyles({ 
    pagination: {
        padding: '0px ', 
        borderRadius: '6px',
        textAlign: 'center',
        display: 'table',
        margin: '0 auto',  
        width: '100%',
        marginTop: '10px',
    }
});

const PaymentList = ({financesList}) => {
    // const {t} = useTranslation();
    // const classes = useStyles();
    const profilerole = localStorage.getItem("switchprofile");

    // const loginDetails = store.getState().loginDetails;
    // const limit = 10;
    // let pageSize = 10; 
    // const [offset, setOffset] = React.useState(0);
    // const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, offset, limit ]; 
    // const {data: finances, refetch: refetchFinanceList } = useQuery(
    //     userDetails, financeServices.dashboardfinanceList
    // );  
    // const [financesList, setFinancesList] = React.useState([]);
    // const [currentPage, setCurrentPage] = React.useState(1); 
    // React.useEffect(() => { 
    //     if (finances && finances?.financeList?.length > 0) {
    //         setFinancesList(finances?.financeList); 
    //     }  
    // }, [finances]);

    // const handlePagination = (page) => {
    //     let offsetVal = page * pageSize - pageSize;
    //     setOffset(offsetVal);
    //     setCurrentPage(page); 
    //     refetchFinanceList().catch(console.error);
    // }
    console.log(financesList, 'PaymentList')
    return (
        <React.Fragment>
            {/* <Typography variant={"h5"} color={"text.secondary"} mb={2}>{t('finance.transaction')} </Typography> */}
            {Number(profilerole) === USERROLECODE.tutor && (
            <List disablePadding>               
                {
                    financesList && financesList?.length > 0 ? financesList?.map((item, index) => (
                        <PaymentItemTutor item={item} key={index}/>
                    )) : <Grid item xs={12} md={12} lg={12} >
                                <Typography variant={"h2"} mb={.5} style={{ textAlign: 'center' }}>No Transactions Found</Typography>
                        </Grid>
                }
            </List>)}

            {Number(profilerole) === USERROLECODE.student && (
            <List disablePadding>               
                {
                    financesList && financesList?.length > 0 ? financesList?.map((item, index) => (
                        <PaymentItemStudent item={item} key={index}/>
                    )) : <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                    <Typography variant={"p"} mb={.5} >No Transactions Found</Typography>
                    </Grid>
                }
            </List>)}
           
            {/* {finances && parseInt(finances?.totalcount) > 10 && <div className={classes.pagination}>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={finances ? parseInt(finances?.totalcount) : 0}
                            pageSize={pageSize}
                            primaryColor="#814DFA"
                            onPageChange={handlePagination} 
                        />
                    </div>} */}
        </React.Fragment>
    );
};

export default PaymentList;
