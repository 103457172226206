import React from 'react';  
import { Card } from "@mui/material";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CardContent from "@mui/material/CardContent"; 
import CalendarWrapper from "./CalendarWrapper"; 
import { useTranslation } from "react-i18next"; 
import moment from 'moment'; 
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { getDateElements_Custom } from "@jumbo/utils";
import { useQuery } from "react-query";
import { calendarServices } from "../../../services/apis/calendar";  
import { store } from "../../../store/store";
import { setMenuName } from '../../../store/actions';
// import FullCalendar from '@fullcalendar/react' // must go before plugins
// import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
const localizer = momentLocalizer(moment);
const today = new Date();  
const CalendarDashboard = () => { 
    const { t } = useTranslation();
    const [month, setMonth] = React.useState(getDateElements_Custom(today).date.month);
    const [events, setEvents] = React.useState([]);
    const [view, setView] = React.useState('week');
    store.dispatch(setMenuName(t('sidebar.menu.calendar')));  
    
    const loginDetails = store.getState().loginDetails;
    const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, month]; 
    const {data: result, refetch: refetchCalendarList } = useQuery(
        userDetails, calendarServices.calendarList
    );  
    const handleNavigation = (focusDate, flipUnit, prevOrNext) => {
        setMonth(getDateElements_Custom(focusDate).date.month);
        refetchCalendarList().catch(console.error) 
    } 

  React.useEffect(() => {
      if (result) {
          let arr_data = [];
          for (let i = 0; i < result.length; i++){
              let classdate = result[i]?.start.split("-");
              let starttime = result[i]?.starttime.split(":");
              let endtime = result[i]?.endtime.split(":");
              arr_data.push({ "title": result[i]?.title, "start": new Date(parseInt(classdate[0]), parseInt(classdate[1])-1, parseInt(classdate[2]), parseInt(starttime[0]), parseInt(starttime[1]), 0, 0), "end": new Date(parseInt(classdate[0]), parseInt(classdate[1])-1, parseInt(classdate[2]), parseInt(endtime[0]), parseInt(endtime[1]), 0, 0),"desc": result[i]?.desc,"roleType": result[i]?.roleType});
          } 
          setEvents(arr_data);
        }
 }, [result]);

 React.useEffect(() => {
    localStorage.setItem("ChatPerson",'');
    localStorage.setItem("ChatPersonProfilePic", '');
    localStorage.setItem("ChatPersonOnlineStatus",'');
    localStorage.setItem("ChatPersonProfileID", '');
    window.scrollTo(0, 0)
});
    return (
        <React.Fragment>
 
            <Card>
                <CardContent> 
                {/* <FullCalendar
                    plugins={[ dayGridPlugin ]}
                    initialView="dayGridMonth"
                    weekends={false}
                    events={[
                        { title: 'event 1', date: '2022-10-01' },
                        { title: 'event 2', date: '2022-10-17' }
                    ]}
                    /> */}
                    <CalendarWrapper>
                        <Calendar
                            selectable
                            popup
                            localizer={localizer}
                            events={events}
                            timeslots={1}
                            step={30}
                            getNow={() => new Date()}
                            // view={'week'}
                            views={[ 'week', 'month','agenda']}
                            view={view}
                            onView={(view)=>{
                                setView(view)
                            }}
                            eventPropGetter={
                                (event) => {  
                                    //event.start.getDay() % 2 === 1
                                    var style = {
                                        backgroundColor:  event.roleType === 'tutor'
                                        ? "#e0c76a5e":'#e0cdff', 
                                        color:  event.roleType === 'tutor' ? '#766112' : '#441a8b',
                                        borderColor: event.roleType === 'tutor' ? '#e0c76a' : '#8d4df7', 
                                        borderRight: '0px',
                                        borderBottom: '0px',
                                        borderTop: '0px',
                                        borderRadius:'0px',
                                    };
                                    return { 
                                        style:   view === 'week' || view === 'month' || view === 'agenda' ?  style : {}
                                    };
                                }
                              }
                            defaultDate={today} 
                            style={{ height: 800 }} 
                            onNavigate={(focusDate, flipUnit, prevOrNext) => handleNavigation(focusDate, flipUnit, prevOrNext)}  
                        />
                          
                    </CalendarWrapper>  
                </CardContent>
            </Card>
    </React.Fragment>
    );
};

export default CalendarDashboard;
