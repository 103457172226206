import React from 'react';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ScheduleList from "./ScheduleList";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {getDateElements} from "@jumbo/utils";
import { USERROLECODE } from '../../../utils/constants/constants' 
const profilerole = localStorage.getItem("switchprofile");
const profile_color = Number(profilerole) === USERROLECODE.tutor ?  ["#FFA500", "#FFC55C"] : ["#843CF6", "#38B8F2"] ;
const ScheduleCard = () => {
    const current = new Date(); 
    const tranrightheight =  Number(profilerole) === USERROLECODE.student ? 255 : 255
    return (
        <JumboCardQuick noWrapper>
            <JumboContent
                bgColor={profile_color} 
                sx={{height: 260, color: "common.white"}}
            > 
                <Stack alignItems={"center"} >
                    <Typography variant={"h3"} color={"common.white"} sx={{p: 2}}>Upcoming Events</Typography>
                    <Avatar
                        sx={{
                            bgcolor: 'common.white',
                            height: 90,
                            width: 90,
                            fontSize: 18,
                            color: '#475259',
                            boxShadow: 2,
                            mb: 2
                        }}
                    >{getDateElements(current).date.month}{" "}{current.getDate()}</Avatar>
                    <Typography variant={"h4"} color={"common.white"}>{getDateElements(current).day}</Typography>
                    <Typography variant={"body1"} mb={1}>{getDateElements(current).date.year} </Typography>
                </Stack>
            </JumboContent>
            <JumboContent sx={{p: 3}}>
                    <JumboScrollbar
                        autoHeight
                        autoHeightMin={tranrightheight}
                        autoHide
                        autoHideDuration={200}
                        autoHideTimeout={500}
                    >
                    <ScheduleList/>
                </JumboScrollbar>
            </JumboContent>
        </JumboCardQuick>
    );
};

export default ScheduleCard;
