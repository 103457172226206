import React from "react"; 
import Messages from "./Messages";
import NewMessage from "./NewMessage";

const ChatSection = () => {
  return (
    <div className="chat_section_container"> 
      <Messages />
      <NewMessage />
    </div>
  );
};

export default ChatSection;
