import React, {useState} from 'react';
import {Grid} from "@mui/material";   
import { useTranslation } from "react-i18next";  
import ClassesList from "../../../shared/widgets/CurrentPlan/ClassesList"; 
import {InputBase, styled, Typography} from "@mui/material"; 
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'; 
import {setMenuName} from '../../../store/actions'
import { USERROLECODE } from '../../../utils/constants/constants'
import { makeStyles } from "@mui/styles"; 
import Pagination from "@jumbo/components/Pagination";
import { classServices } from "../../../services/apis/classes";  
import { useQuery } from "react-query"; 
import { store } from "../../../store/store";
import { setClassDetails } from '../../../store/actions'
import { useMutation } from "react-query";

const useStyles = makeStyles({ 
    pagination: {
        padding: '0px ', 
        borderRadius: '6px',
        textAlign: 'center',
        display: 'table',
        margin: '0 auto',  
        width: '100%',
        marginTop: '10px',
    }
});
export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    display: 'flex',
    borderRadius: 24,
    boxShadow: theme.shadows[25],

    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height: 24
    },
}));
const MyClasses = () => { 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 
    const classes = useStyles();
    const loginDetails = store.getState().loginDetails;  
    const  limit  = 10 ;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);  
    let pageSize = 10; 
    const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, offset, limit, "classes"]; 
    const {data: result, refetch: refetchClassesList } = useQuery(
        userDetails, classServices.classesList
    );   
    const handlePagination = (page) => {
        let offsetVal = page * pageSize - pageSize;
        setOffset(offsetVal);
        setCurrentPage(page); 
        refetchClassesList().catch(console.error) 
    }
    React.useEffect(() => {
        localStorage.setItem("ChatPerson",'');
        localStorage.setItem("ChatPersonProfilePic", '');
        localStorage.setItem("ChatPersonOnlineStatus",'');
        localStorage.setItem("ChatPersonProfileID", '');
        window.scrollTo(0, 0)
    });
    store.dispatch(setMenuName(t('sidebar.menu.classes')));      
    const profilerole = Number(localStorage.getItem("switchprofile"));
    const handleClassCreation = () => {  
        localStorage.setItem("class_local_details", '');
        store.dispatch(setClassDetails(''));    
        navigate("/classes/createclass", { state: {frompage:"classes"} });
    }

    const mutation = useMutation(classServices.getClassDetails, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                return;
           }
            if (data?.statusFlag === 1) { 
                if (data?.body?.classDetails) {
                  localStorage.setItem("class_local_details", JSON.stringify(data?.body?.classDetails));
                  store.dispatch(setClassDetails(data?.body?.classDetails));  
                  navigate("/classes/classdetails/viewclass", { state: {items: data?.body?.classDetails} });
                }
            }         
        },
        onError() {
        },
    });
  

    const handleClassDetails = (item) => {  
        mutation.mutate({
            userCode: loginDetails ? Number(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? Number(loginDetails.roleCode): 0,   
            classCode: Number(item.classCode)
        });       
    }
 
    return (
        <>
            <div style={{ display: 'flex' }}>
                {/* <VoiceChatOutlinedIcon style={{ marginRight: '5px', marginTop: '2px' }} />
                <Typography variant="h1" style={{width:'71%'}} mb={3}>{t('sidebar.menu.classes')}</Typography> */}
                {profilerole === USERROLECODE.tutor && <Grid container spacing={3.75} style={{ textAlign: 'right',marginBottom: '2%'  }}>
                    <Grid item xs={12}  >
                        <Button
                            variant={"contained"}
                            startIcon={<AddToPhotosIcon />}
                            onClick={handleClassCreation}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.5rem'
                                },
                                textTransform: 'none',
                                fontSize: '12px',
                                marginTop: '1px'
                            }}
                        >Create Class</Button>
                    </Grid>
                </Grid>}
            </div>   
            <Grid container spacing={3.75}>  
            {(result && result?.classesList?.length > 0) ? result?.classesList.map((item, index) => (
                     <Grid item xs={12} md={6} lg={6} > 
                    <ClassesList values={item} key={index} frompage={"myclass"} loginDetails={loginDetails} clickAction={() => handleClassDetails(item)} />
                     </Grid> 
                )) :  <Grid item xs={12} md={12} lg={12} > <Typography variant={"h2"} mb={.5} style={{ textAlign: 'center' }}>No Classes Found</Typography>
                {profilerole === USERROLECODE.tutor && <Typography variant={"h6"} mt={2} style={{ textAlign: 'center' }}>Looks like you haven't made your classes yet</Typography>}</Grid>}
                
            </Grid>
            {result && parseInt(result?.totalcount) > 10 && <div className={classes.pagination}>
                <Pagination
                    currentPage={currentPage}
                    totalCount={result ? parseInt(result?.totalcount) : 0}
                    pageSize={pageSize}
                    primaryColor="#814DFA"
                    onPageChange={handlePagination} 
                />
            </div>}
        </>
    );
};

export default MyClasses;
