import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducer";
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
 
export const store = configureStore({
  reducer: reducers,
});
 
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const bindMiddleware = middleware => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return composeEnhancers(applyMiddleware(...middleware));
};

function configure_Store(initialState = {}) {
    const store = createStore(reducers(history), initialState, bindMiddleware([routeMiddleware, thunk]));

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducer', () => {
            const exportReducers = require('./reducer');
            store.replaceReducer(exportReducers);
        });
    }
    return store;
}
 
  
export default configure_Store;
export { history };
