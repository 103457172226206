import React from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import {alpha, Avatar,  ListItemAvatar, ListItemText, Typography, Tooltip, IconButton,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Box, FormControl, TextField, Button} from "@mui/material";
import Span from "@jumbo/shared/Span"; 
import { ASSET_AVATARS } from "../../../utils/constants/paths";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'; 
import { getAssetPath } from "../../../utils/appHelpers";
import { USERROLECODE } from '../../../utils/constants/constants'
import { useMutation } from "react-query";
import { classServices } from "../../../services/apis/classes"; 
import { useSnackbar } from "notistack";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useTranslation } from "react-i18next";   
import { authServices } from "../../../services/auth/user";   
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom"; 
import WarningIcon from '@mui/icons-material/Warning';
import { store } from "../../../store/store";

const labelMenuItems = [
    {icon: <EditIcon/>, title: "Edit", action: "edit"},
    {icon: <DeleteIcon/>, title: "Delete", action: "delete"}
];
const AnnouncementsItem = ({ item, indexValue, profilerole ,success, frompage}) => { 
    const { enqueueSnackbar } = useSnackbar();
    const loginDetails = store.getState().loginDetails;
    const { showDialog, hideDialog } = useJumboDialog(); 
    const navigate = useNavigate();
    const { t } = useTranslation();  
    const [open, setOpen] = React.useState(false); 
    const [confirmDelete, setConfirmDelete] = React.useState("");
    const mutation = useMutation(classServices.announcement, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                setOpen(false);
                enqueueSnackbar(data?.message, { variant: "success" }); 
                success(true);
                setConfirmDelete('');
           }
        },
        onError() { 
            setOpen(false);
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });

    const dismissmutation = useMutation(classServices.dismissAnnouncement, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                // enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                hideDialog()
                success(true);
                // enqueueSnackbar(data?.message, { variant: "success" });               
           }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });

    const dismiss = async(item) => {
        dismissmutation.mutate({
            userCode: loginDetails ? Number(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? Number(loginDetails.roleCode) : 0,
            classCode: Number(item.classCode),           
            announcementCode: Number(item.announcementCode),
            ipAddress: await authServices.getMyIpAddress()            
        });
    }

    const handleDismiss = async (announcement, e) => {  
        // showDialog({
        //     variant: "confirm",
        //     title: "Are you sure?",
        //     content: "Once you delete an announcement that cannot be reversed.",
        //     onYes:  () => dismiss(announcement), 
        //     onNo: () => hideDialog(),
        // })
        e.stopPropagation()
        dismiss(announcement)
    }
    const handleMoreAction = React.useCallback((menuItem) => {
        switch (menuItem.action) {
            case 'edit':
                if (frompage === "home") {
                    navigate("/classes/createannouncement", { state: { frompage: "home", announcementData: item } });
                    break;
                } else {
                    navigate("/classes/classdetails/editannouncement", { state: { frompage: "classes", announcementData: item } });
                    break;
                }
            case 'delete':
                setOpen(true);
                break;
            default:
              return "";
        }
    })
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    const handleDeleteClass = async () => {
       await mutation.mutate({
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,
            announcementCode: item.announcementCode, 
            process: "Delete",
            ipAddress: await  authServices.getMyIpAddress()   
        })
    }
    const handleConfirmDelete = (value) => {
        setConfirmDelete(value)
    }
    return (
        <>
        <ListItemButton
            component={"li"}
            // onClick={() => handleClick(item)}
            sx={{
                p: theme => theme.spacing(1.25, 3),
                transition: 'all 0.2s',
                borderBottom: '1px solid #0000001a',
                '&:hover': {
                    boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                    transform: 'translateY(-4px)', 
                    '& .MuiChip-animation': {
                        width: 'auto',
                        height: 'auto',
                        fontSize: 12,
                        display: 'block',
                    }
                }
            }}
        >
            <ListItemAvatar sx={{ minWidth: 68 }}>
                {indexValue % 2 === 0 ? 
                    (<Avatar alt="Announcement" src={getAssetPath(`${ASSET_AVATARS}/assignment.png`, "48x48")} sx={{ width: "48px", height: "48px" }} />) : 
                    (<Avatar alt="Announcement" src={getAssetPath(`${ASSET_AVATARS}/assignment1.png`, "48x48")} sx={{width: "48px", height: "48px"}}/>) 
                } 
            </ListItemAvatar>
            <ListItemText>
                <>
                    <Typography variant={"h5"} mb={.5}>{item.header}</Typography>
                    <Typography variant={"body1"} color={'text.secondary'} fontSize={"small"}  className={'announcementcss'}>
                    <Typography  component={'span'} color={'text.secondary'} fontSize={"small"}  dangerouslySetInnerHTML={{__html: item.description}}></Typography>
                    <Span> {item.className} | <Span sx={{color: 'primary.main'}}>{item.username}</Span> {"created at"} {item.createddatetime}</Span>
                    </Typography>
                    </>
            </ListItemText>              
            
                {profilerole === USERROLECODE.tutor && <JumboDdMenu icon={<MoreHorizIcon />} menuItems={labelMenuItems}  onClickCallback={handleMoreAction} />}
             
                {profilerole === USERROLECODE.student && frompage=== 'home' && <Tooltip title={ "Dismiss"}>
                    <IconButton className={'MuiChip-animation'} onClick={(e) => handleDismiss(item, e)}
                        sx={{
                            width: 0,
                            height: 0,
                            fontSize: 0,
                            display: 'none',
                            transition: 'all 0.2s',
                        }} >
                          <CancelOutlinedIcon fontSize={"small"} /> 
                    </IconButton>
                </Tooltip>}
        </ListItemButton>
           <Dialog
           open={open}
           onClose={() => setOpen(false)} 
           aria-labelledby="scroll-dialog-title"
           aria-describedby="scroll-dialog-description"
           sx={{
               '& .MuiDialog-paper ':{
                   width: '720px',
               },  
               '& .MuiDialogTitle-root' : {
                   padding: '16px 24px 0px 24px'
               }
           }}
       >
           <DialogTitle id="scroll-dialog-title" style={{background: "#f397113d", marginBottom: '1px'}}>
               <Grid xs={12} sm={12} lg={12}>                    
                     <div style={{ display: 'flex' }}>
                    <WarningIcon color={'warning'} style={{ marginRight: '5px', marginTop: '1px' }} />
                    <Typography variant="h1"  color={'warning'}>{t('common.warning')}</Typography>
                    </div>                   
               </Grid>
           </DialogTitle>
           <DialogContent dividers={true}>
               <DialogContentText
                   id="scroll-dialog-description"
                   ref={descriptionElementRef}
                   tabIndex={-1}
               >
                   <Box component="form"
                   sx={{
                       mx: -1, 
                       '& .MuiFormControl-root:not(.MuiTextField-root)': {
                           p: 1,
                           mb: 2,
                           width: {xs: '100%', sm: '100%'}
                       },
                       '& .MuiFormControl-root.MuiFormControl-fluid': {
                           width: '100%'
                       }
                   }}
                   autoComplete="off"
               > 
               <FormControl size="small">
                   <Grid container spacing={3.5} style={{marginBottom: '3%'}}>                       
                       <Grid item xs={12} sm={12} lg={12}>
                           <Typography variant="h4">{t('announcement.deleteMessage')}</Typography>
                           <Typography variant="p">{t('announcement.deleteMessagealert')}</Typography>
                       </Grid>
                   </Grid>
                   <Grid container spacing={3.5}>                       
                       <Grid item xs={12} sm={12} lg={12}>
                           <TextField
                               fullWidth
                               id="delete"
                               label={t('announcement.confirmDelete')}
                               size="small"
                               value={confirmDelete}
                               onChange={e => handleConfirmDelete(e.target.value)}
                           />
                       </Grid>                       
                   </Grid>                                               
               </FormControl>
                
           </Box>
               </DialogContentText>
           </DialogContent>
           <DialogActions>
               <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={() => setOpen(false)}>{t('common.cancel')}</Button>
               <Button variant={"contained"} style={{textTransform: 'none'}} onClick={handleDeleteClass} 
               disabled={confirmDelete && confirmDelete === 'DELETE' ? false : true }>{t('class.delete')}</Button>
           </DialogActions>
            </Dialog>
            </>
   
    );
};
 
/* Todo item prop define */
export default AnnouncementsItem;
