import React from 'react';
import {Avatar, ListItemAvatar, ListItemText, Typography, Grid} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ListItemButton from "@mui/material/ListItemButton";
import Span from "@jumbo/shared/Span";

const PaymentItemTutor = ({ item }) => {
    const profilerole = localStorage.getItem("switchprofile");

    let bgclr = "#38529A";
    if (item.transType === "Buy") {
        bgclr = "#38529A";
    } else if (item.transType === "Topup") {
        bgclr = "#17A9FC";
    } else {
        bgclr = "#CC4BB7";
    }
    return (
        <ListItemButton component={"li"} sx={{p: theme => theme.spacing(1, 3)}}>  
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4} display={'flex'}>
                    <ListItemAvatar sx={{ minWidth: 68 }}>
                        <Avatar src={item?.studentProfile} sx={{ width: "48px", height: "48px" }} />                                
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"h5"} mb={.5}>{item.studentname}</Typography>}
                        secondary={ 
                                <Typography variant={"body1"} color={'text.secondary'} fontSize={"small"}  style={{marginTop: '-6px',  marginLeft: '-6px' }}>
                                <Span sx={{color: 'primary.main'}}>{item.transName}</Span>
                            </Typography> 
                        }
                    />                                          
                    </Grid>
                    <Grid item xs={6} md={3} lg={3} style={{display: 'flex'}}>
                        <ListItemText                          
                        primary={
                        <Typography variant={"body1"} component={"div"} style={{marginTop: '2%'}}>
                            <Typography variant={"h6"}>{item?.createddatetime}</Typography>                                                                                  
                        </Typography>
                        }
                        sx={{flex: 1}}
                        />
                    </Grid>  
                             
                    <Grid item xs={6} md={3} lg={3}>
                    <ListItemText style={{float: 'right'}}>
                        <Typography variant='body1' component={"span"} style={{marginTop: '2%'}}>$ {item.totalamt}</Typography>
                        {item.transactionType === 'Credit' ?
                            <ArrowUpwardIcon color='success' sx={{ml: 1, verticalAlign: 'middle'}} fontSize={"small"}/> :
                            <ArrowDownwardIcon color='error' sx={{ml: 1, verticalAlign: 'middle'}} fontSize={"small"}/>
                        }
                    </ListItemText> 
                </Grid>
            </Grid> 
        </ListItemButton>
        
    );
};
/* Todo item prop define */

export default PaymentItemTutor;
