import React  from 'react';
import AnnouncementsItem from "./AnnouncementsItem"; 
import { List, Typography } from "@mui/material";    
import { USERROLECODE } from '../../../utils/constants/constants'
const AnnouncementsList = ({ announcementList ,deleteSuccess , frompage} ) => {  
    const [isSuccess, setSuccess] = React.useState(false); 
    
    
    React.useEffect(() => {
        if (isSuccess ) { 
            setSuccess(false)
            deleteSuccess(true)
        }
    }, [isSuccess,deleteSuccess]);  
    
    const profilerole = Number(localStorage.getItem("switchprofile"));
    return (
        <>
        <List disablePadding sx={{mb: 1}}>
                {
                    announcementList?.length > 0 ? announcementList?.map((item, index) => (
                        <AnnouncementsItem item={item} key={index} indexValue={index} profilerole={profilerole} success={setSuccess} frompage={frompage} />
                    )) : <div><Typography variant={"h2"} mb={.5} style={{ textAlign: 'center' }}>No Announcement Found</Typography>
                    {profilerole === USERROLECODE.tutor && <Typography variant={"h6"} mt={2} style={{ textAlign: 'center' }}>Looks like you haven't made your announcement yet</Typography>}</div>
            }
           
            </List>  
            </>
    );
};

export default AnnouncementsList;
