import React from 'react';
import Avatar from "@mui/material/Avatar"; 
import ContentHeader from "../../../../shared/headers/ContentHeader";    
import { connect } from "react-redux";
const Header = (props) => {
    const { loginDetails } = props;
    React.useEffect(() => {  
    }, [loginDetails]);
    return (
        <ContentHeader
            avatar={
                <Avatar
                    sx={{ width: 72, height: 72 }}
                    alt={"User"}
                    src={loginDetails?.profileUrl}
                />} 
            title={loginDetails?.firstName + " "+loginDetails?.lastName}       
            sx={{
                position: 'relative',
                zIndex: 1,

                '& .MuiCardHeader-action': {
                    alignSelf: 'center'
                }
            }}
        />
    );
};
const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
};
export default connect(mapStoreStateToProps)(Header); 
