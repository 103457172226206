import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,DialogContentText,
    Button,  Box, FormControl, Grid, InputLabel, Select, MenuItem,TextField,Typography, IconButton, alpha, Tooltip, FormHelperText
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import S3 from 'react-aws-s3';  
import { connect } from 'react-redux';

import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query"; 
import { BUCKET_FOLDERS, maxUploadFileSize } from '../../../utils/constants/constants'
import DndWrapper from "./DndWrapper"; 
import { classServices } from "../../../services/apis/classes";  
import {ASSET_AVATARS} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'; 
import LoadingButton from "@mui/lab/LoadingButton"; 
import { videoFiles } from '../../../utils/constants/constants';
window.Buffer = window.Buffer || require("buffer").Buffer;

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
}; 

let credentialDetails = '';
let loginDetails = '';
let classDetails = '';

const mapStateToProps = function(state) {
    credentialDetails = state.credentials
    loginDetails = state.loginDetails
    classDetails = state.classDetails
    return {
        credentialDetails: state.credentials,
        loginDetails: state.loginDetails,
        classDetails: state.classDetails,
   };
}

const UploadDialog = (
    {
        open,
        classesFolderList,
        scroll,
        folderCode,
        onClose,
        file,
        isSuccessful
    }) => {
        const { t } = useTranslation();
        const { enqueueSnackbar } = useSnackbar();
        const [title, setTitle] = React.useState('')
        const [selectedFolder, setSelectedFolder] = React.useState('')
        const [errorTitle, setErrorTitle] = React.useState('')
        const [errorSelectFolder, setErrorSelectFolder] = React.useState('')
        const [files, setFiles] = useState([]);
        const [edit, setEdit] = useState(false);
        const [fileTypeErrror, setFileTypeErrror] = useState(false);
        const [fileSizeError, setFileSizeError] = useState(false);
        const [filesData, setFilesData] = React.useState([]) 
        const [isLoading, setLoading] = useState(false);
        const file_types = ['txt','xlsx', 'csv', 'png', 'jpg', 'jpeg', 'pdf', 'ppt', 'docx']
        React.useEffect(() => {
            if (file && file.files && file.files.length > 0) {
               setFiles(file)
               const dropFiles = Object.assign([], files);
               file.files.forEach(f => {
                // if (file_types.includes(f.fileType)) {
                    if (f.fileType ===  'txt') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/txt.png`,"40x40")
                    } else if (f.fileType ===  'xlsx' || f.fileType === 'csv' || f.fileType ===  'xls' ) {
                        f.image = getAssetPath(`${ASSET_AVATARS}/xls.png`,"40x40")
                    } else if (f.fileType ===  'png' || f.fileType ===  'jpg' || f.fileType ===  'jpeg') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/image.png`,"40x40")
                    } else if (f.fileType ===  'pdf') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/pdf.png`,"40x40")
                    } else if (f.fileType ===  'ppt') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/ppt.png`,"40x40")
                    } else if (f.fileType ===  'docx' || f.fileType ===  'doc') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/doc.png`,"40x40")
                    }else {
                        f.image = getAssetPath(`${ASSET_AVATARS}/files.png`,"40x40")
                    }
                    dropFiles.push(f)
                // } 
             });
             setFiles(dropFiles);
            }
            if(file) {
                setEdit(true)
                setTitle(file.fileOriginalName)
            }
        }, [file]);
        //uploadFiles api
        const uploadFilesApi = useMutation(classServices.uploadFiles, {
            onSuccess: (data) => {   
                if (data?.statusFlag === 2) {
                    enqueueSnackbar(data?.message, { variant: "warning" });
                    setLoading(false);
                    return;
            }
                if (data?.statusFlag === 1) {
                    enqueueSnackbar(data?.message, { variant: "success" });                
                    clearData()
                    setLoading(false);
                    isSuccessful(true)
            }  
            },
            onError: (error) => {
                setLoading(false);
                enqueueSnackbar(error, { variant: "error" });
            }
        });    
    
       
    const { getRootProps, getInputProps } = useDropzone({     
            //  accept: '/*',
            onDrop: acceptedFiles => { 
                const dropFiles = Object.assign([], files);
                let hasNoType = false, maxSizeExceed = false;
                setFileTypeErrror(false)
                setFileSizeError(false)  
                if(acceptedFiles && acceptedFiles.length >0) {
                    acceptedFiles.forEach(f => {
                        let f_length = f?.path?.split('.').length;
                         let type = f?.path?.split('.')[f_length-1]
                        f.isAdd = true
                        f.fileType = type
                        if (!videoFiles.includes(type.toUpperCase())) {
                            if (type ===  'docx' || type ===  'doc') {
                                f.image = getAssetPath(`${ASSET_AVATARS}/doc.png`,"40x40")
                            } else if (type ===  'xlsx' || type === 'csv'  || type === 'xls') {
                                f.image = getAssetPath(`${ASSET_AVATARS}/xls.png`,"40x40")
                            } else if (type ===  'png' || type ===  'jpg' || type ===  'jpeg') {
                                f.image = getAssetPath(`${ASSET_AVATARS}/image.png`,"40x40")
                            } else if (type ===  'pdf') {
                                f.image = getAssetPath(`${ASSET_AVATARS}/pdf.png`,"40x40")
                            } else if (type ===  'ppt') {
                                f.image = getAssetPath(`${ASSET_AVATARS}/ppt.png`,"40x40")
                            }  else if (type ===  'txt') {
                                f.image = getAssetPath(`${ASSET_AVATARS}/txt.png`,"40x40")
                            } else {
                                f.image = getAssetPath(`${ASSET_AVATARS}/files.png`,"40x40")
                            }
                            
                            if (parseInt(f?.size) > maxUploadFileSize) {
                                maxSizeExceed = true;
                                setFileSizeError(true)
                                return
                            }
                            dropFiles.push(f)
                        } else {
                            hasNoType = true 
                            setFileTypeErrror(true)
                            return                           
                        }
                         
                    });
                }

                if (!hasNoType && !maxSizeExceed) {
                    setFiles(dropFiles);
                    setFileTypeErrror(false)
                    setFileSizeError(false)  
                } 
            }
            // maxSize: maxUploadFileSize
        },[file_types, files]);
     
        
        const descriptionElementRef = React.useRef(null);
        React.useEffect(() => {
            if (open) {
                const {current: descriptionElement} = descriptionElementRef;
                if (descriptionElement !== null) {
                    descriptionElement.focus();
                }
            }
        }, [open]);

        React.useEffect(() => {
            if (folderCode) {
                setSelectedFolder(folderCode)
            }
        }, [folderCode]);

        const clearData = () => {
            onClose(false)
            setTitle('')
            setSelectedFolder('')
        }

     

    const uploadFile = async () => {
        if (selectedFolder) {
            setErrorSelectFolder('');
        } else {
            setErrorSelectFolder(t('class.errorSelectFolder'));
            return;
        }
        if(!edit) {
        const config = {
            bucketName: credentialDetails?.s3bucket,
            dirName: BUCKET_FOLDERS.FILES, /* optional */
            region: credentialDetails?.region,
            accessKeyId: credentialDetails?.awsAccessKey,
            secretAccessKey: credentialDetails?.awsSecretKey, 
        }
        let fileData = []; let flag = 0; 
        if (files && files.length > 0) { 
            await files.forEach(async (file) => {
                if (file.isAdd) {
                const file_name = 'file_' + loginDetails?.userCode + "_" + Date.now();
                const fileName = loginDetails.userCode + '/' + classDetails.classCode + '/' + selectedFolder + '/' +  file_name;
                const ReactS3Client = new S3(config);
                //Upload new file
                await ReactS3Client
                    .uploadFile(file, fileName)
                    .then(data => { 
                        fileData.push({ fileName: file_name, filePath: data.location, fileType: file.fileType, fileSize: file.size, fileOriginalName: file.name ? file.name.split('.')[0] : '' }) 
                    })
                    .catch(err => console.error(err, 'error'))
                flag = flag + 1;
                } else { 
                    fileData.push({ fileName: file.fileName, filePath: file.filePath, fileType: file.fileType, fileSize: file.size, fileOriginalName: file.name ? file.name.split('.')[0] : '' }) 
                    flag = flag + 1; }
                if (flag === files.length) {
                    setFilesData(fileData);
                } 
            })                
        }
        } else {
            const ipAdd ='';  
                const data = {
                    userCode: loginDetails ? Number(loginDetails.userCode) : '',
                    userRoleCode: loginDetails ? Number(loginDetails.roleCode) : '',
                    classCode: classDetails ? Number(classDetails.classCode) : '',
                    folderCode: Number(selectedFolder),
                    files: filesData,
                    title: title,
                    ipAddress: ipAdd,
                    process: edit ? 'update' : 'insert',
                    fileCode: edit ? Number(file.fileCode) : '',
                }
                setLoading(true);
                uploadFilesApi.mutate(data) 
        }
    };
      
    React.useEffect(() => {
        if (filesData && filesData.length > 0) {  
            const ipAdd ='';  
            const data = {
                userCode: loginDetails ? Number(loginDetails.userCode) : '',
                userRoleCode: loginDetails ? Number(loginDetails.roleCode) : '',
                classCode: classDetails ? Number(classDetails.classCode) : '',
                folderCode: Number(selectedFolder),
                files: filesData,
                title: title,
                ipAddress: ipAdd,
                process: edit ? 'update' : 'insert',
                fileCode: edit ? Number(file.fileCode) : '',
            }
            setFilesData([]);
            setLoading(true);
            uploadFilesApi.mutate(data)            
        }
    },[filesData]); 

    const removeFile = (index) => {
        const files_list = Object.assign([], files)       
        // const deleted_list = Object.assign([], deletedFiles)       
        if (index !== -1) {
          files_list.splice(index, 1)
          setFiles(files_list)
        //   setDeletedFiles(files_list[index])
        }
    }
    const handlekeyPress = (e) => {
        if(e.keyCode == 13) {
            uploadFile()
        }
    }
    //todo: need to work on icon, subheader, maxWidth props
    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                '& .MuiDialog-paper ':{
                    width: '520px',
                }  
            }}
        >
            <DialogTitle id="scroll-dialog-title">{ !edit ? t('class.uploadFile') : "Update File Name"}</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <Box component="form"
                sx={{
                    mx: -1, 
                    '& .MuiFormControl-root:not(.MuiTextField-root)': {
                        p: 1,
                        mb: 2,
                        width: {xs: '100%', sm: '100%'}
                    },
                    '& .MuiFormControl-root.MuiFormControl-fluid': {
                        width: '100%'
                    }
                }}
                autoComplete="off"
        >   
                {!edit && (                 
                 <FormControl size="small">
                    <Grid container spacing={3.5}>   
                        <Grid item xs={12} sm={12} lg={12}>
                                <InputLabel id="folder-select-small">{t('class.folder')}</InputLabel>
                                        <Select fullWidth
                                            labelId="demo-simple-select-error-label"
                                            id="demo-simple-select-error"
                                            value={selectedFolder}
                                            label={t('class.chooseClassType')}
                                            error={errorSelectFolder ? true : false}
                                            onBlur={(event) => {
                                                if (event.target.value) {
                                                    setErrorSelectFolder('');
                                                } else {
                                                    setErrorSelectFolder(t('class.errorSelectFolder'))
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (event.target.value) {
                                                setErrorSelectFolder('');
                                            } else {
                                                setErrorSelectFolder(t('class.errorSelectFolder'));
                                                }
                                                setSelectedFolder(event.target.value)
                                            }}
                                        >
                                        {classesFolderList?.length > 0 && classesFolderList.map((item,index) => (
                                        <MenuItem key={index} value={ item.folderCode }>{ item.folderName }</MenuItem>  
                                        )) } 
                                </Select>
                                <FormHelperText style={{color: '#E73145'}}>{errorSelectFolder ? errorSelectFolder : ""}</FormHelperText>
                            </Grid>
                        </Grid>
                    </FormControl>                
                )}
                {!edit && (  
                <FormControl>
                <Grid container spacing={3.5}>
                    <Grid item xs={12} sm={12} lg={12}>
                    <Div>
                        <DndWrapper>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input type="file" {...getInputProps()} />
                                <Typography variant={"body1"}>Drag 'n' drop some files here, or click to select
                                    files</Typography>
                            </div>
                        </DndWrapper>
                        {fileTypeErrror && (
                            <Typography fontSize={"small"} color='error'>
                            {t('class.errorFileType')}
                            </Typography>                            
                        )} 
                        {fileSizeError && (
                            <Typography fontSize={"small"} color='error'>
                            {t('class.errorFileSize')}
                            </Typography>
                        )}                       
                        {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
                        {files && files.length > 0 && (
                            <Grid container style={thumbsContainer}>
                            {
                                files.map((row, index) => (
                                    <Grid xs={3} md={2} lg={2} 
                                    sx={{
                                        boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                                        marginRight: '10px',
                                        transform: 'translateY(-4px)', 
                                        '& .MuiChip-animation': {
                                            width: 'auto',
                                            height: 'auto',
                                            fontSize: 12,
                                            display: 'block',
                                        } }
                                    }>
                                    <div style={{textAlign: 'center'}} >
                                        <Tooltip title="Delete">
                                        <IconButton  className={'MuiChip-animation'}  
                                            sx={{
                                            width: 0,
                                            height: 0,
                                            fontSize: 0,
                                            display:'none',
                                            transition: 'all 0.2s',
                                            float: 'right'
                                            }}>
                                        <CancelOutlinedIcon fontSize={"small"} onClick={() => removeFile(index)} />
                                        </IconButton>
                                        </Tooltip>
                                        <img src={row.image} alt="altImg" style={{width: '60px', height: '60px'}} />
                                    </div>
                                    
                                </Grid>
                                    
                                ))
                            }
                            </Grid>  
                            )}
                    </Div>                          
                    </Grid> 
                    </Grid>
                </FormControl> 
                )}
                {edit && (
                <FormControl size="small">
                    <Grid container spacing={3.5}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="title"
                                label="File Name"
                                size="small"
                                value={title}
                                onKeyDown={handlekeyPress}
                                onChange={(event) => setTitle(event.target.value)}
                                error={errorTitle ? true : false}
                                helperText={errorTitle ? errorTitle : ""}
                                onBlur={(event) => {
                                    if (event.target.value) {
                                        setErrorTitle('');
                                    } else {
                                        setErrorTitle(t('class.errorTitle'));
                                    }
                                }}
                            />
                        </Grid>                        
                    </Grid>                                               
                </FormControl>
                )}
            
            </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={() => clearData()}>{t('common.cancel')}</Button>
                <LoadingButton   
                                    style={{textTransform: 'none'}}   
                                    variant="contained"
                                    size="medium"
                                    loading={isLoading}
                                    disabled={edit ? (!errorTitle && title)  ? false : true : (!errorTitle && !errorSelectFolder && 
                                        files && files.length > 0) ? false : true }
                                    onClick={() => uploadFile()}>{edit ? t('common.update') : t('class.upload')}</LoadingButton>                                     
                {/* <Button variant={"contained"} style={{textTransform: 'none'}} 
                disabled={!errorTitle && !errorSelectFolder && files && files.length > 0 ? false : true }
                onClick={() => uploadFile()}>{edit ? t('common.update') : t('class.upload')}</Button> */}
            </DialogActions>
        </Dialog>
    );
};

UploadDialog.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.node,
    subheader: PropTypes.node,
    message: PropTypes.node,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default connect(mapStateToProps)(UploadDialog);
