import React  from 'react';
import {Grid, TextField,Typography, Button, Dialog, InputAdornment, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";   
import {ASSET_IMAGES} from "../../utils/constants/paths";
import { getAssetPath } from "../../utils/appHelpers";
import { useTranslation } from "react-i18next"; 
import PaymentsSharpIcon from '@mui/icons-material/PaymentsSharp';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import CardIconText from "@jumbo/shared/CardIconText";
import CardText from "@jumbo/shared/CardText";
import TransactionHistory from "../../shared/widgets/TransactionHistory"; 
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box"; 
import {setMenuName} from '../../store/actions'
import { authServices } from "../../services/auth/user";
import { financeServices } from "../../services/apis/finance";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query"; 
import Pagination from "@jumbo/components/Pagination";
import { makeStyles } from "@mui/styles"; 
import {getDateElements, getDateElements_Custom} from "@jumbo/utils";
import { store } from "../../store/store";
import {  useLocation, useNavigate } from "react-router-dom";
const useStyles = makeStyles({ 
    pagination: {
        padding: '0px ', 
        borderRadius: '6px',
        textAlign: 'center',
        display: 'table',
        margin: '0 auto',  
        width: '100%',
        marginTop: '10px',
    }
});
const Finance = () => { 
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [withdrawOpen, setWithdrawOpen] = React.useState(false);    
    const [scroll, setScroll] = React.useState('paper'); 
    const classes = useStyles();
    const current = new Date();
    const [paymentAmount, setPaymentAmount] = React.useState('');
    const [nameOfCard, setNameOfCard] = React.useState('');
    const [cardNo, setCardNo] = React.useState('');
    const [expiryMonth, setExpiryMonth] = React.useState('');
    const [expiryYear, setExpiryYear] = React.useState('');
    const [cvv, setCvv] = React.useState('');
    const [errorCard, setErrorCard] = React.useState('');
    const [errorAmt, setErrorAmt] = React.useState('');
    const [errorCardNo, setErrorCardNo] = React.useState('');
    const [errorExpiryMonth, setErrorExpiryMonth] = React.useState(''); 
    const [errorExpiryYear, setErrorExpiryYear] = React.useState('');
    const [errorCvv, setErrorCvv] = React.useState('');
    const [withdrawAmount, setWithdrawAmount] = React.useState('');
    const [narration, setNarration] = React.useState('');
    const [errorWithdrawAmt, setErrorWithdrawAmt] = React.useState(''); 
    const [btnDisable, setBtnDisable] = React.useState(false); 
    const location = useLocation();
    const navigate = useNavigate();
    const loginDetails = store.getState().loginDetails;
    const { enqueueSnackbar } = useSnackbar();
    const limit = 10;
    let pageSize = 10; 
    const [offset, setOffset] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1); 
    const [financesList, setFinancesList] = React.useState([]);
    const [balance, setBalance] = React.useState([]);

    const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, offset, limit ]; 
    const {data: finances, refetch: refetchFinanceList } = useQuery(
        userDetails, financeServices.financeList
    ); 
    React.useEffect(() => { 
        if (finances && finances?.financeList?.length > 0) {
            setFinancesList(finances?.financeList); 
            setBalance(finances?.balance);
        }  
    }, [finances]);
    React.useEffect(() => { 
        if (location?.state?.frompage) {
            if (location?.state?.frompage === "marketplace_topup") {
                setOpen(true);
                setScroll('paper'); 
                setPaymentAmount(location?.state?.paymentAmt);
            } 
        } 
    }, [location]);

    const handlePagination = (page) => {
        let offsetVal = page * pageSize - pageSize;
        setOffset(offsetVal);
        setCurrentPage(page); 
        refetchFinanceList().catch(console.error);
    }
    store.dispatch(setMenuName(t('sidebar.menu.finance')));   
    const handleJoinNow = (e) => {
        clearData();
        setOpen(true)
        setScroll('paper')
        e.stopPropagation()       
    };
    const handleWithdraw = (e) => {
        setBtnDisable(false); 
        setErrorWithdrawAmt('');
        setWithdrawAmount('');
        setNarration('');
        setWithdrawOpen(true)
        setScroll('paper')
        e.stopPropagation()       
    };
    
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    //Topup wallet
    const mutation = useMutation(financeServices.topUp, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" }); 
                clearData();
                setBtnDisable(false);
                setOpen(false);
                refetchFinanceList().catch(console.error);
                if (location?.state?.frompage === "marketplace_topup") {
                    navigate("/marketplace");
                }
                return;
           }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });
    const clearData = () => {
        setPaymentAmount('');
        setNameOfCard('');
        setCardNo('');
        setExpiryMonth('');
        setExpiryYear('');
        setCvv('');
    }
    //Topup amount
    const handlePayment = async () => {
        setBtnDisable(true);
        if (!paymentAmount) {
            setBtnDisable(false);
            setErrorAmt(t('finance.amtError'));
            return;
        } 
        if (!nameOfCard) {
            setErrorAmt('');
            setBtnDisable(false);
            setErrorCard(t('finance.cardNameError'));
            return;
        }
        if (!cardNo || cardNo.length < 16) {
            setBtnDisable(false);
            setErrorAmt(''); 
            setErrorCard('');
            setErrorCardNo(t('finance.cardNoError'));
            return;
        }
        if (!expiryMonth || expiryMonth > 12 || (expiryMonth < parseInt(getDateElements_Custom(current).date.month) && expiryYear === getDateElements(current).date.year)) { 
            setBtnDisable(false);
            setErrorAmt(''); 
            setErrorCard('');
            setErrorCardNo('');
            setErrorExpiryMonth(t('finance.monthError'));
            return;
        }
        if (!expiryYear || expiryYear < getDateElements(current).date.year) {
            setBtnDisable(false);
            setErrorAmt(''); 
            setErrorCard('');
            setErrorCardNo('');
            setErrorExpiryMonth('');
            setErrorExpiryYear(t('finance.yearError'));
            return;
        }
       
        if (!cvv || cvv.length < 3) {
            setBtnDisable(false);
            setErrorAmt(''); 
            setErrorCard('');
            setErrorCardNo('');
            setErrorExpiryMonth('');
            setErrorExpiryYear('');
            setErrorCvv(t('finance.cvvError'));
            return;
        }
        setBtnDisable(true);
        setErrorAmt(''); 
        setErrorCard('');
        setErrorCardNo('');
        setErrorExpiryMonth('');
        setErrorExpiryYear('');
        setErrorCvv('');
        mutation.mutate({
            amount: paymentAmount.toString(),
            cardHolder: nameOfCard,
            cardNo: cardNo,
            expiryMonth: expiryMonth,
            expiryYear: expiryYear, 
            cvv: cvv,  
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,
            ipAddress: await authServices.getMyIpAddress()
        });
    }
    //Withdraw
    const withDrawMutation = useMutation(financeServices.withdraw, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
        }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" }); 
                setBtnDisable(false);
                setWithdrawOpen(false);
                setErrorWithdrawAmt('');
                setWithdrawAmount('');
                setNarration('');
                refetchFinanceList().catch(console.error);
                return;
        }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });
    //Withdraw amount
    const handleWithdrawAction = async () => {
        setBtnDisable(true);
        if (!withdrawAmount) {
            setBtnDisable(false);
            setErrorWithdrawAmt(t('finance.amtError'));
            return;
        }  
        if (parseFloat(withdrawAmount) > parseFloat(balance)) {
            setBtnDisable(false);
            setErrorWithdrawAmt(t('finance.amtError'));
            return;
        }
        setErrorWithdrawAmt('');
        withDrawMutation.mutate({
            withdrawAmount: withdrawAmount,
            narration: narration, 
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,
            ipAddress: await authServices.getMyIpAddress()
        });
    }
    React.useEffect(() => {
        localStorage.setItem("ChatPerson",'');
        localStorage.setItem("ChatPersonProfilePic", '');
        localStorage.setItem("ChatPersonOnlineStatus",'');
        localStorage.setItem("ChatPersonProfileID", '');
        window.scrollTo(0, 0)
    });
    return (
        <>  
        <Grid container spacing={3.75} alignItems={"center"}> 
            <Grid item xs={12} md={6} lg={3} >  
                <CardText
                    icon={  <img src={getAssetPath(`${ASSET_IMAGES}/secured-wallet.png`, "90x90")} style={{padding: '15px'}} alt={"Balance"}
                        width={102} />} 
                        color="#1d91c5"
                        title={balance ? "$"+balance : "$"+0}
                    />
            </Grid> 
            <Grid item xs={12} md={6} lg={3} > 
                
            </Grid>
            <Grid item xs={12} md={6} lg={3} > 
                <CardIconText
                    icon={<AddCardRoundedIcon fontSize={"large"}/>}
                    onHoverIcon={<PaymentIcon fontSize={"large"}/>}
                    title={t('finance.topup')} 
                    onClick={handleJoinNow}
                />
             </Grid>
            <Grid item xs={12} md={6} lg={3} >
                <CardIconText
                    icon={<PaymentsSharpIcon fontSize="large"/>}
                    onHoverIcon={<PaymentsOutlinedIcon fontSize="large"/>}
                    title={t('finance.withdraw')} 
                    color={"secondary.main"}
                    onClick={handleWithdraw}
                />
                
            </Grid>
            <Grid item xs={12} md={12} lg={12} >
                    <TransactionHistory scrollHeight={428} financeData={financesList}/>
                    {finances && parseInt(finances?.totalcount) > 10 && <div className={classes.pagination}>
                        <Pagination
                            currentPage={currentPage}
                            totalCount={finances ? parseInt(finances?.totalcount) : 0}
                            pageSize={pageSize}
                            primaryColor="#814DFA"
                            onPageChange={handlePagination} 
                        />
                    </div>}
            </Grid>
            </Grid>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    >
                
                    <DialogTitle id="scroll-dialog-title">
                    {t('finance.topup')}
                    </DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <Box component="form"
                        sx={{
                            mx: -1, 
                            '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                p: 1,
                                mb: 2,
                                width: {xs: '100%', sm: '100%'}
                            },
                            '& .MuiFormControl-root.MuiFormControl-fluid': {
                                width: '100%'
                            }
                        }}
                        autoComplete="off"
                >
                    <FormControl size="small">
                            <Grid container spacing={3.5}>                       
                                <Grid item xs={12} sm={3} lg={3} style={{marginTop: '3%'}}>
                                <Typography variant={"p"} mb={2}>Credit Card</Typography> 
                                </Grid> 
                                <Grid item xs={12} sm={9} lg={9}>
                                <div style={{display: 'flex', justifyContent:'center'}}>
                                    <img src={getAssetPath(`${ASSET_IMAGES}/visa.png`, "50x50")}
                                    alt={"Visa"}   width={50} style={{marginRight: '4%'}}/>
                                    <img src={getAssetPath(`${ASSET_IMAGES}/amex.png`, "50x50")}
                                    alt={"Amex"}   width={50} style={{marginRight: '4%'}}/>
                                    <img src={getAssetPath(`${ASSET_IMAGES}/paypal.png`, "50x50")}
                                    alt={"Amex"}   width={50} style={{marginRight: '4%'}}/>
                                    <img src={getAssetPath(`${ASSET_IMAGES}/jcb.png`, "50x50")}
                                    alt={"Visa"}   width={50} style={{marginRight: '4%'}}/>
                                    <img src={getAssetPath(`${ASSET_IMAGES}/visa.png`, "50x50")}
                                    alt={"Visa"}   width={50} style={{marginRight: '4%'}}/>
                                    
                                 </div>
                                </Grid>                               
                            </Grid>                                                       
                        </FormControl> 
                        <FormControl size="small">
                            <Grid container spacing={3.5}>     
                                <Grid item xs={12} sm={3} lg={3}>
                                    <TextField
                                        fullWidth
                                        id="amount"
                                        label="Amount"
                                        size="small"  
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault()
                                            }
                                          }} 
                                        inputProps={{ maxLength: 8 }}   
                                        InputProps={{ 
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                        value={paymentAmount}
                                        onChange={e => setPaymentAmount(e.target.value)}
                                        error={errorAmt ? true : false}
                                        helperText={errorAmt ? errorAmt : ""}
                                        onBlur={(event) => {
                                            if (event.target.value ) {
                                                setErrorAmt('');
                                            } else {
                                                setErrorAmt(t('finance.amtError'));
                                            }
                                        }} 
                                    />
                                </Grid>                    
                                <Grid item xs={12} sm={9} lg={9}>
                                    <TextField
                                        fullWidth
                                        id="firstname"
                                        label="Name on card"
                                        size="small"
                                        inputProps={{ maxLength: 100 }}   
                                        value={nameOfCard}
                                        onChange={e => setNameOfCard(e.target.value)}
                                        error={errorCard ? true : false}
                                        helperText={errorCard ? errorCard : ""}
                                        onBlur={(event) => {
                                            if (event.target.value ) {
                                                setErrorCard('');
                                            } else {
                                                setErrorCard(t('finance.cardNameError'));
                                            }
                                        }} 
                                    />
                                </Grid>                                                              
                            </Grid>                                                       
                        </FormControl>                      
                        <FormControl>
                            <Grid container spacing={3.5}>                                                     
                                <Grid item xs={12} sm={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        id="cardnumber"
                                        label="Card Number"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault()
                                            }
                                            }} 
                                        size="small"
                                        inputProps={{ maxLength: 16 }}  
                                        value={cardNo}
                                        onChange={e => setCardNo(e.target.value)}
                                        error={errorCardNo ? true : false}
                                        helperText={errorCardNo ? errorCardNo : ""}
                                        onBlur={(event) => {
                                            if (event.target.value && event.target.value.length >= 16) {
                                                setErrorCardNo('');
                                            } else {
                                                setErrorCardNo(t('finance.cardNoError'));
                                            }
                                        }}     
                                    />
                                </Grid>                              
                            </Grid>    
                        </FormControl> 
                       
                        <FormControl size="small">
                            <Grid container spacing={3.5}>                                                 
                                <Grid item xs={12} sm={6} lg={6}> 
                                    <Grid container spacing={3.5}> 
                                    <Grid item xs={12} sm={6} lg={6}>
                                    <TextField
                                        fullWidth
                                        id="month"
                                        label="Expiry Month"
                                        size="small"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault()
                                            }
                                        }} 
                                        inputProps={{ maxLength: 2 }}
                                        value={expiryMonth}
                                        onChange={e => setExpiryMonth(e.target.value)}
                                        error={errorExpiryMonth ? true : false}
                                        helperText={errorExpiryMonth ? errorExpiryMonth : ""}
                                        onBlur={(event) => {
                                            if (event.target.value && event.target.value <= 12) { 
                                                setErrorExpiryMonth('');
                                            } else { 
                                                setErrorExpiryMonth(t('finance.monthError'));
                                            }
                                            if (expiryYear === getDateElements(current).date.year && event.target.value < parseInt(getDateElements_Custom(current).date.month)) {
                                                setErrorExpiryMonth(t('finance.monthError')); 
                                            }else { 
                                                setErrorExpiryMonth('');
                                            }
                                        }} 
                                    />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={6}>
                                    <TextField
                                          fullWidth
                                          id="year"
                                          label="Expiry Year"
                                          size="small"
                                          onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault()
                                                }
                                          }} 
                                            inputProps={{ maxLength: 4 }}   
                                            value={expiryYear}
                                            onChange={e => setExpiryYear(e.target.value)}
                                            error={errorExpiryYear ? true : false}
                                            helperText={errorExpiryYear ? errorExpiryYear : ""}
                                            onBlur={(event) => {
                                            if (event.target.value && event.target.value >= getDateElements(current).date.year  ) {
                                                    setErrorExpiryYear('');
                                                } else {
                                                    setErrorExpiryYear(t('finance.yearError'));
                                                }

                                                if (event.target.value === getDateElements(current).date.year && expiryMonth < parseInt(getDateElements_Custom(current).date.month)) {
                                                    setErrorExpiryMonth(t('finance.monthError')); 
                                                }else { 
                                                    setErrorExpiryMonth('');
                                                }
                                            }} 
                                    />
                                    </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} lg={4}>
                                <Typography variant={"p"} color={'text.primary'}></Typography>
                                    <TextField
                                        fullWidth
                                        id="cvv"
                                        label="CVV"
                                        size="small"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault()
                                            }
                                        }} 
                                        inputProps={{ maxLength: 3 }}   
                                        value={cvv}
                                        onChange={e => setCvv(e.target.value)}
                                        error={errorCvv ? true : false}
                                        helperText={errorCvv ? errorCvv : ""}
                                        onBlur={(event) => {
                                        if (event.target.value && event.target.value.length >= 3) {
                                                setErrorCvv('');
                                            } else {
                                                setErrorCvv(t('finance.cvvError'));
                                            }
                                        }} 
                                    />
                                </Grid>
                            </Grid>                                                       
                        </FormControl>                     
                    </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={() => setOpen(false)}>{t('common.cancel')}</Button>
                        <Button variant={"contained"} disabled={btnDisable} style={{textTransform: 'none'}} onClick={() => handlePayment()}>{t('finance.topup')}</Button>
                    </DialogActions>
                </Dialog>

                {/* Withdraw Popup */}
                <Dialog
                    open={withdrawOpen}
                    onClose={() => setWithdrawOpen(false)}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    >
                
                    <DialogTitle id="scroll-dialog-title">
                    {t('finance.withdraw')}
                    </DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <Box component="form"
                                 sx={{
                                    mx: -1, 
                                    '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                        p: 1,
                                        mb: 2,
                                        width: {xs: '100%', sm: '100%'}
                                    },
                                    '& .MuiFormControl-root.MuiFormControl-fluid': {
                                        width: '100%'
                                    }
                                }}
                                autoComplete="off"
                            >
                        <FormControl size="small">
                            <Grid container spacing={3.5}>                       
                                <Grid item xs={12} sm={3} lg={3}>
                                <Typography variant={"h4"}  >Balance</Typography> 
                                </Grid> 
                                <Grid item xs={12} sm={9} lg={9}>
                                    <Typography variant={"h4"} >{"$"+balance}</Typography> 
                                </Grid>                               
                            </Grid>                                                       
                        </FormControl> 
                        <FormControl size="small">
                            <Grid container spacing={3.5}>     
                                <Grid item xs={12} sm={3} lg={3}>
                                    <TextField
                                        fullWidth
                                        id="amount"
                                        label="Amount"
                                        size="small"  
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault()
                                            }
                                          }} 
                                        inputProps={{ maxLength: 8 }}   
                                        InputProps={{ 
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                        value={withdrawAmount}
                                        onChange={e => setWithdrawAmount(e.target.value)}
                                        error={errorWithdrawAmt ? true : false}
                                        helperText={errorWithdrawAmt ? errorWithdrawAmt : ""}
                                        onBlur={(event) => {
                                            if (event.target.value  && parseFloat(event.target.value) <= parseFloat(balance)) {
                                                setErrorWithdrawAmt('');
                                            } else {
                                                setErrorWithdrawAmt(t('finance.amtError'));
                                            }
                                        }} 
                                    />
                                </Grid>                    
                                <Grid item xs={12} sm={9} lg={9}>
                                    <TextField
                                        fullWidth
                                        id="narration"
                                        label="Narration"
                                        size="small"
                                        inputProps={{ maxLength: 200 }}   
                                        value={narration}
                                        onChange={e => setNarration(e.target.value)} 
                                    />
                                </Grid>                                                              
                            </Grid>                                                       
                        </FormControl>                      
                    </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={() => setWithdrawOpen(false)}>{t('common.cancel')}</Button>
                        <Button variant={"contained"} disabled={btnDisable} style={{textTransform: 'none'}} onClick={() => handleWithdrawAction()}>{t('finance.withdraw')}</Button>
                    </DialogActions>
                </Dialog>
        </>
    );
};

export default Finance;
