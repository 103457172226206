 // USER MESSAGE
 export const USER = { 
     PASSWORD_VALIDATION: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/, 
     MAIL_VALIDATION : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}

// USERROLECODES
export const USERROLECODE = {
    student: 1,
    tutor: 2
}

// USERROLES 
export const USERROLES = [
    {userRoleCode: 1, userRoleName: 'Student'},
    {userRoleCode: 2, userRoleName: 'Tutor'}
]

export const BUCKET_FOLDERS = {
    FILES: 'file'
}

export const maxUploadFileSize = 50000000
export const videoFiles = ['WEBM','MPG', 'MP2', 'MPEG', 'MPE', 'MPV','OGG','MP4', 'M4P', 'M4V','MOV', 'QT','FLV', 'SWF','AVCHD']
