import React from "react";
import menuRoutes from "./menuRoutes";   
import authRoutes from "./authRoutes"; 
import classesRoutes from "./classesRoutes";  
// import LockScreen from "../pages/auth-pages/lock-screen";
import Login2 from "../pages/auth-pages/login2";


const routes = [
    {
        path:
            "/",
        element:  <Login2/>
    },
    ...menuRoutes, 
    ...authRoutes,
    ...classesRoutes

];

export default routes;
