import React from "react";
import {IconButton} from "@mui/material";
import { 
  CallEndRounded
} from "@mui/icons-material";
import { classServices } from "../../../../../services/apis/classes"; 
import { useMutation } from "react-query";
import { store } from "../../../../../../app/store/store"; 
const LeaveRoomButton = ({ room }) => { 
  const roomClassCode = store.getState().roomClassCode;
  const roomLessonCode = store.getState().roomLessonCode;
  const roomStudentCode = store.getState().roomStudentCode;
  const mutation = useMutation(classServices.disconnetClass, {
    onSuccess: (data) => { 
        if (data?.statusFlag === 1) { 
          room.disconnect();
          window.close();
       }           
    },
    onError: (error) => {
         
    }
});
  const handleRoomDisconnection = () => {
    const data = {isCreditAmount:true, userCode: roomStudentCode ? roomStudentCode : 0,classCode:roomClassCode ? roomClassCode : 0,lessonCode:roomLessonCode ? roomLessonCode : 0  }  
     mutation.mutate(data); 
  };

  return (
    <div className="video_button_container">
       <IconButton  onClick={handleRoomDisconnection}  style={{background: "red"}}>
           <CallEndRounded style={{color:"#fff"}}/> 
       </IconButton> 
    </div>
  );
};

export default LeaveRoomButton;
