import React, {useState} from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {ListItem, ListItemIcon, ListItemText, Typography, TextField, Avatar, Button, Grid, Link} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"; 
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined"; 
import {useTranslation} from "react-i18next";   
import {Edit} from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';  
import { USER } from '../../../../../utils/constants/constants';  
import { authServices } from "../../../../../services/auth/user";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query"; 
import { setLoginDetails, setCredentials, setProfile,setMenuName} from '../../../../../store/actions'
import { store } from "../../../../../store/store";
import S3 from 'react-aws-s3';  
import { connect } from "react-redux";
var aws = require('aws-sdk');
window.Buffer = window.Buffer || require("buffer").Buffer;
const Contacts = (props) => {
    const { credentials } = props; 
    const { t } = useTranslation();  
    const [showProfile, setshowProfile] = React.useState(false);
    const [firstName, setFirstName] = React.useState('');
    const [fileData, setFileData] = React.useState(null);
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobileNo, setMobileNo] = React.useState('');
    const [dob, setDob] = React.useState('');  
    const [imgData, setImgData] = useState(null);
    const [errorFn, setErrorFn] = useState('');
    const [errorLn, setErrorLn] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [errorMobileNo, setErrorMobileNo] = useState(''); 
    const [errorDate, setErrorDate] = useState(''); 
    
    const { enqueueSnackbar } = useSnackbar();
    const mail_validation = USER.MAIL_VALIDATION;
    const {data: result, refetch: refetchAuthDetails  } = useQuery(
        [], authServices.retrieveUserDetails
    );  
    const loginDetails = store.getState().loginDetails;
    const credentialDetails = credentials;
    store.dispatch(setMenuName("Profile")); 
    
    React.useEffect(() => {   
        store.dispatch(setLoginDetails(result?.userDetails));
        store.dispatch(setCredentials(result?.credential));
        if (result?.userDetails?.defaultRole) {
            store.dispatch(setProfile(result?.userDetails?.roleCode)); 
            localStorage.setItem("switchprofile", result?.userDetails?.roleCode)
        }
    }, [result]); 
      const config = {
        bucketName: credentialDetails?.s3bucket,
        dirName: 'profile', /* optional */
        region: credentialDetails?.region,
        accessKeyId: credentialDetails?.awsAccessKey,
        secretAccessKey: credentialDetails?.awsSecretKey, 
    }
    const onChangePicture = async (e) => {
        if (e.target.files[0]) {  
            if (e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/svg" || e.target.files[0].type === "image/jpeg") {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setImgData(reader.result);
                });
                reader.readAsDataURL(e.target.files[0]);
                setFileData(e.target.files[0]);
            } else {
                enqueueSnackbar("Accept only jpg,png and svg", { variant: "warning" });
                return;
            }
        }
    };
  
   
    const handleShowProfile = () => {
        if (showProfile) {
            setDob('')
            setshowProfile(false)
        } else { 
            setshowProfile(true)
        } 
    };

    React.useEffect(() => {
        if (loginDetails) {
            setFirstName(loginDetails.firstName);
            setLastName(loginDetails.lastName);
            setEmail(loginDetails.email);
            setMobileNo(loginDetails.mobileNumber);
            setDob(loginDetails.dateOfBirth ? loginDetails.dateOfBirth : '');
            setImgData(loginDetails.profileUrl ? loginDetails.profileUrl : '')
       }
    }, [loginDetails]);
    // for date formating
    function formatDate(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    function formatedDate(date) {
        var format_date = date.split("-"); 
        return [format_date[2], format_date[1], format_date[0]].join('-');
    }
    //Update user details
    const mutation = useMutation(authServices.updateUser, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" }); 
                refetchAuthDetails();
                handleShowProfile();
                return;
           }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });
    //Handle update
    const handleUpdate = async() => {
        if (!firstName) {
            setErrorFn(t('signup.errorFN'));
            return;
        }
        if (!lastName) {
            setErrorFn('');
            setErrorLn(t('signup.errorLN'));
            return;
        }
        if (!email || !mail_validation.test(email)) {
            setErrorFn('');
            setErrorLn('');
            setErrorEmail(t('signup.errorEmail'));
            return;
        }
        if (!mobileNo || mobileNo.length < 8) {
            setErrorFn('');
            setErrorLn('');
            setErrorEmail(''); 
            setErrorMobileNo(t('signup.errorMobileNo'));
            return;
        }
        setErrorFn('');
        setErrorLn('');
        setErrorEmail(''); 
        setErrorMobileNo('');  
        if (fileData) {
            const fileName = 'user_img_' + loginDetails?.userCode + "_" + Date.now();
            const ReactS3Client = new S3(config); 
            if (loginDetails?.profileUrl) { 
                //Delete old file
                const s3 = new aws.S3({
                    accessKeyId: credentialDetails?.awsAccessKey,
                    secretAccessKey: credentialDetails?.awsSecretKey,
                    Bucket: credentialDetails?.s3bucket,
                    region: credentialDetails?.region
                });
                await s3.deleteObject({ Bucket: credentialDetails?.s3bucket, Key: 'profile/' + loginDetails?.profileUrl?.split("/")[4] }, (err, data) => { 
                    console.log(data);
                });
            } 
            //Upload Image in s3 Url
             //Upload new file
             await ReactS3Client
             .uploadFile(fileData, fileName)
                 .then(data => {
                    mutation.mutate({
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        mobileNumber: mobileNo,
                        dateOfBirth: dob,
                        imagePath: data?.location ? data?.location : "",
                        userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
                        userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,
                        ipAddress: ""
                    });
             })
             .catch(err => console.error(err, 'error'))
        } else {
            mutation.mutate({
                firstName: firstName,
                lastName: lastName,
                email: email,
                mobileNumber: mobileNo,
                dateOfBirth: dob,
                imagePath: loginDetails?.profileUrl ? loginDetails?.profileUrl : "",
                userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
                userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,
                ipAddress: await authServices.getMyIpAddress()
            });
        }
       
    }
    
    function isInThePast(date) {
        const today = new Date(); 
        today.setHours(0, 0, 0, 0); 
        return date < today;
      }
    return (
        <JumboCardQuick noWrapper>
                {!showProfile && (
                <Grid container style={{padding: "1% 2% 0 2%"}}>               
                    <Grid item xs={6} md={6} lg={6}>                    
                       <Typography variant="h1" mb={3}>{t('profile.contact')}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} style={{textAlign: 'right'}}>                    
                        <Button
                        disableElevation
                        variant={"contained"}
                        startIcon={<Edit/>}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem'
                            },
                            textTransform: 'none'
                        }}
                        onClick={handleShowProfile}
                    >Edit Profile</Button> 
                    </Grid>                   
                </Grid> )}
                {!showProfile && (
                <Grid container style={{marginBottom: "3%"}}>
                    {loginDetails?.dateOfBirth && (<Grid xs={12} md={12} lg={4}>
                        <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                                <CakeOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="body1" color="text.secondary">{t('profile.dateofbirth')}</Typography>}
                                secondary={<Link variant="body1" href="#" underline="none">{loginDetails?.dateOfBirth ? formatedDate(loginDetails?.dateOfBirth) : ''}</Link>}
                            />
                        </ListItem>
                    </Grid>)}
                    <Grid xs={12} md={12} lg={4}>
                        <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                        <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                            <EmailOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="body1" color="text.secondary">{t('profile.mail')}</Typography>}
                            secondary={<Link variant="body1" href="#" underline="none">{loginDetails?.email ? loginDetails?.email : ''}</Link>}
                        />
                    </ListItem>
                    </Grid>
                    <Grid xs={12} md={12} lg={4}>
                        <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                            <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                                <LocalPhoneOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="body1" color="text.secondary">{t('profile.phone')}</Typography>}
                                secondary={<Typography variant="body1" color="text.primary">{loginDetails?.mobileNumber ? loginDetails?.mobileNumber : ''}</Typography>}
                            />
                        </ListItem>
                    </Grid>
                </Grid>   
                )}
                {showProfile && (
                    <Grid container style={{marginBottom: "3%", padding: "3%"}}>
                        <Grid xs={12} md={6} lg={6}>                  
                        <Div sx={{ mt: 1, mb: 3 , display: 'flex'}}>
                            <TextField
                                fullWidth
                                id="firstname"
                                label={t('signup.fName')}
                                autoComplete='off'
                                value={firstName}
                                inputProps={{ maxLength: 20 }}   
                                onChange={e => setFirstName(e.target.value)}
                                error={errorFn ? true : false}
                                helperText={errorFn ? errorFn : ""}
                                onBlur={(event) => {
                                    if (event.target.value) {
                                        setErrorFn('');
                                    } else {
                                        setErrorFn(t('signup.errorFN'));
                                    }
                                }} 
                            />
                            <TextField
                                fullWidth
                                id="lastname"
                                value={lastName}
                                label={t('signup.lName')}
                                sx={{ ml: 1 }}
                                autoComplete='off'
                                inputProps={{ maxLength: 20 }} 
                                error={errorLn ? true : false}
                                onChange={e => setLastName(e.target.value)}
                                helperText={errorLn ? errorLn : ""}
                                onBlur={(event) => {
                                    if (event.target.value) {
                                        setErrorLn('');
                                    } else {
                                        setErrorLn(t('signup.errorLN'));
                                    }
                                }} 
                            />
                        </Div>
                        <Div sx={{mt: 1, mb: 3}}>
                            <TextField
                                fullWidth
                                id="email"
                                value={email}
                                label={t('signup.email')}
                                autoComplete='off'
                                disabled={true}
                                inputProps={{ maxLength: 200 }}  
                                onChange={e => setEmail(e.target.value)}
                                error={errorEmail ? true : false}
                                helperText={errorEmail ? errorEmail : ""}
                                onBlur={(event) => {
                                    if (event.target.value && mail_validation.test(event.target.value)) {
                                        setErrorEmail('');
                                    } else {
                                        setErrorEmail(t('signup.errorEmail'));
                                    }
                                  }} 
                            />
                        </Div>
                        
                        <Div sx={{mt: 1, mb: 2}}>
                            <TextField
                                fullWidth
                                id="mobileno"
                                value={mobileNo}
                                inputProps={{ maxLength: 8 }}
                                label={t('signup.mobileNo')}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault()
                                    }
                                  }} 
                                onChange={e => setMobileNo(e.target.value)}
                                error={errorMobileNo ? true : false}
                                helperText={errorMobileNo ? errorMobileNo : ""}
                                onBlur={(event) => {
                                    if (event.target.value && event.target.value.length >= 8) {
                                        setErrorMobileNo('');
                                    } else {
                                        setErrorMobileNo(t('signup.errorMobileNo'));
                                    }
                                  }} 
                               
                            />
                        </Div>
                        <Div sx={{ mt: 1, mb: 2 }}> 

                        <TextField
                            fullWidth
                            id="dob"
                            label={t('signup.dob')}
                            value={dob}
                            type="date" 
                            autoComplete='off' 
                            InputProps={{ inputProps: { max: formatDate(new Date()) } }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => setDob(e.target.value)}
                            error={errorDate ? true : false}
                            helperText={errorDate ? errorDate : ""}
                            onBlur={(event) => {
                                if (!isInThePast(new Date(event.target.value))) {
                                    setErrorDate(t('class.errorValidDate'));
                                } else {
                                    setErrorDate(''); 
                                }
                            }} 
                              
                        />
                        </Div>               
                    </Grid>
                    <Grid xs={12} md={6} lg={6} style={{ display: 'flex', justifyContent: 'center'}}> 
                        <Div
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 3
                                },
                            }}
                        >
                                <Avatar
                                sx={{ width: 72, height: 72 }}
                                alt={"User"}
                                src={imgData}
                            />
                            <input style={{marginTop: '5px'}} id="profilePic" type="file" accept="image/*" placeholder="Upload Image" onChange={onChangePicture} /> 
                             </Div>                        
                        </Grid>
                        <Grid container>
                         <Grid xs={12} md={12} lg={12}>   
                            <Div sx={{textAlign: 'right'}}>
                            <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={handleShowProfile}>{t('common.cancel')}</Button>

                            <Button variant="contained" sx={{textTransform : 'none'}} onClick={handleUpdate}>{t('common.update')}</Button>
                        </Div></Grid>
                        </Grid>
                    </Grid>
                   
                )}         
        </JumboCardQuick>
    );
};
const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
};
export default connect(mapStoreStateToProps)(Contacts);
// export default Contacts;
