import React, { useState} from 'react';
import Div from "@jumbo/shared/Div";
import {Typography} from "@mui/material";  
import ConversationItem from "../ConversationItem"; 
import { store } from '../../../../../store/store';
import { classServices } from "../../../../../services/apis/classes"; 
import { BASE_AUTH_URL } from "../../../../../utils/constants/paths";
import {  useQuery } from "react-query";
import {TextField, Grid} from "@mui/material";
import { connect } from "react-redux";
import { setRefreshChat } from '../../../../../store/actions';

const RecentConversationsList = (props) => {
    const {loginDetails, chatRefresh, chatpersonid, fromPage} = props
    const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0]; 
    const {data: users, refetch: refetchContactList} = useQuery(
        userDetails, classServices.contactUser
    );   
    const [contacts, setContacts] = useState([])
    const [fullcontacts, setFullContacts] = useState([])
    const handleContactSearch = (search) => {  
        const contact_List = Object.assign([], fullcontacts)
        let contactList = [];
        if (fullcontacts && fullcontacts.length > 0) { 
            contactList =  contact_List.filter(({ username }) => username.toLowerCase().trim().includes(search.toLowerCase().trim())); 
            setContacts(contactList)
        } else {
            setContacts(fullcontacts)
        }
       
    }
    React.useEffect(() => {
        if(users?.contacts && users?.contacts.length > 0) {
            setContacts(users?.contacts)
            setFullContacts(users?.contacts)
        }        
    }, [users])

    const MINUTE_MS = 30000;

    React.useEffect(() => {
      const interval = setInterval(() => {
          console.log('Logs every minute for chat refresh');
          refetchContactList().catch(console.error) 
      }, MINUTE_MS);
    
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [refetchContactList])

    React.useEffect(() => {
        if(chatRefresh && chatRefresh === true) {
            refetchContactList().catch(console.error) 
            store.dispatch(setRefreshChat(false)); 
        }        
    }, [chatRefresh])
   
    // React.useEffect(() => {
    //     if (chatpersonid) {
    //         setSeenUpdate(true)
    //         refetchContactList().catch(console.error) 
    //     }
    // }, [chatpersonid])

    return (
        <React.Fragment>
            {fromPage === "chat" && 
            <Div sx={{ pl: 2, pr: 2, pb: 1 }}>
                <TextField placeholder={"Search contacts..."} size={"small"} fullWidth 
                onChange={e => handleContactSearch(e.target.value)}/>
            </Div>}
            {fromPage === "chat" && 
            <Div
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: theme => theme.spacing(1.5, 2),
                    bgcolor: theme => theme.palette.action.hover,
                }}
            >                
                <Typography
                    sx={{letterSpacing: 1.5, textTransform: 'uppercase'}}
                    variant="h6"
                    color="text.secondary" mb={0}
                >
                    <small>Contacts</small>
                </Typography>
            </Div>}
            {
               contacts && contacts.length > 0 ?  contacts?.map((row, index) => {
                    return (
                        <ConversationItem conversationItem={row}/>
                    )
                }) : (<Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                <Typography variant={"p"} mb={.5} >No Contacts Found</Typography>
                </Grid>)
            } 

            
        </React.Fragment>

    );
};

const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
};
export default connect(mapStoreStateToProps)(RecentConversationsList); 
