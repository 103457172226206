import React, { useState } from "react";
import { connect } from "react-redux";
import {
  setConnectOnlyWithAudio,
  setIdentity,
  setRoomId,
} from "../../../../store/actions";
import JoinRoomInputs from "./JoinRoomInputs";
import OnlyWithAudioCheckbox from "./OnlyWithAudioCheckbox";
import RoomNotFoundMessage from "./RoomNotFoundMessage";
import JoinRoomButtons from "./JoinRoomButtons";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { checkIfRoomExists } from "../utils/twilioUtils";
import { USERROLECODE } from '../../../../utils/constants/constants';
import { classServices } from "../../../../services/apis/classes"; 
import { useMutation } from "react-query";
import { setRoomClassCode, setRoomLessonCode, setRoomStudentCode } from '../../../../../app/store/actions';
import { store } from "../../../../../app/store/store";
const JoinRoomContent = (props) => {
  const {
    isRoomHost,
    setConnectOnlyWithAudioAction,
    connectOnlyWithAudio,
    setRoomIdAction,
    setIdentityAction,
    setShowLoadingOverlay,
    data,
    classCode,
    loginCode,
    roleCode,
    perLessonPrice,
    lessonCode
  } = props; 
  const [roomIdValue, setRoomIdValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [showRoomNotFoundMessage, setShowRoomNotFoundMessage] = useState(false);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (data && data.length > 0) { 
      setRoomIdValue(data ? data[0].roomId : "")
      setNameValue(data ? data[0].userName : "")
    }
   
  }, [data]);
  const mutation = useMutation(classServices.joinClass, {
    onSuccess: (data) => { 
        if (data?.statusFlag === 1) { 
            
       }           
    },
    onError: (error) => {
         
    }
});
  const handleJoinToRoom = async () => {
    if (roleCode === USERROLECODE.student) { 
      store.dispatch(setRoomClassCode(classCode.toString())); 
      store.dispatch(setRoomLessonCode(lessonCode.toString())); 
      store.dispatch(setRoomStudentCode(loginCode)); 
      const data = {userCode:loginCode? parseInt(loginCode): 0, userRoleCode: roleCode ? parseInt(roleCode) : 0, classCode: classCode ? parseInt(classCode) : 0, perLessonPrice: perLessonPrice.toString(),lessonCode: lessonCode.toString()}        
      mutation.mutate(data);
    }
    setIdentityAction(nameValue);
    if (!isRoomHost) {
      setShowLoadingOverlay(true);
      const roomExists = await checkIfRoomExists(roomIdValue);
      setShowLoadingOverlay(false);
      if (roomExists) {
        setRoomIdAction(roomIdValue);
        navigate("/room");
      } else {
        setShowRoomNotFoundMessage(true);
      }
    } else {
      setRoomIdAction(uuidv4());
      navigate("/room");
    }
  };
  
  return (
    <>
      <JoinRoomInputs
        roomIdValue={roomIdValue}
        setRoomIdValue={setRoomIdValue}
        nameValue={nameValue}
        setNameValue={setNameValue}
        isRoomHost={isRoomHost}
      />
      <OnlyWithAudioCheckbox
        setConnectOnlyWithAudio={setConnectOnlyWithAudioAction}
        connectOnlyWithAudio={connectOnlyWithAudio}
      />
      <RoomNotFoundMessage showRoomNotFoundMessage={showRoomNotFoundMessage} />
      <JoinRoomButtons
        isRoomHost={isRoomHost}
        handleJoinToRoom={handleJoinToRoom}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConnectOnlyWithAudioAction: (onlyWithAudio) =>
      dispatch(setConnectOnlyWithAudio(onlyWithAudio)),
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(JoinRoomContent);
