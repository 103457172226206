import React from "react";

const LoadingOverlay = () => {
  return (
    // <div className="loading_overlay_container">
    //   <div className="loading_overlay_loader"></div>
    // </div>
    <div class="loader">
    <svg class="circular-loader" viewBox="25 25 50 50">
      <circle class="loader-path" cx="50" cy="50" r="20"></circle>
    </svg>
  </div>
  );
};

export default LoadingOverlay;
