import React, {useState} from 'react'; 
import { makeStyles } from "@mui/styles"; 
import Pagination from "@jumbo/components/Pagination";
import { classServices } from "../../../services/apis/classes"; 
import { useQuery } from "react-query";
import {alpha, Avatar,  ListItemAvatar, ListItemText, Typography, Tooltip,  Grid, List, ListItemButton } from "@mui/material";
import {ASSET_AVATARS} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import { USERROLECODE } from '../../../utils/constants/constants'
import {useTranslation} from "react-i18next"; 

const useStyles = makeStyles({ 
    pagination: {
        padding: '0px ', 
        borderRadius: '6px',
        textAlign: 'center',
        display: 'table',
        margin: '0 auto',  
        width: '100%',
        marginTop: '10px',
    }
});
const SubmissionList = ({ classDetails, folderDetails, isSuccess, isSuccessful, loginDetails }) => {  
    const classes = useStyles();
    const {t} = useTranslation();
    const  limit  = 10 ;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); 
    const profilerole = Number(localStorage.getItem("switchprofile")); 

    const [fileItemSuccess, setFileItemSuccess] = useState(false); 
    let pageSize = 10; 

    const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, offset, 
        limit, classDetails ? Number(classDetails.classCode) : '', folderDetails ? Number(folderDetails.folderCode) : ''];  
    const {data: result, refetch: refetchSubmissionsList } = useQuery(
        userDetails, classServices.submissionsList
    );  
    const handlePagination = (page) => {
        let offsetVal = page * pageSize - pageSize;
        setOffset(offsetVal);
        setCurrentPage(page); 
        refetchSubmissionsList().catch(console.error) 
    }
   
    React.useEffect(() => {
        if (isSuccess || fileItemSuccess) {  
            refetchSubmissionsList().catch(console.error)
            setFileItemSuccess(false)  
            isSuccessful(false)
        }
    }, [isSuccess, refetchSubmissionsList, fileItemSuccess, isSuccessful]); 

    const handleDownloadFiles = (item, e) => {
        if (item && item?.filePath) {
            // const url = window.URL.createObjectURL(new Blob([item?.filePath]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', item?.fileName.concat(item?.fileType));
            // document.body.appendChild(link);
            // link.click();

            
            fetch(item?.filePath, {
                method: 'GET', 
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                'download',
                item.fileName+'.'+item.fileType,
                ); 
                // Append to html link element page
                document.body.appendChild(link); 
                // Start download
                link.click(); 
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
        }
        e.preventDefault();
    }

    return (
        <>                
            <List disablePadding sx={{mb: 1}}>
            {
                result?.submissionList?.length > 0 ? result?.submissionList?.map((item, index) => (                
                <ListItemButton
                    component={"li"}
                    // onClick={handleClick}
                    sx={{
                        p: theme => theme.spacing(1.25, 3),
                        transition: 'all 0.2s',
                        borderBottom: '1px solid #0000001a',
                        '&:hover': {
                            boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                            transform: 'translateY(-4px)', 
                            '& .MuiChip-animation': {
                                width: 'auto',
                                height: 'auto',
                                fontSize: 12,
                                display: 'block',
                            }
                        }
                    }}
                >
                    <Grid container spacing={3}>
                    <Grid item xs={8} md={8} lg={8} style={{display: 'flex'}}>
                    <ListItemAvatar sx={{ display: { xs: 'none', md: 'block' }}}> 
                        <Avatar src={item?.profileUrl} style= {{overflow: 'initial'}}/>                               
                    </ListItemAvatar>
                    <ListItemText                          
                        primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Typography variant={"h6"}>{item?.fullname}</Typography>
                                <Typography
                                    variant={"body1"}
                                    color={'text.secondary'}                                        
                                >
                                {item?.createdAt}
                                </Typography>                                   
                                </Typography>
                        }
                        sx={{flex: 1}}
                    />

                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <Tooltip title="Download" style={{float: 'right'}}>
                            <ListItemAvatar sx={{ display: { xs: 'none', md: 'block' }}} onClick={(e) => handleDownloadFiles(item, e)}>                    
                            {item.fileType === 'txt' && (
                                <img alt="txt" src={getAssetPath(`${ASSET_AVATARS}/txt.png`,"40x40")} style={{width: '60px', height: '60px'}} />                         
                            )}
                            {(item.fileType === 'docx' || item.fileType === 'doc') && (
                                <img alt="docx" src={getAssetPath(`${ASSET_AVATARS}/doc.png`,"40x40")} style={{width: '60px', height: '60px'}} />                         
                            )}
                            {(item.fileType === 'xls' || 
                            item.fileType === 'csv' || item.fileType === 'xlsx') && (                        
                                <img  alt="xls" src={getAssetPath(`${ASSET_AVATARS}/xls.png`,"40x40")} style={{width: '60px', height: '60px'}}/>
                            )}
                            {(item.fileType === 'png' || 
                            item.fileType === 'jpg' ||  item.fileType === 'jpeg') && (                        
                                <img alt="images" src={getAssetPath(`${ASSET_AVATARS}/image.png`,"40x40")} style={{width: '60px', height: '60px'}}/>
                            )}
                            {item.fileType === 'pdf' && (                       
                                <img alt="pdf" src={getAssetPath(`${ASSET_AVATARS}/pdf.png`,"40x40")} style={{width: '60px', height: '60px'}}/>
                            )}
                            {item.fileType === 'ppt' && (                        
                                <img alt="ppt" src={getAssetPath(`${ASSET_AVATARS}/ppt.png`,"40x40")} style={{width: '60px', height: '60px'}}/>
                            )}     
                             { (item.fileType !== 'ppt' && item.fileType !== 'pdf' && item.fileType !== 'png' &&   item.fileType !== 'jpg' &&  item.fileType !== 'jpeg' && item.fileType !== 'xls' && item.fileType !== 'csv' && item.fileType !== 'xlsx' && item.fileType !== 'docx' && item.fileType !== 'doc' && item.fileType !== 'txt'   ) && <Avatar alt={item.fileType} src={getAssetPath(`${ASSET_AVATARS}/files.png`, "40x40")} style={{ overflow: 'initial' }} /> }            
                            </ListItemAvatar>
                        </Tooltip>
                    </Grid>
                    </Grid>                                       
                </ListItemButton> 
                )) : <div>
                
                {profilerole === USERROLECODE.tutor && (
                <Typography variant={"h6"} mt={2} style={{ textAlign: 'center' }}>{t('class.noFilesTutor')}</Typography> )
                }</div>
            }
           
            </List>  
            {result && parseInt(result?.totalcount) > 10 && <div className={classes.pagination}>
                <Pagination
                    currentPage={currentPage}
                    totalCount={result ? parseInt(result?.totalcount) : 0}
                    pageSize={pageSize}
                    primaryColor="#814DFA"
                    onPageChange={handlePagination} 
                />
            </div>}
        </>
    );
};

export default SubmissionList;
