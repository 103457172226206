import React from 'react';
import Div from "@jumbo/shared/Div";
import Badge from "@mui/material/Badge";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";  
import { store } from '../../../../../store/store';

const AuthUserSummary = () => { 
    const loginDetails = store.getState().loginDetails;
    return (
        <React.Fragment>
            <Div
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={
                        <React.Fragment>
                            <ArrowDropDownIcon
                                sx={{color: 'inherit', fontSize: '1.25rem'}} 
                            />
                             </React.Fragment>
                    }
                    sx={{
                        mr: 2,
                        '& .MuiBadge-badge': {
                            height: 16,
                            width: 16,
                            minWidth: 16,
                            overflow: 'hidden',
                            border: 1,
                            borderColor: 'common.white',
                            color: 'common.white',
                            bgcolor: '#8dcd03',
                            cursor: 'pointer',
                            right: 9,
                            bottom: 9,
                        }
                    }}
                >
                    <Avatar sx={{width: 50, height: 50}} alt="Cory Smith" src={loginDetails?.profileUrl}/>
                </Badge>
                <Div sx={{flex: '1 1 auto'}}>
                    <Typography variant={"h5"}>{loginDetails?.firstName}{' '}{loginDetails?.lastName}</Typography> 
                </Div>
            </Div>
        </React.Fragment>
    );
};

export default AuthUserSummary;
