import React from "react"; 
import Login2 from "../pages/auth-pages/login2";
import Signup1 from "../pages/auth-pages/signup1";
import Signup2 from "../pages/auth-pages/signup";
import ForgotPassword from "../pages/auth-pages/forgot-password";
import ResetPassword from "../pages/auth-pages/reset-password";
import ChooseProfile from "../pages/auth-pages/choose-profile";
import IntroductionPage from "../pages/auth-pages/video-conferencing/IntroductionPage/IntroductionPage";
import RoomPage from "../pages/auth-pages/video-conferencing/RoomPage/RoomPage";
import JoinRoomPage from "../pages/auth-pages/video-conferencing/JoinRoomPage/JoinRoomPage";

const authRoutes = [ 
    {
        path: "/signin",
        element: <Login2/>
    },
    {
        path: "/auth-pages/signup-1",
        element: <Signup1/>
    },
    {
        path: "/signup",
        element: <Signup2/>
    },
    {
        path: "/auth-pages/forgot-password",
        element: <ForgotPassword/>
    },
    {
        path: "/auth-pages/reset-password",
        element: <ResetPassword/>,
        checkauth: false
    },
    {
        path: "/profile",
        element: <ChooseProfile/>,
        checkauth: false
    },
    {
        path: "/meeting",
        element: <IntroductionPage/>
    },
    {
        path: "/room",
        element: <RoomPage/>
    },
    {
        path: "/join-room",
        element: <JoinRoomPage/>
    }
];

export default authRoutes;
