import React from 'react';
import {Button} from "@mui/material";
import {Edit} from "@mui/icons-material";
import FiltersList from "../FiltersList"; 
import {useNavigate} from 'react-router-dom';
import { USERROLECODE } from '../../../../../utils/constants/constants'

const MailAppSidebar = () => { 
    const navigate = useNavigate();
    const profilerole = Number(localStorage.getItem("switchprofile"));
    const handleComposeForm =() => {
        navigate(`/classes/classdetails/editclass`);
    };
    
    return (
        <React.Fragment>
            {profilerole === USERROLECODE.tutor && <Button
                fullWidth
                disableElevation
                variant={"contained"}
                startIcon={<Edit />}
                sx={{
                    mb: 1,
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem'
                    },
                    textTransform: 'none'
                }}
                onClick={handleComposeForm}
            >Edit Class</Button>}
            <FiltersList/>
        </React.Fragment>
    );
};

export default MailAppSidebar;
