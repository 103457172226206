import Actions from "./actions";

const initState = {
  identity: "",
  isRoomHost: false,
  connectOnlyWithAudio: false,
  roomId: null,
  twilioAccessToken: null,
  showOverlay: true,
  participants: [],
  switchprofile: '',
  menunames: '',
  loginDetails: '',
  credentials: '',
  classDetails: '',
  messages: [],
  stopvideo: true,
  chatperson: '',
  chatpersonpic: '',
  chatpersonid:'',
  chatRefresh: false,
  notification: null,
  className: '',
  chatPersonOnlineStatus: '',
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.SET_IDENTITY:
      return {
        ...state,
        identity: action.identity,
      };
    case Actions.SET_IS_ROOM_HOST:
      return {
        ...state,
        isRoomHost: action.isRoomHost,
      };
    case Actions.SET_CONNECT_ONLY_WITH_AUDIO:
      return {
        ...state,
        connectOnlyWithAudio: action.onlyWithAudio,
      };
    case Actions.SET_ROOM_ID:
      return {
        ...state,
        roomId: action.roomId,
      };
    case Actions.SET_TWILIO_ACCESS_TOKEN:
      return {
        ...state,
        twilioAccessToken: action.token,
      };
    case Actions.SET_SHOW_OVERLAY:
      return {
        ...state,
        showOverlay: action.showOverlay,
      };
    case Actions.SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.participants,
      };
    case Actions.PROFILE:
      return {
        ...state,
        switchprofile: action.switchprofile,
      };
    case Actions.MENUNAME:
        return {
          ...state,
          menunames: action.menunames,
      };
    case Actions.LOGIN_DETAILS:
      return {
        ...state,
        loginDetails: action.loginDetails
      }
      case Actions.CREDENTIAL:
        return {
          ...state,
          credentials: action.credentials
      }
      case Actions.CLASS_DETAILS:
        return {
          ...state,
          classDetails: action.classDetails
        }
        case Actions.SET_MESSAGES:
          return {
            ...state,
            messages: action.messages
      }
      case Actions.STOP_VIDEO:
        return {
          ...state,
          stopvideo: action.stopvideo
      }
      case Actions.CHAT_PERSON:
        return {
          ...state,
          chatperson: action.chatperson
      }
      case Actions.CHAT_PERSON_PROFILE:
        return {
          ...state,
          chatpersonpic: action.chatpersonpic
        } 
      case Actions.CHAT_PERSON_ID:
        return {
          ...state,
          chatpersonid: action.chatpersonid
        }

        case Actions.REFRESH_CHAT:
        return {
          ...state,
          chatRefresh: action.chatRefresh
      }
      case Actions.CLASS_NAME:
        return {
          ...state,
          className: action.className
        }
      case Actions.NOTIFICATION_COUNT:
        return {
          ...state,
          notification: action.notification
      }
      case Actions.CHAT_PERSON_ONLINE_STATUS:
        return {
          ...state,
          chatPersonOnlineStatus: action.chatPersonOnlineStatus
        }
         
    case Actions.ROOM_CLASS_CODE:
      return {
        ...state,
        roomClassCode: action.roomClassCode
      }
      
    case Actions.ROOM_LESSON_CODE:
      return {
        ...state,
        roomLessonCode: action.roomLessonCode
      }
      
    case Actions.ROOM_STUDENT_CODE:
      return {
        ...state,
        roomStudentCode: action.roomStudentCode
      }
            
    default:
      return state;
  }
};

export default reducer;
