import React from "react";
import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar"; 
import ContentHeader from "../../../../../shared/headers/ContentHeader";    
// import { store } from "../../../../../store/store";
import Div from "@jumbo/shared/Div";
const SingleParticipant = ({ identity, lastItem }) => {
  // const loginDetails = store.getState().loginDetails;
  const getParticipantName = (identity) => {
    return identity.slice(36, identity.length);
  };

  return (
    <>
       <div style={{ display: 'flex', alignItems: 'center',marginLeft:'20px' }}>
          <Div sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <Avatar src={''} alt="user" sx={{ width: 30, height: 30}} style={{marginBottom: '10px'}} /> 
        </Div>
             <Div sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Div style={{ marginLeft: '10px', marginTop: '-15px', color: '#002366', display: 'flex', flexDirection: 'column' }}  >
                    <span >{getParticipantName(identity)}</span> 
                </Div>
            </Div> 
            
        </div>
       {!lastItem && <span className="participants_separator_line"></span>} 
      {/* <p className="participants_paragraph">{getParticipantName(identity)}</p>
     */}
    </>
  );
};

const Participants = ({ participants }) => {
  return (
    <div className="participants_container">
      {participants.map((participant, index) => {
        return (
          <SingleParticipant
            key={participant.identity}
            identity={participant.identity}
            lastItem={participants.length === index + 1}
          />
        );
      })}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(Participants);
