import axios from "../config";
import {AUTH_URL} from "../../utils/constants/paths";
const financeServices = {};
//Topup wallet
financeServices.topUp = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/finance/topUpJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/finance/topUp', values); 
        return result?.data;
    } else {
        return "";
    } 
};
//Withdraw
financeServices.withdraw = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/finance/withdrawJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/finance/withdraw', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//Get Finance List
financeServices.financeList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10; 
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit, 
    }
    const { data } = await axios.post(AUTH_URL + '/finance/financeListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/finance/financeList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};

//Get dashboardfinanceList
financeServices.dashboardfinanceList = async ({ queryKey }) => {  
    const userCode = queryKey[0] ?  parseInt(queryKey[0]) : 0;
    const userRoleCode = queryKey[1] ? parseInt(queryKey[1]) : 0;
    const offset = queryKey[2] ? parseInt(queryKey[2]) : 0;
    const limit = queryKey[3] ? parseInt(queryKey[3]) : 10; 
    let values = {
        userCode: userCode,
        userRoleCode: userRoleCode,
        offset: offset,
        limit: limit, 
    }
    const { data } = await axios.post(AUTH_URL + '/finance/dashboardfinanceListJwt', values);
    if (data?.token) { 
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/finance/dashboardfinanceList', values); 
        return result?.data?.body;
    } else {
        return "";
    } 
};

export {financeServices};