import React, {useState} from 'react'; 
import {useMutation} from "react-query";
import { useSnackbar } from "notistack"; 
import Avatar from "@mui/material/Avatar"; 
import {TextField, FormControl, IconButton, ListItemIcon, ListItemText, ThemeProvider, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button"; 
import Grid from "@mui/material/Grid"; 
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'; 
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import {useJumboTheme} from "@jumbo/hooks";
import { authServices } from "../../../services/auth/user"; 
import { USERROLECODE } from '../../../utils/constants/constants'
import { USER } from '../../../utils/constants/constants' 
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff'; 
import { store } from "../../../store/store";
import {setMenuName, setProfile,setLoginDetails} from '../../../store/actions'
import { connect } from "react-redux";
const AuthUserDropdown = (props) => {
    // const {t} = useTranslation();
    // const mutation = useAuthSignOut(auth);
    const navigate = useNavigate(); 
    const { loginDetails } = props;
    const {theme} = useJumboTheme();
    const { enqueueSnackbar } = useSnackbar();  
    const switchprofile = store.getState().switchprofile; 
    const password_validation = USER.PASSWORD_VALIDATION;
    const errorPasswordMessage = "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    const [userLoginDetails, setUserLoginDetails] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [errorOldPassword, setErrorOldPassword] = useState(false);
    const [errorNewPassword, setErrorNewPassword] = useState(false);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);

    const handleChangePassword = (scrollType) => {
        clearData();
        setOpen(true);
        setScroll(scrollType);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => { 
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    
    React.useEffect(() => { 
     setUserLoginDetails(loginDetails) ; 
    }, [loginDetails]);

    //Switch profile api
    const mutation = useMutation(authServices.switchProfile, {
        onSuccess: (data) => {
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) { 
                enqueueSnackbar(data?.message, { variant: "success" });                           
                store.dispatch(setProfile(data?.body?.userRoleCode)); 
                store.dispatch(setLoginDetails(data?.body?.userDetails)); 
                setUserLoginDetails(data?.body?.userDetails)
                localStorage.setItem("switchprofile", data?.body?.userRoleCode)
                localStorage.setItem("isSwitchProfile", data?.body?.userRoleCode)
                navigate("/home", { replace: true });
                window.location.reload(false);
           }           
        },
        onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
        }
    });

    //Switch profile
    const switchProfileChange = () => {
        const profilerole = localStorage.getItem("switchprofile");      
        const profileCode = switchprofile ? switchprofile : profilerole ? profilerole : ''
        const data = {
        userCode: userLoginDetails && userLoginDetails.userCode ? Number(userLoginDetails.userCode) : '',
        userRoleCode: profileCode && Number(profileCode) === USERROLECODE.tutor ? USERROLECODE.student : USERROLECODE.tutor,
        }
        mutation.mutate(data);
    }

    //logout api
    const logoutmutation = useMutation(authServices.logout, {
        onSuccess: (data) => {
            localStorage.clear()
            navigate("/");
            window.location.reload(false);
        },
        onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
        }
    });

    //logout
    const onLogout = async () => { 
        const data = {
        userCode: userLoginDetails && userLoginDetails.userCode ? Number(userLoginDetails.userCode) : '',
        ipAddress: await authServices.getMyIpAddress()
        }
        logoutmutation.mutate(data);
    }

    const handleProfile = () => {
        store.dispatch(setMenuName("Profile")); 
        navigate("/user/profile")
    }

    const handleClickOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleClickNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    //changePassword api
    const changePasswordmutation = useMutation(authServices.changePassword, {
        onSuccess: (data) => {   
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                localStorage.clear()
                enqueueSnackbar(data?.message, { variant: "success" });
                clearData();
                navigate("/") 
           }  
        },
        onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
        }
    });
    const clearData = () => {
        setErrorOldPassword('');
        setErrorNewPassword('');
        setErrorConfirmPassword('');
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }
    const handleSavePassword = () => { 
        if(!errorOldPassword && !errorNewPassword && !errorConfirmPassword) {
            const data = {
                userCode: userLoginDetails && userLoginDetails.userCode ? Number(userLoginDetails.userCode) : '',
                oldPassword: oldPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword
            }
            changePasswordmutation.mutate(data);
        }  
    }

    return (
        <ThemeProvider theme={theme}  style={{
            '& .css-ek9bjg-MuiPaper-root-MuiPopover-paper': {
               width:'200px !important'
            }
        }}>
            <JumboDdPopover  
                triggerButton={
                    <Avatar
                        src={userLoginDetails?.profileUrl}
                        sizes={"small"}
                        sx={{boxShadow: 25, cursor: 'pointer'}}
                    />
                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar src={userLoginDetails?.profileUrl} alt={userLoginDetails?.firstName} sx={{width: 60, height: 60, mb: 2}}/>
                    <Typography variant={"h5"}>{userLoginDetails && userLoginDetails?.firstName.concat(' ', userLoginDetails.lastName)}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{userLoginDetails?.email}</Typography>
                </Div>
                <Divider/>
                <nav>
                    <List disablePadding sx={{pb: 1}} style={{whiteSpace:'nowrap'}}>
                        <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <PersonOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText onClick={() => handleProfile()}  primary="Profile" sx={{my: 0}}/>
                        </ListItemButton> 
                        <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <RepeatOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText onClick={switchProfileChange}  primary="Switch Profile"
                                          sx={{my: 0}}/>
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <PersonOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText onClick={() => handleChangePassword('paper')}  primary="Change Password" sx={{my: 0}}/>
                        </ListItemButton> 
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{my: 0}}/>
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
            <Dialog
            open={open}
            onClose={() => setOpen(false)}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">Change Password</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <Box component="form"
                sx={{
                    mx: -1, 
                    '& .MuiFormControl-root:not(.MuiTextField-root)': {
                        p: 1,
                        mb: 2,
                        width: {xs: '100%', sm: '100%'}
                    },
                    '& .MuiFormControl-root.MuiFormControl-fluid': {
                        width: '100%'
                    }
                }}
                autoComplete="off"
        >   
                <FormControl size="small">
                    <Grid container spacing={3.5}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="oldpassword"
                                label="Old Password"
                                size="small"
                                type={showOldPassword ? 'text' : 'password'}
                                value={oldPassword}
                                onChange={(event) => setOldPassword(event.target.value)}
                                InputProps={{
                                    minLength: 8,
                                    maxLength: 20,
                                    endAdornment: (
                                        <IconButton
                                        onClick={handleClickOldPassword}
                                        edge='end'
                                        aria-label='toggle password visibility'>    
                                        {showOldPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}                                       
                                      </IconButton>
                                    ),
                                }}
                                error={errorOldPassword ? true : false}
                                helperText={errorOldPassword ? errorOldPassword : ""}
                                onBlur={(event) => {
                                    if (event.target.value) {
                                    setErrorOldPassword('');
                                    } else {
                                    setErrorOldPassword('Please enter old password');
                                    }
                                }} 
                            />
                        </Grid>                        
                    </Grid>                                               
                </FormControl>
                <FormControl size="small">
                    <Grid container spacing={3.5}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="newpassword"
                                label="New Password"
                                size="small"
                                type={showNewPassword ? 'text' : 'password'}
                                value={newPassword}
                                onChange={(event) => setNewPassword(event.target.value)}
                                InputProps={{
                                    minLength: 8,
                                    maxLength: 20,
                                    endAdornment: (
                                        <IconButton
                                        onClick={handleClickNewPassword}
                                        edge='end'
                                        aria-label='toggle password visibility'>    
                                        {showNewPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}                                       
                                      </IconButton>
                                    ),
                                }}
                                error={errorNewPassword ? true : false}
                                helperText={errorNewPassword ? errorNewPassword : ""}
                                onBlur={(event) => {
                                    if (event.target.value && password_validation.test(event.target.value)) {
                                        if (oldPassword === event.target.value) {
                                            setErrorNewPassword(`New Password should not be same as old password`);
                                        } else {
                                            setErrorNewPassword('');
                                        }
                                    } else {
                                        setErrorNewPassword(errorPasswordMessage);
                                    }
                                }} 
                                
                            />
                        </Grid>                       
                    </Grid>                                               
                </FormControl>
                <FormControl size="small">
                    <Grid container spacing={3.5}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="confirmpassword"
                                label="Confirm Password"
                                size="small"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                InputProps={{
                                    minLength: 8,
                                    maxLength: 20,
                                    endAdornment: (
                                        <IconButton
                                        onClick={handleClickConfirmPassword}
                                        edge='end'
                                        aria-label='toggle password visibility'>    
                                        {showConfirmPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}                                       
                                      </IconButton>
                                    ),
                                }}
                                error={errorConfirmPassword ? true : false}
                                helperText={errorConfirmPassword ? errorConfirmPassword : ""}
                                onBlur={(event) => {
                                    if (event.target.value && password_validation.test(event.target.value)) {
                                        if (newPassword !== event.target.value) {
                                            setErrorConfirmPassword(`New password & confirm password does not match`);
                                        } else {
                                            setErrorConfirmPassword('');
                                        }
                                    } else {
                                        setErrorConfirmPassword(errorPasswordMessage);
                                    }
                                }} 
                            />
                        </Grid>                       
                    </Grid>                                               
                </FormControl>            
            </Box>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} onClick={() => setOpen(false)}>Cancel</Button>
                <Button variant={"contained"} style={{textTransform: 'none'}} onClick={() => handleSavePassword()}>Save</Button>
            </DialogActions>
        </Dialog>
        </ThemeProvider>
    );
};
const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
};
export default connect(mapStoreStateToProps)(AuthUserDropdown); 
 
