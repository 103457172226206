import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { store } from "../../../../store/store";
import {
  connect,
  LocalAudioTrack,
  LocalDataTrack,
  LocalVideoTrack,
} from "twilio-video";
import { setMessages, setShowOverlay } from "../../../../store/actions";
import { AUTH_URL } from "../../../../utils/constants/paths";
const audioConstraints = {
  video: false,
  audio: true,
};

const videoConstraints = {
  audio: true,
  video: {
    width: 640,
    height: 480,
  },
};

let dataChannel = null;

export const getTokenFromTwilio = async (setAccessToken, identity) => {
  const randomId = uuidv4();

  const response = await axios.get(
    AUTH_URL+`/video/token-service?identity=${randomId}${identity}`
  );

  const data = response.data;

  if (data.accessToken) {
    setAccessToken(data.accessToken);
  }
};

export const connectToRoom = async (
  accessToken,
  roomId = "test-room",
  setRoom
) => {
  const onlyWithAudio = store.getState().connectOnlyWithAudio;
  const constraints = onlyWithAudio ? audioConstraints : videoConstraints;

  navigator.mediaDevices
    .getUserMedia(constraints)
    .then(async (stream) => {
      let tracks;

      // create data track for messages
      const audioTrack = new LocalAudioTrack(stream.getAudioTracks()[0]);
      const dataTrack = new LocalDataTrack();
      dataChannel = dataTrack;
      let videoTrack;

      if (!onlyWithAudio) {
        videoTrack = new LocalVideoTrack(stream.getVideoTracks()[0]);
        tracks = [audioTrack, videoTrack, dataTrack];
      } else {
        tracks = [audioTrack, dataTrack];
      }

      const room = await connect(accessToken, {
        name: roomId,
        tracks,
      });
      setRoom(room);
      store.dispatch(setShowOverlay(false));
    })
    .catch((err) => {     
      console.log(err);
    });
};

export const checkIfRoomExists = async (roomId) => {
  const response = await axios.get(
    AUTH_URL+`/video/room-exists?roomId=${roomId}` 
  );

  return response.data.roomExists;
};


export const sendMessageUsingDataChannel = async (content, messageCreatedByMe = false) => {
  const identity = store.getState().identity;
  const ownMessage = {
    identity,
    content,
    messageCreatedByMe,
  };
  addMessageToMessenger(ownMessage);

  const messageToSent = {
    identity,
    content
  }
  const stringifiedMessage = JSON.stringify(messageToSent);
  dataChannel.send(stringifiedMessage)
};


export const addMessageToMessenger = (message) => {
  const messages = [...store.getState().messages];
  messages.push(message)
  store.dispatch(setMessages(messages))
};

export function getTime() {
  var today = new Date();
  let hours = today.getHours() > 12 ? today.getHours() - 12 : today.getHours();
  let d = today.getHours() > 12 ? "PM" : "AM";
  let time = hours + " : " + today.getMinutes();
  time = time + " " + d;
  return time;
}

export const hideMeetingDetail = () => {
  document.getElementById("meeting_details").style.display = "none";
};

export const hideChatWindow = () => {
  document.getElementById("chat").style.display = "none";
};

export const openChatWindow = () => {
  document.getElementById("chat").style.display = "block";
};
export const openMeetingDetails = () => {
  document.getElementById("meeting_details").style.display = "block";
};

export const meetingId = "abc-123-000";

