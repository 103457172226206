import React from 'react';
import {Card, CardContent, CardHeader, CircularProgress, Typography} from "@mui/material";
import { useQuery} from "react-query";
import {mailServices} from "../../../../../services/mail-services";
import {useParams} from "react-router-dom";
import Div from "@jumbo/shared/Div"; 
import CreateClass from "../../../createClass/createClass"
import CreateAnnouncement from "../../../createAnnouncement/createAnnouncement"
import Conferencing from "../../../conferencing/conferencing"
import ViewClass from "../../../viewclass"
import SubMissionsFiles from "../../../submissions/submissionfiles"
import Submissions from "../../../submissions/submissions"
import MailsList from "../../components/MailsList";
import UploadFiles from "../../../uploadFiles/uploadFiles";
import AnnouncementList from "../../../announcementList/announcementList"
import Multimedia from "../../../multimedia/multimedia"
import Students from "../../../students/students"
import FileList from "../../../uploadFiles/fileList"
import Quiz from "../../../quiz/quiz"
import Gradebook from "../../../gradebook/gradebook"
import Forum from "../../../forum/forum"

import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import {useTranslation} from "react-i18next";

const MailDetail = ({mailID}) => { 
    const {category} = useParams(); 
    const {t} = useTranslation();

   
    const { 
        isLoading,
        isError,
        error
    } = useQuery(["mailDetail", mailID], () => mailServices.getMailDetail(mailID));
    if (isLoading) {
        return (
            <Div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: theme => theme.spacing(3),
                    m: 'auto',
                }}
            >
                <CircularProgress/>
                <Typography variant={'h6'} color={'text.secondary'} mt={2}>Loading...</Typography>
            </Div>
        )
    }
    if (isError) {
        return error.message;
    }
 
    return (
        <Card>
            <CardHeader/>
            <CardContent sx={{p: 0}}>
                { category === 'editclass' ? 
                (<div style={{ display: 'flex',marginLeft: '2%' }}><AddToPhotosIcon style={{ marginRight: '5px',marginTop: '0.4%;' }} />
                    <Typography variant="h1" mb={3}>  {t('class.editClass')}</Typography>
                </div>) : ''
                }
                {category === 'viewclass' ? (<ViewClass frompage={"class"} />) : "" }                
                {category === 'editclass' ? (<CreateClass frompage={"editclass"}/>) : "" }                
                {category === 'conferencing' ? (<Conferencing />) : "" }                
                {category === 'files' ? (<FileList />) : "" }
                {category === 'submissionfiles' ? (<SubMissionsFiles />) : "" }
                {category === 'submissions' ? (<Submissions />) : "" }
                {category === 'uploadFiles' ? (<UploadFiles /> ) : " " }
                {category === 'announcement' ? (<AnnouncementList /> ) : " " }
                {category === 'createannouncement' ? (<CreateAnnouncement />) : " "}
                {category === 'editannouncement' ? (<CreateAnnouncement /> ) : " " }
                {category === 'multimedia' ? (<Multimedia /> ) : " " }
                {category === 'students' ? (<Students /> ) : " " }
                {category === 'students' ? (<MailsList />) : "" }
                {category === 'quiz' ? (<Quiz />) : "" }
                {category === 'gradebook' ? (<Gradebook />) : "" }
                {category === 'forum' ? (<Forum />) : "" }

            </CardContent>
        </Card>
    );
};

export default MailDetail;
