import React from 'react';
import {CardContent, TextField, Typography, Checkbox, FormControlLabel, FormGroup, Select, InputLabel, InputAdornment, Stack,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Table, TableBody} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div";
import styled from "@emotion/styled";
import { TableCell , TableRow} from "@mui/material";
import Button from "@mui/material/Button"; 
import Grid from "@mui/material/Grid"; 
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query"; 
import { useSnackbar } from "notistack";
import { classServices } from "../../../services/apis/classes"; 
import { authServices } from "../../../services/auth/user";
import moment from 'moment';
import LoadingButton from "@mui/lab/LoadingButton";
import WarningIcon from '@mui/icons-material/Warning';
import { store } from "../../../store/store"; 
import { setClassDetails } from '../../../store/actions'; 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-root': {
        borderBottom: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },

    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    }
}));
const CreateClass = ({frompage}) => {  
    const {t} = useTranslation(); 
    const location = useLocation();
    const navigate = useNavigate(); 
    const { enqueueSnackbar } = useSnackbar();
    const classDetails = store.getState().classDetails;
    const handleCloseClasses = () => {
        if (location && location.state && location.state.frompage === "home") {
            navigate("/home");
        } else if (location && location.state && location.state.frompage === "marketplace") {
            navigate("/marketplace");
        } else if (frompage === "editclass") {
            navigate("/classes/classdetails/viewclass", { state: {items: classDetails} });
        } else {
            navigate("/classes");
        }
      
    }
    const [isEdit, setEdit] = React.useState(false);
    const [classType, setClassType] = React.useState("");
    const [className, setClassName] = React.useState("");
    const [classSize, setClassSize] = React.useState("");
    const [lessionPrice, setLessionPrice] = React.useState("");
    const [frequency, setFrequency] = React.useState("2");
    const [shortDescription, setshortDescription] = React.useState("");
    const [description, setDescription] = React.useState("");    
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [noOfLessons, setNoOfLessons] = React.useState("");
    const [sameDuration, setSameDuration] = React.useState(false);
    const [recordClass, setRecordClass] = React.useState(false);
    const [selectedDays, setSelectedDays] =  React.useState([
        { dayCode: 1, dayName: 'Monday', value: 'monday', duration: " ", startTime: " ", endTime: " ", checked: false },
        { dayCode: 2, dayName: 'Tuesday', value: 'tuesday', duration: " ", startTime: " ", endTime: " ", checked: false },
        { dayCode: 3, dayName: 'Wednesday', value: 'wednesday', duration: " ", startTime: " ", endTime: " ",  checked: false},
        { dayCode: 4, dayName: 'Thursday', value: 'thursday', duration: " ", startTime: " ", endTime: " ",  checked: false },
        { dayCode: 5, dayName: 'Friday', value: 'friday', duration: " ", startTime: " ", endTime: " ",  checked: false },
        { dayCode: 6, dayName: 'Saturday', value: 'saturday', duration: " ", startTime: " ", endTime: " ",  checked: false },
        { dayCode: 7, dayName: 'Sunday', value: 'sunday', duration: " ", startTime: " ", endTime: " ",  checked: false }
    ]); 
    const [confirmDelete, setConfirmDelete] = React.useState("")
    const loginDetails = store.getState().loginDetails;
    const userCode = [loginDetails ? parseInt(loginDetails.userCode) : 0,  classDetails ? Number(classDetails.classCode) : '0']; 
    //getclass typelist, frequencylist
    const {data: classData} = useQuery(
        userCode, classServices.getClasses 
    ); 
    
    const [errorClassType, setErrorClassType] = React.useState(false);
    const [errorClassName, setErrorClassName] = React.useState(false);
    const [errorClassFrequency, setErrorClassFrequency] = React.useState(false);
    const [errorClassSize, setErrorClassSize] = React.useState(false);
    const [errorLessionPrice, setErrorLessionPrice] = React.useState(false);
    const [errorShortDescription, setErrorShortDescription] = React.useState(false);
    const [errorDescription, setErrorDescription] = React.useState(false);
    const [errorStartDate, setErrorStartDate] = React.useState(false);
    const [errorEndDate, setErrorEndDate] = React.useState(false);
    const [errorNoOfLessons, setErrorNoOfLessons] = React.useState("");
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [validateClassDatas, setValidateClassDatas] = React.useState([]);
    const [disabledEdit, setDisabledEdit] = React.useState(false);
    
    React.useEffect(() => {
        if (classDetails && Object.keys(classDetails).length > 0) {
            setEdit(true)
            if (classData?.classTypeList?.length > 0) {
                const classType = classData?.classTypeList?.find(e => e.classTypeCode === classDetails.classType) 
                setClassType(classType.classTypeCode)
            }
            setClassName(classDetails.className)
            setClassSize(classDetails.classSize)
            setLessionPrice(classDetails.classLessionPrice)
            if (classData?.classFreqList?.length > 0) {
                const classfrequency = classData?.classFreqList?.find(e => e.classFreqCode === classDetails.frequency) 
                setFrequency(classfrequency.classFreqCode)
            }
            setshortDescription(classDetails.shortDescription)
            setDescription(classDetails.description)
            setStartDate(formatDate(classDetails.startDate))
            setEndDate(formatDate(classDetails.endDate))
            setNoOfLessons(classDetails.noOfLessons)
            setRecordClass(classDetails.isRecordClass)
            setSelectedDays(classDetails.durationList)
            setSameDuration(classDetails.sameDuration) 
            if (parseInt(classData?.joinClassCount) > 0 || classDetails.statusname === 'Completed') {
                setDisabledEdit(true)
            }
            const days = Object.assign([], selectedDays)
            if (classDetails.durationList && classDetails.durationList.length > 0) {                
                days.forEach(e => {
                    const hasDuration = classDetails.durationList.find(d => Number(d.dayCode) === Number(e.dayCode))
                    if (hasDuration) {
                        e.checked = hasDuration.checked
                        e.duration = hasDuration?.duration
                        e.startTime = hasDuration?.startTime
                        e.endTime = hasDuration?.endTime
                    }                    
                })
            }
            setSelectedDays(days)  
        }
    
    }, [classData]);
    

    const handleSelectDay = (day, checked) => {        
        const days = Object.assign([], selectedDays)
        if (days && days.length > 0) {
            const hasduration = days.find(d => d.duration > 0 && d.startTime !== '')
            days.forEach(e => {
                if (Number(e.dayCode) === Number(day.dayCode)) {  
                    e.checked = checked
                    if (checked && sameDuration) {                        
                        e.duration = hasduration?.duration
                        e.startTime = hasduration?.startTime
                        e.endTime = hasduration?.endTime
                    } else {
                        e.duration = ''
                        e.startTime = ''
                        e.endTime = ''
                    }
                    
                }
            })
        }
        setSelectedDays(days)  
        if (startDate && endDate) {
            getNoOfLessons(startDate, endDate);
        }
    };

    const handleStartTime = (day, value) => {
        const days = Object.assign([], selectedDays)
        const timeValue = value.replace(/\:/g , "")
        if (days && days.length > 0) {
            days.forEach(async e => {
                if (Number(e.dayCode) === Number(day.dayCode)) {  
                    e.startTime = value                   
                    var endTime = moment(timeValue, "HH:mm")
                    .add(day.duration, 'minutes')
                    .format('HH:mm');                    
                    e.endTime = endTime
                }
            })
        }
        setSelectedDays(days)   
        if (startDate && endDate) {
            getNoOfLessons(startDate, endDate);
        }
    };

    const handleEndTime = (day, value) => {
        const days = Object.assign([], selectedDays)
        if (days && days.length > 0) {
            days.forEach(e => {
                if (Number(e.dayCode) === Number(day.dayCode)) {  
                    e.endTime = value
                }
            })
        }
        setSelectedDays(days) 
        if (startDate && endDate) {
            getNoOfLessons(startDate, endDate);
        }
    };

    const handleDuration = (day, value) => {
        const days = Object.assign([], selectedDays)
        if (days && days.length > 0) {
            days.forEach(e => {
                if (Number(e.dayCode) === Number(day.dayCode)) {  
                    e.duration = value
                    if (e.startTime) {
                        var endTime = moment(e.startTime, "HH:mm")
                        .add(value, 'minutes')
                        .format('HH:mm');                    
                        e.endTime = endTime
                    }
                }
            })
        }
        setSelectedDays(days) 
        if (startDate && endDate) {
            getNoOfLessons(startDate, endDate);
        }
    };

    const handleSameDuration = (value) => { 
        console.log(value, 'valueeesss')       
        const days = Object.assign([], selectedDays)
        setSameDuration(value)
        // days.forEach(day => {
        //     if(day.checked === true && day.duration > 0 && day.startTime ) {
        //         setSameDuration(value)
        //         return
        //     }                   
        // });
        if (value === true) {
            const hasduration = days.find(d => d.duration > 0)
            if (hasduration) {
                days.forEach(day => {
                    if(day.checked === true) {
                        day.duration = hasduration.duration
                        day.startTime = hasduration.startTime
                        day.endTime = hasduration.endTime
                    }                   
                });
                setSelectedDays(days)  
            }
        }
        if (startDate && endDate) {
            getNoOfLessons(startDate, endDate);
        }
       
    }
    const validWeeks = useMutation(classServices.getNoOfLessons, {
        onSuccess: (data) => {   
            if (data?.statusFlag === 1) {
                setNoOfLessons(data?.body?.noOfLessons)    
            }         
        },
        onError() {
        },
    });
    const handleEndDate = (date) => {
        setEndDate(date)  
        getNoOfLessons(startDate, date);
    }
    const getNoOfLessons = async (fromdates,todates) => {
        let weekdays = selectedDays.filter(d => (d.checked === true && d.duration !== "" && d.startTime !== "")); 
        if (weekdays && weekdays.length > 0) {
           await validWeeks.mutate({
                userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
                fromdate: fromdates,
                todate: todates,
                weeklyList:weekdays
            });
        } 
    }
    function isInThePast(date) {
        const today = new Date(); 
        today.setHours(0, 0, 0, 0); 
        return date < today;
      }
    const handleStartDate = (date) => {   
        setStartDate(date) 
        if (endDate) {
            // var sDate = moment(date, "YYYY-MM-DD");
            // var eDate = moment(endDate, "YYYY-MM-DD");
            // var noofdays = eDate.diff(sDate, 'days');
            // setNoOfLessons(noofdays + 1)
            getNoOfLessons(date, endDate);
        }
    }
   
    const handleNoOfLessons = (lessons) => { 
        setNoOfLessons(lessons)
        let endDate = moment(startDate, "YYYY-MM-DD").add(Number(lessons), 'days');
        setEndDate(formatDate(endDate))
    }

     // for date formating
     function formatDate(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    const getClassDetailsmutation = useMutation(classServices.getClassDetails, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                return;
           }
            if (data?.statusFlag === 1) {
                if (data?.body?.classDetails) {
                  localStorage.setItem("class_local_details", JSON.stringify(data?.body?.classDetails));
                  store.dispatch(setClassDetails(data?.body?.classDetails));  
                  navigate("/classes/classdetails/viewclass", { state: {items: data?.body?.classDetails} });
                }
            }         
        },
        onError() {
        },
    });
  

    const getClassDetails = () => {  
        getClassDetailsmutation.mutate({
            userCode: loginDetails ? Number(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? Number(loginDetails.roleCode): 0,   
            classCode: Number(classDetails.classCode)
        });       
    }

    const mutation = useMutation(classServices.class, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(className+" has been successfully created and listed on the marketplace", { variant: "success" });
                if (isEdit) {
                    getClassDetails()                    
                } else {
                    navigate("/classes", {replace: true});
                }
               
                
           }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });    
    const validateMutation = useMutation(classServices.validateClass, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
           }
            if (data?.statusFlag === 1) { 
                if (data?.body.length > 0) {
                    setValidateClassDatas(data?.body);
                    setAlertOpen(true);
                } else { 
                    callCreateClass();
                } 
            }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });  
    
    const callCreateClass = async () => {
        let duration = selectedDays.filter(d => d.checked === true)
        mutation.mutate({
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,           
            classCode: isEdit ? Number(classDetails.classCode) : "", 
            className: className, 
            classType: Number(classType), 
            classSize: Number(classSize), 
            classLessionPrice: lessionPrice.toString(), 
            frequency: Number(frequency), 
            shortDescription: shortDescription,
            description: description, 
            startDate: startDate,
            endDate: endDate,
            noOfLessons: Number(noOfLessons),
            isRecordClass: recordClass,
            isSameDuration: sameDuration,
            classDuration: duration,
            process: isEdit ? "update" : "insert",
            ipAddress: await authServices.getMyIpAddress()
        });
    }

    const clearData = () => {
        setOpen(false)
        setConfirmDelete("")
        // setOpen(false)
    }

    const handleCreateClass = async () => {
        if (!classType) {
            setErrorClassType(t('class.errorClassType'));
            return;
        }
        if (!className) {
            setErrorClassName(t('class.errorClassName'));
            return
        }
        if (!classSize) {
            setErrorClassSize(t('class.errorClassSize'));
            return
        }
        if (parseInt(classSize) > 50) {
            setErrorClassSize(t('class.errorClassSize1'));
            return
        }
        if (!lessionPrice) {
            setErrorLessionPrice(t('class.errorLessonPrice'));
            return
        }
        if (!frequency) {
            setErrorClassFrequency(t('class.errorClassFrequency'));
            return
        }
        if (!shortDescription) {
            setErrorShortDescription(t('class.errorShortDescription'));
            return
        }
        if (!description) {
            setErrorDescription(t('class.errorDescription'));
            return
        }
        if (!startDate) {
            setErrorStartDate(t('class.errorStartDate'));
            return
        }
        if (!endDate) {
            setErrorEndDate(t('class.errorEndDate'));
            return
        }
        if (!disabledEdit && isInThePast(new Date(startDate))) {
            setErrorStartDate(t('class.errorValidDate'));
            return;
        } 
        if (!disabledEdit && isInThePast(new Date(endDate))) {
            setErrorEndDate(t('class.errorValidDate'));
            return;
        } 
        if (new Date(startDate) > new Date(endDate)) {
            setErrorEndDate(t('class.errorEndDategreater'));
            return
        } 
        if (!noOfLessons) {
            setErrorNoOfLessons(t('class.errorNoOfLessons'));
            return
        }  
        let duration = selectedDays.filter(d => d.checked === true)
        validateMutation.mutate({
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
            startDate: startDate,
            endDate: endDate,
            duration: duration,
            classCode: isEdit ? Number(classDetails.classCode) : 0, 
        })
        
    }

    const deletemutation = useMutation(classServices.class, {
        onSuccess: (data) => {  
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                clearData();
                return;
           }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" });
                clearData();
                navigate("/classes", {replace: true});
           }
        },
        onError() {
            enqueueSnackbar(t('common.error'), { variant: "error" });
        },
    });

    const handleDeleteClass = async () => {
        deletemutation.mutate({
            userCode: loginDetails ? parseInt(loginDetails.userCode) : 0,
            userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,           
            classCode: Number(classDetails.classCode), 
            className: className, 
            classType: Number(classType), 
            classSize: Number(classSize), 
            classLessionPrice: lessionPrice.toString(), 
            frequency: Number(frequency), 
            shortDescription: shortDescription,
            description: description, 
            startDate: startDate,
            endDate: endDate,
            noOfLessons: Number(noOfLessons),
            isRecordClass: recordClass,
            isSameDuration: sameDuration,
            classDuration: [],
            process: "delete" ,
            ipAddress: await authServices.getMyIpAddress()
        });
    }

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handledeleteAlert = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const handleConfirmDelete = (value) => {
        setConfirmDelete(value)
    }
    const Transition = React.forwardRef((props, ref) => {
        return <Slide direction="down" ref={ref} {...props} />;
    });
    return ( 
    <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
        <CardContent>            
            <Box component="form"
                    sx={{
                        mx: -1,

                        '& .MuiFormControl-root:not(.MuiTextField-root)': {
                            p: 1,
                            mb: 2,
                            width: {xs: '100%', sm: '100%'}
                        },

                        '& .MuiFormControl-root.MuiFormControl-fluid': {
                            width: '100%'
                        }
                    }}
                    autoComplete="off">
            <Stack direction={"row"} spacing={2}  >
                <Stack direction={"column"} style={{width: '100%'}} >
                    <FormControl size="small">
                        <Grid container spacing={3.5}>
                            <Grid item xs={12} sm={6} lg={6}>
                                <InputLabel id="demo-simple-select-label">{t('class.classtype')}</InputLabel>                        
                                <Select fullWidth
                                        labelId="class-type-label"
                                        id="class-type-label"
                                        inputProps={{ tabIndex: "1" }}
                                        disabled={disabledEdit}
                                        value={classType} 
                                        label={t('class.chooseClassType')}
                                        error={errorClassType ? true : false}
                                        helperText={errorClassType ? errorClassType : ""}
                                        onBlur={(event) => {
                                            if (event.target.value) {
                                                setErrorClassType('');
                                            } else {
                                                setErrorClassType(t('class.errorClassType'));
                                            }
                                        }} 
                                        onChange={(event) => setClassType(event.target.value)}
                                        >
                                        {classData?.classTypeList?.length > 0 && classData?.classTypeList.map((item,index) => (
                                        <MenuItem key={index} value={ item.classTypeCode }>{ item.classTypeName }</MenuItem>
                                        )) } 
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <TextField
                                    fullWidth
                                    id="classname"
                                    label="Class Name"
                                    size="small" 
                                    disabled={disabledEdit}
                                    inputProps={{ maxLength: 100, tabIndex:"2" }}
                                    value={className}
                                    onChange={(event) => setClassName(event.target.value)}
                                    error={errorClassName ? true : false}
                                    helperText={errorClassName ? errorClassName : ""}
                                    onBlur={(event) => {
                                        if (event.target.value) {
                                            setErrorClassName('');
                                        } else {
                                            setErrorClassName(t('class.errorClassName'));
                                        }
                                    }}
                                    
                                />
                            </Grid>
                        </Grid>                               
                    </FormControl>                           
                    <FormControl>
                        <Grid container spacing={3.5}>
                            <Grid item xs={12} sm={6} lg={6}>
                                <TextField
                                    fullWidth
                                    id="classid"
                                    label={t('class.classsize')}
                                    size="small" 
                                    disabled={disabledEdit}
                                    inputProps={{ maxLength: 2, tabIndex:"4" }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault()
                                        }
                                    }} 
                                    value={classSize}
                                    onChange={(event) => setClassSize(event.target.value)}
                                    error={errorClassSize ? true : false}
                                    helperText={errorClassSize ? errorClassSize : ""}
                                    onBlur={(event) => {
                                        if (event.target.value) {
                                            setErrorClassSize('');
                                        } else {
                                            setErrorClassSize(t('class.errorClassSize'));
                                        }
                                        if (parseInt(event?.target?.value) > 50) {
                                            setErrorClassSize(t('class.errorClassSize1')); 
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <TextField
                                    fullWidth
                                    id="classid"
                                    label={t('class.lessonprice')}
                                    inputProps={{ maxLength: 3, tabIndex:"5"  
                                    }}
                                    disabled={disabledEdit}
                                    InputProps={{ 
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    endAdornment: <InputAdornment position="end">/lesson</InputAdornment>
                                     }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault()
                                        }
                                    }} 
                                    size="small"
                                    value={lessionPrice}
                                    onChange={(event) => setLessionPrice(event.target.value)}
                                    error={errorLessionPrice ? true : false}
                                    helperText={errorLessionPrice ? errorLessionPrice : ""}
                                    onBlur={(event) => {
                                        if (event.target.value) {
                                            setErrorLessionPrice('');
                                        } else {
                                            setErrorLessionPrice(t('class.errorLessonPrice'));
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                            </FormControl>
                            <FormControl  size="small" style={{paddingBottom: '0px',paddingTop: '0px',marginTop: '-17px'}}>
                                {/* <Grid container spacing={3.5}>                       
                                    <Grid item xs={12} sm={12} lg={12} style={{paddingTop: '10px',   paddingLeft: '20px',  paddingBottom: '0px'}}> */}
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={sameDuration} tabIndex="8"
                                                    onChange={(event) => handleSameDuration(event.target.checked)}/>
                                                }
                                                label={t('class.sameDuration')}
                                            />
                                        {/* </Grid>
                                    </Grid> */}
                                </FormControl>   
                        <FormControl  size="small"   style={{display:'none'}}>
                    <Grid container spacing={3.5}>
                        <Grid item xs={12} sm={12} lg={12}>
                        <InputLabel id="demo-simple-select-label">{t('class.frequency')}</InputLabel>                           
                        <Select
                            fullWidth
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={frequency}
                            label="Frequency"
                           
                            disabled={disabledEdit}
                            inputProps={{ tabIndex:"7" }}
                            onChange={(event) => setFrequency(event.target.value)}
                            error={errorClassFrequency ? true : false}
                            helperText={errorClassFrequency ? errorClassFrequency : ""}
                            onBlur={(event) => {
                                if (event.target.value) {
                                    setErrorClassFrequency('');
                                } else {
                                    setErrorClassFrequency(t('class.errorClassFrequency'));
                                }
                            }}
                            >
                            {classData?.classFreqList?.length > 0 && classData?.classFreqList.map((item,index) => (
                                            <MenuItem key={index} value={ item.classFreqCode }>{ item.classFreqName }</MenuItem>
                                        )) } 
                        </Select>
                        </Grid>                       
                        </Grid>
                </FormControl>
                </Stack>
                <Stack direction={"column"} style={{width: '100%'}} >
                    <FormControl  size="small">
                        <Grid container spacing={3.5}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label={t('class.shortDescription')}
                                    // multiline
                                    // rows={3.6} 
                                    inputProps={{ maxLength: 200, tabIndex:"3" }} 
                                    size="small"
                                    // rows={5}
                                    value={shortDescription}
                                    onChange={(event) => setshortDescription(event.target.value)}
                                    error={errorShortDescription ? true : false}
                                    helperText={errorShortDescription ? errorShortDescription : ""}
                                    onBlur={(event) => {
                                        if (event.target.value) {
                                            setErrorShortDescription('');
                                        } else {
                                            setErrorShortDescription(t('class.errorShortDescription'));
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl  size="small" style ={{marginBottom: '0%'}}>
                        <Grid container spacing={3.5}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label={t('class.description')}
                                    multiline 
                                    inputProps={{ maxLength: 1000, tabIndex:"6" }}
                                    size="small"
                                    rows={2}
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                    error={errorDescription ? true : false}
                                    helperText={errorDescription ? errorDescription : ""}
                                    onBlur={(event) => {
                                        if (event.target.value) {
                                            setErrorDescription('');
                                        } else {
                                            setErrorDescription(t('class.errorDescription'));
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                  
                </Stack>
                    </Stack>            
                    
            <Typography component={"h4"}>{t('class.day')}</Typography>
                <Div sx={{display: 'flex'}}>
                    <FormControl component="fieldset" variant="standard">
                        <FormGroup >
                            {selectedDays.map((day, index) => {
                                return (
                                <Grid container spacing={3} style={{marginBottom: '1%'}} key={index}>                                           
                                    <Grid item xs={6} sm={3} lg={2}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox disabled={disabledEdit} checked={day.checked} onChange={(e) => handleSelectDay(day, e.target.checked)} name={day.value}  tabIndex="9"/>
                                            }
                                            label={day.dayName}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} lg={2}>
                                        <TextField
                                            fullWidth
                                            id="duration"
                                            label={t('class.duartion')}
                                            size="small" 
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault()
                                                }
                                            }} 
                                            inputProps={{ maxLength: 3}}
                                            disabled={disabledEdit ? disabledEdit : day.checked ? false : true}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">{t('class.mins')}</InputAdornment>,
                                                style: { textAlign: 'right' }
                                            }}
                                            value={day.duration}
                                            onChange={(event) => handleDuration(day, event.target.value)}                                          
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} lg={2}>
                                    <TextField
                                        id="time"
                                        label={t('class.starttime')}
                                        type="time"
                                        // defaultValue="07:30" 
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300// 5 min
                                                }}
                                        disabled={disabledEdit ? disabledEdit : day.checked ? false : true}
                                        sx={{width: 150}}
                                        size="small"
                                        value={day.startTime}
                                        onChange={(event) => handleStartTime(day, event.target.value)}
                                    />
                                    </Grid>
                                    <Grid item xs={6} sm={3} lg={2}>
                                    <TextField
                                        id="time"
                                        label={t('class.endtime')}
                                        type="time"
                                        disabled={true}
                                        // defaultValue="07:30"
                                        InputLabelProps={{
                                            shrink: true,
                                                }}
                                                
                                        inputProps={{
                                            step: "00:15", // 5 min
                                        }}
                                        sx={{
                                            width: 150, marginLeft: '25px',
                                            '& .MuiInputBase-input': {
                                                cursor: 'not-allowed',
                                            }
                                        }}
                                        size="small"
                                        readOnly={true}
                                        value={day.endTime}
                                        onChange={(event) => handleEndTime(event.target.value)}
                                    />
                                    </Grid>                                            
                                
                                    </Grid>
                                    )
                                })
                            }
                        </FormGroup>                                      
                        </FormControl>     
                           
                </Div> 
                <FormControl>                            
                        <Grid container spacing={3.5}>   
                              
                    <Grid item xs={12} sm={4} lg={3} > 
                    <TextField                        
                        size="small"
                        fullWidth
                        id={t('class.startdate')}
                        label={t('class.startdate')}
                        value={startDate}
                        disabled={disabledEdit}
                        type="date"  
                        autoComplete='off'
                        InputProps={{ inputProps: { min: formatDate(new Date()) }, tabIndex:11 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => handleStartDate(e.target.value)}
                        error={errorStartDate ? true : false}
                        helperText={errorStartDate ? errorStartDate : ""}
                        onBlur={(event) => {
                            if (event.target.value) {
                                if (endDate && new Date(event.target.value) > new Date(endDate)) {
                                    setErrorStartDate(t('class.errorStartDatelesser'));
                                } else { setErrorStartDate(''); }
                                if (!disabledEdit && isInThePast(new Date(event.target.value))) {
                                    setErrorStartDate(t('class.errorValidDate'));
                                } else {
                                    setErrorStartDate(''); 
                                }
                            } else {
                                setErrorStartDate(t('class.errorStartDate'));
                            }   
                        }}
                    />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                    <TextField
                        size="small"
                        fullWidth
                        id={t('class.enddate')}
                        label={t('class.enddate')}
                        value={endDate}
                        disabled={disabledEdit}
                        type="date" 
                        autoComplete='off'
                        InputProps={{ inputProps: { min: formatDate(startDate) }, tabIndex:12  }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => handleEndDate(e.target.value)}
                        error={errorEndDate ? true : false}
                        helperText={errorEndDate ? errorEndDate : ""}
                        onBlur={(event) => {
                            if (event.target.value) {
                                if (startDate && new Date(startDate) > new Date(event.target.value)) {
                                    setErrorEndDate(t('class.errorEndDategreater'));
                                } else { setErrorEndDate(''); }
                                if (!disabledEdit && isInThePast(new Date(event.target.value))) {
                                    setErrorEndDate(t('class.errorValidDate'));
                                } else {
                                    setErrorEndDate(''); 
                                }
                            } else {
                                setErrorEndDate(t('class.errorEndDate'));
                            }
                        }}
                    />
                    </Grid>
                    <Grid item xs={12} sm={3} lg={3}>
                    <TextField
                        fullWidth
                        id="lessons"
                        label={t('class.nooflessons')}
                        readOnly={true}
                        value={noOfLessons}
                        disabled
                        size="small"
                        sx={{ 
                            '& .MuiInputBase-input': {
                                cursor: 'not-allowed',
                            }
                        }}
                        inputProps={{style: { textAlign: 'right' }, tabIndex:13 }} 
                        onChange={e => handleNoOfLessons(e.target.value)}
                        error={errorNoOfLessons ? true : false}
                        helperText={errorNoOfLessons ? errorNoOfLessons : ""}
                        onBlur={(event) => {
                            if (event.target.value) {
                                setErrorNoOfLessons('');
                            } else {
                                setErrorNoOfLessons(t('class.errorNoOfLessons'));
                            }
                        }}
                    />
                    </Grid>
                    </Grid>
                </FormControl>   
                <FormControl  size="small">
                <Grid container spacing={3.5}>                   
                    <Grid item xs={12} sm={12} lg={12}>
                    <FormControlLabel
                        control={
                        <Checkbox  disabled={disabledEdit} checked={recordClass} tabIndex="14" onChange={(e) => setRecordClass(e.target.checked)}/>
                        }
                        label={t('class.recordclass')}
                    />
                    </Grid>
                    </Grid>
                </FormControl>                   
                <Div sx={{ mx: 1 }}>
                    <Grid container spacing={3.5}>                   
                     <Grid item xs={6} sm={6} lg={6}>
                         {isEdit && (
                         <Button variant={"contained"} tabIndex="15" color="error" style={{textTransform: 'none', marginRight: '10px'}} disabled={disabledEdit} onClick={handledeleteAlert('paper')}>{t('class.deleteClass')}</Button>
                         )}
                     </Grid>
                     <Grid item xs={6} sm={6} lg={6} style={{ textAlign: 'right' }}>
                                <Button variant={"outlined"}  tabIndex="16"  style={{ textTransform: 'none', marginRight: '10px' }} onClick={handleCloseClasses}>{t('common.cancel')}</Button>
                                <LoadingButton   tabIndex="17" 
                                    style={{textTransform: 'none'}}                                 
                                    variant="contained"
                                    size="medium" 
                                    onClick={handleCreateClass}
                                    loading={mutation.isLoading}
                                >{isEdit ? t('common.update') : t('common.create')}</LoadingButton>  
                    </Grid>
                    </Grid>
                </Div>
            </Box>
        </CardContent>

        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                '& .MuiDialog-paper ':{
                    width: '720px',
                },  
                '& .MuiDialogTitle-root' : {
                    padding: '16px 24px 0px 24px'
                }
            }}
        >
            <DialogTitle id="scroll-dialog-title" style={{background: "#f397113d", marginBottom: '1px'}}>
                <Grid xs={12} sm={12} lg={12}>                    
                    <div style={{ display: 'flex' }}><WarningIcon  color={'warning'} style={{ marginRight: '5px', marginTop: '3px' }} />
                    <Typography variant="h1"  color={'warning'}>{t('common.warning')}</Typography>
                    </div>                   
                </Grid>
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <Box component="form"
                    sx={{
                        mx: -1, 
                        '& .MuiFormControl-root:not(.MuiTextField-root)': {
                            p: 1,
                            mb: 2,
                            width: {xs: '100%', sm: '100%'}
                        },
                        '& .MuiFormControl-root.MuiFormControl-fluid': {
                            width: '100%'
                        }
                    }}
                    autoComplete="off"
                > 
                <FormControl size="small">
                    <Grid container spacing={3.5} style={{marginBottom: '3%'}}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <Typography variant="h4">{t('class.deleteMessage')}</Typography>
                            <Typography variant="p">{t('class.deleteMessagealert')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3.5}>                       
                        <Grid item xs={12} sm={12} lg={12}>
                            <TextField
                                fullWidth
                                id="delete"
                                label={t('class.confirmDelete')}
                                size="small"
                                value={confirmDelete}
                                onChange={e => handleConfirmDelete(e.target.value)}
                            />
                        </Grid>                       
                    </Grid>                                               
                </FormControl>
                 
            </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} style={{textTransform: 'none', marginRight: '10px'}} 
                onClick={() => clearData()}>{t('common.cancel')}</Button>
                <Button variant={"contained"} style={{textTransform: 'none'}} onClick={handleDeleteClass} 
                disabled={confirmDelete && confirmDelete === 'DELETE' ? false : true }>{t('class.delete')}</Button>
            </DialogActions>
        </Dialog>

            {/* Alert Dialog    */}
            <Dialog
                    open={alertOpen}  
                    onClose={() => setAlertOpen(false)}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{
                        '& .MuiDialog-paper ':{
                            width: '720px',
                        },  
                        '& .MuiDialogTitle-root' : {
                            padding: '16px 24px 0px 24px'
                        }
                    }}
                >
                   <DialogTitle id="scroll-dialog-title" style={{background: "#f397113d", marginBottom: '1px'}}>
                    <Grid xs={12} sm={12} lg={12}>                    
                        <div style={{ display: 'flex' }}><WarningIcon  color={'warning'} style={{ marginRight: '5px', marginTop: '3px' }} />
                        <Typography variant="h1"  color={'warning'}>{t('common.warning')}</Typography>
                        </div>                   
                    </Grid>
                </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    <Typography variant="h5" style={{padding: '10px'}}  color={'warning'}> {
                            (validateClassDatas && validateClassDatas.length > 0)  ? validateClassDatas[0].message : ''
                        } </Typography>
                        <Table> 
                            <TableBody>
                                {validateClassDatas.map((item, index) => {
                                    return (
                                        <StyledTableRow key={"firstitem"}>
                                            <TableCell sx={{ pl: theme => theme.spacing(3) }}>
                                               {item.className}
                                            </TableCell>
                                            <TableCell align="left">  {item.dayName} </TableCell>
                                            <TableCell align="right">   {item.classTime}</TableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </DialogContentText>
                    </DialogContent>
                        <DialogActions> 
                        <Button variant={"contained"}  style={{textTransform: 'none'}} onClick={() => setAlertOpen(false)}>Okay</Button> 
                    </DialogActions>
                </Dialog>
            
    </Div>
                
    );
}; 
export default CreateClass;
