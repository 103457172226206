import React, {useEffect, useState} from 'react';
import { alpha, Typography,  Breadcrumbs, Link, Tooltip} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid"; 
import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";
import DndWrapper from "./DndWrapper"; 
import { useNavigate, useLocation } from "react-router-dom";
import {ASSET_AVATARS} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import { USERROLECODE, BUCKET_FOLDERS, maxUploadFileSize } from '../../../utils/constants/constants'
import SubmissionList from "./submissionList";
import { classServices } from "../../../services/apis/classes"; 
import { authServices } from "../../../services/auth/user"; 
import { useQuery } from "react-query";
import DeleteIcon from '@mui/icons-material/Delete';
import {useMutation} from "react-query";
import { useSnackbar } from "notistack";
import S3 from 'react-aws-s3';  
import LinearProgress from '@mui/material/LinearProgress';
import { connect } from "react-redux";
import { videoFiles } from '../../../utils/constants/constants';
var aws = require('aws-sdk');

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};
 
const Submissions = (props) => { 
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const profilerole = Number(localStorage.getItem("switchprofile")); 
    // const classDetails = store.getState().classDetails;
    // const loginDetails = store.getState().loginDetails;
    // const credentialDetails = store.getState().credentials;
    const { classDetails, loginDetails, credentials } = props    
    const [filesData, setFilesData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const config = {
        bucketName: credentials?.s3bucket,
        dirName: BUCKET_FOLDERS.FILES, /* optional */
        region: credentials?.region,
        accessKeyId: credentials?.awsAccessKey,
        secretAccessKey: credentials?.awsSecretKey, 
    }
    // const file_types = ['txt','xlsx', 'csv', 'png', 'jpg', 'jpeg', 'pdf', 'ppt', 'docx']
    const [isSuccess, setSuccessfull] = React.useState(false); 
    const [files, setFiles] = React.useState([]) 
    const  limit  = 10 ;
    const offset= 0; 
    const userDetails = [loginDetails ? loginDetails.userCode : 0 , loginDetails ? loginDetails.roleCode : 0, offset, 
    limit, classDetails ? Number(classDetails.classCode) : '', 
    location?.state?.filedetails?.folderCode ? Number(location?.state?.filedetails?.folderCode) : ''];  
    const {data: result, refetch: refetchsubmissionFileList } = useQuery(
        userDetails, classServices.submissionFileList
    );  
    const [fileTypeErrror, setFileTypeErrror] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(false);
 
    React.useEffect(() => {
        if (isSuccess) {  
            refetchsubmissionFileList().catch(console.error)
            // setFileItemSuccess(false)  
            setSuccessfull(false)
            setLoading(false)
        }
    }, [isSuccess]);

    //uploadFiles api
    const uploadFilesApi = useMutation(classServices.uploadSubmissionFiles, {
        onSuccess: (data) => {   
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
        }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message, { variant: "success" }); 
                setSuccessfull(true)             
        }  
        },
        onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
        }
    });  
    
    const uploadFile = async(files) => {
        let fileData = []; let flag = 0;
        setLoading(true)
        if (files && files.length > 0) { 
            await files.forEach(async (file) => {
                if (file.isAdd) {
                const file_name = 'file_' + loginDetails?.userCode + "_" + Date.now();
                const fileName = loginDetails.userCode + '/' + classDetails.classCode + '/' + file_name;
                const ReactS3Client = new S3(config);
                //Upload new file
                await ReactS3Client
                    .uploadFile(file, fileName)
                    .then(data => { 
                        fileData.push({ fileName: file_name, filePath: data.location, fileType: file.fileType }) 
                    })
                    .catch(err => console.error(err, 'error'))
                flag = flag + 1;
                } else { 
                    fileData.push({ fileName: file.fileName, filePath: file.filePath, fileType: file.fileType }) 
                    flag = flag + 1; }
                if (flag === files.length) {
                    setFilesData(fileData);
                } 
            })                
        }
    };    

    const {getRootProps, getInputProps} = useDropzone({       
        onDrop: acceptedFiles => {
            const dropFiles = Object.assign([], []);
            let hasNoType = false, maxSizeExceed = false;
            if(acceptedFiles && acceptedFiles.length >0) {
                acceptedFiles.forEach(f => { 
                    let f_length = f?.path?.split('.').length;
                    let type = f?.path?.split('.')[f_length-1]
                    f.isAdd = true
                    f.fileType = type 
                    if (!videoFiles.includes(type.toUpperCase())) { 
                        if (type ===  'docx' || type ===  'doc') {
                            f.image = getAssetPath(`${ASSET_AVATARS}/doc.png`,"40x40")
                        } else if (type ===  'xlsx' || type === 'csv' || type ===  'xls') {
                            f.image = getAssetPath(`${ASSET_AVATARS}/xls.png`,"40x40")
                        } else if (type ===  'png' || type ===  'jpg' || type ===  'jpeg') {
                            f.image = getAssetPath(`${ASSET_AVATARS}/image.png`,"40x40")
                        } else if (type ===  'pdf') {
                            f.image = getAssetPath(`${ASSET_AVATARS}/pdf.png`,"40x40")
                        } else if (type ===  'ppt') {
                            f.image = getAssetPath(`${ASSET_AVATARS}/ppt.png`,"40x40")
                        }  else if (type ===  'txt') {
                            f.image = getAssetPath(`${ASSET_AVATARS}/txt.png`,"40x40")
                        } else {
                            f.image = getAssetPath(`${ASSET_AVATARS}/files.png`,"40x40")
                        }
                        if (parseInt(f?.size) > maxUploadFileSize) {
                            maxSizeExceed = true;
                            setFileSizeError(true)
                            return
                        }
                        dropFiles.push(f)
                    } else {
                        hasNoType = true
                        setFileTypeErrror(true)
                        return
                    }
                });
            }
            if (!hasNoType && !maxSizeExceed) {
                setFiles(dropFiles);
                setFileTypeErrror(false)
                setFileSizeError(false)  
                uploadFile(dropFiles) 
            }           
        }
        // maxSize: maxUploadFileSize
    });
 

    useEffect(
        () => () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files],
    );

    const open = false; 
    const [filesList, setFilesList] = React.useState([]);
   
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const back = () => {
        navigate("/classes/classdetails/submissionfiles");
    }

    React.useEffect(() => {
        if (filesData && filesData.length > 0 ) { 
            const data = {
                userCode: loginDetails ? Number(loginDetails.userCode) : '',
                userRoleCode: loginDetails ? Number(loginDetails.roleCode) : '',
                classCode: classDetails ? Number(classDetails.classCode) : '',
                folderCode: Number(location.state.filedetails.folderCode),
                files: filesData,
                title: '',
                ipAddress: '',
                process:'insert',
            }
            uploadFilesApi.mutate(data)
        }
    }, [filesData]); 

     //deleteSubmissionFiles
     const deleteSubmissionFiles = useMutation(classServices.deleteSubmissionFiles, {
        onSuccess: (data) => {   
            if (data?.statusFlag === 2) {
                enqueueSnackbar(data?.message, { variant: "warning" });
                return;
        }
            if (data?.statusFlag === 1) {
                enqueueSnackbar(data?.message+ location.state.filedetails.folderName, { variant: "success" }); 
                setSuccessfull(true)            
        }  
        },
        onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
        }
    }); 

    const removeFile = async (index, row) => {
        const s3 = new aws.S3({
            accessKeyId: credentials?.awsAccessKey,
            secretAccessKey: credentials?.awsSecretKey,
            Bucket: credentials?.s3bucket,
            region: credentials?.region
        });
      
        await s3.deleteObject({ Bucket: credentials?.s3bucket, 
            Key: row.filePath.split("/")[3]+'/' + row.filePath.split("/")[4]+'/'+ 
            row.filePath.split("/")[5]+'/'+ row.filePath.split("/")[6]}, (err, data) => { 
        });
        const files_list = Object.assign([], filesList)       
        if (index !== -1) {
          files_list.splice(index, 1)
          setFilesList(files_list)
            const data = {
                userCode: loginDetails ? Number(loginDetails.userCode) : '',
                userRoleCode: loginDetails ? Number(loginDetails.roleCode) : '',
                fileCode: Number(row.fileCode),
                ipAddress: await authServices.getMyIpAddress()
            }
            setLoading(true)
            deleteSubmissionFiles.mutate(data)
        }
    }   

    React.useEffect(() => {
        if (result) {
            if(result.fileList && result.fileList.length > 0) {
                const files_array = [];
                result.fileList.forEach(f => {
                // if (file_types.includes(f.fileType)) {
                    if (f.fileType ===  'docx' || f.fileType ===  'doc') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/doc.png`,"40x40")
                    } else if (f.fileType ===  'xlsx' || f.fileType === 'csv' || f.fileType === 'xls') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/xls.png`,"40x40")
                    } else if (f.fileType ===  'png' || f.fileType ===  'jpg' || f.fileType ===  'jpeg') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/image.png`,"40x40")
                    } else if (f.fileType ===  'pdf') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/pdf.png`,"40x40")
                    } else if (f.fileType ===  'ppt') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/ppt.png`,"40x40")
                    }  else if (f.fileType ===  'txt') {
                        f.image = getAssetPath(`${ASSET_AVATARS}/txt.png`,"40x40")
                    } else {
                        f.image = getAssetPath(`${ASSET_AVATARS}/files.png`,"40x40")
                    }
                    files_array.push(f)
                // } 
             });
             setFilesList(files_array)
            }            
        }
    }, [result]);
    
    return ( 
        <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
            {/* <CardContent>  */}
                <Grid container style={{paddingLeft: '3%', paddingRight: '3%', marginBottom: '2%'}}>              
                  
                    <Grid xs={8} >
                     <div>
                        <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit"  style={{cursor: 'pointer'}} onClick={back}>
                            Submissions
                        </Link>                        
                        <Typography color="text.primary">{location.state.filedetails.folderName}</Typography>
                    </Breadcrumbs>
                    </div>                   
                    </Grid>                                    
                </Grid>  
                {profilerole === USERROLECODE.tutor && ( 
                    <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>
                        <Grid xs={12} md={12} lg={12}>
                            <SubmissionList classDetails={classDetails} folderDetails = {location.state.filedetails} isSuccess={isSuccess} loginDetails={loginDetails} />
                        </Grid>
                     </Grid>
                )}
                {profilerole === USERROLECODE.student && (  
                <>
                 <Grid container style={{paddingLeft: '3%', paddingRight: '3%'}}>
                    {                        
                        filesList && filesList.length > 0 && (
                            <Grid container style={thumbsContainer}>
                            {
                                filesList.map((row, index) => (
                                    <Grid xs={2} md={1} lg={1} 
                                    sx={{
                                        'cursor': `pointer`,
                                        '&:hover': {
                                        boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                                        transform: 'translateY(-4px)', 
                                        '& .MuiChip-animation': {
                                            width: 'auto',
                                            height: 'auto',
                                            display: 'block',
                                            textAlign: 'right',
                                            padding: '1%'
                                        } }}
                                    }>                                      
                                    <Tooltip title="Delete"> 
                                        <Div   className={'MuiChip-animation'} 
                                            onClick={() => removeFile(index, row)}
                                            sx={{ width: 0, height: 0, fontSize: 0, display: 'none', transition: 'all 0.2s'}}>                
                                            <DeleteIcon fontSize={"small"}/>
                                        </Div>
                                    </Tooltip>
                                    <div style={{textAlign: 'center'}} >                                        
                                        <img src={row.image} alt="userImg" style={{width: '60px', height: '60px'}} />
                                    </div>
                                    
                                </Grid>
                                    
                                ))
                            }
                            </Grid>  
                        )                       
                    }
                </Grid> 
                {loading && (
                <Grid container spacing={3.5} style={{padding: '3%'}}>
                    <Grid item xs={6} sm={6} lg={6}>
                        <LinearProgress/>
                    </Grid>
                </Grid>)}
                {location?.state?.filedetails?.enableFolder && (
                <Grid container spacing={3.5} style={{paddingLeft: '3%', paddingRight: '3%'}}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Div>
                            <DndWrapper>
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <Typography variant={"body1"}>Drag 'n' drop some files here, or click to select files</Typography>
                                </div>
                            </DndWrapper>
                            {fileTypeErrror && (
                            <Typography fontSize={"small"} color='error'>
                            {t('class.errorFileType')}
                            </Typography>                            
                            )}  
                            {fileSizeError && (
                            <Typography fontSize={"small"} color='error'>
                            {t('class.errorFileSize')}
                            </Typography>
                        )}                                                   
                        </Div>                        
                    </Grid> 
                </Grid>
                )}
                </>
                )}
                                 
        </Div>
        
    );
};
const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
};
export default connect(mapStoreStateToProps)(Submissions);
