import React from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import {mailServices} from "../../../../../services/mail-services";
import MailItem from "./MailItem"; 
import {useParams} from "react-router-dom"; 
import useMailsApp from "../../hooks/useMailsApp"; 

const MailsList = () => {
    const params = useParams();
    const listRef = React.useRef();
    const {refreshMailsList, setMailsListRefresh, setSelectedMails} = useMailsApp();
    const profilerole = localStorage.getItem("switchprofile");
    const renderMailItem = React.useCallback((mailItem) => {
        return <MailItem mailItem={mailItem} profilerole={profilerole}/>
    });
    const [queryOptions, setQueryOptions] = React.useState({
        queryKey: "mails-list",
        queryParams: {category: params.category, id: params.id},
        countKey: "count",
        dataKey: "mailsList"
    }); 
    React.useEffect(() => {
        if (listRef?.current && refreshMailsList) {
            listRef.current.refresh();
            setMailsListRefresh(false);
        }
    }, [refreshMailsList, setMailsListRefresh])

    React.useEffect(() => {
        setQueryOptions(state => ({
            ...state,
            queryParams: {category: params.category, id: params.id},
        }))
    }, [params]);

    return (
        <JumboRqList
            ref={listRef}
            // wrapperComponent={Card}
            queryOptions={queryOptions}
            primaryKey={"id"}
            service={mailServices.getMails}
            renderItem={renderMailItem}
            itemsPerPage={8}
            itemsPerPageOptions={[8, 12, 15]}
            componentElement={'div'}
            wrapperSx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
            }}           
            onSelectionChange={setSelectedMails}
        />
    );
};

export default MailsList;
