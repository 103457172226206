import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom"; 
import {  Grid,  Typography } from "@mui/material"; 
import Avatar from "@mui/material/Avatar";
import Div from "@jumbo/shared/Div";
import { store } from "../../../../../../store/store";
const VideoTrack = ({ track,participant }) => {
  const trackRef = useRef();  
  const stop_video = store.getState().stopvideo;
  // const loginDetails = store.getState().loginDetails;
  useEffect(() => {
    if (stop_video) {
      const child = track.attach();
      trackRef.current.classList.add(track.kind);
      trackRef.current.appendChild(child);
    }

    // const videosPortal = document.getElementById("videos_portal");

    // if (!videosPortal.classList.contains("videos_portal_styles")) {
    //   videosPortal.classList.add("videos_portal_styles");
    // }
  }, [stop_video]);
  const getParticipantName = (identity) => {
    return identity.slice(36, identity.length);
  };
  const content = ( 
    // <Grid container spacing={1.5}>
    <Grid xs={3} sm={3} lg={3} style={stop_video ? { paddingTop: "30px", paddingBottom: "30px", paddingRight: "20px" } : {background: "#2e2c2c",  padding: "4%",  borderRadius: "12px", marginTop: "10px", marginRight: '2%'}}> 
      {!stop_video ?
         <Div sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column' }}>
            <Avatar src={''} alt={'username'} sx={{width: 100, height: 100, mb: 2}}/>
            <Typography variant={"h2"} style={{color: '#fff'}}> {getParticipantName(participant?.identity)}</Typography> 
        </Div> 
        : <div ref={trackRef}></div>}
      </Grid> 
  );

  return ReactDOM.createPortal(
    content,
    document.getElementById("videoData")
  );
};

export default VideoTrack;
