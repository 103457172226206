import axios from "../config";
import {AUTH_URL} from "../../utils/constants/paths";
const authServices = {};
//Create User
authServices.createUser = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/userMgmt/createUserJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/userMgmt/createUser', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//User Login
authServices.login = async (req) => {
    const {data} = await axios.post(AUTH_URL + '/userMgmt/loginJwt', req);
    if (data?.token) {
        req.jwtToken = data?.token;
        localStorage.setItem("jwtToken", data?.token)
        const result = await axios.post(AUTH_URL + '/userMgmt/login', req);
        return result?.data;
    } else {
        return ""
    }
};

//User Login
authServices.switchProfile = async (req) => { 
    const {data} = await axios.post(AUTH_URL + '/userMgmt/switchProfileJwt', req);
    if (data?.token) {
        req.jwtToken = data?.token;
        const result = await axios.post(AUTH_URL + '/userMgmt/switchProfile', req);
        return result?.data;
    } else {
        return ""
    }
};

//get IPAddress
authServices.getMyIpAddress = async() => {
    const res = '172.16.1.200'
    return res
}

//logout
authServices.logout = async (req) => {
    const {data} = await axios.post(AUTH_URL + '/userMgmt/logoutJwt', req);
    if (data?.token) {
        req.jwtToken = data?.token;
        const result = await axios.post(AUTH_URL + '/userMgmt/logout', req);
        return result?.data;
    } else {
        return ""
    }
}; 
//Get User List
authServices.retrieveUserDetails = async ({ queryKey }) => { 
    const token = localStorage.getItem("jwtToken"); 
    const isSwitchProfile = localStorage.getItem("isSwitchProfile"); 
    if (token) {
        let values = {
            email: "dummy",
            password: "password",
            ipAddress: "ipAddress",
            switchProfile: isSwitchProfile ? Number(isSwitchProfile) : '',
            jwtToken: token
        } 
        const  result = await axios.post(AUTH_URL + '/userMgmt/login', values); 
        return result?.data?.body  ? result?.data?.body : result?.data;
    } else {
        return ''
    }
   };

//Update USer
authServices.updateUser = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/userMgmt/updateUserJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/userMgmt/updateUser', values); 
        return result?.data;
    } else {
        return "";
    } 
};

//changePassword
authServices.changePassword = async (values) => {
    const { data } = await axios.post(AUTH_URL + '/userMgmt/changePasswordJwt', values);
    if (data?.token) {
        values.jwtToken = data?.token;
        const  result = await axios.post(AUTH_URL + '/userMgmt/changePassword', values); 
        return result?.data;
    } else {
        return "";
    } 
};


export {authServices};