import React from 'react';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import NotificationBirthday from "./NotificationBirthday";
import NotificationInvitation from "./NotificationInvitation";
import NotificationSharedPost from "./NotificationSharedPost";
import NotificationPost from "./NotificationPost";
import {notifications} from "./fake-data";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import {CardActions, ThemeProvider} from "@mui/material";
import {useJumboHeaderTheme, useJumboTheme} from "@jumbo/hooks";
import { connect } from "react-redux";
import NotificationList from './NotificationsList';

const NotificationsDropdown = (props) => {
    const {notification} = props
    const {theme} = useJumboTheme();
    const { headerTheme } = useJumboHeaderTheme(); 
    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <ThemeProvider theme={headerTheme}>
                        {notification && parseInt(notification?.totalcount) > 0 ? 
                            <JumboIconButton badge={{variant: "dot"}} elevation={25} style={{background: '#fff'}}>
                             <NotificationImportantOutlinedIcon sx={{fontSize: '1.25rem'}}/>
                            </JumboIconButton> : 
                             <JumboIconButton elevation={25} style={{background: '#fff'}}>
                             <NotificationImportantOutlinedIcon sx={{fontSize: '1.25rem'}}/>
                              </JumboIconButton> 
                        }
                       
                    </ThemeProvider>
                }
                disableInsideClick
            >
                <Div sx={{width: 360, maxWidth: '100%'}}>
                    <CardHeader
                        title={"Notifications"}
                        // action={<IconButton sx={{my: -1}}><MoreHorizIcon/></IconButton>}
                    />
                                     
                   <NotificationList />
                </Div>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };

export default connect(mapStoreStateToProps)(NotificationsDropdown);
