import React from 'react';
import {Collapse, ListItemAvatar,  ListItemText, Tooltip, Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Span from "@jumbo/shared/Span";
import MailDetail from "../MailDetail";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import {useParams} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";  
import Div from "@jumbo/shared/Div";
import moment from 'moment'; 
import {useJumboTheme} from "@jumbo/hooks";
import EditIcon from "@mui/icons-material/Edit";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'; 
import ChatIcon from '@mui/icons-material/Chat';
import { USERROLECODE } from '../../../../../utils/constants/constants'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const MailItem = ({mailItem, profilerole}) => {
    const {theme} = useJumboTheme(); 
    const  showMessage = false ;
    const {category} = useParams();
   
    return (
        <React.Fragment>
            {
                showMessage && (
                    <Collapse in={showMessage}>
                        <MailDetail/>
                    </Collapse>
                )
            }
            {
                <React.Fragment>
                    <JumboListItem
                        componentElement={"div"}
                        itemData={mailItem}
                        sx={{
                            cursor: 'pointer',
                            borderTop: 1,
                            borderTopColor: 'divider',

                            '&:hover': {
                                bgcolor: 'action.hover',

                                '& .ListAction': {
                                    width: {sm: '100%'},
                                    opacity: {sm: 1}
                                },

                                '& .ListTextExtra': {
                                    visibility: {sm: 'hidden'},
                                    opacity: {sm: 0},
                                }
                            },

                            '& .MuiListItemIcon-root': {
                                 minWidth: 48
                            },

                            [theme.breakpoints.down('sm')]: {
                                flexWrap: 'wrap'
                            }
                        }}
                    >
                        {category === 'uploadFiles' && (
                            <ListItemAvatar sx={{ display: { xs: 'none', md: 'block' }}}>
                                <Avatar alt={mailItem?.from?.name} src={mailItem?.from?.profile_pic} style= {{overflow: 'initial'}}/>
                            </ListItemAvatar>
                        )}

                        {(category === 'submissions' || category === 'students')  && (
                        <ListItemAvatar sx={{ display: { xs: 'none', md: 'block' }}}>
                            <Avatar alt={mailItem?.from?.name} src={mailItem?.from?.profile_pic}/>
                        </ListItemAvatar>
                        )}
                        {category !== 'forum' && (
                        <ListItemText                          
                            primary={
                                <Typography variant={"body1"} component={"div"}>
                                    <Typography variant={"h6"}>{mailItem?.from?.name}</Typography>
                                    <Typography
                                        variant={"body1"}
                                        color={'text.secondary'}                                        
                                    >
                                        {mailItem?.subject} {mailItem.description ? '|' : '' } {mailItem?.description}
                                    </Typography>                                   
                                </Typography>
                            }
                            sx={{flex: 1}}
                        /> )}
                        {category === 'forum' && (
                        <ListItemText                          
                            primary={
                                <Typography variant={"body1"} component={"div"}>
                                    <Typography variant={"h6"} style={{color: '#007FFF', fontSize: '16px'}}>{mailItem?.from?.name}</Typography>
                                    <Typography
                                        variant={"body1"}
                                        color={'text.secondary'}
                                        
                                    >
                                        {mailItem?.subject} {mailItem.description ? '|' : '' } {mailItem?.description}
                                    </Typography>
                                    <Typography   variant={"body1"}
                                        style={{color: '#000'}}>
                                        {mailItem?.visibledate}
                                    </Typography>
                                </Typography>
                            }
                            sx={{flex: 1}}
                        /> )}
                        <Div
                            sx={{
                                width: 160,
                                display: 'flex',
                                flexShrink: '0',
                                position: 'relative',
                                justifyContent: 'flex-end',

                                [theme.breakpoints.down('sm')]: {
                                    width: '100%',
                                    justifyContent: 'space-between'
                                }
                            }}
                        >
                            <Div
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    flex: 1,
                                    transition: 'all 0.5s ease',

                                    [theme.breakpoints.down('sm')]: {
                                        justifyContent: 'flex-start',
                                        ml: 6
                                    }
                                }}
                                className={'ListTextExtra'}
                            >
                               {category !== 'students' && category !== 'forum' && (
                                <Typography
                                    variant={'body1'}
                                    color={'text.secondary'}
                                    ml={1}
                                >
                                    {moment(mailItem?.date).format("MMMM DD HH:mm")}
                                </Typography>
                               )}
                                {category === 'forum' && (
                                 <div style={{ display: 'flex' }}><ChatIcon style={{ marginRight: '5px' }} />
                                     <Typography variant={"body1"} mb={3}> {mailItem?.discussion}</Typography>
                                 </div>                             
                               )}
                            </Div>
                            <Div
                                className='ListAction'
                                sx={{
                                    display: 'flex',

                                    [theme.breakpoints.up('sm')]: {
                                        position: 'absolute',
                                        top: '50%',
                                        width: 0,
                                        opacity: 0,
                                        overflow: 'hidden',
                                        transition: 'all 0.5s ease',
                                        justifyContent: 'flex-end',
                                        transform: 'translateY(-50%)',
                                    }
                                }}
                            >
                                <Span sx={{height: 36, overflow: 'hidden'}}>
                                    {category === 'students' && (
                                    <><Tooltip title="Expel">
                                        <IconButton >
                                            <CancelOutlinedIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Tooltip> 
                                    <Tooltip title="chat">
                                        <IconButton >
                                            <ChatIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Tooltip> </>)}
                                    {category === 'submissions' && (
                                    <Tooltip title="Download">
                                        <IconButton >
                                            <CloudDownloadIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Tooltip> )}
                                    {category === 'uploadFiles' && Number(profilerole) === USERROLECODE.tutor && (
                                    <Tooltip title={"Edit"}>
                                        <IconButton>
                                            <EditIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Tooltip> )}
                                    {category === 'uploadFiles' &&  Number(profilerole) === USERROLECODE.tutor && (
                                    <Tooltip title="Delete">
                                        <IconButton>
                                            <DeleteIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Tooltip> )}
                                
                                </Span>
                            </Div>
                        </Div>

                    </JumboListItem>
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default MailItem;
