import React from 'react';
import {Avatar, ListItemAvatar, ListItemText, Typography, Grid} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ListItemButton from "@mui/material/ListItemButton";
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'; 
import PaymentsSharpIcon from '@mui/icons-material/PaymentsSharp'; 
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import Div from "@jumbo/shared/Div";
const TransactionItem = ({ item }) => {
    let bgclr = "#38529A";
    if (item.transType === "Buy") {
        bgclr = "#38529A";
    } else if (item.transType === "Topup") {
        bgclr = "#17A9FC";
    } else {
        bgclr = "#CC4BB7";
    }
    return (
        <ListItemButton component={"li"} sx={{p: theme => theme.spacing(1, 3)}}>
            <Grid container spacing={3}>
                <Grid item xs={5} md={5} lg={5} style={{display: 'flex'}}>
                <ListItemAvatar>
                    <Avatar alt={item.transName} sx={{color: 'common.white', bgcolor: `${bgclr}`}}>
                        {item.transType === "Buy" ? <AutoStoriesOutlinedIcon /> : item.transType === "Topup" ? <AddCardRoundedIcon/> : <PaymentsSharpIcon/> }
                    </Avatar>
                </ListItemAvatar>
                <ListItemText sx={{
                    flexBasis: '30%'
                    }} primary={<Typography variant='h5' mb={.5}>{item.transName}</Typography>}
                            secondary={item.createddatetime}
                />  
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                <ListItemText>
                    <Grid container>
                        <Grid item xs={11} sm={11} lg={11} style={{textAlign:'right'}}>
                        <Typography variant='body1' component={"span"}>$ {item.totalamt}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} lg={1}>
                        {item.transactionType === 'Credit' ?
                        <ArrowUpwardIcon color='success' sx={{ml: 1, verticalAlign: 'middle'}} fontSize={"small"}/> :
                        <ArrowDownwardIcon color='error' sx={{ml: 1, verticalAlign: 'middle'}} fontSize={"small"}/>
                        }
                        </Grid>
                    </Grid>                    
                </ListItemText>  
                </Grid>
                <Grid item xs={3} md={3} lg={3} style={{textAlign: 'right'}}>
                <ListItemText  sx={{flexGrow: 0, verticalAlign: 'middle'}} primary={<Typography variant="body1" sx={{fontSize: 13}}>$ {item.balance}</Typography>}
                            
                />
            </Grid>
           </Grid>
        </ListItemButton>
    );
};
/* Todo item prop define */

export default TransactionItem;
