import React, { useState} from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import {ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {useNavigate} from "react-router-dom";
import { setChatPerson,setChatPersonProfilePic, setChatPersonProfileID,setChatPersonOnlineStatus} from '../../../../../store/actions';
import { store } from '../../../../../store/store';
const ConversationItem = ({conversationItem}) => { 
    const navigate = useNavigate();
    const [chatId, setChatId] = useState('')
    const handleConversationClick = () => { 
        localStorage.setItem("ChatPerson", conversationItem?.username);
        localStorage.setItem("ChatPersonProfilePic", conversationItem?.profilepic);
        localStorage.setItem("ChatPersonOnlineStatus", conversationItem?.onlineStatus);
        localStorage.setItem("ChatPersonProfileID", conversationItem?._id);
        store.dispatch(setChatPerson(conversationItem?.username));
        store.dispatch(setChatPersonProfilePic(conversationItem?.profilepic)); 
        store.dispatch(setChatPersonOnlineStatus(conversationItem?.onlineStatus)); 
        store.dispatch(setChatPersonProfileID(conversationItem?._id)); 
        navigate(`/chats/conversation/${conversationItem?._id}`);
        setChatId(conversationItem?._id)
    }; 
    React.useEffect(() => {
        setChatId(localStorage.getItem("ChatPersonProfileID") ? localStorage.getItem("ChatPersonProfileID") : '')
      } ) 
    const MINUTE_MS = 60000; 
    React.useEffect(() => {
      const interval = setInterval(() => {
        setChatId('')
      }, MINUTE_MS);
    
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [chatId]) 
    return (
        <List disablePadding>
            <ListItemButton component="li">
                <ListItemAvatar>
                    <Badge
                        overlap="circular"
                        variant="dot"
                        sx={{
                            '& .MuiBadge-badge': {
                                height: 10,
                                width: 10,
                                border: 1,
                                borderColor: 'common.white',
                                borderRadius: '50%',
                                backgroundColor: ( 
                                         conversationItem?.onlineStatus ===  true ? '#72d63a' : '#F7BB07'
                                )
                            }
                        }}
                    >
                        <Avatar alt={conversationItem?.username} src={conversationItem?.profilepic}/>
                    </Badge>
                </ListItemAvatar>
                <ListItemText
                    onClick={handleConversationClick}
                    primary={
                        <Typography component="div" variant="body1" sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Typography variant="h6" mb={.5}
                            fontSize={15}>{conversationItem?.username}</Typography>
                            {(conversationItem?.seencount) > 0 && (conversationItem?._id !== chatId)  && (
                                <Badge badgeContent={conversationItem?.seencount} 
                                color="success" style={{marginLeft: '6%;'}}></Badge>                                
                            )}
                        </Typography>
                    }
                    secondary={
                        <Typography
                            component="div"
                            variant="body1"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                           {conversationItem?.last_message && (
                            <Typography
                                noWrap
                                variant="body1"
                                color={"text.secondary"}
                                fontSize={13}
                            >{conversationItem?.last_message}</Typography> )}
                        </Typography>
                    }
                />
            </ListItemButton>
            <Divider component="li"/>
        </List>
    );
};
/* Todo conversationItem prop define */
export default ConversationItem;
