import React from 'react';
import ChatAppProvider from "./ChatAppProvider";
import ChatAppLayout from "./ChatAppLayout";
import { useTranslation } from "react-i18next";  
import Card from "@mui/material/Card";    
import { store } from "../../../store/store";
import { setMenuName } from '../../../store/actions';
const ChatApp = () => {
    const { t } = useTranslation(); 
    store.dispatch(setMenuName(t('sidebar.menu.chat')));   
    return (
        <>
            <React.Fragment> 
                {/* <div style={{ display: 'flex' }}>
                    <ChatOutlinedIcon style={{ marginRight: '5px', marginTop: '2.5px' }} />
                    <Typography variant="h1" mb={3}>{t('sidebar.menu.chat')}</Typography>
                </div>  */}
                <Card style={{height:'500px'}}> 
                    <ChatAppProvider>
                        <ChatAppLayout />
                    </ChatAppProvider> 
                </Card>
            </React.Fragment> 
           
       </>
    );
};

export default ChatApp;
