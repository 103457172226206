import React, {useEffect}  from 'react';
import ContentPlaceholder from "./ActiveConversationChat/ContentPlaceholder";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import ActiveConversationHeader from "./ActiveConversationHeader";
import ConversationChatGroupByDate from "./ActiveConversationChat/ConversationChatGroupByDate";
import {useParams} from "react-router-dom";
import Div from "@jumbo/shared/Div";
import {CircularProgress, Typography} from "@mui/material";
import io from "socket.io-client";
import {useMutation} from "react-query";
import {TextField} from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'; 
import { v4 as uuidv4 } from "uuid";
import { BASE_AUTH_URL } from "../../../../../utils/constants/paths";
import { store } from '../../../../../store/store';
import {  useQuery } from "react-query";
import { classServices } from "../../../../../services/apis/classes";  
import { authServices } from "../../../../../services/auth/user";
import moment from 'moment';
import { setRefreshChat } from '../../../../../store/actions';
import { connect } from "react-redux";
import { setChatPerson, setChatPersonProfilePic, setChatPersonProfileID, setChatPersonOnlineStatus, setNotificationCount } from '../../../../../store/actions'; 
import {makeStyles} from "@mui/styles";  
const socket = io.connect(BASE_AUTH_URL);
const useStyles = makeStyles(theme => ({ 
    tutor_backgroundcolor : {
        '& .CmtLayout-main':{
            backgroundColor: 'white !important',
        }
    }
    
   
}));
const ActiveConversation = React.forwardRef((props) => {
    const classes = useStyles();
    const scrollbarRef = React.useRef();
    const {chatBy, id} = useParams();
    const [message, setMessage] = React.useState('');
    const loginDetails = store.getState().loginDetails;
    const chatpersonid = localStorage.getItem("ChatPersonProfileID");    
    const chatperson = store.getState().chatperson;    
    const [chatToken, setChatToken] = React.useState('')
    const userDetails = [loginDetails ? loginDetails.userCode : 0, chatpersonid ? chatpersonid : 0, loginDetails ? loginDetails.userCode : 0];  

    const userDetails1 = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0, 0, 10]; 
    const {data: myData  } = useQuery(
        userDetails1, classServices.notificationList
    ); 
    React.useEffect(() => {  
        store.dispatch(setNotificationCount(myData));  
    }, [myData]);

    const {data: result } = useQuery(
        userDetails, classServices.getMsg
    );
    const [messageList, setMessageList] = React.useState([]);
    React.useEffect(() => { 
        store.dispatch(setChatPerson(localStorage.getItem("ChatPerson")));
        store.dispatch(setChatPersonProfilePic(localStorage.getItem("ChatPersonProfilePic"))); 
        store.dispatch(setChatPersonOnlineStatus(localStorage.getItem("ChatPersonOnlineStatus"))); 
        store.dispatch(setChatPersonProfileID(localStorage.getItem("ChatPersonProfileID")));  
    })
    React.useEffect(() => { 
        if (result) { 
            setMessageList(result.mesageList); 
            if(result.chatToken) {
                //join room for instant messaging
                setChatToken(result.chatToken)
                socket.emit("join_room", result.chatToken);
            }
        }    
    }, [result])
    useEffect(() => {
        var messageBody = document.getElementsByClassName('conversation-scroll');
        if (messageBody && messageList?.length > 0 &&  document.getElementsByClassName('conversation-scroll')[0]) {
            document.getElementsByClassName('conversation-scroll')[0].scrollTop = document.getElementsByClassName('conversation-scroll')[0].scrollHeight ? document.getElementsByClassName('conversation-scroll')[0].scrollHeight : 0;
        }
      }, [messageList]);
    const addMessageMutation = useMutation(classServices.addMsg, {
        onSuccess: (data) => {             
            if (data?.statusFlag === 1) {
                store.dispatch(setRefreshChat(true)); 
           }
        },
        onError() {
        },
    });

    const handlekeyPress = (e) => {
        if(e.keyCode == 13) {
            onSendMessage()
        }
    }
    const bindDate = () => {
        if (messageList && messageList.length > 0) {
            let check = messageList.find(t => t.createddate === moment(new Date()).format('DD-MM-YYYY'))
            if (check) {
                return ''
            } else {
               return moment(new Date()).format('DD-MM-YYYY')
            }
        } else {
            return moment(new Date()).format('DD-MM-YYYY')
        }
    }
    const onSendMessage = async () => {
      if (message !== "") {
        const messageData = {
            senderId: loginDetails ? loginDetails.userCode : 0,
            receiverId: chatpersonid ? chatpersonid : 0,
            createddate: bindDate(),
            createdtime: moment(new Date()).format("HH:mm"),
            message: message          
        };
        
        const socketData = {
            room: chatToken,
            author: chatperson,
            message: message,           
            senderId: loginDetails ? loginDetails.userCode : 0,
            receiverId: chatpersonid ? chatpersonid : 0,
            createddate: bindDate(),
            createdtime: moment(new Date()).format("HH:mm"),
          };
    
        await socket.emit("send_message", socketData);
        addMessageMutation.mutate({
            senderId: loginDetails ? parseInt(loginDetails.userCode) : 0,
            receiverId: Number(chatpersonid),           
            message: message, 
            userRoleCode: loginDetails ? parseInt(loginDetails.roleCode) : 0,               
            ipAddress: await authServices.getMyIpAddress()
        });
        setMessageList((list) => [...list, messageData]);
          setMessage("");
          document.getElementsByClassName('conversation-scroll')[0].scrollTop = document.getElementsByClassName('conversation-scroll')[0].scrollHeight;
        // var messageBody = document.getElementsByClassName('conversation-scroll');
        // if (messageBody) {
        //   messageBody.scrollTop = messageBody.scrollHeight;
        // }
      }
    };

    //Receive socket message
    useEffect(() => {
      socket.on("receive_message", (data) => {
        store.dispatch(setRefreshChat(true)); 
        setMessageList((list) => [...list, data]);     
      });
    }, [socket]);

    // if (isLoading) {
    //     return (
    //         <Div
    //             sx={{
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //                 p: theme => theme.spacing(3),
    //                 m: 'auto',
    //             }}
    //         >
    //             <CircularProgress/>
    //             <Typography variant={'h6'} color={'text.secondary'} mt={2}>Loading messages</Typography>
    //         </Div>
    //     )
    // }
    if (!id) {
        return (
            <React.Fragment className={classes.mainframe}>
                <ContentPlaceholder/>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment className={classes.mainframe}>
                <ActiveConversationHeader/>
                <JumboScrollbar
                    ref={scrollbarRef}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}
                    style={{ minHeight: 200 }}
                    classname="conversation-scroll"
                >
                    <ConversationChatGroupByDate conversationMessages = {messageList} />
                </JumboScrollbar>
                {/* <ActiveConversationFooter/> */}
                <Div
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: theme => theme.spacing(2, 3),
                    borderTop: 1,
                    borderTopColor: 'divider',
                    bgcolor: theme => theme.palette.action.hover,
                }}
            >
                <TextField
                    value={message} 
                    onChange={(e) => setMessage(e.target.value)}
                    size={'small'}
                    autoComplete="off"
                    placeholder={"Type message...."}
                    onKeyDown={handlekeyPress}
                    fullWidth
                    sx={{
                        backgroundColor: theme => theme.palette.background.paper,
                        mr: '14px'
                    }}
                />
            <SendOutlinedIcon style={{ fontSize: '25px', cursor: 'pointer' }} onClick={onSendMessage}  />
            </Div>
            </React.Fragment>
        );
    }

});

const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
};  

export default connect(mapStoreStateToProps)(ActiveConversation);
