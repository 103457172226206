import React, { useState} from 'react';
import {Typography} from "@mui/material";  
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button"; 
import Grid from "@mui/material/Grid";  
import {useTranslation} from "react-i18next"; 
import Link from '@mui/material/Link';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails'; 
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import KeyIcon from '@mui/icons-material/Key';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { USERROLECODE } from '../../../utils/constants/constants'
import { store } from '../../../store/store';
import {  useQuery } from "react-query";
import { classServices } from "../../../services/apis/classes";  
import {Link as RouterLink} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { connect } from "react-redux";
import {
    setIdentity,
    setRoomId,
  } from "../../../store/actions";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Conferencing = (props) => { 
    const { setIdentityAction, setRoomIdAction } = props; 
    const {t} = useTranslation();
    const [expanded, setExpanded] = useState(false); 
     const [showPassword, setShowPassword] = useState(false);
    const profilerole = Number(localStorage.getItem("switchprofile"));
    const loginDetails = store.getState().loginDetails;
    const classDetails = store.getState().classDetails;
    const userDetails = [loginDetails ? loginDetails.userCode : 0, loginDetails ? loginDetails.roleCode : 0,classDetails ? Number(classDetails.classCode) : 0];  
    const navigate = useNavigate();
    const handleChange = (panel,items) => (event, newExpanded) => { 
        if (items?.classstatus !== "Completed") {
            setExpanded(newExpanded ? panel : false);
        } 
    };   
    const {data: result, refetch: refetchConferenceList } = useQuery(
        userDetails, classServices.conferenceList
    );  
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    
    const MINUTE_MS = 60000;

    React.useEffect(() => {
      const interval = setInterval(() => {
        //   console.log('Logs every minute');
          refetchConferenceList().catch(console.error) 
      }, MINUTE_MS);
    
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [refetchConferenceList])

    //meetingDetails api
    const meetingDetails = useMutation(classServices.meetingDetails, {
        onSuccess: (res) => {
            if (res?.joinDetails) {
                setIdentityAction(res?.joinDetails[0]?.userName);
                setRoomIdAction(res?.joinDetails[0]?.roomId);     
                navigate("/room");
                // window.open('/room', '_blank', 'noopener,noreferrer');
            }
        },
        onError: (error) => {
        }
    });

    
    return (
            <Grid container>
                <Grid xs={12} style={{margin: '0 3%'}}>
                <Div sx={{flex: 1}}>
                <Grid container>               
                    <Grid xs={8}>                    
                        <div style={{ display: 'flex' }}><CastForEducationIcon style={{ marginRight: '5px' }} />
                            <Typography variant="h1" mb={3}>  {t('class.conferencing')}</Typography>
                        </div>                   
                    </Grid>                   
                </Grid>
                    {result && result?.conferenceList?.length > 0 && result.conferenceList.map((item, index) => {
                        const disableval = index === 0 && item?.currentstatus === "enable" ? true : false;
                        const routepath = '/join-room?code=' + classDetails?.classCode +'&logcode='+loginDetails?.userCode+'&price='+item?.classLessionPrice+'&lesson='+item?.lesson;
                        return (
                            <Accordion expanded={(index === 0 && item?.classstatus !== "Completed") ? 'panel' + index : expanded === ('panel' + index)} onChange={handleChange('panel' + index,item)}>
                                <AccordionSummary aria-controls="panel1d-content" id={"panel" + index} style={item?.classstatus === "Completed" ? { background: "#befde3" } : { background: "none" }}>
                                    <Grid container>
                                        <Grid xs={6}><Typography>{item?.title}{item?.lesson}</Typography></Grid>
                                        <Grid xs={6} style={{ textAlign: 'right' }}><Typography>{item?.start}{' , '} {item?.starttime} {'-'} {item?.endtime}</Typography></Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'none', marginBottom: '1%' }}>
                                        <LinkOutlinedIcon style={{ marginRight: '10px', fontSize: '18px', marginTop: '3px' }} />
                                        <Link href="https://www.twilio.com/docs/video/tutorials"> https://www.twilio.com/docs/video/tutorials</Link>
                                    </div>
                                    <div style={{ display: 'none', marginBottom: '1%' }}>
                                        <KeyIcon style={{ marginRight: '10px', fontSize: '18px', marginTop: '2px' }} />
                                        {showPassword ? <Typography>7BvKjGx7L$0s</Typography> :
                                            <Typography style={{ fontSize: 'x-large', marginTop: '-11px' }}>..........</Typography>}
                                        {showPassword ? (
                                            <VisibilityOff style={{ fontSize: '18px', cursor: 'pointer', marginLeft: '1%', marginTop: '2px' }} onClick={handleClickShowPassword} />
                                        ) : (
                                                <Visibility style={{ fontSize: '18px', cursor: 'pointer', marginLeft: '1%', marginTop: '2px' }} onClick={handleClickShowPassword} />
                                            )}
                                    </div>
                                    {profilerole === USERROLECODE.tutor && item?.classstatus !== "Completed"  && (
                                        <div style={{ textAlign: 'right' }}>
                                            {disableval ? 
                                            <Link  underline={"none"} target="_blank" component={RouterLink} to={routepath}>
                                            <Button
                                                disabled={!disableval}
                                                disableElevation
                                                variant={"contained"}
                                                startIcon={<PlayCircleOutlineOutlinedIcon />}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: '1.5rem'
                                                    }
                                                }}
                                                style={{ textTransform: 'none' }}
                                                // onClick={(e) => handleJoinClass(e)}
                                                >Start Class</Button>
                                            </Link>:
                                            <Button
                                                disabled={!disableval}
                                                disableElevation
                                                variant={"contained"}
                                                startIcon={<PlayCircleOutlineOutlinedIcon />}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: '1.5rem'
                                                    }
                                                }}
                                                style={{ textTransform: 'none' }}
                                                // onClick={(e) => handleJoinClass(e)}
                                                >Start Class</Button>}
                                            
                                        </div>)}
                                    {profilerole === USERROLECODE.student && item?.classstatus !== "Completed" && (
                                        <div style={{ textAlign: 'right' }}>
                                            {disableval ?  
                                            <Link underline={"none"} target="_blank"  component={RouterLink}  to={routepath}>
                                            <Button
                                                disabled={!disableval}
                                                disableElevation
                                                variant={"contained"}
                                                startIcon={<PlayCircleOutlineOutlinedIcon />}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: '1.5rem'
                                                    }
                                                }}
                                                style={{ textTransform: 'none' }}
                                                // onClick={(e) => handleJoinClass(e)}
                                            > {t('class.joinClass')}</Button>
                                            </Link> 
                                            : <Button
                                                disabled={!disableval}
                                                disableElevation
                                                variant={"contained"}
                                                startIcon={<PlayCircleOutlineOutlinedIcon />}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: '1.5rem'
                                                    }
                                                }}
                                                style={{ textTransform: 'none' }}
                                                // onClick={(e) => handleJoinClass(e)}
                                            > {t('class.joinClass')}</Button> }
                                        </div>
                                    )}
                                </AccordionDetails>
                            </Accordion>)
                         }
                        )} 
                     </Div>
                </Grid>
            </Grid>
    );
};

const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setIdentityAction: (identity) => dispatch(setIdentity(identity)),
      setRoomIdAction: (id) => dispatch(setRoomId(id)),
    };
  };
  
export default connect(mapStoreStateToProps, mapDispatchToProps)(Conferencing);

